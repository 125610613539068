import Border from 'components/common/border/Border';
import FormControl from 'components/common/form-control/FormControl';
import GradientLoader from 'components/common/loading/gradient/GradientLoader';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import usePoint from 'hooks/feature/point/usePoint';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  selectedPointOption: CommonOptionType | undefined;
  setSelectedPointOption: Dispatch<SetStateAction<CommonOptionType | undefined>>;

  refetchCategoryUsage: (pointId?: string) => Promise<void>;

  setLoading: Dispatch<SetStateAction<boolean>>;
};

const CategoryBuildingFilter = ({
  selectedPointOption,
  setSelectedPointOption,
  refetchCategoryUsage,
  setLoading,
}: Props) => {
  const [points, setPoints] = useState<Point[]>([]);
  const [filterLoading, setFilterLoading] = useState<boolean>(true);

  const { getMyPoints } = usePoint();
  const location = useLocation();

  // 포인트 옵션 설정
  const initSelectedOptions = async (points: Point[]) => {
    if (location.state) {
      setSelectedPointOption({ label: location.state.pointName, value: location.state.pointId });
      await refetchCategoryUsage(location.state.pointId);
      setLoading(false);
      return;
    }

    // 포인트가 1개일 경우 해당 포인트 선택
    if (points.length === 1) {
      await refetchCategoryUsage(points[0].id);
      setSelectedPointOption({ label: points[0].name.ko, value: points[0].id });
    }
    // 포인트가 1개 이상일 경우 전체 포인트 선택
    else {
      await refetchCategoryUsage();
      setSelectedPointOption({ label: '전체', value: '' });
    }

    setLoading(false);
  };

  // 포인트 변경 시
  const onChangePoint = async (option: CommonOptionType) => {
    setLoading(true);

    setSelectedPointOption(option);

    // 선택한 포인트의, 전체 층
    await refetchCategoryUsage(option.value);

    setLoading(false);
  };

  // 빌딩 목록 조회
  const fetchPoints = async () => {
    setFilterLoading(true);
    const points = await getMyPoints();

    if (points) {
      setPoints(points);
    }

    setFilterLoading(false);
  };

  useEffect(() => {
    fetchPoints();
  }, []);

  useEffect(() => {
    if (points && points.length > 0) {
      initSelectedOptions(points);
    }
  }, [points, location.state]);

  return (
    <>
      {filterLoading ? (
        <div className='w-full h-[60px]'>
          <GradientLoader />
        </div>
      ) : (
        // 빌딩 - 2개부터 빌딩 선택 노출
        points.length > 1 && (
          <>
            <div className='flex flex-col gap-6'>
              <div className='flex gap-5'>
                <FormControl
                  name='빌딩'
                  labelSize='none'
                  control={
                    <CommonSelectBox
                      options={[
                        { label: '전체', value: '' },
                        ...points.map(point => {
                          return {
                            label: point.name.ko,
                            value: point.id,
                            data: point,
                          };
                        }),
                      ]}
                      disabled={points.length === 1}
                      selected={{
                        label: selectedPointOption?.label || '',
                        value: selectedPointOption?.value || '',
                        data: selectedPointOption?.data,
                      }}
                      onChangeSelect={onChangePoint}
                      width={200}
                    />
                  }
                />
              </div>

              <Border />
            </div>
          </>
        )
      )}
    </>
  );
};
export default CategoryBuildingFilter;
