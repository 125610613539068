import OuterGnb from 'components/common/layout/gnb/outer/OuterGnb';
import TitleContainer from 'components/common/title-container/TitleContainer';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import InnerGnb from './gnb/inner/InnerGnb';

type Props = {
  children: React.ReactElement;
};

const Layout = ({ children }: Props) => {
  const location = useLocation();

  const currentPath = location.pathname;

  const isNoGnb = currentPath.includes('/guide');

  const isOuterGnb =
    currentPath === RoutePath.plan || currentPath === RoutePath.workspaces || currentPath.includes('/workspaces');

  return (
    <>
      {
        // gnb 필요없는 페이지
        isNoGnb ? (
          <div className='flex flex-col items-center w-full h-full'>
            <div className='fixed left-0 flex flex-col flex-1 w-full h-full'>{children}</div>
          </div>
        ) : // plan 이 있는 workspace 외부 gnb 사용
        // TODO: 이름 변경
        isOuterGnb ? (
          <div className='flex flex-col items-center w-full h-full'>
            <OuterGnb />

            <div className='w-full h-full min-h-screen mt-20 overflow-auto bg-bg-f9'>{children}</div>
          </div>
        ) : (
          // workspace 내부 gnb 사용
          <div className='flex flex-col items-center justify-center w-full h-full bg-bg-f9 px-[60px]'>
            <InnerGnb />

            <div className='max-w-[1400px] w-full h-full gap-5 flex flex-col items-center justify-start min-h-screen flex-1 pt-[120px] pb-10'>
              <TitleContainer />

              <div className='w-full h-full'>{children}</div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default Layout;
