import { TextType } from 'components/common/title/Title';
import { IMAGES } from 'constants/images';
import { FLEX_CENTER } from 'styles/flex';

const LoadingMap = () => {
  return (
    <div className={`bg-gray-cc bg-opacity-40 z-40  w-full h-full ${FLEX_CENTER.column} gap-5 absolute top-0 left-0`}>
      <div>
        <img src={IMAGES.LOADING} alt='loading' />
      </div>
      <div className={`${TextType.body1} text-gray-99`}>지도를 불러오고 있습니다.</div>
    </div>
  );
};

export default LoadingMap;
