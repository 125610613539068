import { ICON } from 'constants/icons';
import React from 'react';
import { DEFAULT_TEXT } from 'styles/text';

const HolidayGuide = () => {
  return (
    <div className='h-9 flex gap-2.5 items-center'>
      <img src={ICON.INFO} alt='info' />
      <span className={DEFAULT_TEXT}>
        <span className='font-h5_bold text-h4 text-gray-44'>한달에 최대 4일</span>
        <span className='text-gray-77'>까지만 선택 가능합니다.</span>
      </span>
    </div>
  );
};

export default HolidayGuide;
