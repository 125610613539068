import { AxiosError } from 'axios';
import AlertModal from 'components/common/modal/alert/AlertModal';
import { TextType } from 'components/common/title/Title';
import LimitLength from 'components/common/word-length/LimitLength';
import { ICON } from 'constants/icons';
import useModal from 'hooks/common/useModal';
import useTenant from 'hooks/feature/tenant/useTenant';
import { ChangeEvent, useEffect, useState } from 'react';
import useTenantToastStore from 'stores/tenantToast';
import { Language, MultiLangs } from 'types/feature/multi-lang/language';
import { Tenant } from 'types/feature/tenant/tenant';
import SaveEditBtns from './save-edit-btns/SaveEditBtns';

type Props = {
  tenantId: string;
  contentKey: string;
  tenantName: MultiLangs;
  title: string;
  tenant: Tenant | undefined;
  languageList: Language[];
  fetchTenant: () => Promise<void>;
};

const TenantMultiLangInput = ({
  tenantId,
  languageList,
  tenant,
  title,
  tenantName,
  contentKey,
  fetchTenant,
}: Props) => {
  // state
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [titleContent, setTitleContent] = useState<MultiLangs>({
    ko: tenantName.ko,
    en: tenantName.en,
    ja: tenantName.ja,
    cn: tenantName.cn,
    tw: tenantName.tw,
  });

  // hook
  const { openAlert, openModalPopup, closeModalPopup } = useModal();
  const { patchTenant } = useTenant();

  // store
  const tenantToastStore = useTenantToastStore();

  const handleShowEditBtn = () => {
    if (isEditMode) return;
    setShowEditMode(true);
  };

  // 수정 버튼 클릭 시
  const onClickEditBtn = () => {
    setShowEditMode(false);
    setIsEditMode(true);
  };

  // input 일 경우
  const onChangeMultiLangInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setTitleContent((prev: MultiLangs) => ({ ...prev, [name]: value }));
  };

  // textarea 일 경우
  const onChangeMultiLangTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    setTitleContent((prev: MultiLangs) => ({ ...prev, [name]: value }));
  };

  const updateContent = async () => {
    try {
      await patchTenant(tenantId, { [contentKey]: titleContent });
      await fetchTenant();

      // 토스트 팝업
      tenantToastStore.setIsToastOpen(true);
      tenantToastStore.setToastPopupMessage('매장 정보가 저장되었습니다.');

      setIsEditMode(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          openModalPopup('ALERT');
        }
      }
    }
  };

  useEffect(() => {
    setTitleContent((prev: MultiLangs) => ({
      ...prev,
      ko: tenantName.ko,
      en: tenantName.en,
      ja: tenantName.ja,
      cn: tenantName.cn,
      tw: tenantName.tw,
    }));
    setIsEditMode(false);
  }, [tenantId]);

  const isDesc = contentKey === 'description';

  return (
    <div
      onFocus={handleShowEditBtn}
      onMouseOver={handleShowEditBtn}
      onMouseLeave={() => setShowEditMode(false)}
      onClick={onClickEditBtn}
      className={`relative flex flex-col items-end w-full h-full gap-2 py-2 px-5 ${showEditMode && 'bg-bg-f9'}`}
    >
      <div className={`flex items-start h-full w-full`}>
        {/* 타이틀 */}
        <div className={`text-[12px] font-bold text-gray-77 w-20 h-9 flex items-center justify-start`}>{title}</div>

        {/* 내용 */}
        <div className={`flex flex-col items-center justify-end gap-2 w-full h-full`}>
          {languageList.map(language => {
            // const multiLangLimit = TenantTitleWordLimit[language.code.toLowerCase()];

            // 타이틀 글자수 제한
            const TITLE_LIMIT = 20;

            // 상세설명 글자수 제한
            const DESC_LIMIT = 150;
            return (
              <div key={language.id} className={`flex items-start justify-start gap-2.5 w-full h-auto`}>
                {/* 언어 코드 (key) */}
                <div className='flex items-center justify-center h-9'>
                  <div
                    className={`flex items-center justify-center bg-[#999] text-white w-[22px] text-[11px] h-[16px]`}
                  >
                    {language.code}
                  </div>
                </div>

                <div
                  className={`flex-1 w-full shrink-0
                ${!isEditMode && 'h-auto'} 
                ${isEditMode && isDesc && 'h-[94px]'} 
                ${isEditMode && !isDesc && 'h-9'} `}
                >
                  {/* 수정일 경우 input 노출 */}
                  {isEditMode ? (
                    // 상세 설명일 경우 textarea 노출
                    isDesc ? (
                      <div
                        className={`flex flex-col h-[94px] w-full items-end ${
                          isEditMode ? 'border border-gray-99 bg-white pt-3 pl-3 pb-2' : 'border-none'
                        }`}
                      >
                        <textarea
                          maxLength={DESC_LIMIT}
                          className={`resize-none w-full h-[60px] border-none 
                      text-gray-44 ${TextType.body1} 
                      placeholder:text-gray-cc placeholder:text-[14px] placeholder:font-body
                        outline-none
                      `}
                          placeholder={`${title}을 입력해주세요.`}
                          name={language.code.toLowerCase()}
                          defaultValue={tenantName && tenantName[language.code.toLowerCase()]}
                          onChange={e => {
                            onChangeMultiLangTextarea(e);
                          }}
                        />

                        <div className='pr-3 h-[14px]'>
                          {/* 글자 수 제한 */}
                          <LimitLength
                            length={titleContent[language.code.toLowerCase()].length}
                            limit={isDesc ? DESC_LIMIT : TITLE_LIMIT}
                          />
                        </div>
                      </div>
                    ) : (
                      // 나머지는 input 노출
                      <div className='flex items-center gap-3 border h-9 border-gray-99 w-[100%] bg-white px-3'>
                        <input
                          maxLength={TITLE_LIMIT}
                          className={`h-full w-full border-0 placeholder:text-gray-cc placeholder:font-body text-gray-44 ${TextType.body1}`}
                          placeholder={`${title}을 입력해주세요.`}
                          name={language.code.toLowerCase()}
                          defaultValue={tenantName && tenantName[language.code.toLowerCase()]}
                          onChange={e => {
                            onChangeMultiLangInput(e);
                          }}
                        />
                        {/* 글자 수 제한 */}
                        <LimitLength
                          length={titleContent[language.code.toLowerCase()].length}
                          limit={isDesc ? DESC_LIMIT : TITLE_LIMIT}
                        />
                      </div>
                    )
                  ) : (
                    // 읽기 모드일 경우 내용 노출
                    <>
                      {tenantName && tenantName[language.code.toLowerCase()] ? (
                        // description 일 경우
                        isDesc ? (
                          <div className='text-gray-44 text-[14px] font-medium flex items-center min-h-9 h-auto'>
                            {tenantName && tenantName[language.code.toLowerCase()]}
                          </div>
                        ) : (
                          // 나머지
                          <div className='text-gray-44 text-[14px] font-medium flex items-center h-9'>
                            {tenantName && tenantName[language.code.toLowerCase()]}
                          </div>
                        )
                      ) : (
                        <div
                          className={`${TextType.body1} text-gray-99 flex items-center h-9`}
                        >{`${title}이 없습니다.`}</div>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* 수정 버튼 */}
        {showEditMode && (
          <div className='absolute bottom-2 right-2'>
            <img src={ICON.PENCIL_WHITE} alt='pencil' />
          </div>
        )}

        {/* 에러 발생 시 알림창 노출 */}
        {openAlert && (
          <AlertModal message={'매장 정보를 저장할 수 없습니다.'} closeModal={() => closeModalPopup('ALERT')} />
        )}
      </div>

      {/* 저장, 취소 버튼 */}
      {!showEditMode && isEditMode && (
        <SaveEditBtns
          onClickCancel={() => {
            setIsEditMode(false);
          }}
          onClickSave={updateContent}
        />
      )}
    </div>
  );
};

export default TenantMultiLangInput;
