import { IndexType } from 'types/common';

export enum FileType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

// export type FileType = keyof typeof FILE_TYPES;

export const FILE_TYPES: IndexType = {
  IMAGE: '이미지',
  VIDEO: '영상',
};

export type CommonFile = {
  uri: string;
  originalName: string;
  size: number;
  width: number;
  height: number;
};

export enum FileExtensionName {
  WEBM = 'video/webm',
  MP4 = 'video/mp4',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
}
