import CustomWordCloud, { WORD_COLORS } from 'components/common/graph/word-cloud/CustomWordCloud';
import Title, { TextType } from 'components/common/title/Title';
import { Word } from 'react-wordcloud';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import { CategoryUsage } from 'types/feature/statistics/categoryUsage';
import { roundUpToNearestTen } from 'utils/legend/makeSixStepsLegend';

type Props = {
  categoryUsageList: CategoryUsage[];
};

const CategoryWordCloud = ({ categoryUsageList }: Props) => {
  const data: Word[] = categoryUsageList.map(usage => ({
    ...usage,
    text: usage.tenantCategoryGroupName.ko,
    value: usage.count,
  }));
  const totalArr = categoryUsageList.map(item => item.count); // 데이터의 max 값을 구한다.
  const maxCount: number = Math.max(...totalArr); // 데이터 최대값
  const legendMax: number = roundUpToNearestTen(maxCount); // 범례 최대값

  return (
    <div className='flex flex-col h-[414px] w-full p-10 bg-white border border-gray-ea gap-10'>
      <div className='flex items-center justify-between h-5'>
        <Title text='인기 카테고리 순위' textType={TextType.h3_bold} />

        {/* 범례 */}
        {categoryUsageList.length > 0 && (
          <div className='flex gap-2.5'>
            {/* 최대값 */}
            <div
              style={{
                color: WORD_COLORS[5],
              }}
              className='text-xs font-semibold text-center leading-[14px]'
            >
              {legendMax > 0 ? legendMax : ''}
            </div>
            {/* 색상 */}
            <div className='flex flex-row-reverse'>
              {WORD_COLORS.map(color => (
                <div
                  key={color}
                  style={{
                    background: color,
                    width: 20,
                    height: 10,
                  }}
                />
              ))}
            </div>
            {/* 최소값 */}
            <div
              style={{
                color: WORD_COLORS[0],
              }}
              className='text-xs font-semibold text-center leading-[14px]'
            >
              0
            </div>
          </div>
        )}
      </div>

      <CustomWordCloud data={data} maxCount={maxCount} height='240px' />
    </div>
  );
};
export default CategoryWordCloud;
