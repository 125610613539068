import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import { TextType } from 'components/common/title/Title';

const DashboardError = ({ onRetry }: { onRetry?: () => Promise<void> }) => {
  return (
    <div className='flex flex-col items-center justify-center h-full gap-2'>
      <div className={`${TextType.h4} text-gray-bf cursor-default`}>데이터를 불러올 수 없습니다.</div>

      <Button onClick={onRetry} color={ButtonColor.gray} size='rounded' text='다시시도' />
    </div>
  );
};
export default DashboardError;
