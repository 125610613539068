import FileTypeIcon from 'components/common/file-type-icon/FileTypeIcon';
import Radio from 'components/common/radio/Radio';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import SelectBox, { OptionType } from 'components/common/select/SelectBox';
import Title, { TextType } from 'components/common/title/Title';
import { SCREEN_TYPE } from 'data/screenType';
import { SCREEN_TYPE_OPTIONS } from 'data/select-box/options';
import useFilterStore from 'stores/filter';
import { FileType } from 'types/common/file/file';
import { WaitingList } from 'types/feature/screensaver/waitingList';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  contents: WaitingList[] | null;
  selectedContent: WaitingList | null;
  handleClickItem: (content: WaitingList) => void;
  onChangeScreenType: (option: OptionType) => void;
  points: Point[];
  onChangePoint: (option: CommonOptionType) => void;
  selectedPoint: CommonOptionType | undefined;
};

const AddModalContents = ({
  contents,
  selectedContent,
  handleClickItem,
  onChangeScreenType,
  points,
  onChangePoint,
  selectedPoint,
}: Props) => {
  const filterStore = useFilterStore();

  return (
    <div className='flex flex-col gap-2.5 w-full px-10'>
      <div className='flex flex-col items-start gap-2.5'>
        <Title text='콘텐츠 선택' textType={TextType.h4_bold} />

        <div className='flex gap-2.5'>
          <CommonSelectBox
            width={160}
            options={points.map(point => ({
              value: point.id,
              label: point.name.ko,
              data: point,
            }))}
            onChangeSelect={onChangePoint}
            selected={{
              label: selectedPoint?.label || '',
              value: selectedPoint?.value || '',
              data: selectedPoint,
            }}
          />

          <SelectBox
            width='w-[160px]'
            placeholder={SCREEN_TYPE[filterStore.scheduleScreenType]}
            options={[...SCREEN_TYPE_OPTIONS]}
            onChangeSelect={onChangeScreenType}
          />
        </div>
      </div>

      <div className='flex w-full gap-5'>
        <section className='flex flex-col gap-2.5 w-[330px] shrink-0'>
          <div className='h-[320px] border border-gray-77 overflow-y-auto'>
            {contents?.map(content => (
              <div
                onClick={handleClickItem.bind(this, content)}
                key={content.id}
                className={`${
                  content?.id === selectedContent?.id && 'bg-state-blue_bg'
                } h-[52px] px-5 flex items-center justify-start border-b border-b-gray-ea gap-4 hover:bg-state-blue_bg cursor-pointer`}
              >
                <Radio
                  value={String(content?.id === selectedContent?.id)}
                  checked={content?.id === selectedContent?.id}
                  onChange={() => {
                    console.log('change');
                  }}
                />
                <span className={`${TextType.body2} shrink-0 text-gray-77`}>{SCREEN_TYPE[content.screenType]}</span>

                <FileTypeIcon fileType={content?.fileType ?? FileType.IMAGE} />

                <span className='shrink-0 text-[14px] text-gray-22 leading-5 truncate text-ellipsis w-[154px]'>
                  {content?.title}
                </span>
              </div>
            ))}
          </div>
        </section>

        <section className='w-full h-[320px] bg-bg-f9 flex flex-col gap-2.5 items-center justify-center'>
          {selectedContent ? (
            <div className='w-[300px] h-[248px] flex items-center justify-center'>
              {selectedContent?.fileType === FileType.IMAGE ? (
                <img className='object-contain h-[248px]' src={selectedContent?.file.uri} alt='thumbnail' />
              ) : (
                <video className='object-contain h-[248px]' src={selectedContent?.file.uri} autoPlay muted controls>
                  <source />
                </video>
              )}
            </div>
          ) : (
            <div className='w-[300px] h-[248px] flex items-center justify-center absolute'>
              <span className={`${TextType.body2} text-gray-99`}>등록된 콘텐츠가 없습니다.</span>
            </div>
          )}

          <div className='h-6'>
            <span className={`${TextType.body2} text-gray-44`}>{selectedContent?.file.originalName}</span>
          </div>
        </section>
      </div>
    </div>
  );
};
export default AddModalContents;
