import { create } from 'zustand';

type PoiContentStoreProps = {
  currentContentId: string | null;
  setCurrentContentId: (poiId: string) => void;
  clearCurrentContentId: () => void;
};

const usePoiContentStore = create<PoiContentStoreProps>((set, get) => ({
  currentContentId: null,
  setCurrentContentId(id) {
    set({ currentContentId: id });
  },
  clearCurrentContentId() {
    set({ currentContentId: null });
  },
}));

export default usePoiContentStore;
