import React from 'react';

type Props = {
  length: number | undefined;
  limit: number;
};

const LimitLength = ({ length, limit }: Props) => {
  return (
    <div className='flex items-center'>
      <span
        className={`${Number(length) > 0 ? 'text-gray-44 font-h3_bold' : 'text-gray-99'}
      text-[10px] leading-4 font-normal
      `}
      >
        {length || 0}
      </span>
      <span className='text-gray-99 text-[10px] leading-4 font-normal'>/</span>
      <span className={`text-gray-99 text-[10px] leading-4 font-normal`}>{limit}</span>
    </div>
  );
};
export default LimitLength;
