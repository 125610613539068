import { TableHeader } from 'types/common/tableHeader';

export const TENANT_USAGE_TABLE_HEADER_WITHOUT_POINT: TableHeader = [
  {
    label: '순위',
    width: 70,
    direction: 'left',
  },
  {
    label: '매장명',
    width: 450,
    direction: 'left',
  },
  {
    label: '층',
    width: 80,
    direction: 'left',
  },
  {
    label: '층별안내 진입수',
    width: 160,
    direction: 'left',
  },
  {
    label: '매장검색 진입수',
    width: 160,
    direction: 'left',
  },
  {
    label: '전체 진입수',
    width: 200,
    direction: 'left',
  },
  {
    label: '길찾기 진행건',
    width: 200,
    direction: 'left',
  },
];
