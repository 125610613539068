import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GradientLoader from 'components/common/loading/gradient/GradientLoader';
import Title, { TextType } from 'components/common/title/Title';
import React from 'react';
import { CONTENTS_BG } from 'styles/background';
import DashboardError from './DashboardError';

export type DashboardSectionProps = {
  title: string;
  onClickMore: () => void;
  width?: string;
  height?: string;
  children: React.ReactElement;
  gap?: string;
  loading?: boolean;
  error?: boolean;
  onRetry?: () => Promise<void>;
  noData?: boolean;
};

const DashboardSection = ({
  title,
  onClickMore,
  width = '100%',
  height = '100%',
  children,
  gap,
  loading,
  error,
  onRetry,
  noData,
}: DashboardSectionProps) => {
  return (
    <div
      style={{
        width,
        height,
        gap,
      }}
      className={`${CONTENTS_BG} px-[60px] py-10 flex flex-col justify-between shrink-0 cursor-default`}
    >
      {/* 제목 */}
      <div className='flex items-center justify-between h-6'>
        <Title text={title} textType={TextType.h3_bold} />

        <Button onClick={onClickMore} text='더보기' color={ButtonColor.secondary} size='more' />
      </div>

      {/* 내용 */}
      {loading ? (
        // 로딩
        <GradientLoader />
      ) : error ? (
        // 에러
        <DashboardError onRetry={onRetry} />
      ) : (
        // 성공
        <div className='h-full'>
          {noData ? (
            <div className={`${TextType.h4} flex items-center justify-center h-full text-gray-bf`}>
              데이터가 없습니다.
            </div>
          ) : (
            <div>{children}</div>
          )}
        </div>
      )}
    </div>
  );
};
export default DashboardSection;
