import useWorkspaceStatus from 'hooks/common/useWorkspaceStatus';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { Fragment, useEffect, useState } from 'react';
import { Workspace } from 'types/feature/workspace/workspace';
import WorkspaceCard from './item/WorkspaceCard';

const WorkspaceListContainer = () => {
  // hook
  const { getWorkspaces } = useWorkspace();
  const { handleWorkspaceStatus } = useWorkspaceStatus();

  // state
  const [workspaces, setWorkspaces] = useState<Workspace[]>();

  const fetchWorkspaces = async () => {
    const result = await getWorkspaces();

    if (!result) return;
    setWorkspaces(result);
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  useEffect(() => {
    if (workspaces) {
      // 워크스페이스 fetch 이후 workspace status 세팅
      handleWorkspaceStatus(workspaces);
    }
  }, [workspaces]);

  return (
    <div className='flex items-center justify-center w-full h-full py-10'>
      <div className='flex flex-col gap-10 w-[1400px] h-full'>
        <div className='grid w-full h-full grid-cols-4 gap-5 large:grid-cols-4 medium:grid-cols-2 small:grid-cols-2'>
          {workspaces?.map(workspace => (
            <Fragment key={workspace.id}>
              <WorkspaceCard workspace={workspace} />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
export default WorkspaceListContainer;
