import Border from 'components/common/border/Border';
import FormControl from 'components/common/form-control/FormControl';
import GradientLoader from 'components/common/loading/gradient/GradientLoader';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import usePoint from 'hooks/feature/point/usePoint';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Point } from 'types/feature/workspace/workspace';
import { sortByLangOrder } from 'utils/sort/sortByLangOrder';
import { TotalUsageFilterType } from '../../TotalUsageStatsContainer';

type Props = {
  selectedPointOption: CommonOptionType | undefined;
  setSelectedPointOption: Dispatch<SetStateAction<CommonOptionType | undefined>>;
  selectedTypeOption: CommonOptionType | undefined;

  refetchTotalUsage: (type: TotalUsageFilterType, pointId?: string) => Promise<void>;

  setLoading: Dispatch<SetStateAction<boolean>>;
};

const BuildingFilter = ({
  selectedPointOption,
  setSelectedPointOption,
  selectedTypeOption,
  refetchTotalUsage,
  setLoading,
}: Props) => {
  const [points, setPoints] = useState<Point[]>([]);
  const [filterLoading, setFilterLoading] = useState<boolean>(true);

  const { getMyPoints } = usePoint();
  const location = useLocation();

  const initSelectedPointOption = async (points: Point[]) => {
    // 전달받은 state 가 있을 경우 (from 대시보드)
    if (location.state) {
      await refetchTotalUsage('date', location.state.pointId);
      setSelectedPointOption({ label: location.state.pointName, value: location.state.pointId });
      setLoading(false);
      return;
    }

    // 포인트가 1개일 경우 해당 포인트 선택
    if (points.length === 1) {
      await refetchTotalUsage('date', points[0].id);
      setSelectedPointOption({ label: points[0].name.ko, value: points[0].id });
    }
    // 포인트가 1개 이상일 경우 전체 포인트 선택
    else {
      await refetchTotalUsage('date', '');
      setSelectedPointOption({ label: '전체', value: '' });
    }

    setLoading(false);
  };

  // 포인트 변경 시
  const onChangePoint = async (option: CommonOptionType) => {
    setSelectedPointOption(option);

    // 선택한 포인트
    await refetchTotalUsage((selectedTypeOption?.value as TotalUsageFilterType) || 'date', option.value);
  };

  // 포인트 목록 조회
  const fetchPoints = async () => {
    setFilterLoading(true);

    const points = await getMyPoints();

    if (points) {
      setPoints(points);
      await initSelectedPointOption(points);
    }

    setFilterLoading(false);
  };

  useEffect(() => {
    fetchPoints();
  }, [location.state]);

  return (
    <>
      {/* 빌딩 */}
      {filterLoading ? (
        <div className='h-[60px]'>
          <GradientLoader />
        </div>
      ) : (
        points.length > 1 && (
          <div className='flex flex-col gap-6'>
            <FormControl
              name='빌딩'
              labelSize='none'
              control={
                <CommonSelectBox
                  options={[
                    { label: '전체', value: '' },
                    ...points
                      .map(point => ({
                        label: point.name.ko,
                        value: point.id,
                        data: point,
                      }))
                      .sort((a, b) => sortByLangOrder(a.label, b.label)),
                  ]}
                  disabled={points.length === 1}
                  selected={{
                    label: selectedPointOption?.label || '',
                    value: selectedPointOption?.value || '',
                    data: selectedPointOption?.data,
                  }}
                  onChangeSelect={onChangePoint}
                  width={200}
                />
              }
            />

            <Border />
          </div>
        )
      )}
    </>
  );
};
export default BuildingFilter;
