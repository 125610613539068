export const LIST_MENU_CONTENT_GUIDES = [
  '목록 이미지는 2MB 이하, 상세 이미지는 5MB 이하의 png, jpeg, jpg 파일만 첨부 가능합니다.',
  '최적 사이즈는 목록 이미지 760*760 / 상세 이미지 1720*2556 입니다.',
  '상세 이미지 세로값이 길 경우, 영역 내에 스크롤이 생성됩니다.',
  '이미지는 영역에 맞게 확대되어 제공됩니다.',
];

export const CARD_MENU_CONTENT_GUIDES = [
  '5MB 이하의 png, jpeg, jpg 파일만 첨부 가능합니다.',
  '최적 사이즈는 1720*2200 입니다.',
  '이미지는 영역에 맞게 확대되어 제공됩니다.',
];
