import HallContainer from 'components/feature/plan/HallContainer';

const PlanPage = () => {
  return (
    <div className='flex items-center justify-center w-full h-full'>
      <HallContainer />
    </div>
  );
};
export default PlanPage;
