import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { CategoryUsage } from 'types/feature/statistics/categoryUsage';
import { MachineUsage } from 'types/feature/statistics/machineUsage';
import { TenantUsage } from 'types/feature/statistics/tenantUsage';
import { TotalUsagePerDate, TotalUsagePerDayOfWeek, TotalUsagePerTime } from 'types/feature/statistics/totalUsage';

const useStatistics = () => {
  const { api } = useApi();

  // 일자별 전체 사용량 조회
  const getUsagePerDate = async (workspaceId: string, pointId?: string, startDate?: string, endDate?: string) => {
    const result = await api.get<ApiResponse<TotalUsagePerDate[]>>(Endpoint.usageStatisticPerDate, {
      params: {
        workspaceId,
        pointId,
        startDate,
        endDate,
      },
    });
    return result.data;
  };

  // 요일별 전체 사용량 조회
  const getUsagePerDayOfWeek = async (workspaceId: string, pointId?: string, startDate?: string, endDate?: string) => {
    const result = await api.get<ApiResponse<TotalUsagePerDayOfWeek[]>>(Endpoint.usageStatisticsPerDayOfWeek, {
      params: {
        workspaceId,
        pointId,
        startDate,
        endDate,
      },
    });
    return result.data;
  };

  // 시간별 전체 사용량 조회
  const getUsagePerTime = async (workspaceId: string, pointId?: string, startDate?: string, endDate?: string) => {
    const result = await api.get<ApiResponse<TotalUsagePerTime[]>>(Endpoint.usageStatisticsPerTime, {
      params: {
        workspaceId,
        pointId,
        startDate,
        endDate,
      },
    });
    return result.data;
  };

  // 기기별 사용량 조회
  const getUsagePerMachine = async (
    workspaceId: string,
    pointId?: string,
    floorId?: string,
    startDate?: string,
    endDate?: string,
  ) => {
    const result = await api.get<ApiResponse<MachineUsage[]>>(Endpoint.machineStatistics, {
      params: {
        workspaceId,
        pointId,
        floorId,
        startDate,
        endDate,
      },
    });
    return result.data;
  };

  // 인기 매장 조회
  const getTenantUsage = async (
    workspaceId: string,
    pointId?: string,
    floorId?: string,
    startDate?: string,
    endDate?: string,
    size?: number,
  ) => {
    const result = await api.get<ApiResponse<TenantUsage[]>>(Endpoint.tenantStatistics, {
      params: {
        workspaceId,
        pointId,
        floorId,
        startDate,
        endDate,
        size,
      },
    });
    return result.data;
  };

  // 인기 카테고리 조회
  const getCategoryUsage = async (workspaceId: string, pointId?: string, startDate?: string, endDate?: string) => {
    const result = await api.get<ApiResponse<CategoryUsage[]>>(Endpoint.categoryStatistics, {
      params: {
        workspaceId,
        pointId,
        startDate,
        endDate,
      },
    });
    return result.data;
  };

  return {
    getUsagePerMachine,
    getUsagePerDate,
    getUsagePerDayOfWeek,
    getUsagePerTime,
    getTenantUsage,
    getCategoryUsage,
  };
};
export default useStatistics;
