import { TextType } from 'components/common/title/Title';
import React from 'react';

type Props = {
  visible: boolean;
  message: string;
};

const NoData = ({ visible, message }: Props) => {
  return (
    <>
      {visible && (
        <div
          className={`${TextType.h4} flex items-center justify-center w-full h-[404px] text-gray-bf bg-white border border-gray-ea`}
        >
          {message}
        </div>
      )}
    </>
  );
};
export default NoData;
