import React from 'react';

type Props = {
  title: string;
};
const Title = ({ title }: Props) => {
  return <div className='text-[32px] font-bold pb-6'>{title}</div>;
};

export default Title;
