import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';

type Props = {
  selected: CommonOptionType;
  onChangeType: (option: CommonOptionType) => void;
};

const GraphTypeFilter = ({ selected, onChangeType }: Props) => {
  return (
    <div className='mr-5'>
      <CommonSelectBox
        options={[
          { label: '일자별', value: 'date' },
          { label: '요일별', value: 'day-of-week' },
          { label: '시간대별', value: 'time' },
        ]}
        selected={selected}
        onChangeSelect={onChangeType}
        width={120}
      />
    </div>
  );
};
export default GraphTypeFilter;
