import { TextType } from 'components/common/title/Title';
import { IndexType } from 'types/common';

type BadgeProps = {
  style?: string;
  status: string;
};

export const DISPLAY_BADGE_TYPE: IndexType = {
  DISPLAY: 'bg-state-green_bg text-state-green',
  WAIT: 'bg-state-yellow_bg text-state-yellow',
  CLOSE: 'bg-state-gray_bg text-state-gray',
  WAIT_DELETE: 'bg-state-gray_bg text-state-gray',
  NONE: 'bg-state-red_bg text-state-red',
};

export const DISPLAY_BADGE_LABEL: IndexType = {
  DISPLAY: '진행',
  WAIT: '예정',
  CLOSE: '종료',
  WAIT_DELETE: '삭제예정',
  NONE: '확인 필요',
};

const DisplayStatusBadge = ({ status, style }: BadgeProps) => {
  return (
    <div className={`flex h-full`}>
      <div
        className={`w-[60px] h-[32px] flex items-center ${TextType.body2} m-auto justify-center text-center rounded-full ${DISPLAY_BADGE_TYPE[status]} ${style}`}
      >
        {DISPLAY_BADGE_LABEL[status]}
      </div>
    </div>
  );
};

export default DisplayStatusBadge;
