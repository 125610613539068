import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { revokeCsvDownloadURL } from 'utils/file/csv/revokeCsvDownloadURL';

type DownloadParam = {
  workspaceId: string;
  startDate: string;
  endDate: string;
  pointId?: string;
  floorId?: string;
  size?: number;
};

const useStatsDownload = () => {
  const { api } = useApi();

  // 일자별 전체 사용량 다운로드
  const downloadTotalUsagePerDate = async (params: DownloadParam) => {
    const response = await api.get<Blob>(`${Endpoint.usageStatisticPerDate}/download`, {
      responseType: 'blob',
      params,
    });

    if (response) {
      revokeCsvDownloadURL(response, 'usage-statistics-date');
    }
  };

  // 요일별 전체 사용량 다운로드
  const downloadTotalUsagePerDayOfWeek = async (params: DownloadParam) => {
    const response = await api.get<Blob>(`${Endpoint.usageStatisticsPerDayOfWeek}/download`, {
      responseType: 'blob',
      params,
    });

    if (response) {
      revokeCsvDownloadURL(response, 'usage-statistics-day-of-week');
    }
  };

  // 시간별 전체 사용량 다운로드
  const downloadTotalUsagePerTime = async (params: DownloadParam) => {
    const response = await api.get<Blob>(`${Endpoint.usageStatisticsPerTime}/download`, {
      responseType: 'blob',
      params,
    });

    if (response) {
      revokeCsvDownloadURL(response, 'usage-statistics-time');
    }
  };

  // 기기별 사용량 다운로드
  const downloadMachineUsage = async (params: DownloadParam) => {
    const response = await api.get<Blob>(`${Endpoint.machineStatistics}/download`, {
      responseType: 'blob',
      params,
    });

    if (response) {
      revokeCsvDownloadURL(response, 'machine-statistics');
    }
  };

  // 인기 매장 다운로드
  const downloadTenantStats = async (params: DownloadParam) => {
    const response = await api.get<Blob>(`${Endpoint.tenantStatistics}/download`, {
      responseType: 'blob',
      params,
    });

    if (response) {
      revokeCsvDownloadURL(response, 'tenant-statistics');
    }
  };

  // 인기 카테고리 다운로드
  const downloadCategoryStats = async (params: DownloadParam) => {
    const response = await api.get<Blob>(`${Endpoint.categoryStatistics}/download`, {
      responseType: 'blob',
      params,
    });

    if (response) {
      revokeCsvDownloadURL(response, 'category-statistics');
    }
  };

  return {
    downloadTotalUsagePerDate,
    downloadTotalUsagePerDayOfWeek,
    downloadTotalUsagePerTime,
    downloadMachineUsage,
    downloadTenantStats,
    downloadCategoryStats,
  };
};
export default useStatsDownload;
