import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import Divider from 'components/common/divider/Divider';
import LoadingBackground from 'components/common/loading/background/LoadingBackgroud';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import RetryModal from 'components/common/modal/retry/RetryModal';
import { TextType } from 'components/common/title/Title';
import useAccount from 'hooks/common/useAccount';
import useSync from 'hooks/common/useSync';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DEFAULT_TEXT } from 'styles/text';
import { AccountMap } from 'types/feature/account/account';
import { SyncResult } from 'types/feature/sync/sync';
import { Workspace } from 'types/feature/workspace/workspace';
import { handle400Error } from 'utils/error/handle400Error';
import SyncResultModal from './modal/SyncResultModal';

const MapInfo = () => {
  // state
  const [accountMap, setAccountMap] = useState<AccountMap>();
  const [syncResult, setSyncResult] = useState<SyncResult | null>(null);
  const [workspace, setWorkspace] = useState<Workspace>();

  const [isSync, setIsSync] = useState(false);
  const [ableSync, setAbleSync] = useState<boolean>();
  // modal state
  const [openResultModal, setOpenResultModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSyncFailed, setSyncFailed] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  // hook
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();
  const { findAccountMap, account } = useAccount();
  const { getSyncCheck, postSync, getSyncResult } = useSync();

  // 워크스페이스 조회
  const fetchWorkspace = async () => {
    if (workspaceId && account) {
      const result = await getWorkspace(workspaceId);
      if (result) {
        setWorkspace(result);

        const accountMap = findAccountMap(account.maps, result.map.id);

        setAccountMap(accountMap);
      }
    }
  };

  // 동기화 가능 여부 확인
  const handleAbleSync = async () => {
    const result = await getSyncCheck();
    if (result) {
      setAbleSync(result.synchronizable);
    }
  };

  // 동기화 예외처리
  const handleSyncError = () => {
    setIsSync(false);
    setSyncFailed(true);
  };

  // 지도 동기화
  const handleMapSync = async () => {
    try {
      setOpenConfirm(false);
      setIsSync(true);

      const syncResult = await postSync();

      setOpenConfirm(false);

      // 동기화 결과
      if (syncResult) {
        setOpenResultModal(true);
        setSyncResult(syncResult);
        setAbleSync(false);
      }

      setIsSync(false);
    } catch (error) {
      handle400Error(error, handleSyncError);
    }
  };

  // 동기화 재시도
  const handleRetry = async () => {
    setSyncFailed(false);
    setOpenConfirm(false);
    setIsSync(false);

    await handleMapSync();
  };

  // 재시도 취소
  const handleClickCancelRetry = () => {
    setSyncFailed(false);
    setIsSync(false);
    setOpenConfirm(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  // 지도 동기화 결과보기
  const handleOpenSyncResult = async () => {
    try {
      setOpenResultModal(true);

      const result = await getSyncResult();

      if (result) {
        setSyncResult(result);
      }
    } catch (error) {
      handle400Error(error, handleOpenAlert);
    }
  };

  useEffect(() => {
    fetchWorkspace();
  }, [workspaceId, ableSync, account]);

  useEffect(() => {
    handleAbleSync();
  }, []);

  if (!workspace) {
    return <></>;
  }

  return (
    <>
      <div className='flex flex-col'>
        <div className='flex items-center gap-6 h-9'>
          {accountMap ? (
            /**
             * 지도 이름 : Editor 에서 실시간으로 조회
             * 지도 버전 : 현재 workspace 의 지도 버전 조회
             */
            <div className={`flex items-center gap-2 ${DEFAULT_TEXT}`}>
              {accountMap.name} <Divider /> {workspace?.map.version}v
            </div>
          ) : (
            <div className={`${TextType.body1} text-gray-99 flex items-center justify-start h-5 w-[200px]`}>
              지도 정보를 불러오는 중 입니다.
            </div>
          )}

          {!workspace.autoSync && (
            <div className='flex gap-2.5'>
              <Button
                onClick={() => setOpenConfirm(true)}
                color={ButtonColor.primary}
                size='rounded'
                text='지도 동기화'
                textStyle={TextType.body2}
                disabled={!ableSync}
              />

              <Button
                onClick={handleOpenSyncResult}
                color={ButtonColor.secondary}
                size='rounded'
                text='결과보기'
                textStyle={TextType.body2}
              />
            </div>
          )}
        </div>

        {/* autoSync 불가능한 워크스페이스만 노출 */}
        {!workspace.autoSync && (
          <div
            className={`${TextType.body3} ${
              typeof ableSync === 'undefined'
                ? 'text-gray-ea'
                : ableSync
                ? 'text-primary-DA_Red'
                : 'text-primary-DA_Blue_light'
            }`}
          >
            {typeof ableSync === 'undefined'
              ? ''
              : ableSync
              ? '최신버전으로 지도 동기화가 가능합니다.'
              : '최신버전 지도입니다.'}
          </div>
        )}
      </div>

      {openConfirm && (
        <ConfirmModal
          message='최신 버전 지도로 동기화하시겠습니까?'
          onClickOk={handleMapSync}
          onClickCancel={() => {
            setOpenConfirm(false);
            setSyncFailed(false);
          }}
          disabled={isSync}
        />
      )}

      {isSync && <LoadingBackground message='지도를 최신 버전으로 동기화 중입니다.' />}

      {openResultModal && syncResult && (
        <SyncResultModal
          legacy={workspace.legacy}
          syncResult={syncResult}
          closeModal={() => setOpenResultModal(false)}
        />
      )}

      {openSyncFailed && (
        <RetryModal
          message={`동기화에 실패하였습니다.\n다시 시도해주세요.`}
          onClickCancel={handleClickCancelRetry}
          onRetry={handleRetry}
          disabled={isSync}
        />
      )}

      {openAlert && <AlertModal message='동기화 정보를 찾을 수 없습니다.' closeModal={() => setOpenAlert(false)} />}
    </>
  );
};
export default MapInfo;
