import Guide from 'components/common/guide/Guide';
import { FileUploadType } from 'types/common/file/fileUpload';
import { Tenant } from 'types/feature/tenant/tenant';
import TenantImageField from './item/TenantImageField';

type Props = {
  tenant: Tenant;
  fetchTenant: () => Promise<void>;
};

const LOGO_GUIDES = [
  '2MB 이하의 png, jpeg, jpg 파일만 첨부 가능합니다.',
  '최적 사이즈는 목록 이미지 360*360 / 상세 이미지 1560*1208 입니다.',
  '이미지는 영역에 맞게 확대되어 제공됩니다.',
];

// 목록 이미지, 상세 이미지
const TenantImages = ({ tenant: { id, mainLogoFile, subLogoFile }, tenant, fetchTenant }: Props) => {
  return (
    <div className='flex flex-col w-full gap-3 px-5 py-3'>
      <div className='flex gap-5'>
        <TenantImageField
          fileType={FileUploadType.TENANT_IMAGE}
          title='목록 이미지'
          tenantId={id}
          logoFile={mainLogoFile && mainLogoFile}
          fieldId='mainLogoFile'
          originalName={mainLogoFile && mainLogoFile.originalName}
          fetchTenant={fetchTenant}
        />

        <TenantImageField
          fileType={FileUploadType.TENANT_IMAGE}
          title='상세 이미지'
          tenantId={id}
          logoFile={subLogoFile && subLogoFile}
          fieldId='subLogoFile'
          originalName={subLogoFile && subLogoFile.originalName}
          fetchTenant={fetchTenant}
        />
      </div>

      <Guide guides={LOGO_GUIDES} isList={false} />
    </div>
  );
};

export default TenantImages;
