import Border from 'components/common/border/Border';
import FormControl from 'components/common/form-control/FormControl';
import { TextType } from 'components/common/title/Title';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import usePoint from 'hooks/feature/point/usePoint';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Point } from 'types/feature/workspace/workspace';
import BusinessHour from './business-hour/BusinessHour';
import Holiday from './holiday/Holiday';
import MapInfo from './map/MapInfo';
import PointsTab from './points-tab/PointsTab';

const BasicSetting = () => {
  const [points, setPoints] = useState<Point[]>();
  const [selectedPoint, setSelectedPoint] = useState<Point>();

  const { getMyPoints } = usePoint();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId, pointId } = useParams();

  const fetchPoints = async () => {
    const points = await getMyPoints();
    if (points) {
      setPoints(points);

      return points;
    }
  };

  // point 선택 시
  const onSelectPoint = (point: Point) => {
    setSelectedPoint(point);

    navigateToReplacedPath(RoutePath.pointBasicSetting, workspaceId, ':pointId', point.id);
  };

  const handleSelectedPoint = async () => {
    const points = await fetchPoints();

    if (points) {
      if (!pointId) {
        setSelectedPoint(points[0]);
      } else {
        const selectedPoint = points.find(point => point.id === pointId);

        if (selectedPoint) {
          setSelectedPoint(selectedPoint);
        }
      }
    }
  };

  useEffect(() => {
    fetchPoints();
  }, []);

  useEffect(() => {
    handleSelectedPoint();
  }, [pointId]);

  if (!points || !selectedPoint) {
    return <> </>;
  }

  return (
    <div className='flex flex-col gap-5'>
      {/* 지도 정보 */}
      <div className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
        <FormControl name='지도 정보' labelStyle={TextType.h3_bold} control={<MapInfo />} />
      </div>

      <div>
        {points.length > 1 && (
          <PointsTab points={points} handleClickPoint={onSelectPoint} selectedPoint={selectedPoint} />
        )}

        <div className='flex flex-col p-10 bg-white border gap-9 border-gray-ea'>
          {/* 운영시간 */}
          <BusinessHour point={selectedPoint} fetchPoints={fetchPoints} handlePoint={onSelectPoint} />

          <Border />

          {/* 휴무일 */}
          <Holiday point={selectedPoint} fetchPoints={fetchPoints} handlePoint={onSelectPoint} />
        </div>
      </div>
    </div>
  );
};

export default BasicSetting;
