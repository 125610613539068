import LoadingSpinner from 'components/common/loading/spinner/LoadingSpinner';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import useMachines from 'hooks/feature/machine/useMachine';
import useMachineSocket from 'hooks/feature/socket/useMachineSocket';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConnectedMachine } from 'types/feature/machine/machine';
import { Point, Workspace } from 'types/feature/workspace/workspace';
import MachineMonitoringBody from './body/MachineMonitoringBody';

const MachineMonitoringContainer = () => {
  // state
  const [workspace, setWorkspace] = useState<Workspace>();
  const [points, setPoints] = useState<Point[]>();
  const [selectedPoint, setSelectedPoint] = useState<CommonOptionType>();
  const [machines, setMachines] = useState<ConnectedMachine[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // hook
  const { getWorkspace } = useWorkspace();
  const { getMachines } = useMachines();
  const { workspaceId } = useParams();

  /**
   * 워크스페이스, 포인트 를 조회한다
   */
  const fetchWorkspaceAndPoints = async () => {
    if (!workspaceId) return;

    const findWorkspace = await getWorkspace(workspaceId);

    setWorkspace(findWorkspace);
    setPoints(findWorkspace.points);
    setSelectedPoint({
      label: selectedPoint?.label || findWorkspace.points[0].name.ko,
      value: selectedPoint?.value || findWorkspace.points[0].id,
    });
  };

  /**
   * 기기 목록을 조회한다
   * @param pointId
   */
  const fetchMachines = async (pointId: string) => {
    setLoading(true);

    if (workspace) {
      const result = await getMachines(pointId);
      setMachines(result.data);
    }

    setLoading(false);
  };

  /**
   * 포인트를 변경하면 기기 목록을 다시 조회한다
   */
  const onChangePoint = async (option: CommonOptionType) => {
    setSelectedPoint(option);
    await fetchMachines(option.value);
  };

  /**
   * 워크스페이스와 포인트를 조회한다
   */
  useEffect(() => {
    fetchWorkspaceAndPoints();
  }, [workspaceId]);

  /**
   * 기기 목록을 조회한다
   */
  useEffect(() => {
    if (!workspace) return;

    fetchMachines(workspace.points[0].id);
  }, [workspace]);

  /**
   * socket 연결 custom hook
   * socket 이 connect / close 되었을 때 목록을 다시 fetch 하도록 처리한다
   */
  const { updatedMachine } = useMachineSocket({
    callback: async () => {
      await fetchMachines(selectedPoint?.value || workspace?.points[0].id || '');
    },
    pointId: selectedPoint?.value || '',
  });

  /**
   * socket 이 update message 를 주면 기기 목록을 다시 조회한다
   */
  useEffect(() => {
    if (updatedMachine.machineId) {
      fetchMachines(selectedPoint?.value || '');
    }
  }, [updatedMachine]);

  return (
    <div className='flex flex-col gap-6'>
      {loading ? (
        <LoadingSpinner position='relative' height='36px' />
      ) : (
        points && (
          <CommonSelectBox
            options={points.map(point => ({
              label: point.name.ko,
              value: point.id,
            }))}
            onChangeSelect={onChangePoint}
            selected={selectedPoint}
          />
        )
      )}

      <MachineMonitoringBody machineList={machines} />
    </div>
  );
};

export default MachineMonitoringContainer;
