import { AxiosError } from 'axios';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import { ICON } from 'constants/icons';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import React, { useState } from 'react';
import useFilterStore from 'stores/filter';
import { ScreenType } from 'types/feature/content/contents';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';

type Props = {
  screensaver: GetScreensaver;
  refetchScreensaver: (pointId: string, screenType: ScreenType) => Promise<void>;
  disabled: boolean;
  selectedPoint: CommonOptionType | undefined;
};

const ScheduleDelete = ({ screensaver, refetchScreensaver, disabled, selectedPoint }: Props) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const { deleteScreensaver } = useScreensaver();

  const filterStore = useFilterStore();

  // 스크린세이버 삭제
  const handleDelete = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      e.stopPropagation();

      await deleteScreensaver(screensaver.id);
      await refetchScreensaver(selectedPoint?.value || '', filterStore.scheduleScreenType);

      setOpenDeleteConfirm(false);
      setOpenAlert(true);
      setAlertMessage('광고 스케줄을 삭제하였습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        setOpenDeleteConfirm(false);
        setOpenAlert(true);
        setAlertMessage('광고 스케줄을 삭제할 수 없습니다.');
      }
    }
  };

  return (
    <>
      {!disabled && (
        <div onClick={() => setOpenDeleteConfirm(true)} className='cursor-pointer'>
          <div className='flex items-center justify-center w-full'>
            <img src={ICON.DELETE_BUTTON} alt='delete icon' />
          </div>
        </div>
      )}

      {openDeleteConfirm && (
        <ConfirmModal
          message='광고 스케줄을 삭제하시겠습니까?'
          onClickOk={handleDelete}
          onClickCancel={() => setOpenDeleteConfirm(false)}
        />
      )}

      {openAlert && <AlertModal message={alertMessage} closeModal={() => setOpenAlert(false)} />}
    </>
  );
};
export default ScheduleDelete;
