import { CustomPage } from 'types/feature/menu/customPage';
import { create } from 'zustand';

type Props = {
  customPages: CustomPage[];
  setCustomPages: (customPages: CustomPage[]) => void;
  resetCustomPages: () => void;
};

const useCustomPageStore = create<Props>((set, get) => ({
  customPages: [],
  setCustomPages(customPages: CustomPage[]) {
    set({ customPages });
  },
  resetCustomPages() {
    set({ customPages: [] });
  },
}));

export default useCustomPageStore;
