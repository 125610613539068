/* eslint-disable jsx-a11y/alt-text */
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import { TextType } from 'components/common/title/Title';
import { useCallback } from 'react';
import { ErrorCode, FileError, FileRejection, useDropzone } from 'react-dropzone';
import { FileType } from 'types/common/file/file';
import { PostFile } from 'types/feature/content/postContent';
import { convertMbToByte } from 'utils/file/size/convertMbToByte';
import FileDeleteButton from '../area/button/FileDeleteButton';
import FileName from '../area/name/FileName';
import FileLoading from '../loading/FileLoading';
import FilePreview from '../preview/FilePreview';

type Accept = {
  'image/jpeg'?: string[];
  'image/png'?: string[];
  'video/mp4'?: string[];
};

type Props = {
  maxSize: number;
  uploadFile: (file: File) => Promise<void>;
  fileUri: string;
  fileType: FileType;
  file: PostFile;
  deleteFile: () => void;
  accept: Accept;
  openFileErrorAlert: (message: string) => void;
  loading: boolean;
};

const FileDropZone = ({
  maxSize,
  uploadFile,
  fileUri,
  fileType,
  file,
  deleteFile,
  accept,
  openFileErrorAlert,
  loading,
}: Props) => {
  // drop 했을 경우
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      await uploadFile(acceptedFiles[0]);
    },
    [fileType],
  );

  // drop 실패 시
  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections[0].errors.forEach(({ code }: FileError) => {
      switch (code) {
        case ErrorCode.FileInvalidType:
          openFileErrorAlert('파일 확장자를 확인해주세요.');
          break;

        case ErrorCode.FileTooSmall:
          openFileErrorAlert('파일 용량이 너무 작습니다.');
          break;

        case ErrorCode.FileTooLarge:
          openFileErrorAlert(`파일 용량은 ${maxSize}MB를 넘어갈 수 없습니다.`);
          break;

        case ErrorCode.TooManyFiles:
          openFileErrorAlert('파일을 최대 1개만 등록할 수 있습니다.');
          break;

        default:
          openFileErrorAlert('파일을 업로드할 수 없습니다.');
          break;
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
    maxSize: convertMbToByte(maxSize),
    maxFiles: 1,
  });

  return (
    <>
      {file.originalName ? (
        <div className='flex flex-col gap-3'>
          <div className='flex items-center border px-2 border-gray-99 gap-2.5 w-90'>
            <FileName fileName={file.originalName} />
            <FileDeleteButton deleteFile={deleteFile} />
          </div>
          <FilePreview fileType={fileType} fileUri={fileUri} />
        </div>
      ) : loading ? (
        <FileLoading />
      ) : (
        <div
          {...getRootProps()}
          className='items-center cursor-pointer 
            w-[360px] h-[120px] rounded border border-dashed border-gray-44 
            flex flex-col justify-center
            hover:bg-bg-f1'
        >
          <div className='flex items-center justify-center text-sm text-center'>
            <input {...getInputProps()} />
            {isDragAccept && <p className={`${TextType.body1} text-gray-44`}>파일을 여기에 놓아주세요!</p>}
            {isDragReject && <p className={`${TextType.body1} text-gray-44`}>업로드할 수 없는 파일입니다.</p>}
            {!isDragActive && (
              <div className='flex flex-col items-center justify-center gap-1'>
                <Button color={ButtonColor.primary} text='파일선택' size='rounded' />
                <p className={`${TextType.body1} text-gray-44`}>또는 파일을 여기로 드래그해주세요.</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FileDropZone;
