import React from 'react';

type Props = {
  code: string;
  isMain: boolean;
};

const LangTag = ({ code, isMain }: Props) => {
  return (
    <div
      className={`flex items-center justify-center text-white w-[22px] h-[16px] text-[11px] font-medium
      text-center uppercase
      ${isMain ? 'bg-gray-22' : 'bg-gray-99'}`}
    >
      {code}
    </div>
  );
};
export default LangTag;
