import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import Switch from 'components/common/switch/Switch';
import Title, { TextType } from 'components/common/title/Title';
import useModal from 'hooks/common/useModal';
import usePoint from 'hooks/feature/point/usePoint';
import { useEffect, useState } from 'react';
import { Point } from 'types/feature/workspace/workspace';
import AltText from './alt-text/AltText';
import TimeForm from './time/TimeForm';

type Props = {
  point: Point;
  handlePoint: (point: Point) => void;
  fetchPoints: () => Promise<Point[] | undefined>;
};

const BusinessHour = ({ point, fetchPoints, handlePoint }: Props) => {
  const [hoursUsed, setHoursUsed] = useState(false);

  const { openModalPopup, openConfirm, openAlert, closeModalPopup } = useModal();
  const { patchPoint } = usePoint();

  const handleChangeOption = (hoursUsed: boolean) => {
    handlePoint({
      ...point,
      operation: {
        ...point.operation,
        hoursUsed,
      },
    });
  };

  // 저장
  const handleSave = async () => {
    try {
      await patchPoint(point.id, {
        hoursDescription: point.operation.hoursDescription,
        hoursUsed: point.operation.hoursUsed,
        hoursStart: point.operation.hoursStart,
        hoursEnd: point.operation.hoursEnd,
      });
      await fetchPoints();

      closeModalPopup('CONFIRM');
    } catch (error) {
      if (error instanceof AxiosError && error.status === 400) {
        closeModalPopup('CONFIRM');
        openModalPopup('ALERT');
      }
    }
  };

  useEffect(() => {
    setHoursUsed(point.operation.hoursUsed);
  }, [point]);

  const props = {
    point,
    handlePoint,
  };

  return (
    <>
      <Title text='운영시간 설정' textType={TextType.h3_bold} />

      <div className='flex flex-col gap-6'>
        <FormControl
          required
          name='운영시간 사용여부'
          control={<Switch color='BLUE' type='USE' value={hoursUsed} onChangeOption={handleChangeOption} />}
        />

        <FormControl name='운영시간' required control={hoursUsed ? <TimeForm {...props} /> : <AltText {...props} />} />
      </div>

      <div className='flex justify-end'>
        <Button text='저장' color={ButtonColor.point} size={80} onClick={() => openModalPopup('CONFIRM')} />
      </div>

      {openConfirm && (
        <ConfirmModal
          message='저장 하시겠습니까?'
          onClickCancel={() => closeModalPopup('CONFIRM')}
          onClickOk={handleSave}
        />
      )}
      {openAlert && <AlertModal message='오류가 발생했습니다.' closeModal={() => closeModalPopup('ALERT')} />}
    </>
  );
};

export default BusinessHour;
