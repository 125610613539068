import InfoSvg from 'assets/icon/InfoSvg';
import React from 'react';

const WarnMessage = () => {
  return (
    <div className='flex gap-2.5 items-center justify-end w-[400px] h-5'>
      <InfoSvg color='#EC4545' />

      <div className='text-primary-DA_Red text-[14px] font-medium leading-5'>매장 정보가 노출되지 않습니다.</div>
    </div>
  );
};
export default WarnMessage;
