import { IndexType } from 'types/common';
import { Floor } from 'types/feature/floor/floor';

export const MACHINE_TYPE: IndexType = {
  ALL: '전체',
  WEB: '웹형',
  DESKTOP: '설치형',
};

export enum MACHINE_STATUS {
  WEB = 'WEB',
  DESKTOP = 'DESKTOP',
}

export type ConnectedMachine = {
  id: string;
  idSuffix: string;
  name: string;
  description: string;
  position: {
    x: string;
    y: string;
  };
  connected: boolean;
  floor: Floor;
};

export type UpdatedMachine = {
  machineId: string;
  connected: boolean;
};
