import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { Fragment } from 'react';
import { TotalUsagePerDayOfWeek } from 'types/feature/statistics/totalUsage';
import { convertDayEnToKo } from 'utils/date/convertDay';
import { DAY_USAGE_TABLE_HEADER } from '../head/dayUsageTableHead';

type Props = {
  totalUsageList: TotalUsagePerDayOfWeek[];
};

const DayOfWeekUsageTable = ({ totalUsageList }: Props) => {
  return (
    <table className='w-full border-collapse'>
      <CommonTableHeader tableHead={DAY_USAGE_TABLE_HEADER} />

      {totalUsageList.map((data, index) => (
        <Fragment key={index}>
          <tbody>
            <tr className='h-[52px] text-[14px]'>
              {/* 요일 */}
              <td className='border-b p-2.5'>{convertDayEnToKo(data.dayOfWeek)}</td>

              {/* 키오스크 사용량 */}
              <td className='border p-2.5 bg-bg-f9'>{Number(data.machineCount).toLocaleString()}</td>

              {/* 모바일 사용량 */}
              <td className='border p-2.5 bg-bg-f9'>{Number(data.mobileCount).toLocaleString()}</td>

              {/* 전체 사용량 */}
              <td className='border p-2.5'>{Number(data.totalCount).toLocaleString()}</td>

              {/* 비율 */}
              <td className='border border-r-0 p-2.5'>{data.ratio}%</td>
            </tr>
          </tbody>
        </Fragment>
      ))}
    </table>
  );
};
export default DayOfWeekUsageTable;
