import React from 'react';

type Props = {
  handleClick?: () => void;
  disabled?: boolean;
  id?: string;
  fileName: string;
};

const FileName = ({ disabled, id, fileName }: Props) => {
  return (
    <label
      className={`text-gray-44
      ${disabled ? 'cursor-default opacity-40' : 'cursor-pointer'} 
      text-ellipsis overflow-hidden whitespace-nowrap text-sm leading-5 flex flex-col justify-center items-start w-full h-9`}
      htmlFor={id}
    >
      {fileName}
    </label>
  );
};
export default FileName;
