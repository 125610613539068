import React from 'react';
import { GlobalMenu } from 'types/common/globalMenu';

type Props = {
  menus: GlobalMenu[];
  setShowParentMenu: (value: boolean) => void;
  setShowSubMenus: (value: boolean) => void;
};

const TwoDepthSubMenus = ({ menus, setShowSubMenus, setShowParentMenu }: Props) => {
  const handleOpenMenu = () => {
    console.log('focus');
    setShowSubMenus(true);
    setShowParentMenu(true);
  };

  const handleCloseMenu = () => {
    // setShowSubMenus(false);
    // setShowParentMenu(false);
  };
  return (
    <div
      onFocus={handleOpenMenu}
      onMouseOver={handleOpenMenu}
      onMouseLeave={handleCloseMenu}
      onBlur={handleCloseMenu}
      className={`w-32 absolute left-0  border border-gray-ea bg-primary-White z-10
}`}
    >
      {menus.map(menu => (
        <div className='text-center border-b text-h4 whitespace-nowrap'>{menu.id}</div>
      ))}
    </div>
  );
};

export default TwoDepthSubMenus;
