import { AxiosError } from 'axios';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { IMAGES } from 'constants/images';
import useFileUpload from 'hooks/common/useFileUpload';
import useModal from 'hooks/common/useModal';
import useTenant from 'hooks/feature/tenant/useTenant';
import { useEffect, useRef, useState } from 'react';
import useTenantToastStore from 'stores/tenantToast';
import { CommonFile } from 'types/common/file/file';
import { FileUploadType, PatchFile } from 'types/common/file/fileUpload';
import { returnFileAccept } from 'utils/file/extension/returnFileAccept';
import { validateFileExtension, validateFileVolume } from 'utils/file/extension/validateFileType';
import { returnFileMaxSize } from 'utils/file/size/returnFileSize';
import TenantFileDropArea from './drop-zone/TenantFileDropArea';

type Props = {
  fileType?: FileUploadType | null;
  title: string;
  originalName: string;
  fieldId: string;
  tenantId: string;
  logoFile: CommonFile;
  fetchTenant: () => Promise<void>;
};

const TenantImageField = ({ fileType, title, originalName, fieldId, tenantId, logoFile, fetchTenant }: Props) => {
  // state
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [fileName, setFileName] = useState<string>(originalName);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [fileUri, setFileUri] = useState(logoFile && logoFile.uri);
  const [uploadFail, setUploadFail] = useState<boolean>(false);

  // store
  const tenantToastStore = useTenantToastStore();

  // ref
  const fileInputRef = useRef<HTMLInputElement>(null);

  // hook
  const { uploadFile } = useFileUpload();
  const { patchTenant } = useTenant();
  const { openAlert, openConfirm, openModalPopup, closeModalPopup } = useModal();

  // 파일 ref 초기화
  const resetFileRef = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // 테넌트 디테일 업데이트
  const handleUpdate = async (isDelete: boolean, imageFileName?: string, imageOriginalName?: string) => {
    try {
      const fileData: PatchFile = {
        name: imageFileName ?? '',
        originalName: imageOriginalName ?? '',
        delete: isDelete,
      };

      await patchTenant(tenantId, {
        [fieldId]: fileData,
      });

      await fetchTenant();
    } catch (error) {
      if (error instanceof AxiosError) {
        openModalPopup('ALERT');
        setAlertMessage('이미지 수정에 실패했습니다.');
      }
    }
  };

  // 파일 input change
  const onChangeFile = async (file: File) => {
    try {
      if (file) {
        // 파일 확장자 체크
        if (!validateFileExtension(file, FileUploadType.TENANT_IMAGE)) {
          openModalPopup('ALERT');
          setAlertMessage('파일 확장자는 png, jpg, jpeg만 가능합니다.');
          return;
        }

        const uploadFileVolumeChecked = validateFileVolume(file.size, FileUploadType.TENANT_IMAGE);
        if (uploadFileVolumeChecked) {
          if (!uploadFileVolumeChecked.enableUpload) {
            openModalPopup('ALERT');
            setAlertMessage(uploadFileVolumeChecked.message);
            return;
          }
        }

        setFileName(file.name);
        setFileUploading(true);
        const result = await uploadFile(file, FileUploadType.TENANT_IMAGE);

        if (result) {
          const response = result.data;

          setFileUri(response.fileUri);
          setFileUploading(false);
          await handleUpdate(false, result.data.fileName, file.name);

          // 토스트 팝업
          tenantToastStore.setIsToastOpen(true);
          tenantToastStore.setToastPopupMessage('매장 정보가 저장되었습니다.');
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          openModalPopup('ALERT');
          setAlertMessage('파일 업로드 중 문제가 발생했습니다.');
          setFileUploading(false);
        }
      }
    }
  };

  // 파일 삭제
  const onDeleteImage = async () => {
    resetFileRef();
    setFileName('');
    setFileUri('');
    closeModalPopup('CONFIRM');

    await handleUpdate(true);

    // 토스트 팝업
    tenantToastStore.setIsToastOpen(true);
    tenantToastStore.setToastPopupMessage('매장 이미지가 삭제되었습니다.');
  };

  // 파일 업로드 실패 시
  const openFileErrorAlert = (message: string) => {
    setAlertMessage(message);
    setUploadFail(true);
    setFileUploading(false);
  };

  useEffect(() => {
    setFileName(originalName);
    setFileUri(logoFile && logoFile.uri);
  }, [tenantId]);

  return (
    <div className='flex flex-col w-[183px]'>
      {/* 목록 이미지 / 상세 이미지 */}
      <div className='text-xs h-[36px] font-bold whitespace-nowrap text-gray-77 items-center flex'>{title}</div>

      {/* input 인척 하는 이미지 정보 */}
      <div className={`${TextType.body2} border border-gray-99 h-[30px] flex items-center px-2 mb-[6px]`}>
        {logoFile ? (
          <div className='flex gap-2.5 justify-between w-full'>
            {/* 파일 이름 */}
            <span className='w-[146px] h-full truncate text-gray-99 text-ellipsis'>{fileName}</span>

            {/* 삭제 버튼 */}
            <div className='w-[18px] h-[18px] cursor-pointer' onClick={() => openModalPopup('CONFIRM')}>
              <img src={ICON.CLOSE} alt='delete' />
            </div>
          </div>
        ) : (
          <span className='h-4 text-gray-cc'>등록된 파일이 없습니다</span>
        )}
      </div>

      {/* 이미지 영역 */}
      <div className={`${logoFile && 'border'} w-[183px] h-[190px] overflow-hidden flex items-center justify-center`}>
        {logoFile ? (
          // 미리보기 영역
          <img
            className='object-contain h-full'
            src={logoFile.uri || IMAGES.THUMBNAIL_DEFAULT}
            alt='logo'
            onError={e => {
              const element = e.target as HTMLInputElement;
              element.src = IMAGES.THUMBNAIL_DEFAULT;
            }}
          />
        ) : (
          // 파일 선택 영역
          <TenantFileDropArea
            fileUri={fileUri}
            uploadFile={onChangeFile}
            loading={fileUploading}
            openFileErrorAlert={openFileErrorAlert}
            maxSize={returnFileMaxSize(FileUploadType.LOGO_IMAGE)}
            accept={returnFileAccept(FileUploadType.LOGO_IMAGE)}
          />
        )}
      </div>

      {openConfirm && (
        <ConfirmModal
          message={`${title}를 삭제하시겠습니까?`}
          onClickCancel={() => closeModalPopup('CONFIRM')}
          onClickOk={onDeleteImage}
        />
      )}

      {/* 이미지 업로드 실패 시 */}
      {uploadFail && <AlertModal closeModal={() => setUploadFail(false)} message={alertMessage} />}

      {openAlert && <AlertModal closeModal={() => closeModalPopup('ALERT')} message={alertMessage} />}
    </div>
  );
};

export default TenantImageField;
