import Title, { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { RefObject, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { FLEX_CENTER } from 'styles/flex';
import { DisplayType } from 'types/feature/screensaver/getScreensaver';
import DateCalendar from '../schedule/date-picker/DateCalendar';

type CalendarProps = {
  disabled?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  handleDate: (date: Date | null) => void;
  handleStartDate: (startDate: Date | null) => void;
  handleEndDate: (startDate: Date | null) => void;
  type: DisplayType;
};

const DateScheduleCalendar = ({
  disabled,
  startDate,
  endDate,
  handleDate,
  handleStartDate,
  handleEndDate,
  type,
}: CalendarProps) => {
  const alwaysCalendarRef = useRef<DatePicker>(null);
  const rangeStartCalendarRef = useRef<DatePicker>(null);
  const rangeEndCalendarRef = useRef<DatePicker>(null);

  // 아이콘 클릭 시  캘린더 오픈
  const handleOpen = (calendarRef: RefObject<DatePicker>) => {
    if (calendarRef.current) {
      calendarRef.current?.setFocus();
    }
  };

  const dateString = startDate?.toISOString().slice(0, 10); // 또는 다른 원하는 형식으로 변환

  return (
    <div>
      {type === DisplayType.ALWAYS_ON ? (
        // 상시
        <div className='flex items-center gap-4'>
          <Title text='시작일' textType={TextType.body1} />

          {disabled ? (
            <div className='flex items-center h-9 w-[210px]'>{startDate && dateString}</div>
          ) : (
            <div className={`${FLEX_CENTER.row} w-[210px] flex items-center gap-4 border h-[38px] px-3 border-gray-99`}>
              <DateCalendar calendarRef={alwaysCalendarRef} handleDate={handleDate} startDate={startDate} />
              <img
                className='cursor-pointer'
                onClick={handleOpen.bind(this, alwaysCalendarRef)}
                src={ICON.CALENDAR}
                alt='calendar icon'
              />
            </div>
          )}
        </div>
      ) : (
        // 기간
        <div className='flex flex-col items-start gap-4'>
          <div className='flex items-center gap-4'>
            <Title text='시작일' textType={TextType.body1} />
            {disabled ? (
              <div className='flex items-center h-9 w-[210px]'>{startDate && dateString}</div>
            ) : (
              <div
                className={`${FLEX_CENTER.row} w-[210px] flex items-center gap-4 border h-[38px] px-3 border-gray-99`}
              >
                <DateCalendar calendarRef={rangeStartCalendarRef} handleDate={handleStartDate} startDate={startDate} />
                <img
                  className='cursor-pointer'
                  onClick={handleOpen.bind(this, rangeStartCalendarRef)}
                  src={ICON.CALENDAR}
                  alt='calendar icon'
                />
              </div>
            )}
          </div>

          <div className='flex items-center gap-4'>
            <Title text='종료일' textType={TextType.body1} />
            <div className={`${FLEX_CENTER.row} w-[210px] flex items-center gap-4 border h-[38px] px-3 border-gray-99`}>
              <DateCalendar calendarRef={rangeEndCalendarRef} handleDate={handleEndDate} startDate={endDate} />

              <img
                className='cursor-pointer'
                onClick={handleOpen.bind(this, rangeEndCalendarRef)}
                src={ICON.CALENDAR}
                alt='calendar icon'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateScheduleCalendar;
