import useMouseOverlay from 'hooks/common/useMouseOverlay';
import { CommonFile, FileType } from 'types/common/file/file';
import ThumbnailOverlay from './overlay/ThumbnailOverlay';

type ThumbnailTableRowCellProps = {
  title: string;
  files: CommonFile;
  fileType: FileType;
  index: number;
};

const ThumbnailTableRowCell = ({ files, fileType, title, index }: ThumbnailTableRowCellProps) => {
  const { hoveredRow, mousePosition, handleMouseEnter, handleMouseLeave } = useMouseOverlay();

  return (
    <>
      <div
        onMouseEnter={e => handleMouseEnter(index, e)}
        onMouseLeave={handleMouseLeave}
        className='w-full px-2 overflow-hidden cursor-pointer line-clamp-1'
      >
        {title}
      </div>
      {hoveredRow === index && <ThumbnailOverlay mousePosition={mousePosition} file={files} fileType={fileType} />}
    </>
  );
};
export default ThumbnailTableRowCell;
