/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import { OptionType } from 'components/common/select/SelectBox';
import { TextType } from 'components/common/title/Title';
import ToastPopup from 'components/common/toast/ToastPopup';
import TenantChangedEditSpace from 'components/feature/tenant/edit/TenantChangedEditSpace';
import useModal from 'hooks/common/useModal';
import useFloor from 'hooks/feature/floor/useFloor';
import useLanguage from 'hooks/feature/language/useLanguage';
import usePoint from 'hooks/feature/point/usePoint';
import useTenant from 'hooks/feature/tenant/useTenant';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useTenantToastStore from 'stores/tenantToast';
import { CONTENTS_BG } from 'styles/background';
import { ChangedStatus } from 'types/common/status';
import { Floor } from 'types/feature/floor/floor';
import { Language } from 'types/feature/multi-lang/language';
import { ChangeTenant, ChangedTenants } from 'types/feature/tenant/changedTenant';
import { Point } from 'types/feature/workspace/workspace';
import { handle400Error } from 'utils/error/handle400Error';
import ChangedInfoFilter from './filter/ChangedInfoFilter';
import ChangedInfoTable from './table/ChangedInfoTable';

const TenantChangedInfoContainer = () => {
  // state
  const [displayLanguages, setDisplayLanguages] = useState<Language[]>([]);
  const [mainLang, setMainLang] = useState<string>('');

  const [changedTenants, setChangedTenants] = useState<ChangedTenants | null>(null);
  const [currentChangeTenant, setCurrentChangeTenant] = useState<ChangeTenant | null>(null);
  const [isLoadingChangedTenants, setLoadingChangedTenants] = useState<boolean>(false);

  const [points, setPoints] = useState<Point[]>();
  const [selectedPoint, setSelectedPoint] = useState<Point>();

  const [floors, setFloors] = useState<Floor[]>([]);

  const [selectedFloor, setSelectedFloor] = useState<Floor>();
  const [status, setStatus] = useState<ChangedStatus>(ChangedStatus.ALL);
  const [viewAll, setViewAll] = useState<boolean>(false);

  const [alertMessage, setAlertMessage] = useState<string>('');

  const [allFloorOptions, setAllFloorOptions] = useState<OptionType[]>([
    {
      label: '',
      value: '',
    },
  ]);

  // hook
  const { getMyPoints } = usePoint();
  const { getFloors } = useFloor();
  const { getLanguages } = useLanguage();
  const { openAlert, openConfirm, openModalPopup, closeModalPopup } = useModal();
  const { getChangedTenants, patchChangeTenantChanged } = useTenant();
  const { workspaceId } = useParams();

  // store
  const tenantToastStore = useTenantToastStore();

  // ref
  const toastTimerRef = useRef<NodeJS.Timer | null>(null);

  // 층 옵션
  const handleFloorOptions = () => {
    const floorOptions = floors.map(floor => ({
      label: floor.name.ko || '',
      value: floor.id || '',
    }));

    // 한 개 이상일 경우 전체 추가
    if (floorOptions.length > 1) {
      setAllFloorOptions([{ label: '전체', value: '' }].concat(floorOptions));
      setSelectedFloor({
        id: '',
        name: { ko: '', en: '', ja: '', cn: '', tw: '' },
        description: { ko: '', en: '', ja: '', cn: '', tw: '' },
        main: false,
        mainName: '',
        mainDescription: '',
      });
    } else {
      setAllFloorOptions(floorOptions);
      setSelectedFloor(floors[0]);
    }
  };

  useEffect(() => {
    handleFloorOptions();
  }, [floors]);

  const handleOpenAlert = () => {
    openModalPopup('ALERT');
    closeModalPopup('CONFIRM');
  };

  // 포인트 fetch
  const fetchPoints = async () => {
    const points = await getMyPoints();

    if (points) {
      setPoints(points);
      setSelectedPoint(points[0]);

      const pointFloors = await fetchFloors(points[0].id);

      if (pointFloors) {
        if (pointFloors.length > 1) {
          // 전체
          await fetchChangeTenants(points[0].id, ChangedStatus.ALL, '', false);
        } else {
          // 첫번째 층
          await fetchChangeTenants(points[0].id, ChangedStatus.ALL, pointFloors[0].id, false);
        }
      }
    }
  };

  // 층 fetch (point)
  const fetchFloors = async (pointId: string) => {
    try {
      const result = await getFloors(pointId);

      if (result) {
        setFloors(result);
        return result;
      }
    } catch (error) {
      setAlertMessage('층 목록을 불러올 수 없습니다. 다시 시도해주세요.');
      handle400Error(error, handleOpenAlert);
    }
  };

  // 언어 리스트 fetch (workspace)
  const fetchLanguages = async () => {
    try {
      const languages = await getLanguages();

      if (languages) {
        const mainLanguage = languages.find(lang => lang.main);
        const displayLanguages = languages.filter(lang => lang.display);

        if (mainLanguage) {
          setMainLang(mainLanguage.code);
        }

        setDisplayLanguages(displayLanguages);
      }
    } catch (error) {
      setAlertMessage('언어 목록을 불러올 수 없습니다. 다시 시도해주세요.');
      handle400Error(error, handleOpenAlert);
    }
  };

  // 현재 floor find
  const findMyFloor = (floorId: string) => {
    const floor: Floor | undefined = floors.find(floor => floor.id === floorId);

    if (floor) {
      setSelectedFloor(floor);
    }
  };

  // 상태 select 변경
  const onChangeStatus = async (option: OptionType) => {
    if (selectedPoint) {
      const { value } = option;

      setStatus(value as ChangedStatus);

      await fetchChangeTenants(selectedPoint.id, value as ChangedStatus, selectedFloor?.id, viewAll);
    }
  };

  // 포인트 select 변경
  const onChangePoint = async (option: OptionType) => {
    const { value: pointId, data } = option;

    setSelectedPoint(data);

    const pointFloors = await fetchFloors(pointId);

    if (pointFloors) {
      // 전체
      if (pointFloors.length > 1) {
        await fetchChangeTenants(pointId, status, '', viewAll);
      } else {
        // 첫번째 층
        await fetchChangeTenants(pointId, status, pointFloors[0].id, viewAll);
      }
    }
  };

  // 층 select 변경
  const onChangeFloor = async (option: OptionType) => {
    if (selectedPoint) {
      findMyFloor(option.value);
      await fetchChangeTenants(selectedPoint.id, status, option.value, viewAll);
    }
  };

  // 전체보기 checkbox
  const onChangeViewAll = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedPoint) {
      const { checked } = e.target;

      setViewAll(checked);
      await fetchChangeTenants(selectedPoint.id, status, selectedFloor?.id, checked);
    }
  };

  // 선택된 테넌트 관리
  const handleCurrentChangeTenant = (changeTenants: ChangeTenant[]) => {
    if (currentChangeTenant) {
      const findTenant = changeTenants.find(item => item.id === currentChangeTenant.id) ?? null;

      if (findTenant) {
        setCurrentChangeTenant(findTenant);

        return;
      }
    }

    if (changedTenants) {
      setCurrentChangeTenant(changeTenants[0]);
    }
  };

  // 변경된 테넌트 fetch
  const fetchChangeTenants = async (pointId: string, status?: ChangedStatus, floorId?: string, viewAll?: boolean) => {
    try {
      setLoadingChangedTenants(true);

      const changedTenantsResult: ChangedTenants = await getChangedTenants(pointId, status, floorId, viewAll);

      setChangedTenants(changedTenantsResult);

      if (changedTenantsResult) {
        handleCurrentChangeTenant(changedTenantsResult.changeTenants);
      }

      setLoadingChangedTenants(false);
    } catch (error) {
      setLoadingChangedTenants(false);
      setAlertMessage('변경된 테넌트를 불러올 수 없습니다.');
      handle400Error(error, handleOpenAlert);
    }
  };

  // 변경사항 확인 완료 시
  const handleChangeStatus = async () => {
    try {
      closeModalPopup('CONFIRM');

      if (currentChangeTenant && selectedPoint) {
        await patchChangeTenantChanged(currentChangeTenant?.id);
        await fetchChangeTenants(selectedPoint.id, status, selectedFloor?.id, viewAll);
      }
    } catch (error) {
      setAlertMessage('다시 시도해주세요');
      handle400Error(error, handleOpenAlert);
    }
  };

  // 변경사항 확인 완료 노출 조건
  const handleVisibleCheckBtn = () => {
    if (!currentChangeTenant) {
      return false;
    }

    if (!currentChangeTenant?.changed) {
      return true;
    }

    return false;
  };

  const handleCheckConfirm = () => {
    openModalPopup('CONFIRM');
  };

  // 토스트 팝업용 타이머 생성
  useEffect(() => {
    tenantToastStore.setToastTimerRef(toastTimerRef);
  }, []);

  useEffect(() => {
    fetchPoints();
  }, []);

  useEffect(() => {
    if (workspaceId) {
      fetchLanguages();
    }
  }, [workspaceId]);

  // 선택된 테넌트 관리
  useEffect(() => {
    if (changedTenants) {
      handleCurrentChangeTenant(changedTenants.changeTenants);
    }
  }, [changedTenants]);

  if (!selectedPoint || !points || !selectedFloor) {
    return <> </>;
  }

  return (
    <>
      <div className={`${TextType.h4} text-gray-99 relative top-[-14px]`}>
        지도 동기화 진행 후 추가 또는 수정된 매장(POI) 리스트입니다.
      </div>

      <div className='w-[1400px] flex gap-6'>
        <div className={`${CONTENTS_BG} w-[896px] h-[800px] flex flex-col gap-5`}>
          {/* 필터 */}
          <ChangedInfoFilter
            onChangeStatus={onChangeStatus}
            onChangePoint={onChangePoint}
            onChangeFloor={onChangeFloor}
            onChangeViewAll={onChangeViewAll}
            viewAll={viewAll}
            points={points}
            allFloorOptions={allFloorOptions}
          />

          {/* table */}
          <ChangedInfoTable
            currentTenantId={currentChangeTenant?.tenant.id ?? ''}
            setCurrentTenant={setCurrentChangeTenant}
            changedTenants={changedTenants}
            isLoading={isLoadingChangedTenants}
          />
        </div>

        {/* 테넌트 정보 수정 필드 */}
        <div className={`${CONTENTS_BG} w-[480px] h-[800px] flex flex-col justify-between`}>
          <TenantChangedEditSpace
            tenant={currentChangeTenant?.tenant ?? null}
            mainLanguage={mainLang}
            languageList={displayLanguages}
            fetchTenant={fetchChangeTenants}
            status={status}
            viewAll={viewAll}
            point={selectedPoint}
          />

          {handleVisibleCheckBtn() && (
            <div className='px-6 min-h-[80px] border-t flex items-center'>
              <Button
                onClick={handleCheckConfirm}
                text='변경사항 확인 완료'
                color={ButtonColor.point}
                buttonStyle='w-full h-[52px]'
              />
            </div>
          )}
        </div>
      </div>

      {openConfirm && (
        <ConfirmModal
          message='변경사항을 모두 확인하셨습니까?'
          onClickCancel={() => closeModalPopup('CONFIRM')}
          onClickOk={handleChangeStatus}
          okMessage='네'
          cancelMessage='아니오'
        />
      )}

      {openAlert && <AlertModal message={alertMessage} closeModal={() => closeModalPopup('ALERT')} />}

      {/* 토스트 팝업 */}
      {/* TODO: APP으로 이동 */}
      {tenantToastStore.isToastOpen && (
        <ToastPopup
          message={tenantToastStore.toastMessage}
          closeToastAfterSeconds={() => {
            tenantToastStore.closeToastAfterSeconds(() => {
              tenantToastStore.setIsToastOpen(false);
            });
          }}
        />
      )}
    </>
  );
};
export default TenantChangedInfoContainer;
