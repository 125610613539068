import { TableHeader } from 'types/common/tableHeader';

export const SCHEDULE_TABLE_HEADER: TableHeader = [
  {
    label: '',
    width: 60,
    direction: 'center',
  },
  {
    label: '노출 순서',
    width: 80,
    direction: 'center',
  },
  {
    label: '구분',
    width: 100,
    direction: 'center',
  },
  {
    label: '타이틀',
    width: 590,
    direction: 'left',
  },
  {
    label: '노출 기간',
    width: 170,
    direction: 'left',
  },
  {
    label: '최근 수정일',
    width: 120,
    direction: 'center',
  },
  {
    label: '상태',
    width: 120,
    direction: 'center',
  },
  {
    label: '삭제',
    width: 80,
    direction: 'center',
  },
];
