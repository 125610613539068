import { TableHeader } from 'types/common/tableHeader';

export const MONITORING_MACHINE_TABLE_HEADER: TableHeader = [
  {
    label: 'No',
    width: 60,
    direction: 'center',
  },
  {
    label: '층',
    width: 80,
    direction: 'left',
  },
  {
    label: '기기 코드',
    width: 140,
    direction: 'left',
  },
  {
    label: '상세위치',
    width: 800,
    direction: 'left',
  },

  {
    label: '연결',
    width: 120,
    direction: 'left',
  },
  // ! 재시작 기능 정상으로 돌아올때까지 비활성화
  // {
  //   label: '재시작',
  //   width: 120,
  //   direction: 'left',
  // },
];
