import useCustomPage from 'hooks/feature/menu/custom/useCustomPage';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalMenu } from 'types/common/globalMenu';
import { RoutePath } from 'types/common/paths';
import { GLOBAL_MENUS } from '../data/gnbMenuData';
import MenuItem from './item/MenuItem';

const GlobalMenuContainer = () => {
  // hook
  const { getCustomPagesCheck } = useCustomPage();
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();

  // state
  const [menus, setMenus] = useState<GlobalMenu[]>(GLOBAL_MENUS);
  const [loading, setLoading] = useState(true);

  /**
   * legacy 워크스페이스라면 매장 관리를 필터링한다.
   */
  const filterMenusByLegacy = async () => {
    if (workspaceId) {
      // 워크스페이스 조회
      const workspace = await getWorkspace(workspaceId);
      if (workspace) {
        if (!workspace.legacy) {
          return setMenus(prev => prev.filter(menu => menu.path !== RoutePath.tenantManage));
        }
      }
    }
  };

  /**
   * 커스텀 페이지가 없다면 메뉴 관리를 필터링한다.
   */
  const filterMenusByCustomPageExist = async () => {
    if (workspaceId) {
      // 커스텀 페이지 유무 체크
      const isExist = await getCustomPagesCheck(workspaceId);

      if (!isExist) {
        return setMenus(prev => prev.filter(menu => menu.path !== RoutePath.customPages));
      }
    }
  };

  /**
   * 메뉴를 필터링 한다.
   */
  const filterMenus = async () => {
    try {
      setLoading(true);
      await filterMenusByLegacy();
      await filterMenusByCustomPageExist();
    } catch {
      console.error('메뉴 필터링 실패');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (workspaceId) {
      filterMenus();
    }
  }, [workspaceId]);

  return (
    <div className='flex gap-10'>{loading ? <></> : menus.map(menu => <MenuItem key={menu.id} menu={menu} />)}</div>
  );
};
export default GlobalMenuContainer;
