import { ICON } from 'constants/icons';
import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { FLEX_CENTER } from 'styles/flex';
import { DisplayType } from 'types/feature/screensaver/getScreensaver';
import DateCalendar from './date-picker/DateCalendar';
import RangeCalendar from './range/RangeCalendar';

type CalendarProps = {
  displayType: DisplayType;
  disabled?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  handleDate: any;
  handleRange: any;
  hasIcon?: boolean;
  width?: string;
};

const ScheduleCalendar = ({
  displayType,
  disabled,
  startDate,
  endDate,
  handleRange,
  handleDate,
  hasIcon = true,
  width,
}: CalendarProps) => {
  const hasRange = displayType === DisplayType.DURATION;

  const calendarRef = useRef<DatePicker>(null);

  // 아이콘 클릭 시  캘린더 오픈
  const handleOpen = () => {
    if (calendarRef.current) {
      calendarRef.current?.setFocus();
    }
  };

  return (
    <div className={`${FLEX_CENTER.row} ${width ? width : 'w-[265px]'} border h-[38px] px-3 border-gray-99 gap-2.5`}>
      {hasRange ? (
        // 기간
        <RangeCalendar calendarRef={calendarRef} handleRange={handleRange} startDate={startDate} endDate={endDate} />
      ) : (
        // 상시
        <DateCalendar calendarRef={calendarRef} handleDate={handleDate} startDate={startDate} />
      )}

      {!disabled && hasIcon && (
        <img className='cursor-pointer' onClick={handleOpen} src={ICON.CALENDAR} alt='calendar icon' />
      )}
    </div>
  );
};

export default ScheduleCalendar;
