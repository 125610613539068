import { ICON } from 'constants/icons';
import React from 'react';

type Props = {
  onClickCancel: () => void;
  onClickSave: () => Promise<void>;
};

const SaveEditBtns = ({ onClickCancel, onClickSave }: Props) => {
  return (
    <div className='flex items-center justify-center h-full gap-2'>
      <div
        className='h-[24px]'
        onClick={e => {
          e.stopPropagation();
          onClickCancel();
        }}
      >
        <img src={ICON.CANCEL} alt='cancel' />
      </div>

      {/* 저장 */}
      <div className='h-[24px]' onClick={onClickSave}>
        <img src={ICON.BLACK_CHECK_BOX} alt='success' />
      </div>
    </div>
  );
};
export default SaveEditBtns;
