export const Endpoint = {
  ssoSignIn: '/signin',
  ssoRefreshToken: '/refreshtoken',
  ssoLogout: '/log-out',

  auth: '/api/auth/token',
  logout: '/api/auth/logout',

  // 동기화
  sync: '/api/synchronization',
  syncCheck: '/api/synchronization/check',
  syncResult: '/api/synchronization/result',

  workspaces: '/api/workspaces',
  workspace: '/api/workspaces/:workspaceId',

  points: '/api/points',
  account: '/api/accounts',

  // 파일 업로드
  files: '/api/files',

  contents: '/api/contents',
  content: '/api/contents/:contentId',

  screensaver: '/api/screensavers',
  screensaverV2: '/v2/api/screensavers',

  // 언어
  languages: '/api/languages',

  // 층
  floors: '/api/floors',

  // 테넌트
  tenants: '/api/tenants',
  tenantCustomField: '/api/tenant-custom-fields/:fieldId',
  changedTenants: 'api/change-tenants',

  // 기기
  machines: '/api/connect-machines',

  // 메뉴
  customPagesV2: '/v2/api/custom-pages',
  customPagesCheck: '/api/custom-pages/check',
  customBoards: '/api/custom-boards',

  // 스케쥴
  plans: '/api/plans',
  events: '/api/events',

  // 통계
  // 사용량
  usageStatisticPerDate: '/api/usage-statistics/date', // 일자별
  usageStatisticsPerDayOfWeek: '/api/usage-statistics/day-of-week', // 요일별
  usageStatisticsPerTime: '/api/usage-statistics/time', // 시간대별
  machineStatistics: '/api/machine-statistics', // 기기별
  // 인기 매장 조회
  tenantStatistics: '/api/tenant-statistics',
  // 인기 카테고리 조회
  categoryStatistics: '/api/tenant-category-group-statistics',

  // 대시보드
  dashboardsMachineStatus: '/api/dashboards/machine-statuses',
  dashboardScreensavers: '/api/dashboards/screensavers',
  dashboardTotalUsages: '/api/dashboards/total-usages',
  dashboardWeeklyUsages: '/api/dashboards/weekly-usages',
};
