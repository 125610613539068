import React from 'react';
import Header from '../header/Header';
import Navigation from '../nav/Navigation';
import Contents from '../contents/Contents';
import Footer from '../footer/Footer';

const Layout = () => {
  return (
    <div className='flex flex-col w-full h-full bg-white'>
      <Header />
      <div className='flex'>
        <Navigation />
        <Contents />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
