import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useEffect } from 'react';
import { RoutePath } from 'types/common/paths';
import { DashBoardMachineStatus } from 'types/feature/dashboard/dashboard';
import DashboardSectionNoBg from '../section/DashboardSectionNoBg';
import DashboardState from '../section/DashboardState';

const RealtimeMachineStatus = (props: {
  selectedPoint: CommonOptionType;
  machineStatus: DashBoardMachineStatus;
  loading: boolean;
  error: boolean;
  fetchMachineUsage: (pointId: string) => Promise<void>;
}) => {
  const { customNavigate } = useCustomNavigate();

  useEffect(() => {
    props.fetchMachineUsage(props.selectedPoint.value || '');
  }, []);

  return (
    <div className='flex flex-col flex-1 gap-5'>
      <DashboardSectionNoBg
        title='실시간 기기현황'
        loading={props.loading}
        error={props.error}
        onClickMore={() => {
          customNavigate(RoutePath.machineManage, '', '', '', {
            state: {
              pointId: props.selectedPoint.value,
              pointName: props.selectedPoint.label,
            },
          });
        }}
        height='124px'
      >
        <div className='flex gap-5'>
          <DashboardState label='ON' value={props.machineStatus.connected} theme='GREEN' />
          <DashboardState label='OFF' value={props.machineStatus.disconnected} theme='RED' />
        </div>
      </DashboardSectionNoBg>
    </div>
  );
};
export default RealtimeMachineStatus;
