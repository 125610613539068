import CommonCalendarHeader, {
  CalendarHeaderProps,
} from 'components/common/calendar/common/header/CommonCalendarHeader';
import 'components/common/calendar/style/react-datepicker.css';
import { TextType } from 'components/common/title/Title';
import { ko } from 'date-fns/locale';
import { RefObject } from 'react';
import DatePicker from 'react-datepicker';
import { setTomorrow } from 'utils/date/setTomorrow';

const calendarInputStyle = 'bg-transparent w-full h-full py-1 border-none text-gray-44 tracking-tighter';

type Props = {
  calendarRef: RefObject<DatePicker<never, undefined>>;
  handleDate: any;
  startDate: Date | null;
  disabled?: boolean;
};

const DateCalendar = ({ calendarRef, handleDate, startDate, disabled }: Props) => {
  // custom header
  const renderHeader = ({ decreaseMonth, increaseMonth, date }: CalendarHeaderProps) => (
    <CommonCalendarHeader decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} date={date} />
  );

  return (
    <DatePicker
      disabled={disabled}
      selectsRange={false}
      className={`${calendarInputStyle} ${TextType.body1}`}
      selected={startDate}
      dateFormat='yyyy.MM.dd'
      onChange={handleDate}
      startDate={startDate}
      locale={ko}
      minDate={setTomorrow()}
      renderCustomHeader={renderHeader}
      ref={calendarRef}
    />
  );
};
export default DateCalendar;
