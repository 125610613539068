import { TableHeader } from 'types/common/tableHeader';

export const MACHINE_USAGE_TABLE_HEADER: TableHeader = [
  {
    label: '순위',
    width: 100,
    direction: 'left',
  },
  {
    label: '기기코드',
    width: 200,
    direction: 'left',
  },
  {
    label: '빌딩',
    width: 240,
    direction: 'left',
  },
  {
    label: '층',
    width: 80,
    direction: 'left',
  },
  {
    label: '키오스크 사용량',
    width: 200,
    direction: 'left',
  },
  {
    label: '모바일 사용량',
    width: 200,
    direction: 'left',
  },
  {
    label: '전체 사용량',
    width: 200,
    direction: 'left',
  },
  {
    label: '비율',
    width: 100,
    direction: 'left',
  },
];
