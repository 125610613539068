import { AxiosError } from 'axios';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import TableNoContent from 'components/common/no-content/TableNoContent';
import Pagination from 'components/common/pagination/Pagination';
import ThumbnailTableRowCell from 'components/common/thumbnail/ThumbnailTableRowCell';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { PAGINATION_LIMIT } from 'data/common';
import { SCREEN_TYPE } from 'data/screenType';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useContents from 'hooks/feature/content/useContents';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CONTENTS_BG } from 'styles/background';
import { TABLE_PADDING } from 'styles/padding';
import { FILE_TYPES } from 'types/common/file/file';
import { RoutePath } from 'types/common/paths';
import { Content, ScreenType } from 'types/feature/content/contents';
import { reverseIndex } from 'utils/index/reverseIndex';
import ContentTableHeader from './header/ContentTableHeader';

type Props = {
  contents: Content[];
  currentPage: number;
  totalElements: number | undefined;
  onChangePage: (pageNum: number) => void;
  refreshContents: (pageNum: number, title?: string, screenType?: ScreenType) => Promise<void>;
};

const ContentsTable = ({ contents, currentPage, totalElements, onChangePage, refreshContents }: Props) => {
  const { workspaceId } = useParams();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { deleteContent } = useContents();

  const [openDeleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteContentId, setDeleteContentId] = useState('');
  const [deleteContentScreenType, setDeleteContentScreenType] = useState<ScreenType>('VERTICAL');

  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  const handleClickContent = (id: string) => {
    navigateToReplacedPath(RoutePath.contentDetail, workspaceId, ':contentId', id);
  };

  // 삭제 버튼 클릭
  const deleteContentRow = (e: React.MouseEvent<HTMLElement>, content: Content) => {
    e.stopPropagation();
    setDeleteContentId(content.id);
    setDeleteContentScreenType(content.screenType);
    setDeleteConfirm(true);
  };

  // 삭제
  const handleDelete = async () => {
    try {
      if (deleteContentId && deleteContentScreenType) {
        await deleteContent(deleteContentId);
        await refreshContents(currentPage, '', deleteContentScreenType);

        setDeleteConfirm(false);
        openDoneAlert();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setAlertMessage('콘텐츠를 삭제하는 중 오류가 발생했습니다.');
          setOpenAlert(true);
        }
      }
    }
  };

  // 삭제 완료
  const openDoneAlert = () => {
    setOpenAlert(true);
    setAlertMessage('콘텐츠를 삭제하였습니다.');
  };

  return (
    <>
      <div className={`${CONTENTS_BG} ${TABLE_PADDING}`}>
        <table>
          <ContentTableHeader />

          <tbody>
            {contents.length > 0 ? (
              contents.map((row, index) => {
                return (
                  <tr
                    key={row.id}
                    className='cursor-pointer h-[52px] border-b text-center text-gray-44'
                    onClick={handleClickContent.bind(this, row.id)}
                  >
                    {/* index 11 21 31... => currentPage * PAGINATION_LIMIT + (index + 1) */}
                    <td className='text-sm font-h3 text-gray-99'>{reverseIndex(contents.length)[index]}</td>
                    <td className={`${TextType.h4} text-inherit`}>{SCREEN_TYPE[row.screenType]}</td>
                    <td className={`${TextType.h4} text-inherit`}>{FILE_TYPES[row.fileType]}</td>
                    <td className='text-left text-inherit text-[14px] font-bold'>
                      <ThumbnailTableRowCell index={index} title={row.title} files={row.file} fileType={row.fileType} />
                    </td>
                    <td className={`${TextType.body1} text-inherit`}>{row.updatedDate}</td>
                    <td onClick={e => deleteContentRow(e, row)}>
                      <div className='flex items-center justify-center w-full'>
                        <img src={ICON.DELETE_BUTTON} alt='delete icon' />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <TableNoContent message='등록된 콘텐츠가 없습니다.' colSpan={6} />
            )}
          </tbody>
        </table>

        {contents.length > 0 && (
          <Pagination
            totalElements={totalElements ?? 0}
            currentPage={currentPage}
            onChangePage={onChangePage}
            // hasNext={Boolean(totalElements && totalElements / PAGINATION_LIMIT > currentPage - 1)}
          />
        )}
      </div>

      {openDeleteConfirm && (
        <ConfirmModal
          message='콘텐츠를 삭제하시겠습니까?'
          onClickOk={handleDelete}
          onClickCancel={() => setDeleteConfirm(false)}
        />
      )}

      {openAlert && <AlertModal message={alertMessage} closeModal={() => setOpenAlert(false)} />}
    </>
  );
};
export default ContentsTable;
