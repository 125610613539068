import { RoutePath } from 'types/common/paths';
import { Menu } from 'types/guide/menu';

// 메뉴
export const MENUS: Menu[] = [
  {
    index: '1',
    title: '로그인',
    path: RoutePath.loginGuide,
    children: [{ index: '1-1', title: '회원가입 및 로그인 조건', path: 'sign' }],
  },

  {
    index: '2',
    title: '워크스페이스',
    path: RoutePath.workspaceGuide,
    children: [{ index: '2-1', title: '선택 및 변경', path: 'workspace' }],
  },

  {
    index: '3',
    title: '기본정보 관리',
    path: RoutePath.basicGuide,
    children: [
      { index: '3-1', title: '운영시간 설정', path: 'operation' },
      { index: '3-2', title: '휴무일 설정', path: 'holidays' },
    ],
  },

  {
    index: '4',
    title: '콘텐츠 관리',
    path: RoutePath.contentsGuide,
    children: [
      { index: '4-1', title: '콘텐츠 등록', path: 'add-content' },
      { index: '4-2', title: '콘텐츠 수정', path: 'edit-content' },
      { index: '4-3', title: '콘텐츠 삭제', path: 'delete-content' },
    ],
  },

  {
    index: '5',
    title: '스케줄 관리',
    path: RoutePath.scheduleGuide,
    children: [
      { index: '5-1', title: '스케줄 추가', path: 'add-schedule' },
      { index: '5-2', title: '스케줄 수정', path: 'edit-schedule' },
      { index: '5-3', title: '스케줄 삭제', path: 'delete-schedule' },
      { index: '5-4', title: '스케줄 순서 편집', path: 'order-schedule' },
    ],
  },

  {
    index: '6',
    title: '매장 관리',
    path: RoutePath.storeGuide,
    children: [
      { index: '6-1', title: '매장 정보 확인', path: 'store' },
      { index: '6-2', title: '매장 사용유무 설정', path: 'set-use-store' },
      { index: '6-3', title: '팝업 타입 설정', path: 'set-popup' },
      { index: '6-4', title: '매장 정보 입력', path: 'set-store' },
    ],
  },
];
