import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const Contents = () => {
  const location = useLocation();

  const pathname = location.pathname;

  const elementId = location.hash;

  useEffect(() => {
    if (elementId === '') {
      window.scrollTo(0, 0);
      return;
    }

    const el = document.getElementById(elementId.replace('#', ''));

    if (el) {
      el.scrollIntoView({ behavior: 'auto', block: 'start' });
      el.style.color = '#4869DF';
    }

    return () => {
      if (el) {
        el.style.color = 'black';
      }
    };
  }, [elementId, pathname]);

  return (
    <div className='w-full h-full p-12 overflow-y-auto'>
      <Outlet />
    </div>
  );
};

export default Contents;
