/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { loginLocalAccess } from 'data/storage/storageKey';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useHandleError from 'hooks/common/useError';
import useSsoAuth from 'hooks/common/useSsoAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore } from 'stores/auth';
import { AuthTokens } from 'types/common/auth';
import { RoutePath } from 'types/common/paths';

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
}

const useMultipartApi = () => {
  // hook
  const { handleResponseError } = useHandleError();
  const navigate = useNavigate();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();
  const { refreshToken } = useSsoAuth();

  // store
  const authStore = useAuthStore();

  const apiFile: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: false,
  });

  apiFile.interceptors.request.use(
    async config => {
      config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8';
      config.headers['Authorization'] = `Bearer ${sessionStorage.getItem(loginLocalAccess)}`;

      return config;
    },
    error => Promise.reject(error),
  );

  let isRetrying = false;

  apiFile.interceptors.response.use(
    response => {
      return response.data;
    },
    async (error: AxiosError) => {
      const { response, config } = error;

      if (response) {
        // 오류 상태가 401 이고 재시도가 아직 시도되지 않았는지 확인
        if (response.status === 401 && !isRetrying) {
          isRetrying = true; // 전역 재시도 상태 설정

          try {
            // 1. refreshToken API 이용해서 새로운 accessToken, refreshToken 발급
            const newToken: AuthTokens = await refreshToken();
            apiFile.defaults.headers['Authorization'] = `Bearer ${newToken.access_token}`;

            // 2-1. token 성공적으로 가져왔다면 새로운 accessToken 으로 다시 API 요청
            const retryResponse = await apiFile(config as AxiosRequestConfig);

            return retryResponse.data;
          } catch (retryError) {
            // 2-2. 이것마저 실패했다면 팝업 알림 후, 통합인증 로그인 페이지로 이동
            if (
              error instanceof AxiosError &&
              error.response &&
              error.response.status >= 400 &&
              error.response.status < 500
            ) {
              authStore.setIsTokenExpired(true);
            }

            throw retryError;
          } finally {
            isRetrying = false; // 재시도 완료 후 상태 리셋
          }
        }

        // api 에러
        handleResponseError(error);
        throw error;
      }

      // 서버 장애, 네트워크 에러 등
      if (workspaceId) {
        navigateToReplacedPath(RoutePath.errorWithWorkspaceId, workspaceId);
        throw error;
      }

      navigate(RoutePath.error);
      throw error;
    },
  );

  return { apiFile };
};

export default useMultipartApi;
