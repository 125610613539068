import TableCsvDownload from 'components/common/table/csv/TableCsvDownload';
import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { Fragment } from 'react';
import { CategoryUsage } from 'types/feature/statistics/categoryUsage';
import { CATEGORY_USAGE_TABLE_HEADER } from './header/categoryUsageTableHead';
import { CATEGORY_USAGE_TABLE_HEADER_WITHOUT_POINT } from './header/categoryUsageTableHeadWithoutPoint';

type Props = {
  categoryUsageList: CategoryUsage[];
  isAll: boolean;
  onClickDownload: () => Promise<void>;
};

const CategoryUsageTable = ({ categoryUsageList, isAll, onClickDownload }: Props) => {
  return (
    <div className='flex flex-col w-full gap-3 px-10 pt-6 pb-10 bg-white border border-gray-ea'>
      {/* 총 개수, 다운로드 버튼 */}
      <TableCsvDownload totalCount={categoryUsageList.length} onClickDownload={onClickDownload} />

      <table className='w-full border-collapse'>
        {isAll ? (
          <CommonTableHeader tableHead={CATEGORY_USAGE_TABLE_HEADER} />
        ) : (
          <CommonTableHeader tableHead={CATEGORY_USAGE_TABLE_HEADER_WITHOUT_POINT} />
        )}

        {categoryUsageList.map((data, index) => (
          <Fragment key={data.tenantCategoryGroupId}>
            <tbody>
              <tr className='h-[52px] text-[14px]'>
                {/* 순위 */}
                <td className='border-b p-2.5'>{index + 1}</td>

                {/* 카테고리명 */}
                <td className='border p-2.5'>{data.tenantCategoryGroupName.ko}</td>

                {/* 빌딩 - 전체 빌딩일 경우만 노출 */}
                {isAll && <td className='border p-2.5'>{data.pointName.ko}</td>}

                {/* 검색수 */}
                <td className='border p-2.5'>{Number(data.count).toLocaleString()}</td>

                {/* 점유율 */}
                <td className='border border-r-0 p-2.5'>{data.ratio}%</td>
              </tr>
            </tbody>
          </Fragment>
        ))}
      </table>
    </div>
  );
};
export default CategoryUsageTable;
