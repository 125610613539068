import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Plan } from 'types/feature/plan/plan';

const usePlan = () => {
  const { api } = useApi();

  const getPlans = async () => {
    const response = await api.get<ApiResponse<Plan[]>>(Endpoint.plans);

    if (response.data) {
      return response.data;
    }
  };

  // ! 다음 전시 스케쥴로 변경 (임시)
  const postShiftEvent = async (planId: string) => {
    await api.post(`${Endpoint.plans}/${planId}/schedule`);
  };

  // ! 전시 스케쥴 리셋 (임시)
  const postResetPlan = async (planId: string) => {
    await api.post(`${Endpoint.plans}/${planId}/reset`);
  };

  return {
    getPlans,
    postShiftEvent,
    postResetPlan,
  };
};

export default usePlan;
