import IMAGE3 from 'assets/guide/image/image3.png';
import IMAGE4 from 'assets/guide/image/image4.png';
import IMAGE5 from 'assets/guide/image/image5.png';
import IMAGE6 from 'assets/guide/image/image6.png';
import ImageWrapper from 'components/guide/image/Image';
import Title from 'components/guide/title/Title';
import { LIST_STYLE } from 'data/guide/ui';
import React from 'react';

const BasicGuide = () => {
  return (
    <>
      <Title title='기본정보 관리' />

      <div className='flex flex-col gap-20'>
        <section className='flex flex-col gap-7'>
          <p id='operation' className='text-[20px] font-semibold'>
            3-1. 운영시간 설정
          </p>
          <ImageWrapper imageUrl={IMAGE3} />
          <ul className={LIST_STYLE}>
            <li>{`운영시간 설정 시 1) 사용 선택 후 시작 및 종료 시간을 선택하여 노출할 수 있습니다.`}</li>
          </ul>
          <ImageWrapper imageUrl={IMAGE4} />
          <ul className={LIST_STYLE}>
            <li>{`운영시간 2) 미사용 선택 시에는 운영시간을 노출하지 않을 수 있습니다.`}</li>
          </ul>
          <ImageWrapper imageUrl={IMAGE5} />
          <ul className={LIST_STYLE}>
            <li>
              <b>{`단, 미사용 후 3) 텍스트 입력란에 직접 문구를 입력할 경우 입력된 문구가 운영시간 정보로 노출됩니다.`}</b>
            </li>
          </ul>
        </section>

        <section className='flex flex-col gap-7'>
          <p id='holidays' className='text-[20px] font-semibold'>
            3-2. 휴무일 설정
          </p>
          <ImageWrapper imageUrl={IMAGE6} />
          <ul className={LIST_STYLE}>
            <li>{`휴무일 설정 시 1) 사용 선택 후 2) 달력에서 특정 날짜를 선택하여 휴무일을 표시할 수 있습니다.`}</li>
            <li>{`휴무일은 최대 4개까지 선택 가능합니다.`}</li>
            <li>{`3) X 또는 전체삭제 버튼을 통해 추가한 휴무일을 삭제할 수 있습니다.`}</li>
            <li>{`휴무일 미사용 선택 시에는 휴무일을 노출하지 않을 수 있습니다.`}</li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default BasicGuide;
