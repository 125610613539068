import IMAGE12 from 'assets/guide/image/image12.png';
import IMAGE13 from 'assets/guide/image/image13.png';
import IMAGE14 from 'assets/guide/image/image14.png';
import IMAGE15 from 'assets/guide/image/image15.png';
import IMAGE16 from 'assets/guide/image/image16.png';
import IMAGE17 from 'assets/guide/image/image17.png';
import IMAGE18 from 'assets/guide/image/image18.png';
import IMAGE19 from 'assets/guide/image/image19.png';
import IMAGE20 from 'assets/guide/image/image20.png';
import ImageWrapper from 'components/guide/image/Image';
import Title from 'components/guide/title/Title';
import { LIST_STYLE } from 'data/guide/ui';

const ScheduleGuide = () => {
  return (
    <>
      <Title title='스케줄 관리' />

      <div className='flex flex-col gap-20'>
        <section className='flex flex-col gap-7'>
          <p id='add-schedule' className='text-[20px] font-semibold'>
            5-1. 스케줄 추가
          </p>
          <ImageWrapper imageUrl={IMAGE12} />
          <ul className={LIST_STYLE}>
            <li>{`1) 키오스크 유형에 맞게 가로/세로 콘텐츠를 스케줄에 추가할 수 있습니다.`}</li>
            <li>
              {`2) 노출할 콘텐츠를 선택하시면 3) 해당 콘텐츠를 미리보기할 수 있으며, 4) 상시 또는 기간을 선택하여 노출일자를 설정할 수 있습니다.`}
            </li>
          </ul>
          <div className='flex gap-5 w-2/3'>
            <ImageWrapper imageUrl={IMAGE13} />
            <ImageWrapper imageUrl={IMAGE14} />
          </div>

          <ul className={LIST_STYLE}>
            <li>
              {`5) 상시의 경우 시작일만 설정하시면 되며, 6) 기간일 경우 시작일과 종료일을 모두 설정하셔야 합니다.`}
            </li>
          </ul>
          <ImageWrapper imageUrl={IMAGE15} />
          <ul className={LIST_STYLE}>
            <li>
              {`7) 스케줄 추가 시 노출 기간에 따라 상태가 결정되며 상태는 예정, 진행, 삭제예정, 종료로 구분됩니다.`}
            </li>
          </ul>

          <div className='bg-gray-100 p-5 w-2/3 rounded-sm flex flex-col gap-1'>
            <b className='text-[#666]'>스케줄 상태 정의</b>
            <ul className='text-[#666] text-[14px] leading-6'>
              <li>
                <b>예정</b> : 노출 시작일이 오늘 이전으로 아직 노출이 진행되지 않은 상태
              </li>
              <li>
                <b>진행</b> : 노출 시작일이 오늘 이후로 현재 노출이 진행되고 있는 상태
              </li>
              <li>
                <b>삭제예정</b> : 진행중인 스케줄을 삭제한 상태로 익일부터 삭제 적용
              </li>
              <li>
                <b>종료</b> : 노출 종료일이 지난 상태
              </li>
            </ul>
          </div>
        </section>

        <section className='flex flex-col gap-7'>
          <p id='edit-schedule' className='text-[20px] font-semibold'>
            5-2. 스케줄 수정
          </p>
          <ImageWrapper imageUrl={IMAGE16} />
          <ul className={LIST_STYLE}>
            <li>
              {`1) 스케줄 수정은 예정, 진행 상태인 경우에만 기간 및 유형을 수정할 수 있으며, 삭제예정, 종료인 경우에는 기간 및 유형 수정이 불가합니다.`}
            </li>
          </ul>

          <ImageWrapper imageUrl={IMAGE17} />
          <ul className={LIST_STYLE}>
            <li>{`2) 상태 예정이며, 노출 유형 상시/기간인 경우 기간 및 유형 모두 수정 가능`}</li>
          </ul>

          <ImageWrapper imageUrl={IMAGE18} />
          <ul className={LIST_STYLE}>
            <li>
              {`3) 상태 진행이며, 노출 유형 상시인 경우 유형만 수정 가능하며 시작일 수정 불가`}
              <ul className='pl-4'>
                <li>{`4) 상시에서 기간으로 수정 시 시작일은 유지되며, 종료일만 수정 가능`}</li>
              </ul>
            </li>

            <li>
              {`4) 상태 진행이며, 노출 유형 기간인 경우 유형 수정 가능하며 종료일만 수정 가능`}
              <ul className='pl-4'>
                <li>{`3) 기간에서 상시로 수정 시 시작일은 유지되며, 시작일 수정 불가`}</li>
              </ul>
            </li>
          </ul>
        </section>

        {/* 삭제 */}
        <section className='flex flex-col gap-7'>
          <p id='delete-schedule' className='text-[20px] font-semibold'>
            5-3. 스케줄 삭제
          </p>
          <ImageWrapper imageUrl={IMAGE19} />
          <ul className={LIST_STYLE}>
            <li>{`1) 스케줄 삭제는 예정, 진행, 종료인 경우에만 가능하며, 삭제예정인 경우에는 불가합니다.`}</li>
            <li>
              예정, 종료인 경우 삭제 시 스케줄 목록에서 바로 삭제되며, 진행인 경우 삭제 시 상태가 진행에서 삭제예정으로
              변경됩니다.
            </li>
          </ul>
        </section>

        <section className='flex flex-col gap-7'>
          <p id='order-schedule' className='text-[20px] font-semibold'>
            5-4. 스케줄 순서편집
          </p>
          <ImageWrapper imageUrl={IMAGE20} />
          <ul className={LIST_STYLE}>
            <li>{`1) 스케줄 추가 후 키오스크 유형에 따라 노출 순서를 편집할 수 있습니다.`}</li>
            <div className='bg-gray-100 p-5 w-2/3 rounded-sm flex flex-col gap-1 mt-5'>
              <b className='text-[#666]'>순서 편집 방법</b>
              <ul className='text-[#666] text-[14px] leading-6 list-decimal pl-4'>
                <li>순서를 편집할 유형(세로형 또는 가로형)을 선택하세요.</li>
                <li>스케줄 목록에 있는 순서편집 버튼을 Drag and Drop하여 원하는 순서로 변경하세요.</li>
                <li>순서편집의 경우 변경 시 별도 저장없이 바로 반영됩니다.</li>
              </ul>
            </div>
          </ul>
        </section>
      </div>
    </>
  );
};

export default ScheduleGuide;
