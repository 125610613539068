import { create } from 'zustand';

type Props = {
  isOpenWorkspaceModal: boolean;
  setOpenWorkspaceModal: (isOpen: boolean) => void;

  workspaceModalText: string;
  setWorkspaceModalText: (text: string) => void;
};

const useModalStore = create<Props>(set => ({
  isOpenWorkspaceModal: false,
  setOpenWorkspaceModal: (isOpenWorkspaceModal: boolean) => set({ isOpenWorkspaceModal }),

  workspaceModalText: '',
  setWorkspaceModalText: (workspaceModalText: string) => set({ workspaceModalText }),
}));

export default useModalStore;
