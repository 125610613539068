import ConnectedBadge from 'components/common/badge/connected/ConnectedBadge';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import useModal from 'hooks/common/useModal';
import { ConnectedMachine } from 'types/feature/machine/machine';

type Props = {
  machineIndex: number;
  connectedMachine: ConnectedMachine;
  handleReloadMachine: (machineId: string) => Promise<void>;
};

const MachineListBody = ({ connectedMachine, machineIndex, handleReloadMachine }: Props) => {
  const { closeModalPopup, openConfirm } = useModal();

  const isSameMachineWithUpdatedMachine = connectedMachine.connected;

  return (
    <>
      <tbody>
        <tr className='h-[52px] text-[14px]'>
          {/* No */}
          <td className='border-b text-center p-2.5'>{machineIndex}</td>

          {/* 층 */}
          <td className='border p-2.5'>{connectedMachine.floor.name.ko || ''}</td>

          {/* 기기코드 */}
          <td className='border p-2.5'>{connectedMachine.idSuffix}</td>

          {/* 상세위치 */}
          <td className='border p-2.5'>{connectedMachine.description}</td>

          {/* 연결 */}
          <td className='border p-2.5'>
            <ConnectedBadge status={isSameMachineWithUpdatedMachine} />
          </td>

          {/* 재시작 */}
          {/* // ! 재시작 기능 정상으로 돌아올때까지 비활성화 */}
          {/* <td className='border-b p-2.5'>
            {isSameMachineWithUpdatedMachine ? (
              <div className='flex gap-2.5' onClick={() => openModalPopup('CONFIRM')}>
                <img src={ICON.RELOAD_MACHINE} alt='reload' />
              </div>
            ) : (
              <div className='flex gap-2.5'>
                <img src={ICON.RELOAD_MACHINE_DISABLED} alt='reload' />
              </div>
            )}
          </td> */}
        </tr>
      </tbody>

      {openConfirm && (
        <ConfirmModal
          message='기기를 재시작 하시겠습니까?'
          onClickCancel={() => closeModalPopup('CONFIRM')}
          onClickOk={() => {
            closeModalPopup('CONFIRM');
            handleReloadMachine(connectedMachine.id);
          }}
        />
      )}
    </>
  );
};

export default MachineListBody;
