import { create } from 'zustand';

interface AuthStoreProps {
  /** 로그인 처리 */
  isLoggedIn: boolean;
  setIsLoggedIn: (isLogIn: boolean) => void;

  /** 로그아웃 모달 처리 */
  isLogout: boolean;
  setIsLogout: (isLogout: boolean) => void;

  /** 로그아웃 처리 */
  isTokenExpired: boolean;
  setIsTokenExpired: (isTokenExpired: boolean) => void;
}

export const useAuthStore = create<AuthStoreProps>(set => ({
  /** 로그인 처리 */
  isLoggedIn: false,
  setIsLoggedIn: (isLogIn: boolean) => set(() => ({ isLoggedIn: isLogIn })),

  /** 로그아웃 모달 처리 */
  isLogout: false,
  setIsLogout: (isLogout: boolean) => set(() => ({ isLogout })),

  /** 토큰 만료 처리 용 */
  isTokenExpired: false,
  setIsTokenExpired: (isTokenExpired: boolean) => set(() => ({ isTokenExpired })),
}));
