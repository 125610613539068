import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import useTenant from 'hooks/feature/tenant/useTenant';
import { useEffect, useState } from 'react';
import usePoiContentStore from 'stores/poiContent';
import { Language, MultiLangs } from 'types/feature/multi-lang/language';
import { PoiWithTenants, Tenant } from 'types/feature/tenant/tenant';
import PoiInfo from '../info/map/PoiInfo';
import TenantInfo from '../info/TenantInfo';

type Props = {
  tenant: Tenant;
  languageList: Language[];
  mainLanguage: string;
  fetchTenant: (contentId: string) => Promise<void>;
  pointName: MultiLangs;
  pointId: string;
  clickedPoiId?: string;
};

const TenantEditableFields = ({
  tenant,
  languageList,
  mainLanguage,
  fetchTenant,
  pointName,
  pointId,
  clickedPoiId,
}: Props) => {
  const [tenantOptions, setTenantOptions] = useState<CommonOptionType[]>([]);
  const [selected, setSelected] = useState<CommonOptionType>();
  const [isShowSelect, setShowSelect] = useState<boolean>(false);

  const poisMap = new Map<string, Tenant[]>();

  const { getPoisWithTenants } = useTenant();

  const poiContentStore = usePoiContentStore();

  const fetchPoisWithTenants = async () => {
    const poisWithTenants: PoiWithTenants[] = await getPoisWithTenants(pointId);

    if (poisWithTenants) {
      poisWithTenants.forEach(poiWithTenants => {
        poisMap.set(poiWithTenants.poiId, poiWithTenants.tenants);
      });

      if (clickedPoiId) {
        const findPois = poisMap.get(clickedPoiId);

        if (!findPois || findPois.length < 2) {
          setShowSelect(false);
          return;
        }

        if (findPois.length > 1) {
          const options = findPois.map(poi => ({
            label: poi.mainName,
            value: poi.content.poiId,
            data: poi,
          }));

          const sortedOptions = options.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

          setTenantOptions(sortedOptions);
          setSelected(sortedOptions[0]);

          await fetchTenant(sortedOptions[0].data.contentId);

          setShowSelect(true);
        }
      }
    }
  };

  const onChangeSelect = (option: CommonOptionType) => {
    setSelected(option);
    poiContentStore.setCurrentContentId(option.data.content.id);
  };

  useEffect(() => {
    if (clickedPoiId) {
      fetchPoisWithTenants();
    }
  }, [clickedPoiId]);

  return (
    <div className='flex flex-col gap-6 pt-6'>
      <>
        {/* 다중 contents select box */}
        {isShowSelect && (
          <div className='px-10'>
            <CommonSelectBox options={tenantOptions} onChangeSelect={onChangeSelect} selected={selected} width='full' />
          </div>
        )}

        {/* 지도 정보 */}
        <PoiInfo tenant={tenant} languageList={languageList} mainLanguage={mainLanguage} pointName={pointName} />
      </>

      <Border borderColor={BorderColor.light_gray} />

      {/* 매장 정보 */}
      <TenantInfo languageList={languageList} tenant={tenant} fetchTenant={fetchTenant} />
    </div>
  );
};
export default TenantEditableFields;
