import Title from 'components/guide/title/Title';
import { LIST_STYLE } from 'data/guide/ui';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const LoginGuide = () => {
  const location = useLocation();

  return (
    <div>
      <Title title='로그인' />

      <div className='flex flex-col gap-5'>
        <section className='flex flex-col gap-3'>
          <p id='sign' className='text-[20px] font-semibold'>
            1.1 회원가입 및 로그인 조건
          </p>
          <ul className={LIST_STYLE}>
            <li>다비오 스튜디오™ 에서 가입한 아이디/비밀번호를 DID Platform에서 그대로 사용 가능합니다.</li>
            <li>비밀번호 변경을 원하실 경우 스튜디오에 접속하신 후 내정보조회에서 비밀번호를 변경할 수 있습니다.</li>
            <li>로그인이 실패한 경우 다음 사항을 확인해 주세요.</li>
            <ul className='list-decimal pl-8'>
              <li>아이디 또는 비밀번호를 정확히 입력했는지 확인해 주세요.</li>
              <li>
                이용가능한 워크스페이스가 생성되지 않은 경우 로그인이 불가합니다.
                <b>※워크스페이스는 관리자가 초기 세팅 후 제공됩니다.</b>
              </li>
            </ul>
            * 그 외 사항으로 로그인 실패 발생 시 관리자에게 문의해 주세요.
          </ul>

          <a className='font-bold ' target='_blank' href='https://www.dabeeostudio.com/'>
            [참고] 다비오 스튜디오 URL
          </a>
          <a className='font-bold' target='_blank' href='https://www.dabeeo.com/product/studio/detail'>
            [참고] 스튜디오 소개
          </a>
        </section>
      </div>
    </div>
  );
};

export default LoginGuide;
