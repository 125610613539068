import { TextType } from 'components/common/title/Title';
import InnerGnb from 'components/common/layout/gnb/inner/InnerGnb';
import { ICON } from 'constants/icons';

const NotFountPage = () => {
  return (
    <div>
      <InnerGnb />
      <div className='absolute top-0 left-0 flex flex-col items-center justify-center w-screen h-screen gap-4 bg-bg-f9'>
        <img src={ICON.ALERT} alt='알림 아이콘' />
        <div className={`${TextType.h4} text-gray-cc`}>존재하지 않는 페이지입니다.</div>
      </div>
    </div>
  );
};

export default NotFountPage;
