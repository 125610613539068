import DisplayStatusBadge from 'components/common/badge/display-status/DisplayStatusBadge';
import DraggableButton from 'components/common/draggable-button/DraggableButton';
import FileTypeIcon from 'components/common/file-type-icon/FileTypeIcon';
import ThumbnailTableRowCell from 'components/common/thumbnail/ThumbnailTableRowCell';
import { TextType } from 'components/common/title/Title';
import { SCREEN_TYPE } from 'data/screenType';
import { DraggableProvided } from 'react-beautiful-dnd';
import { ScreenType } from 'types/feature/content/contents';
import { DisplayStatus, GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { convertDashToDot } from 'utils/date/dateUtils';
import ScheduleDelete from './delete/ScheduleDelete';
import ScheduleDisplayRange from './display-range/ScheduleDisplayRange';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';

type Props = {
  row: GetScreensaver;
  index: number;
  refetchScreensaver: (pointId: string, screenType: ScreenType) => Promise<void>;
  provided: DraggableProvided;
  selectedPoint: CommonOptionType | undefined;
};

const ScheduleTableRow = ({ row: screensaver, index, refetchScreensaver, provided, selectedPoint }: Props) => {
  const disabledAction =
    screensaver.displayStatus === DisplayStatus.WAIT_DELETE ||
    screensaver.displayStatus === DisplayStatus.CLOSE ||
    screensaver.displayStatus === DisplayStatus.NONE;

  return (
    <tr
      {...provided.draggableProps}
      ref={provided.innerRef}
      key={screensaver.id}
      className='h-[52px] border-b text-center cursor-default'
    >
      {/* 순서변경 */}
      <td className='px-2.5'>
        <div {...provided.dragHandleProps}>
          <DraggableButton />
        </div>
      </td>

      {/* 노출 순서 */}
      <td className='text-gray-99 px-2.5 text-sm'>{index + 1}</td>
      {/* 구분 */}
      <td className={`${TextType.h4} whitespace-nowrap text-gray-44 px-2.5`}>
        {SCREEN_TYPE[screensaver.content.screenType]}
      </td>
      {/* 타이틀 */}
      <td className={`${TextType.h4} text-left text-gray-44 px-2.5`}>
        <div className='flex items-center h-full gap-2'>
          <FileTypeIcon fileType={screensaver.content.fileType} />
          <ThumbnailTableRowCell
            index={index}
            title={screensaver.content.title}
            files={screensaver.content.file}
            fileType={screensaver.content.fileType}
          />
        </div>
      </td>

      {/* 노출 기간 */}
      <td>
        <ScheduleDisplayRange
          screensaver={screensaver}
          refetchScreensaver={refetchScreensaver}
          disabled={disabledAction}
          selectedPoint={selectedPoint}
        />
      </td>

      {/* 최근 수정일 */}
      <td className={`${TextType.body1} text-gray-44`}>{convertDashToDot(screensaver.updatedDate.slice(0, 10))}</td>

      {/* 상태 */}
      <td>
        <DisplayStatusBadge status={screensaver.displayStatus} />
      </td>

      {/* 삭제 */}
      <td>
        <ScheduleDelete
          disabled={disabledAction}
          screensaver={screensaver}
          refetchScreensaver={refetchScreensaver}
          selectedPoint={selectedPoint}
        />
      </td>
    </tr>
  );
};
export default ScheduleTableRow;
