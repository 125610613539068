import FileDropZone from 'components/common/file/drop-zone/FileDropZone';
import FormControl from 'components/common/form-control/FormControl';
import Guide from 'components/common/guide/Guide';
import { FILE_UPLOAD_NOTICE } from 'components/common/guide/data/fileUpload';
import AlertModal from 'components/common/modal/alert/AlertModal';
import useFileUpload from 'hooks/common/useFileUpload';
import useModal from 'hooks/common/useModal';
import { useState } from 'react';
import { FileType } from 'types/common/file/file';
import { FileUploadType } from 'types/common/file/fileUpload';
import { UploadFile } from 'types/feature/content/contents';
import { CustomBoard } from 'types/feature/menu/customBoard';
import { CustomPageType } from 'types/feature/menu/customPage';
import { returnFileAccept } from 'utils/file/extension/returnFileAccept';
import { returnFileMaxSize } from 'utils/file/size/returnFileSize';
import { CARD_MENU_CONTENT_GUIDES, LIST_MENU_CONTENT_GUIDES } from '../guide/menuContent';

type Props = {
  customBoard: CustomBoard;
  setCustomBoard: React.Dispatch<React.SetStateAction<CustomBoard>>;
  customPageType: CustomPageType;
  isEdit: boolean;
};

const ImageForm = ({ customBoard, setCustomBoard, customPageType, isEdit }: Props) => {
  // state
  const [isMainImageLoading, setMainImageLoading] = useState(false);
  const [isContentImageLoading, setContentImageLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // hook
  const { uploadFile } = useFileUpload();
  const { openModalPopup, openAlert, closeModalPopup } = useModal();

  const handleAlert = (message: string) => {
    setAlertMessage(message);
    openModalPopup('ALERT');
  };

  // 목록 이미지
  const uploadImageFile = async (file: File) => {
    try {
      if (file) {
        setMainImageLoading(true);
        const result = await uploadFile(file, FileUploadType.CUSTOM_BOARD_IMAGE);

        if (result) {
          const fileResponse: UploadFile = result.data;

          const mainImage = {
            uri: fileResponse.fileUri,
            name: fileResponse.fileName,
            originalName: file.name,
            delete: false,
          };

          setCustomBoard((prev: CustomBoard) => ({
            ...prev,
            mainImage,
          }));
        }
      }

      setMainImageLoading(false);
    } catch (error) {
      setMainImageLoading(false);
    }
  };

  // 상세 이미지
  const uploadContentImageFile = async (file: File) => {
    try {
      if (file) {
        setContentImageLoading(true);

        const result = await uploadFile(file, FileUploadType.CUSTOM_BOARD_CONTENT_IMAGE);

        if (result) {
          const fileResponse: UploadFile = result.data;

          const contentImage = {
            uri: fileResponse.fileUri,
            name: fileResponse.fileName,
            originalName: file.name,
            delete: !customBoard.contentImage ? true : false,
          };

          setCustomBoard((prev: CustomBoard) => ({
            ...prev,
            contentImage,
          }));
        }
      }
      setContentImageLoading(false);
    } catch (error) {
      setContentImageLoading(false);
    }
  };

  const deleteMainImage = () => {
    setCustomBoard((prev: CustomBoard) => ({
      ...prev,
      mainImage: null,
    }));
  };

  const deleteContentImage = () => {
    setCustomBoard((prev: CustomBoard) => ({
      ...prev,
      contentImage: null,
    }));
  };

  const isList = customPageType === 'LIST';

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex gap-2.5 w-full'>
        {/* 목록형일 경우만 목록 이미지를 등록한다. */}
        {isList && (
          <div className='flex-1'>
            <FormControl
              name='목록 이미지'
              required={isList}
              labelSize={200}
              control={
                <div className=''>
                  <FileDropZone
                    maxSize={returnFileMaxSize(FileUploadType.CUSTOM_BOARD_IMAGE)}
                    uploadFile={uploadImageFile}
                    fileUri={customBoard.mainImage?.uri || ''}
                    fileType={FileType.IMAGE}
                    file={
                      customBoard.mainImage || {
                        uri: '',
                        name: '',
                        originalName: '',
                        delete: false,
                      }
                    }
                    deleteFile={deleteMainImage}
                    accept={returnFileAccept(FileUploadType.CUSTOM_BOARD_IMAGE)}
                    openFileErrorAlert={handleAlert}
                    loading={isMainImageLoading}
                  />
                </div>
              }
            />
          </div>
        )}

        <div className='flex-1'>
          <FormControl
            name='상세 이미지'
            labelSize={200}
            required
            control={
              <div className=''>
                <FileDropZone
                  maxSize={returnFileMaxSize(FileUploadType.CUSTOM_BOARD_CONTENT_IMAGE)}
                  uploadFile={uploadContentImageFile}
                  fileUri={customBoard.contentImage?.uri || ''}
                  fileType={FileType.IMAGE}
                  file={
                    customBoard.contentImage || {
                      uri: '',
                      name: '',
                      originalName: '',
                      delete: false,
                    }
                  }
                  deleteFile={deleteContentImage}
                  accept={returnFileAccept(FileUploadType.CUSTOM_BOARD_CONTENT_IMAGE)}
                  openFileErrorAlert={handleAlert}
                  loading={isContentImageLoading}
                />
              </div>
            }
          />
        </div>
      </div>

      {/* 가이드 */}
      <div className='pl-[210px]'>
        <Guide
          guides={isList ? LIST_MENU_CONTENT_GUIDES : CARD_MENU_CONTENT_GUIDES}
          notice={FILE_UPLOAD_NOTICE}
          isList
        />
      </div>

      {openAlert && (
        <AlertModal
          message={alertMessage}
          closeModal={() => {
            closeModalPopup('ALERT');
          }}
        />
      )}
    </div>
  );
};
export default ImageForm;
