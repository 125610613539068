import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { CustomPage } from 'types/feature/menu/customPage';

const useCustomPage = () => {
  const { api } = useApi();

  // 커스텀 페이지 목록 조회
  const getCustomPages = async (pointId: string) => {
    const result = await api.get<ApiResponse<CustomPage[]>>(Endpoint.customPagesV2, {
      params: {
        pointId,
      },
    });

    if (result) {
      return result.data;
    }
  };

  // 커스텀 페이지 유무 확인
  const getCustomPagesCheck = async (workspaceId: string) => {
    const result = await api.get<
      ApiResponse<{
        isExist: boolean;
      }>
    >(Endpoint.customPagesCheck, {
      params: {
        workspaceId,
      },
    });

    return result.data.isExist;
  };

  return { getCustomPages, getCustomPagesCheck };
};
export default useCustomPage;
