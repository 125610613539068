import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { useEffect, useState } from 'react';
import { ApiResponse } from 'types/api/response';
import { Account, AccountMap } from 'types/feature/account/account';

/**
 * 지도 이름 : Editor 에서 실시간으로 조회
 * 지도 버전 : 현재 workspace 의 지도 버전 조회
 */
const useAccount = () => {
  // hook
  const { api } = useApi();

  // state
  const [account, setAccount] = useState<Account>();

  // 현재 워크스페이스의 지도 찾는 함수
  const findAccountMap = (maps: AccountMap[], workspaceMapId: string): AccountMap | undefined => {
    const accountMap = maps.find((map: AccountMap) => map.id === workspaceMapId);
    if (accountMap) {
      return accountMap;
    }
  };

  // 현재 계정 불러오기
  const getAccount = async () => {
    const response = await api.get<ApiResponse<Account>>(Endpoint.account);

    if (response) {
      setAccount(response.data);

      return response.data;
    }
  };

  useEffect(() => {
    getAccount();
  }, []);

  return {
    getAccount,
    findAccountMap,
    account,
  };
};

export default useAccount;
