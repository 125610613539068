import { useState } from 'react';

/**
 * 매번 confirm, alert 모달 상태를 관리하기 번거로워 만든 hook
 * 파라미터로 모달 타입을 넣어주고, 실제 사용하는 컴포넌트에서 보여줄 modal 컴포넌트를 hook에서 return 하는 값에 맞게 렌더링 해주면 된다.
 */
const useModal = () => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const openModalPopup = (type: 'ALERT' | 'CONFIRM') => {
    if (type === 'CONFIRM') {
      setOpenConfirm(true);
    }

    if (type === 'ALERT') {
      setOpenAlert(true);
    }
  };

  const closeModalPopup = (type: 'ALERT' | 'CONFIRM') => {
    if (type === 'CONFIRM') {
      setOpenConfirm(false);
    }

    if (type === 'ALERT') {
      setOpenAlert(false);
    }
  };

  return {
    openAlert,
    openConfirm,
    openModalPopup,
    closeModalPopup,
  };
};

export default useModal;
