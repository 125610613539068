import { ICON } from 'constants/icons';
import { TextType } from '../title/Title';

type Props = {
  guides: string[];
  notice?: string;
  isList: boolean;
};

const Guide = ({ notice, guides, isList }: Props) => {
  return (
    <div className='px-6 py-4 cursor-default bg-gray-50 shrink-0'>
      <div className='flex flex-col gap-2.5'>
        {notice && (
          <div className='flex items-center gap-1'>
            <img src={ICON.INFO_GRAY} alt='info icon' />
            <pre className={`${TextType.h5} text-gray-99 font-pretendard`}>{notice}</pre>
          </div>
        )}

        <div className='text-gray-99 text-[10px] font-medium leading-4 flex items-start flex-col'>
          {guides?.map((guide, index) => (
            <div key={index} className='flex items-center justify-center gap-2 whitespace-break-spaces text-inherit'>
              {isList && <span className='w-[2px] h-[2px] rounded-full bg-gray-99' />}

              {guide}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Guide;
