import { TextType } from 'components/common/title/Title';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Workspace } from 'types/feature/workspace/workspace';
import WorkspaceItem from './item/WorkspaceItem';

type Props = {
  workspaceList: Workspace[];
  closeList: () => void;
};

const WorkspaceSelectBox = ({ workspaceList, closeList }: Props) => {
  const navigate = useNavigate();
  const workspaceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (workspaceRef.current && !workspaceRef.current.contains(event.target)) {
        closeList();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const moveWorkspaceList = () => {
    navigate(RoutePath.workspaces);
    closeList();
  };
  return (
    <div className='absolute z-50 bg-white top-16 shadow-smDrop' ref={workspaceRef}>
      <div className='w-60 max-h-[268px] overflow-y-auto '>
        <div className={`pl-5 pt-3 ${TextType.h5_bold} text-gray-77 h-9`}>워크스페이스</div>
        <div>
          {workspaceList.map((workspace, workspaceIndex) => (
            <WorkspaceItem key={workspaceIndex} workspace={workspace} closeList={closeList} />
          ))}
        </div>
      </div>
      <div
        onClick={moveWorkspaceList}
        className={`cursor-pointer px-5 flex items-center h-[52px] border-t-2 text-gray-44 text-h4`}
      >
        워크스페이스 목록으로 이동
      </div>
    </div>
  );
};

export default WorkspaceSelectBox;
