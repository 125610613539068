import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GradientLoader from 'components/common/loading/gradient/GradientLoader';
import AlertModal from 'components/common/modal/alert/AlertModal';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import NoData from 'components/common/table/no-data/NoData';
import { ICON } from 'constants/icons';
import { SERVER_DATE_FORMAT } from 'data/common';
import dayjs from 'dayjs';
import useStatistics from 'hooks/feature/stats/useStatistics';
import useStatsDownload from 'hooks/feature/stats/useStatsDownload';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MachineUsage } from 'types/feature/statistics/machineUsage';
import { convertDateToString } from 'utils/date/dateUtils';
import { handle400Error } from 'utils/error/handle400Error';
import MachineBuildingFilter from './filter/building/MachineBuildingFilter';
import MachineRangeFilter from './filter/range/MachineRangeFilter';
import MachineUsageTable from './table/MachineUsageTable';

// ! 날짜는 한국 시간 기준
const MachineUsageStatsContainer = () => {
  const [selectedPointOption, setSelectedPointOption] = useState<CommonOptionType>();
  const [selectedFloorOption, setSelectedFloorOption] = useState<CommonOptionType>();

  // 실시간 데이터 지원하지 않음
  const yesterday = dayjs().subtract(1, 'day');

  const [selectedStart, setSelectedStart] = useState<Date>(yesterday.subtract(30, 'day').toDate());
  const [selectedEnd, setSelectedEnd] = useState<Date>(yesterday.toDate());

  const [machineUsageList, setMachineUsageList] = useState<MachineUsage[]>([]);

  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);

  const { getUsagePerMachine } = useStatistics();
  const { downloadMachineUsage } = useStatsDownload();
  const { workspaceId } = useParams();

  // 기기별 사용량 api 호출
  const refetchMachineUsage = async (pointId?: string, floorId?: string) => {
    if (workspaceId) {
      const response = await getUsagePerMachine(
        workspaceId,
        pointId ?? selectedPointOption?.value,
        floorId ?? selectedFloorOption?.value,
        convertDateToString(selectedStart),
        convertDateToString(selectedEnd),
      );

      setMachineUsageList(response);
    }
  };

  // 검색 버튼 클릭 시
  const onClickSearch = async () => {
    try {
      if (!workspaceId) {
        return;
      }

      setLoading(true);

      // 기기별 사용량 조회 api 호출
      const response = await getUsagePerMachine(
        workspaceId,
        selectedPointOption?.value,
        selectedFloorOption?.value,
        convertDateToString(selectedStart),
        convertDateToString(selectedEnd),
      );

      setMachineUsageList(response);
      setLoading(false);
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlert(true);
        setLoading(false);
      });
    }
  };

  // 기간 초기화
  const refreshRangeState = () => {
    setSelectedStart(yesterday.subtract(30, 'day').toDate());
    setSelectedEnd(yesterday.toDate());
  };

  // 처음 세팅 상태로 api 호출
  const refreshMachineStats = async () => {
    try {
      if (!workspaceId) {
        return;
      }

      setLoading(true);

      refreshRangeState();

      const response = await getUsagePerMachine(
        workspaceId,
        selectedPointOption?.value,
        selectedFloorOption?.value,
        yesterday.subtract(30, 'day').format(SERVER_DATE_FORMAT),
        yesterday.format(SERVER_DATE_FORMAT),
      );

      setMachineUsageList(response);
      setLoading(false);
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlert(true);
      });
    }
  };

  // 초기화 클릭 시 기간 초기화
  const onClickRefreshDates = async () => {
    refreshRangeState();

    // 처음 세팅 상태로 api 호출
    await refreshMachineStats();
  };

  useEffect(() => {
    refreshMachineStats();
  }, [workspaceId]);

  // 기기별 사용량 다운로드
  const onClickDownload = async () => {
    await downloadMachineUsage({
      workspaceId: workspaceId || '',
      startDate: convertDateToString(selectedStart),
      endDate: convertDateToString(selectedEnd),
      pointId: selectedPointOption?.value,
      floorId: selectedFloorOption?.value,
    });
  };

  return (
    <div className='flex flex-col gap-6'>
      {/* 빌딩, 층 필터 */}
      <MachineBuildingFilter
        selectedPointOption={selectedPointOption}
        setSelectedPointOption={setSelectedPointOption}
        selectedFloorOption={selectedFloorOption}
        setSelectedFloorOption={setSelectedFloorOption}
        refetchMachineUsage={refetchMachineUsage}
        setLoading={setLoading}
      />

      <Border />

      <div className='flex'>
        {/* 검색 기간 필터 */}
        <MachineRangeFilter
          selectedStart={selectedStart}
          selectedEnd={selectedEnd}
          setSelectedStart={setSelectedStart}
          setSelectedEnd={setSelectedEnd}
        />

        <div className='flex gap-2.5'>
          {/* 검색 버튼 */}
          <Button onClick={onClickSearch} color={ButtonColor.primary} text='검색' size={80} />

          {/* 초기화 버튼 */}
          <Button
            onClick={onClickRefreshDates}
            color={ButtonColor.secondary}
            text='초기화'
            size={80}
            iconPath={ICON.REFRESH_BK}
          />
        </div>
      </div>

      {loading ? (
        // 로딩
        <div className='w-full h-[404px] bg-white border border-gray-ea'>
          <GradientLoader />
        </div>
      ) : machineUsageList.length === 0 ? (
        // 데이터 없을 경우
        <NoData visible={machineUsageList.length === 0} message='데이터가 없습니다.' />
      ) : (
        // 테이블
        <MachineUsageTable
          machineStats={machineUsageList}
          isAll={selectedPointOption?.label === '전체'}
          onClickDownload={onClickDownload}
        />
      )}

      {openAlert && (
        <AlertModal
          message='통계 데이터를 불러올 수 없습니다.'
          closeModal={() => {
            setOpenAlert(false);
          }}
        />
      )}
    </div>
  );
};
export default MachineUsageStatsContainer;
