import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { Fragment } from 'react';
import { TotalUsagePerTime } from 'types/feature/statistics/totalUsage';
import { TIME_USAGE_TABLE_HEADER } from '../head/timeUsageTableHead';

type Props = {
  totalUsageList: TotalUsagePerTime[];
};

const TimeUsageTable = ({ totalUsageList }: Props) => {
  return (
    <table className='w-full border-collapse'>
      <CommonTableHeader tableHead={TIME_USAGE_TABLE_HEADER} />

      {totalUsageList.map((data, index) => (
        <Fragment key={index}>
          <tbody>
            <tr className='h-[52px] text-[14px]'>
              {/* 시간 */}
              <td className='border-b p-2.5'>{data.time}</td>

              {/* 키오스크 사용량 */}
              <td className='border p-2.5 bg-bg-f9'>{Number(data.machineCount).toLocaleString()}</td>

              {/* 모바일 사용량 */}
              <td className='border p-2.5 bg-bg-f9'>{Number(data.mobileCount).toLocaleString()}</td>

              {/* 전체 사용량 */}
              <td className='border p-2.5'>{Number(data.totalCount).toLocaleString()}</td>

              {/* 비율 */}
              <td className='border border-r-0 p-2.5'>{data.ratio}%</td>
            </tr>
          </tbody>
        </Fragment>
      ))}
    </table>
  );
};
export default TimeUsageTable;
