import React from 'react';

const ContentTableHeader = () => {
  return (
    <>
      <colgroup>
        <col width='80px' />
        <col width='100px' />
        <col width='120px' />
        <col width='760px' />
        <col width='120px' />
        <col width='140px' />
      </colgroup>

      <thead>
        <tr className='h-12 border-b-2 border-gray-44 whitespace-nowrap text-gray-77 text-[13px] font-bold'>
          <th className='text-inherit'>No.</th>
          <th className='text-inherit'>구분</th>
          <th className='text-inherit'>파일 유형</th>
          <th className='text-left text-inherit'>타이틀</th>
          <th className='text-inherit'>최근 수정일</th>
          <th className='text-inherit'>삭제</th>
        </tr>
      </thead>
    </>
  );
};
export default ContentTableHeader;
