import FormControl from 'components/common/form-control/FormControl';
import SelectBox, { OptionType } from 'components/common/select/SelectBox';
import useFloor from 'hooks/feature/floor/useFloor';
import { useState } from 'react';
import usePoiContentStore from 'stores/poiContent';
import { Floor } from 'types/feature/floor/floor';
import { Language, LanguageName } from 'types/feature/multi-lang/language';
import { Point } from 'types/feature/workspace/workspace';
import { clearAllMarkers } from 'utils/map/control/clear';
import { changeFloor } from 'utils/map/control/floor';
import { changeMapLang } from 'utils/map/control/language';
import { resetMapControl } from 'utils/map/control/reset';
import MapDraw from 'utils/map/mapDraw';
import { MainLang } from '../TenantInfoContainer';

type Props = {
  languageList: Language[];
  floorList: Floor[];
  pointList: Point[];
  mainLanguage: MainLang;
  selectedPoint: Point;
  selectedFloor: Floor;
  handleSelectedFloor: (floor: Floor) => void;
  handleSelectedPoint: (point: Point) => Promise<void>;
};

const TenantInfoControllers = ({
  languageList,
  floorList,
  pointList,
  mainLanguage,
  selectedPoint,
  selectedFloor,
  handleSelectedFloor,
  handleSelectedPoint,
}: Props) => {
  const [selectedLang, setSelectedLang] = useState('');

  const poiContentStore = usePoiContentStore();

  const { getFloors } = useFloor();

  // 언어 변경
  const onChangeLang = (option: OptionType) => {
    const lang = option.value;

    changeMapLang(lang);
    setSelectedLang(lang);
  };

  // 층 fetch
  const findMainFloor = async (pointId: string) => {
    const result = await getFloors(pointId);

    if (result) {
      return result.find(floor => floor.main);
    }
  };

  // 포인트 변경
  const onChangePoint = async (option: OptionType) => {
    const { value: pointId, data: point } = option;
    const selectedPoint = point as Point;

    // 지도 리셋
    resetMapControl();
    clearAllMarkers();

    // 포인트의 mainFloor 를 찾는다
    const mainFloor = await findMainFloor(pointId);

    // 다른 포인트(빌딩)로 이동하기 위해서
    if (mainFloor) {
      // 모든 빌딩 제거한다
      pointList.forEach(point => {
        MapDraw.map.context.removeBuilding(point.building.id);
      });

      // 선택한 빌딩 그린다
      await MapDraw.map.context.addBuilding(selectedPoint.building.id, mainFloor.id);

      // 선택된 언어로 변경
      changeMapLang(selectedLang);

      // 선택한 tenant clear
      poiContentStore.clearCurrentContentId();

      // 선택된 포인트 변경
      handleSelectedPoint(selectedPoint);
    }
  };

  // 층 변경
  const onChangeFloor = async (option: OptionType) => {
    const { value: pointId, data: floor } = option;
    const currentFloor = floor as Floor;

    // 지도 리셋
    resetMapControl();
    clearAllMarkers();

    // 층이동
    await changeFloor(pointId);

    // 상태 리셋
    poiContentStore.clearCurrentContentId();

    // 선택된 층 변경
    handleSelectedFloor(currentFloor);
  };

  // 언어 옵션
  const langOptions = languageList.map(language => ({
    label: LanguageName[language.code],
    value: language.code.toLowerCase(),
  }));

  // 포인트 옵션
  const pointOptions = pointList.map(point => {
    return { label: point.name.ko, value: point.id, data: point };
  });

  // 층 옵션
  const floorOptions = floorList.map(floor => ({
    label: floor.mainName,
    value: floor.id,
    data: floor,
  }));

  return (
    <div className='z-30 flex gap-5'>
      {/* 언어 */}
      {mainLanguage.defaultLang && (
        <FormControl
          name='언어'
          labelSize='none'
          control={
            <SelectBox
              placeholder={LanguageName[mainLanguage?.defaultLang]}
              // disabled={languageList.length <= 1}
              options={langOptions}
              onChangeSelect={onChangeLang}
            />
          }
        />
      )}

      {/* 빌딩 */}
      <FormControl
        name='빌딩'
        labelSize='none'
        control={
          <SelectBox
            placeholder={selectedPoint.name.ko}
            // disabled={pointList.length <= 1}
            options={pointOptions}
            onChangeSelect={onChangePoint}
          />
        }
      />

      {/* 층 */}
      {selectedFloor.id && (
        <FormControl
          name='층'
          labelSize='none'
          control={
            <SelectBox
              placeholder={selectedFloor.mainName}
              // disabled={floorList.length === 1}
              options={floorOptions}
              onChangeSelect={onChangeFloor}
            />
          }
        />
      )}
    </div>
  );
};

export default TenantInfoControllers;
