import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import dayjs from 'dayjs';

export type CalendarHeaderProps = {
  decreaseMonth: () => void;
  increaseMonth: () => void;
  date: Date;
};

const CommonCalendarHeader = ({ decreaseMonth, increaseMonth, date }: CalendarHeaderProps) => {
  return (
    <div className='flex items-center justify-between w-full h-10 select-none shrink-0'>
      <div className='cursor-pointer' onClick={decreaseMonth}>
        <img src={ICON.ARROW_LEFT} alt='이전' />
      </div>

      <span className={`${TextType.h4_bold} font-pretendard`}>
        {date.getFullYear()}. {dayjs(date).format('MM')}
      </span>

      <div className='cursor-pointer' onClick={increaseMonth}>
        <img src={ICON.ARROW_RIGHT} alt='다음' />
      </div>
    </div>
  );
};
export default CommonCalendarHeader;
