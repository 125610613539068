import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { HallData } from 'types/feature/plan/event';

const useEvent = () => {
  const { api } = useApi();

  const getEvents = async (planId: string, startDate: string, endDate: string) => {
    const response = await api.get<ApiResponse<HallData[]>>(
      `${Endpoint.events}?planId=${planId}&startDate=${startDate}&endDate=${endDate}`,
    );

    if (response.data) {
      return response.data;
    }
  };

  return {
    getEvents,
  };
};

export default useEvent;
