import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import { TextType } from 'components/common/title/Title';
import useKeyboard from 'hooks/common/useKeyboard';
import { ModalType } from 'types/common/modalType';
import ModalContainer from '../container/ModalContainer';

type Props = {
  message: string | undefined;
  okMessage?: string;
  cancelMessage?: string;
  onClickCancel: (e?: any) => void;
  onClickOk: ((e: React.MouseEvent<HTMLElement>) => void) | (() => void);
  disabled?: boolean;
  id?: string;
};

const ConfirmModal = ({
  message,
  onClickCancel,
  onClickOk,
  okMessage = '확인',
  cancelMessage = '취소',
  disabled,
  id,
}: Props) => {
  useKeyboard({ modalType: ModalType.confirm, closeModal: onClickCancel, callback: onClickOk });

  return (
    <ModalContainer>
      <div className='z-50 flex flex-col items-center justify-center gap-8 py-20 bg-primary-White px-28'>
        <pre className={`${TextType.body1} font-pretendard break-all max-w-sm whitespace-pre-wrap text-center`}>
          {message}
        </pre>
        <div className='flex items-center justify-center w-full gap-2'>
          <Button size={120} text={cancelMessage} color={ButtonColor.secondary} onClick={onClickCancel} />
          <Button
            disabled={disabled}
            size={120}
            text={okMessage}
            color={disabled ? ButtonColor.primary_disable : ButtonColor.primary}
            onClick={onClickOk}
            id={id}
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default ConfirmModal;
