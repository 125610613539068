import React from 'react';

type Props = {
  data: {
    title: string;
    color: string;
  }[];
  colorChipSize?: string;
  colorChipType?: 'rounded-square' | 'square' | 'circle';
  listDirection?: 'row' | 'column';
  itemDirection?: 'row' | 'column';
  gap?: string;
};

const Legend = ({
  data: list,
  colorChipSize = '14px',
  colorChipType = 'square',
  listDirection = 'row',
  itemDirection = 'row',
  gap = '24px',
}: Props) => {
  return (
    <div
      style={{
        flexDirection: listDirection,
        gap: gap,
      }}
      className='flex'
    >
      {list.map(item => (
        <div
          key={item.color}
          style={{
            flexDirection: itemDirection,
          }}
          className='flex items-center gap-1'
        >
          <div
            style={{
              background: item.color,
              width: colorChipSize,
              height: colorChipSize,
              borderRadius: colorChipType === 'circle' ? '100%' : colorChipType === 'rounded-square' ? '2px' : 0,
            }}
            className='w-3 h-3 rounded-sm shrink-0'
          />
          <div className='text-gray-77 text-[12px] font-semibold leading-[14px] shrink-0 mt-0.5'>{item.title}</div>
        </div>
      ))}
    </div>
  );
};
export default Legend;
