/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ContentLayout from 'components/common/layout/content/ContentLayout';
import AlertModal from 'components/common/modal/alert/AlertModal';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useModal from 'hooks/common/useModal';
import useCustomBoard from 'hooks/feature/menu/custom/useCustomBoard';
import useCustomPage from 'hooks/feature/menu/custom/useCustomPage';
import usePoint from 'hooks/feature/point/usePoint';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { CustomBoards } from 'types/feature/menu/customBoard';
import { CustomPage, CustomPageType } from 'types/feature/menu/customPage';
import { Point } from 'types/feature/workspace/workspace';
import MenuContentsTable from './table/MenuContentsTable';

const MenuContentsContainer = () => {
  // hook
  const { customNavigate } = useCustomNavigate();
  const { workspaceId, pointId: paramPointId, customPageId: paramCustomPageId } = useParams();
  const { getMyPoints } = usePoint();
  const { getCustomPages } = useCustomPage();
  const { getCustomBoards } = useCustomBoard();
  const { openModalPopup, openAlert, closeModalPopup } = useModal();

  // state
  const [points, setPoints] = useState<Point[]>([]);
  const [customPages, setCustomPages] = useState<CustomPage[]>([]);
  const [customBoards, setCustomBoards] = useState<CustomBoards>();
  const [pageOptions, setPageOptions] = useState<CommonOptionType[]>([]);
  const [selectedPoint, setSelectedPoint] = useState<CommonOptionType>({ value: '', label: '', iconUrl: '' });
  const [selectedPage, setSelectedPage] = useState<CommonOptionType>({ value: '', label: '', iconUrl: '' });
  const [customPageType, setCustomPageType] = useState<CustomPageType>('LIST');
  const [message, setMessage] = useState('');

  // 포인트 목록 조회
  const fetchPoints = async () => {
    const points = await getMyPoints();

    if (points) {
      points.forEach(async point => {
        const customPages = await getCustomPages(point.id);

        if (customPages && customPages?.length > 0) {
          setPoints(prev => [...prev, { ...point }]);
        }
      });
    }
  };

  // 커스텀 페이지 목록 조회
  const fetchCustomPages = async (pointId: string) => {
    try {
      const customPages = await getCustomPages(pointId);
      if (customPages) {
        setCustomPages(customPages);

        return customPages;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          openModalPopup('ALERT');
          setMessage('메뉴 목록을 불러올 수 없습니다.');
        }
      }
    }
  };

  // 커스텀 보드 목록 조회
  const fetchCustomBoards = async (customPageId: string, pageNum?: number) => {
    try {
      const customBoards = await getCustomBoards(customPageId, pageNum);

      setCustomBoards(customBoards);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          openModalPopup('ALERT');
          setMessage('메뉴 콘텐츠 목록을 불러올 수 없습니다.');
        }
      }
    }
  };

  // point select box
  const onChangePoint = async (option: CommonOptionType) => {
    setSelectedPoint(option);

    const pointId = option.value;

    if (pointId) {
      customNavigate(RoutePath.pointCustomBoards, pointId, selectedPage.value, '', {
        state: customPageType,
      });

      const pages = await fetchCustomPages(pointId);

      if (pages) {
        if (pages.length < 1) {
          openModalPopup('ALERT');
          setMessage('해당 빌딩에는 사용자 지정 메뉴가 없습니다.');

          setCustomBoards(undefined);

          return;
        }

        const pageOptions = pages.map(page => ({
          label: page.name.ko,
          value: page.id,
          iconUrl: page.type === 'LIST' ? ICON.LIST : ICON.CARD,
          data: page,
        }));
        setPageOptions(pageOptions);

        setSelectedPage(pageOptions[0]);
      }
    }
  };

  // custom page select box
  const onChangePage = async (option: CommonOptionType) => {
    const customPageId = option.value;
    const customPage = option.data as CustomPage;

    setSelectedPage(option);
    setCustomPageType(customPage.type); // 커스텀 페이지 타입으로 등록 페이지에서 이미지 validate 하고 있음

    if (paramPointId) {
      customNavigate(RoutePath.pointCustomBoards, paramPointId, customPageId, '', {
        state: customPageType,
      });
    }

    await fetchCustomBoards(customPageId);
  };

  // 선택된 포인트 init
  const handleSelectedPoint = () => {
    if (paramPointId) {
      const findPoint = points.find(point => point.id === paramPointId);

      if (findPoint) {
        setSelectedPoint({
          label: findPoint.name.ko,
          value: findPoint.id,
        });
      }
    } else {
      setSelectedPoint({
        label: points[0]?.name.ko,
        value: points[0]?.id,
      });
    }
  };

  // 커스텀 페이지 init
  const handleSelectedCustomPage = async (customPageId: string) => {
    // customPageId param 이 있을 경우
    // 커스텀 페이지를 찾아서 세팅해준다.
    if (paramCustomPageId) {
      const findCustomPage = customPages.find(page => page.id === paramCustomPageId);

      if (findCustomPage) {
        setSelectedPage({
          label: findCustomPage.name.ko,
          value: findCustomPage.id,
          iconUrl: findCustomPage.type === 'LIST' ? ICON.LIST : ICON.CARD,
          data: findCustomPage,
        });
        setCustomPageType(findCustomPage.type);

        await fetchCustomBoards(findCustomPage.id);
      }
    } else {
      // customPageId param 이 없을 경우 = 페이지에 처음 들어온 경우는
      // 첫번째 custom page 로 세팅해준다.
      setSelectedPage({
        label: customPages[0]?.name.ko,
        value: customPages[0]?.id,
        iconUrl: customPages[0]?.type === 'LIST' ? ICON.LIST : ICON.CARD,
        data: customPages,
      });

      setCustomPageType(customPages[0]?.type);

      await fetchCustomBoards(customPageId);
    }
  };

  // 콘텐츠 등록 페이지로 이동
  const moveToMenuContentAddPage = () => {
    customNavigate(
      RoutePath.pointCustomBoardAdd,
      paramPointId || points[0]?.id,
      paramCustomPageId || customPages[0]?.id,
      '',
      {
        state: customPageType,
      },
    );
  };

  useEffect(() => {
    setPageOptions(
      customPages.map(page => ({
        label: page.name.ko,
        value: page.id,
        iconUrl: page.type === 'LIST' ? ICON.LIST : ICON.CARD,
        data: page,
      })),
    );
  }, [customPages]);

  useEffect(() => {
    handleSelectedPoint();
  }, [points, paramPointId]);

  useEffect(() => {
    if (customPages.length > 0) {
      handleSelectedCustomPage(paramCustomPageId || customPages[0]?.id);
    }
  }, [customPages, paramCustomPageId]);

  useEffect(() => {
    fetchPoints();
  }, [workspaceId]);

  useEffect(() => {
    if (points.length > 0) {
      fetchCustomPages(points[0]?.id);
    }
  }, [points]);

  return (
    <ContentLayout>
      <>
        <div className='flex justify-between'>
          <div className='flex gap-2.5'>
            <CommonSelectBox
              width={160}
              options={points.map(point => ({
                label: point.name.ko,
                value: point.id,
              }))}
              onChangeSelect={onChangePoint}
              selected={selectedPoint}
            />

            {pageOptions.length > 0 && (
              <CommonSelectBox
                width={160}
                options={pageOptions}
                onChangeSelect={onChangePage}
                selected={selectedPage}
              />
            )}
          </div>

          <Button
            onClick={moveToMenuContentAddPage}
            disabled={pageOptions.length < 1}
            color={ButtonColor.primary}
            iconPath={ICON.PLUS_WHITE}
            text='콘텐츠 등록'
            textStyle={TextType.h5}
            size={96}
          />
        </div>

        <MenuContentsTable
          customBoards={customBoards}
          fetchCustomBoards={fetchCustomBoards}
          pointId={selectedPoint.value}
          pageId={selectedPage.value}
          customPageType={customPageType}
        />

        {openAlert && (
          <AlertModal
            message={message}
            closeModal={() => {
              closeModalPopup('ALERT');
            }}
          />
        )}
      </>
    </ContentLayout>
  );
};
export default MenuContentsContainer;
