import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { ChangedStatus } from 'types/common/status';
import { ChangedTenants } from 'types/feature/tenant/changedTenant';
import { PatchTenant, PoiWithTenants, PutTenantCustomField, Tenant } from 'types/feature/tenant/tenant';

const useTenant = () => {
  const { api } = useApi();

  const getTenant = async (poiContentId: string, pointId: string) => {
    const result = await api.get<ApiResponse<Tenant>>(Endpoint.tenants.concat(`/poi-contents/${poiContentId}`), {
      params: {
        pointId,
      },
    });
    return result.data;
  };

  const getPoisWithTenants = async (pointId: string) => {
    const result = await api.get<ApiResponse<PoiWithTenants[]>>(Endpoint.tenants.concat(`/pois`), {
      params: {
        pointId,
      },
    });
    return result.data;
  };

  const patchTenant = async (tenantId: string, patchTenant: PatchTenant) => {
    const result = await api.patch<ApiResponse<PatchTenant>>(Endpoint.tenants.concat(`/${tenantId}`), patchTenant);
    return result;
  };

  const updateTenantCustomField = async (fieldId: string, updateCustomDetail: PutTenantCustomField) => {
    await api.put<ApiResponse<PutTenantCustomField>>(
      Endpoint.tenantCustomField.replace(':fieldId', fieldId),
      updateCustomDetail,
    );
  };

  // 변경된 테넌트
  const getChangedTenants = async (pointId: string, type?: ChangedStatus, floorId?: string, all?: boolean) => {
    const result = await api.get<ApiResponse<ChangedTenants>>(`${Endpoint.changedTenants}`, {
      params: {
        pointId,
        type,
        floorId,
        all,
      },
    });

    return result.data;
  };

  // 변경사항 확인 완료
  const patchChangeTenantChanged = async (changeTenantId: string) => {
    await api.patch(`${Endpoint.changedTenants}/${changeTenantId}/changed`);
  };

  return {
    getTenant,
    getPoisWithTenants,
    patchTenant,
    updateTenantCustomField,
    getChangedTenants,
    patchChangeTenantChanged,
  };
};

export default useTenant;
