import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { Fragment } from 'react';
import { TotalUsagePerDate } from 'types/feature/statistics/totalUsage';
import { convertServerDateToClientDate } from 'utils/date/dateUtils';
import { TOTAL_USAGE_TABLE_HEADER } from '../head/dateUsageTableHead';

type Props = {
  totalUsageList: TotalUsagePerDate[];
};

const DateUsageTable = (props: Props) => {
  return (
    <table className='w-full border-collapse'>
      <CommonTableHeader tableHead={TOTAL_USAGE_TABLE_HEADER} />

      {props.totalUsageList.map((data, index) => (
        <Fragment key={index}>
          <tbody>
            <tr className='h-[52px] text-[14px]'>
              {/* 날짜 */}
              <td className='border-b p-2.5'>{convertServerDateToClientDate(data.date)}</td>
              {/* 키오스크 사용량 */}
              <td className='border p-2.5 bg-bg-f9'>{Number(data.machineCount).toLocaleString()}</td>
              {/* 모바일 사용량 */}
              <td className='border p-2.5 bg-bg-f9'>{Number(data.mobileCount).toLocaleString()}</td>
              {/* 전체 사용량 */}
              <td className='border p-2.5'>{Number(data.totalCount).toLocaleString()}</td>
              {/* 비율 */}
              <td className='border border-r-0 p-2.5'>{data.ratio}%</td>
            </tr>
          </tbody>
        </Fragment>
      ))}
    </table>
  );
};
export default DateUsageTable;
