import Input from 'components/common/input/Input';
import { ChangeEvent } from 'react';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  point: Point;
  handlePoint: (point: Point) => void;
};

const AltText = ({ point, handlePoint }: Props) => {
  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.length > 10) {
      return;
    }

    handlePoint({
      ...point,
      operation: {
        ...point.operation,
        hoursDescription: value,
      },
    });
  };

  return (
    <div className='flex flex-col justify-center gap-1'>
      <Input
        useWordLimit
        limit={10}
        onChange={handleChangeDescription}
        placeholder='운영시간을 입력해주세요'
        size={384}
        value={point.operation.hoursDescription}
        name='hoursDescription'
      />
      <div className='text-h5 text-gray-99'>최대 10자까지 입력 가능합니다.</div>
    </div>
  );
};

export default AltText;
