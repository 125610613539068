import React from 'react';
import IMAGE1 from 'assets/guide/image/image1.png';
import IMAGE2 from 'assets/guide/image/image2.png';
import Title from 'components/guide/title/Title';

const WorkspaceGuide = () => {
  return (
    <>
      <Title title='워크스페이스' />

      <div className='flex flex-col gap-5'>
        <section className='flex flex-col gap-7'>
          <p id='workspace' className='text-[20px] font-semibold'>
            2-1. 워크스페이스 선택 및 변경
          </p>
          <div className='w-2/3'>
            <img src={IMAGE1} alt='img1' />
          </div>
          <ul className='pl-8 leading-8 list-disc'>
            <li>
              {`워크스페이스를 다수개 보유한 경우 로그인 시 워크스페이스 목록화면으로 진입하며, 
                1) 목록 선택 시 선택한 워크스페이스의 2) 기본정보관리 화면으로 진입합니다.`}
            </li>
          </ul>

          <div className='w-2/3'>
            <img src={IMAGE2} alt='img1' />
          </div>

          <ul className='pl-8 leading-8 list-disc'>
            <li>
              {`이후 좌측상단 3) 워크스페이스명칭 선택을 통해 워크스페이스를 변경하거나 워크스페이스 목록화면으로 이동할 수 있습니다.`}
            </li>
            <li>
              워크스페이스를 한 개만 보유한 경우에는 로그인 시 해당 워크스페이스의 기본정보관리 화면으로 바로
              진입합니다.
            </li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default WorkspaceGuide;
