/**
 * token 인증 에러 발생 시 통합인증 로그인 화면으로 redirect 처리를 하는 함수
 * @function redirectToIntegratedAuthUrl
 * @returns {void}
 */
export const redirectToIntegratedAuthUrl = (): void => {
  window.location.replace(String(process.env.REACT_APP_INTEG_AUTH_URL));
};

export const isLocalEnv = process.env.REACT_APP_ENVIRONMENT === 'local' || 'coldbox';
