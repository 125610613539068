declare global {
  interface Window {
    dabeeo: any;
  }
}

class MapDraw {
  static get mapContainer() {
    return this._mapContainer;
  }

  private static _mapContainer: HTMLDivElement;

  static dabeeoMaps: any = null;

  static map: any = null;

  static mapData: any = null;

  public static getInstance() {
    if (!this.dabeeoMaps) {
      this.dabeeoMaps = new window.dabeeo.Maps() as any;
    }

    return this.dabeeoMaps;
  }

  async showMap(mapContainer: HTMLDivElement, floorId: string) {
    const mapOption = {
      camera: '2d',
      // floor: floorId,
      language: 'ko',
      showPoi: true,
      spriteEnable: true,
      spriteKeepRotation: true,
      showWaterMarker: false,
      framerate: 30,

      enablePoiCollisionTest: false,
      dragDistance: 50,

      /**
       * georeferencing 하지 않은 지도와 동일하게 동작
       * https://api-sample.dabeeomaps.com/multiMap
       */
      enableGeoreferencing: false,

      backgroundImage: {
        visible: true,
      },
    };

    MapDraw._mapContainer = mapContainer;

    MapDraw.map = await MapDraw.getInstance().showMap(mapContainer, mapOption, MapDraw.mapData);

    MapDraw.map.control.setOption({
      // 컨트롤 옵션
      controlRangeOption: {},
    });

    return MapDraw.map;
  }

  static cleanup() {
    if (MapDraw.map) {
      MapDraw.map.context.cleanup();
    }
  }
}

export default MapDraw;
