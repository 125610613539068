import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import { TextType } from 'components/common/title/Title';
import { IMAGES } from 'constants/images';
import dayjs from 'dayjs';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useModal from 'hooks/common/useModal';
import { RoutePath } from 'types/common/paths';
import { Workspace } from 'types/feature/workspace/workspace';

type Props = {
  workspace: Workspace;
  closeList: () => void;
};

const WorkspaceItem = ({ workspace, closeList }: Props) => {
  const { closeModalPopup, openConfirm } = useModal();
  const { navigateToReplacedPath } = useCustomNavigate();

  const onClick = (id: string) => {
    navigateToReplacedPath(RoutePath.dashboard, id);
    closeList();

    window.scrollTo(0, 0);
    window.location.reload();
  };

  return (
    <>
      <div
        className='cursor-pointer h-[58px] pl-5 py-3 flex gap-2.5 items-center hover:bg-state-blue_bg bg-white'
        onClick={onClick.bind(this, workspace.id)}
      >
        <div className='h-[30px] w-[30px] shrink-0'>
          <img src={IMAGES.MAP} alt='workspace' className='w-full' />
        </div>
        <div>
          <div className={`${TextType.h4} text-gray-44 line-clamp-1`}>{workspace.name}</div>
          <div className='text-[11px] text-gray-99'>{dayjs(workspace.updatedDate).format('YYYY-MM-DD')} 최종수정</div>
        </div>
      </div>
      {openConfirm && (
        <ConfirmModal
          message={`변경 사항이 있습니다.\n다른 워크 스페이스로 이동 하시겠습니까?`}
          onClickOk={onClick.bind(this, workspace.id)}
          onClickCancel={() => closeModalPopup('CONFIRM')}
        />
      )}
    </>
  );
};

export default WorkspaceItem;
