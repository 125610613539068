import { create } from 'zustand';

export enum WorkspaceStatus {
  NONE = 'NONE',
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

type WorkspaceStoreProps = {
  workspaceLength: number;
  mainWorkspaceId: string;
  workspaceStatus: WorkspaceStatus;
  setWorkspaceStatus: (length: number, workspaceId?: string) => void;
};

const useWorkspaceStore = create<WorkspaceStoreProps>((set, get) => ({
  workspaceLength: 0,
  workspaceStatus: WorkspaceStatus.MULTIPLE,
  mainWorkspaceId: '',

  setWorkspaceStatus(length: number, workspaceId?: string) {
    set({
      workspaceLength: length,
      mainWorkspaceId: workspaceId,
    });

    switch (length) {
      case 0:
        set({
          workspaceStatus: WorkspaceStatus.NONE,
        });
        break;

      case 1:
        set({
          workspaceStatus: WorkspaceStatus.SINGLE,
        });

        break;

      default:
        set({
          workspaceStatus: WorkspaceStatus.MULTIPLE,
        });
    }
  },
}));

export default useWorkspaceStore;
