import { ICON } from 'constants/icons';
import React from 'react';

const DraggableButton = () => {
  return (
    <div className='flex items-center justify-center w-6 h-6 cursor-pointer shrink-0'>
      <img src={ICON.DRAGGABLE_BUTTON} alt='draggable icon' />
    </div>
  );
};
export default DraggableButton;
