import { TextType } from 'components/common/title/Title';
import { IMAGES } from 'constants/images';
import React from 'react';
import { FLEX_CENTER } from 'styles/flex';

const FileLoading = () => {
  return (
    <div className='gap-2 bg-bg-f1 w-[360px] h-[120px] rounded border border-dashed border-gray-44 flex flex-col justify-center'>
      <div className='relative bg-center bg-no-repeat h-9 bg-loading'>
        <div className={`${FLEX_CENTER.row} h-full animate-[arrowMove_1s_ease-in-out_infinite]`}>
          <img src={IMAGES.LOADING_ARROW} alt='arrow' />
        </div>
      </div>
      <div className={`${TextType.h5} text-center text-gray-cc whitespace-nowrap`}>{`파일 업로드 중입니다.`}</div>
    </div>
  );
};
export default FileLoading;
