import { CommonFile, FileType } from 'types/common/file/file';
import { ScreenType } from '../content/contents';

export enum DisplayType {
  ALWAYS_ON = 'ALWAYS_ON',
  DURATION = 'DURATION',
}

export type ScreensaverType = 'KIOSK' | 'MOBILE';

export enum DisplayStatus {
  DISPLAY = 'DISPLAY',
  WAIT = 'WAIT',
  CLOSE = 'CLOSE',
  WAIT_DELETE = 'WAIT_DELETE',
  NONE = 'NONE',
}

type ScreensaverContent = {
  title: string;
  type: ScreensaverType;
  fileType: FileType;
  screenType: ScreenType;
  file: CommonFile;
};

export type GetScreensaver = {
  id: string;
  displayType: DisplayType;
  startDate: string;
  endDate: string;
  sortOrder: number;
  content: ScreensaverContent;
  displayStatus: DisplayStatus;
  createdDate: string;
  updatedDate: string;
};
