import { TextType } from 'components/common/title/Title';
import TenantEditableFields from 'components/feature/tenant/edit/fields/TenantEditableFields';
import { useRef } from 'react';
import usePoiContentStore from 'stores/poiContent';
import { CONTENTS_BG } from 'styles/background';
import { FLEX_CENTER } from 'styles/flex';
import { Language } from 'types/feature/multi-lang/language';
import { Tenant } from 'types/feature/tenant/tenant';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  mainLanguage: string;
  languageList: Language[];
  tenant: Tenant | null;
  fetchTenant: (contentId: string) => Promise<void>;
  point: Point;
  clickedPoiId?: string;
};

const TenantEditSpace = ({ languageList, mainLanguage, tenant, fetchTenant, point, clickedPoiId }: Props) => {
  const poiContentStore = usePoiContentStore();

  const scrollRef = useRef<HTMLDivElement>(null);

  const returnTenantEditableField = () => {
    // 지도에서 선택하는 경우 poiId 가 없다면
    if (!poiContentStore.currentContentId) {
      return (
        <div className={`${FLEX_CENTER.row} h-[460px] text-gray-cc ${TextType.h4}`}>지도에서 poi를 선택해주세요.</div>
      );
    }

    if (!tenant) {
      return <> </>;
    }

    return (
      <TenantEditableFields
        tenant={tenant}
        languageList={languageList}
        mainLanguage={mainLanguage}
        fetchTenant={fetchTenant}
        pointName={point.name}
        pointId={point.id}
        clickedPoiId={clickedPoiId}
      />
    );
  };

  return (
    <div className={`h-[698px] ${CONTENTS_BG} w-[480px] overflow-y-auto`}>
      <div ref={scrollRef} />

      <div className='flex flex-col w-full'>{returnTenantEditableField()}</div>
    </div>
  );
};

export default TenantEditSpace;
