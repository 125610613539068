import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import {
  DashBoardMachineStatus,
  DashBoardScreensaverStatus,
  DashBoardTotalUsage,
  DashBoardWeeklyUsage,
} from 'types/feature/dashboard/dashboard';

const useDashBoard = () => {
  const { api } = useApi();

  // 실시간 기기 현황
  const getDashBoardMachineStatus = async (workspaceId: string, pointId?: string) => {
    const result = await api.get<ApiResponse<DashBoardMachineStatus>>(Endpoint.dashboardsMachineStatus, {
      params: {
        workspaceId,
        pointId,
      },
    });
    return result.data;
  };

  // 스크린세이버 스케줄
  const getDashBoardScreensaverStatus = async (workspaceId: string, pointId?: string) => {
    const result = await api.get<ApiResponse<DashBoardScreensaverStatus>>(Endpoint.dashboardScreensavers, {
      params: {
        workspaceId,
        pointId,
      },
    });
    return result.data;
  };

  // 전체 사용량
  const getDashBoardTotalUsage = async (workspaceId: string, standardDate: string, pointId?: string) => {
    const result = await api.get<ApiResponse<DashBoardTotalUsage>>(Endpoint.dashboardTotalUsages, {
      params: {
        workspaceId,
        pointId,
        standardDate,
      },
    });
    return result.data;
  };

  // 주간 사용량
  const getDashBoardWeeklyUsage = async (workspaceId: string, standardDate: string, pointId?: string) => {
    const result = await api.get<ApiResponse<DashBoardWeeklyUsage>>(Endpoint.dashboardWeeklyUsages, {
      params: {
        workspaceId,
        pointId,
        standardDate,
      },
    });
    return result.data;
  };

  return { getDashBoardMachineStatus, getDashBoardScreensaverStatus, getDashBoardTotalUsage, getDashBoardWeeklyUsage };
};
export default useDashBoard;
