import React from 'react';

type Props = {
  imageUrl: string;
};

const ImageWrapper = ({ imageUrl }: Props) => {
  return (
    <div className='w-2/3'>
      <img src={imageUrl} alt='guide-img' />
    </div>
  );
};

export default ImageWrapper;
