import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import { TextType } from 'components/common/title/Title';
import useKeyboard from 'hooks/common/useKeyboard';
import { ModalType } from 'types/common/modalType';
import ModalContainer from '../container/ModalContainer';

type Props = {
  message: string;
  closeModal: () => void;
  width?: string;
  buttonText?: string;
  maxZIndex?: boolean;
};

const AlertModal = ({ message, closeModal, width, buttonText, maxZIndex }: Props) => {
  useKeyboard({ modalType: ModalType.alert, closeModal });
  return (
    <ModalContainer>
      <div
        className={`
      ${maxZIndex ? 'z-60' : ''}
      flex flex-col items-center gap-5 p-20 px-20 bg-primary-White ${width}`}
      >
        <pre className={`${TextType.body1} font-pretendard break-all max-w-sm whitespace-pre-wrap text-center`}>
          {message}
        </pre>

        <div className='flex gap-2.5'>
          <Button
            onClick={closeModal}
            color={ButtonColor.primary}
            size={120}
            text={buttonText || '닫기'}
            type='button'
          />
        </div>
      </div>
    </ModalContainer>
  );
};
export default AlertModal;
