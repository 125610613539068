import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import React from 'react';

type Props = {
  message: string;
  type: 'loading' | 'no_content';
};

const TableException = ({ message }: Props) => {
  return (
    <div className={`flex flex-col gap-3 items-center justify-center w-full`}>
      <div>
        <img src={ICON.NO_CHANGED_TENANT} alt='동기화 아이콘' />
      </div>

      <div className={`${TextType.h4} text-gray-cc`}>{message}</div>
    </div>
  );
};
export default TableException;
