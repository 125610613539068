import React from 'react';

type Props = {
  children: React.ReactElement;
};

const ContentLayout = ({ children }: Props) => {
  return <div className='flex flex-col gap-6'>{children}</div>;
};
export default ContentLayout;
