import FormControl from 'components/common/form-control/FormControl';
import GradientLoader from 'components/common/loading/gradient/GradientLoader';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import useFloor from 'hooks/feature/floor/useFloor';
import usePoint from 'hooks/feature/point/usePoint';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Floor } from 'types/feature/floor/floor';
import { Point } from 'types/feature/workspace/workspace';
import { sortByLangOrder } from 'utils/sort/sortByLangOrder';

type Props = {
  selectedPointOption: CommonOptionType | undefined;
  setSelectedPointOption: Dispatch<SetStateAction<CommonOptionType | undefined>>;

  selectedFloorOption: CommonOptionType | undefined;
  setSelectedFloorOption: Dispatch<SetStateAction<CommonOptionType | undefined>>;

  refetchMachineUsage: (pointId?: string, floorId?: string) => Promise<void>;

  setLoading: Dispatch<SetStateAction<boolean>>;
};

const MachineBuildingFilter = ({
  selectedPointOption,
  setSelectedPointOption,
  selectedFloorOption,
  setSelectedFloorOption,
  refetchMachineUsage,
  setLoading,
}: Props) => {
  const [points, setPoints] = useState<Point[]>([]);
  const [floors, setFloors] = useState<Floor[]>([]);

  const [filterLoading, setFilterLoading] = useState<boolean>(true);

  const { getMyPoints } = usePoint();
  const { getFloors } = useFloor();

  // 포인트 목록 조회
  const fetchPoints = async () => {
    setFilterLoading(true);
    const points = await getMyPoints();

    if (points) {
      setPoints(points);
      setSelectedOptions(points);
    }

    setFilterLoading(false);
  };

  // 층 목록 조회
  const fetchFloors = async (pointId: string) => {
    setFilterLoading(true);
    const floorList = await getFloors(pointId);
    setFloors(floorList);

    setFilterLoading(false);
  };

  // 포인트, 층 옵션 설정
  const setSelectedOptions = async (points: Point[]) => {
    // 포인트가 1개일 경우 해당 포인트 선택
    if (points.length === 1) {
      setSelectedPointOption({ label: points[0].name.ko, value: points[0].id });
    }
    // 포인트가 1개 이상일 경우 전체 포인트 선택
    else {
      setSelectedPointOption({ label: '전체', value: '' });
    }

    // 층 목록 조회
    await fetchFloors(points[0].id);
    setSelectedFloorOption({ label: '전체', value: '' });
  };

  // 포인트 변경 시
  const onChangePoint = async (option: CommonOptionType) => {
    setLoading(true);
    setSelectedPointOption(option);

    // 선택한 포인트의, 전체 층
    await refetchMachineUsage(option.value, '');

    const pointId = option.value;

    // pointId 로 층 변경 (pointId 있을 경우만 fetch)
    if (pointId) {
      await fetchFloors(pointId);
    }

    setSelectedFloorOption({ label: '전체', value: '' });
    setLoading(false);
  };

  // 층 변경 시
  const onChangeFloor = async (option: CommonOptionType) => {
    setLoading(true);

    setSelectedFloorOption(option);

    // 선택한 포인트의, 선택한 층
    await refetchMachineUsage(selectedPointOption?.value, option.value);
    setLoading(false);
  };

  useEffect(() => {
    fetchPoints();
  }, []);

  return (
    <>
      {filterLoading ? (
        <div className='w-full h-[36px]'>
          <GradientLoader />
        </div>
      ) : (
        <div className='flex w-full gap-5'>
          {points.length > 1 && (
            <FormControl
              name='빌딩'
              labelSize='none'
              control={
                <CommonSelectBox
                  options={[
                    { label: '전체', value: '' },
                    ...points
                      .map(point => ({
                        label: point.name.ko,
                        value: point.id,
                        data: point,
                      }))
                      .sort((a, b) => sortByLangOrder(a.label, b.label)),
                  ]}
                  disabled={points.length === 1}
                  selected={{
                    label: selectedPointOption?.label || '',
                    value: selectedPointOption?.value || '',
                    data: selectedPointOption?.data,
                  }}
                  onChangeSelect={onChangePoint}
                  width={200}
                />
              }
            />
          )}

          {/* 층 */}
          <FormControl
            name='층'
            labelSize='none'
            control={
              <CommonSelectBox
                disabled={
                  // 층이 한개거나
                  floors.length === 1 ||
                  //  포인트가 전체일 경우 disable
                  selectedPointOption?.label === '전체'
                }
                options={[
                  { label: '전체', value: '' },
                  ...floors.map(floor => ({
                    label: floor.mainName,
                    value: floor.id,
                    data: floor,
                  })),
                ]}
                selected={{
                  label: selectedFloorOption?.label || '',
                  value: selectedFloorOption?.value || '',
                  data: selectedFloorOption?.data,
                }}
                onChangeSelect={onChangeFloor}
                width={100}
              />
            }
          />
        </div>
      )}
    </>
  );
};
export default MachineBuildingFilter;
