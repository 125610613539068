export const loginStatusKey = 'DID_ADMIN_LOGIN_STATUS';

export const loginLocalAccess = 'DID_ADMIN_ACCESS';

export const logoutStatusKey = 'DID_ADMIN_LOGOUT_STATUS';

export const loginUsername = 'DID_ADMIN_ID';

export const workspaceStatus = 'DID_ADMIN_WORKSPACE_STATUS';

export const mainWorkspaceId = 'DID_ADMIN_MAIN_ID';
