import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import { ICON } from 'constants/icons';
import useSsoAuth from 'hooks/common/useSsoAuth';
import { useState } from 'react';

const Logout = () => {
  const [openConfirm, setOpenConfirm] = useState(false);

  const { logout } = useSsoAuth();

  const handleLogout = async () => {
    await logout();
  };

  return (
    <>
      <div
        className='cursor-pointer'
        onClick={() => {
          setOpenConfirm(true);
        }}
      >
        <img src={ICON.LOGOUT} alt='logout' />
      </div>

      {openConfirm && (
        <ConfirmModal
          onClickOk={handleLogout}
          onClickCancel={() => setOpenConfirm(false)}
          message='로그아웃 하시겠습니까?'
        />
      )}
    </>
  );
};

export default Logout;
