import React from 'react';

type Props = {
  children: React.ReactElement;
  pagination: React.ReactElement | undefined;
};

const TableLayout = ({ children, pagination }: Props) => {
  return (
    <div className='w-full h-full p-10 pt-6 bg-white border'>
      <table>{children}</table>

      <div>{pagination}</div>
    </div>
  );
};

export default TableLayout;
