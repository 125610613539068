import Legend from 'components/common/graph/legend/Legend';
import Title, { TextType } from 'components/common/title/Title';

const TotalUsageGraphLegend = ({ title }: { title: string }) => {
  return (
    <div className='flex items-center justify-between h-5'>
      <Title text={title} textType={TextType.h3_bold} />

      {/* 범례 */}
      <Legend
        data={[
          {
            title: '키오스크',
            color: '#00A472',
          },
          {
            title: '모바일',
            color: '#9CE1CC',
          },
        ]}
      />
    </div>
  );
};
export default TotalUsageGraphLegend;
