import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import Logout from 'components/common/layout/gnb/inner/logout/Logout';
import useAccount from 'hooks/common/useAccount';
import usePlan from 'hooks/feature/plan/usePlan';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Account } from 'types/feature/account/account';
import { Plan } from 'types/feature/plan/plan';

const OuterGnb = () => {
  // hook
  const { account } = useAccount();
  const { getPlans } = usePlan();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // state
  const [selectedMenu, setSelectedMenu] = useState<RoutePath>(RoutePath.workspaces);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isShowPlan, setShowPlan] = useState(false);
  const [isLoadingMenu, setLoadingMenu] = useState(true);

  // Plan 메뉴 노출 처리
  const handlePlanMenu = (account: Account, totalPlans: number) => {
    // TODO: 서버에서 내려주는 형식 변경 예정
    const userId = account.id.split(':')[1];

    const validateUserId = userId === 'eeeeve007' || userId === 'platformcell' || userId === 'victor';

    // TODO: 상용화되면 계정관련 분기처리는 제거 예정
    // 특정 계정이 Plan 을 1개 이상 가지고 있을 경우에만 Plan 메뉴 노출
    if (validateUserId) {
      if (totalPlans > 0) {
        setShowPlan(true);
        setLoadingMenu(false);

        return;
      }
    }

    // 나머지는 비노출 처리
    setShowPlan(false);
    setLoadingMenu(false);
  };

  // 플랜 조회
  const fetchPlans = async () => {
    const plans = await getPlans();

    if (plans) {
      setPlans(plans);
    }
  };

  // 메뉴 클릭 시
  const onClickMenu = (path: RoutePath) => {
    // guide 메뉴 클릭 시 새 창 열림
    if (path === RoutePath.guide) {
      window.open(RoutePath.guide, '_blank');

      return;
    }

    setSelectedMenu(path);
    navigate(path);
  };

  useEffect(() => {
    setSelectedMenu(pathname as RoutePath);
  }, [pathname]);

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    if (account && plans) {
      handlePlanMenu(account, plans.length);
    }
  }, [account, plans]);

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-20 cursor-default shrink-0 bg-primary-White'>
      <div className='flex items-center justify-between h-full w-[1400px]'>
        {account ? (
          <div className='w-[314px] h-7 flex gap-1 font-h2 text-h2 leading-h2'>
            <span>{account.name}</span>
            <span>워크스페이스</span>
          </div>
        ) : (
          <div className='w-[314px] h-7' />
        )}

        <div className='flex gap-10'>
          {/* menu */}
          <div className='flex items-center gap-10'>
            {isLoadingMenu ? (
              <></>
            ) : (
              <>
                {isShowPlan
                  ? planMenus.map(menu => (
                      <span
                        key={menu.path}
                        onClick={onClickMenu.bind(this, menu.path)}
                        className={`${selectedMenu === menu.path ? 'font-bold' : 'font-normal'} cursor-pointer`}
                      >
                        {menu.label}
                      </span>
                    ))
                  : planMenus
                      .filter(menu => menu.path !== RoutePath.plan)
                      .map(menu => (
                        <span
                          key={menu.path}
                          onClick={onClickMenu.bind(this, menu.path)}
                          className={`${selectedMenu === menu.path ? 'font-bold' : 'font-normal'} cursor-pointer`}
                        >
                          {menu.label}
                        </span>
                      ))}
              </>
            )}
          </div>

          <Logout />
        </div>
      </div>

      <Border borderColor={BorderColor.black} position='absolute top-20 left-0' />
    </div>
  );
};
export default OuterGnb;

const planMenus = [
  {
    path: RoutePath.workspaces,
    label: 'Workspace',
  },
  {
    path: RoutePath.plan,
    label: 'Plan',
  },
  {
    path: RoutePath.guide,
    label: 'User Guide',
  },
];
