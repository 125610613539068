import Calendar from 'components/common/calendar/common/CommonCalendar';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { useEffect, useState } from 'react';
import { Point } from 'types/feature/workspace/workspace';
import HolidayGuide from './guide/HolidayGuide';
import SelectedDay from './selected/SelectedDay';
import { convertStringToCurrentDate, closedDayToFormat, closedDaysToFormat } from 'utils/date/dateUtils';

type Props = {
  point: Point;
  handlePoint: (point: Point) => void;
};

const HolidayForm = ({ point, handlePoint }: Props) => {
  const [startDate, setStartDate] = useState<Date>(new Date());

  // TODO: 날짜 관련 state 따로 관리 하지 않고 하나로 합쳐서 사용하도록 변경 필요
  const [selectedDates, setSelectedDates] = useState<string[]>([]); // 서버에 전송할 날짜
  const [selectedCalendarDates, setSelectedCalendarDates] = useState<Date[]>([]); // 화면에서 보여줄 날짜

  // 날짜 초기 설정
  useEffect(() => {
    setSelectedDates(point.operation.closedDays);

    // 휴무일을 날짜로 변환 후 시간순으로 정렬
    const dates = point.operation.closedDays
      .map(day => convertStringToCurrentDate(day))
      .sort((a, b) => {
        return a.getTime() - b.getTime();
      });

    setSelectedCalendarDates(dates);
  }, [point]);

  // 달력 선택 시
  const handleDate = (date: Date) => {
    // 휴무일 4개 넘은 경우 리턴
    if (selectedDates.length >= 4) return;
    setStartDate(date);
    const selectedCalDays: Date[] = [...selectedCalendarDates, date];
    const selectedDays: string[] = [...selectedDates, closedDayToFormat(date)];
    const filteredDays: string[] = Array.from(new Set(closedDaysToFormat(selectedDays)));

    setSelectedDates([...filteredDays]);
    setSelectedCalendarDates(selectedCalDays);

    handlePoint({
      ...point,
      operation: {
        ...point.operation,
        closedDays: filteredDays,
      },
    });
  };

  // 전체 삭제
  const handleClickDeleteAllDate = () => {
    setSelectedDates([]);
    setSelectedCalendarDates([]);
    handlePoint({
      ...point,
      operation: {
        ...point.operation,
        closedDays: [],
      },
    });
  };

  return (
    <div className='flex flex-col gap-2.5 '>
      <HolidayGuide />

      {selectedCalendarDates.length > 0 ? (
        <>
          <div className='flex gap-2.5'>
            {selectedCalendarDates
              // 날짜순으로 sorting
              .sort((a, b) => {
                return a.getTime() - b.getTime();
              })
              .map((date, dateIndex) => (
                <SelectedDay
                  key={dateIndex}
                  selectedDays={selectedDates}
                  setSelectedDay={setSelectedDates}
                  setSelectedCalendarDay={setSelectedCalendarDates}
                  day={closedDayToFormat(date)}
                  point={point}
                  handlePoint={handlePoint}
                />
              ))}

            {selectedCalendarDates.length > 0 && (
              <div
                onClick={handleClickDeleteAllDate}
                className='flex items-center gap-1 pl-3 pr-2 bg-white border rounded-full cursor-pointer shrink-0 border-gray-44 h-9'
              >
                <div className={` text-gray-44 ${TextType.h4}`}>전체 삭제</div>
                <div>
                  <img src={ICON.DELETE} alt='delete-white' />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={`${TextType.h4} flex items-center h-9 text-gray-44`}>선택된 휴무일이 없습니다.</div>
      )}

      {/* 달력 */}
      <Calendar startDate={startDate} handleDate={handleDate} calendarDays={selectedCalendarDates} />
    </div>
  );
};

export default HolidayForm;
