import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { ScreenType } from 'types/feature/content/contents';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { PatchScreensaver } from 'types/feature/screensaver/patchScreensaver';
import { PostScreensaver } from 'types/feature/screensaver/postScreensaver';

const useScreensaver = () => {
  const { api } = useApi();

  const getScreensavers = async (pointId: string, screenType: ScreenType) => {
    const res = await api.get<ApiResponse<GetScreensaver[]>>(Endpoint.screensaverV2, {
      params: {
        pointId,
        screenType,
      },
    });

    if (!res.data) return;

    return res.data;
  };

  const postScreensaver = async (screensaver: PostScreensaver) => {
    await api.post(Endpoint.screensaverV2, screensaver);
  };

  const deleteScreensaver = async (id: string) => {
    await api.delete(`${Endpoint.screensaver}/${id}`);
  };

  const updateScreensaver = async (id: string, data: PatchScreensaver) => {
    await api.patch(`${Endpoint.screensaver}/${id}`, data);
  };

  return {
    getScreensavers,
    postScreensaver,
    deleteScreensaver,
    updateScreensaver,
  };
};
export default useScreensaver;
