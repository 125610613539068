import { CheckboxProps } from './Checkbox.type';

const Checkbox = ({ checked, label, onChange }: CheckboxProps) => {
  return (
    <div className={`flex gap-2.5 h-9 items-center`}>
      <input
        onChange={onChange}
        value={label}
        id='checkbox'
        type='checkbox'
        className='w-4 h-4 border-0 cursor-pointer border-gray-cc'
        checked={checked}
      />

      <label htmlFor='checkbox' className='cursor-pointer text-h4 font-h4 leading-h4 text-gray-44'>
        {label}
      </label>
    </div>
  );
};
export default Checkbox;
