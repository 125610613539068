import Layout from 'components/guide/layout/Layout';

const GuideBook = () => {
  return (
    <div className='absolute top-0 left-0 z-50 flex flex-col items-center justify-center w-screen h-screen gap-3 bg-primary-White'>
      <Layout />
    </div>
  );
};

export default GuideBook;
