import { TextType } from 'components/common/title/Title';
import React from 'react';
import { TenantUsage } from 'types/feature/statistics/tenantUsage';

type Props = {
  tenant: TenantUsage;
  numbering: number;
};

const TenantShareItem = (props: Props) => {
  return (
    <div className='h-[18px] flex items-center justify-between gap-3'>
      <div className={`${TextType.h4_bold} w-[20px] text-gray-22`}>{props.numbering + 1}</div>
      <div className={`${TextType.h4} text-gray-44 text-left w-[130px] truncate whitespace-nowrap`}>
        {props.tenant.tenantName.ko}
      </div>
      <div className='w-[106px] flex items-center justify-start'>
        <div
          style={{
            width: props.tenant.ratio,
          }}
          className={`h-[6px] bg-blue_green`}
        />
      </div>
      <div className={`${TextType.h5} text-gray-99`}>{props.tenant.totalCount.toLocaleString()}</div>
    </div>
  );
};
export default TenantShareItem;
