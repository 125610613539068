import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCustomPageStore from 'stores/customPage';
import { Workspace } from 'types/feature/workspace/workspace';
import GlobalMenuContainer from './gnb/menu/GlobalMenuContainer';
import Logout from './logout/Logout';
import WorkspaceSelectBox from './workspace-select-box/WorkspaceSelectBox';

const InnerGnb = () => {
  const { workspaceId } = useParams();
  const { getWorkspace, getWorkspaces } = useWorkspace();

  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceList, setWorkspaceList] = useState<Workspace[]>([]);
  const [openList, setOpenList] = useState(false);

  // store
  const customPageStore = useCustomPageStore();

  // TODO: workspace select box 로 이동
  // 워크스페이스 조회
  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);

      if (result) {
        setWorkspace(result);
      }
    }
  };

  // 워크스페이스 목록 조회
  const fetchWorkspaces = async () => {
    const workspaces = await getWorkspaces();

    if (workspaces) {
      setWorkspaceList(workspaces);
    }
  };

  const closeList = () => {
    setOpenList(false);
  };

  const handleClickWorkspaceList = () => {
    if (workspaceList.length === 1) {
      return;
    }

    setOpenList(!openList);
  };

  useEffect(() => {
    fetchWorkspaces();

    return () => {
      customPageStore.resetCustomPages();
    };
  }, []);

  useEffect(() => {
    fetchWorkspace();
  }, [workspaceId]);

  return (
    <div className='z-50 fixed top-0 left-0 flex items-center justify-center w-full h-20 bg-primary-White px-[60px]'>
      <div className='relative flex items-center justify-between w-full max-w-[1400px]'>
        <div
          onClick={handleClickWorkspaceList}
          className={`flex items-center gap-2 h-full cursor-pointer hover:bg-state-blue_bg px-1 ${
            openList && 'bg-state-blue_bg'
          }`}
        >
          <div className={`${TextType.h2} truncate shrink-0 text-gray-22 mt-0.5`}>{workspace?.name}</div>
          {workspaceList.length > 1 && (
            <div>
              <img src={ICON.ARROW_EMPTY_DOWN} alt='' />
            </div>
          )}
        </div>

        {openList && workspaceList.length > 1 && (
          <WorkspaceSelectBox workspaceList={workspaceList} closeList={closeList} />
        )}

        <div className='flex items-center gap-10'>
          <GlobalMenuContainer />

          <Logout />
        </div>
      </div>

      <Border borderColor={BorderColor.black} position='absolute top-20 left-0' />
    </div>
  );
};

export default InnerGnb;
