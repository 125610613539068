import DatePicker from 'react-datepicker';
import 'components/common/calendar/style/react-datepicker.css';
import CommonCalendarHeader, { CalendarHeaderProps } from './header/CommonCalendarHeader';
import { ko } from 'date-fns/locale';
import { getDate, getMonth } from 'date-fns';

type Props = {
  startDate: Date;
  endDate?: Date;
  calendarDays?: Date[] | [];
  handleDate: (date: Date) => void;
};

const Calendar = ({ startDate, endDate, calendarDays, handleDate }: Props) => {
  const renderHeader = ({ decreaseMonth, increaseMonth, date }: CalendarHeaderProps) => (
    <CommonCalendarHeader decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} date={date} />
  );

  return (
    <div className='w-[360px]'>
      <DatePicker
        dateFormat='yyyy/MM/dd'
        locale={ko}
        renderCustomHeader={renderHeader}
        startDate={startDate}
        onChange={handleDate}
        inline // 캘린더 오픈
        shouldCloseOnSelect={false} // 클릭해도 안 사라짐
        highlightDates={calendarDays && [...calendarDays]}
        // dayClassName={d =>
        //   getDate(d) === getDate(startDate) && getMonth(d) === getMonth(startDate)
        //     ? 'normal-day selected-day'
        //     : 'normal-day'
        // }
      />
    </div>
  );
};

export default Calendar;
