import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import useModalStore from 'stores/modal';
import useWorkspaceStore, { WorkspaceStatus } from 'stores/workspace';
import { RoutePath } from 'types/common/paths';

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const workspaceStore = useWorkspaceStore();
  const modalStore = useModalStore();

  // workspaceId를 replace한 path를 return
  const replaceWorkspaceId = (path: RoutePath | string, workspaceId: string | undefined): RoutePath | string => {
    if (workspaceId) {
      return path.replace(':workspaceId', workspaceId);
    }
    return path;
  };

  // TODO: 제거 후 replacePath 로 변경
  // replace한 path로 navigate(workspaceId는 알아서 replace 해줌)
  const navigateToReplacedPath = (
    path: RoutePath,
    workspaceId: string | undefined,
    searchValue?: string,
    replaceValue?: string,
  ) => {
    if (!workspaceId) {
      navigate(path);
      return;
    }

    const replacedPath = replaceWorkspaceId(path, workspaceId);

    // replace할 value가 workspaceId 말고 더 있을 경우
    if (searchValue && replaceValue) {
      navigate(replacedPath.replace(searchValue, replaceValue));
      return;
    }

    // replace할 value가 workspaceId 뿐일 경우
    navigate(replacedPath);
  };

  // TODO: 제거
  // 워크스페이스 개수별 페이지 이동 분기처리
  const navigateToMain = () => {
    // 워크스페이스 없다면 alert
    if (workspaceStore.workspaceStatus === WorkspaceStatus.NONE) {
      modalStore.setOpenWorkspaceModal(true);
      modalStore.setWorkspaceModalText(`사용가능한 워크스페이스가 없습니다.\n관리자에게 문의바랍니다.`);
      return;
    }

    // 1개일 경우 메인페이지로 바로 이동
    if (workspaceStore.workspaceStatus === WorkspaceStatus.SINGLE) {
      navigateToReplacedPath(RoutePath.dashboard, workspaceStore.mainWorkspaceId);
      return;
    }
    // 여러개일 경우 워크스페이스 리스트 페이지로 이동
    navigate(RoutePath.workspaces);
  };

  // 필요한 id 들을 모두 replace 한 경로로 페이지 이동
  const customNavigate = (
    routePath: RoutePath,
    pointId?: string,
    customPageId?: string,
    customBoardId?: string,
    option?: NavigateOptions,
  ): void => {
    const navigatePath = routePath
      .replace(':workspaceId', workspaceId || '')
      .replace(':pointId', pointId || '')
      .replace(':customPageId', customPageId || '')
      .replace(':customBoardId', customBoardId || '');

    if (option) {
      navigate(navigatePath, option);
    } else {
      navigate(navigatePath);
    }
  };

  return {
    navigateToReplacedPath,
    replaceWorkspaceId,
    navigateToMain,
    customNavigate,
  };
};

export default useCustomNavigate;
