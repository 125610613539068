import { TableHeader } from 'types/common/tableHeader';

export const CHANGED_INFO_TABLE_HEADER: TableHeader = [
  {
    label: 'No.',
    width: 60,
    direction: 'center',
  },
  {
    label: '상태',
    width: 100,
    direction: 'left',
  },
  {
    label: '층',
    width: 80,
    direction: 'left',
  },
  {
    label: '지도 타이틀',
    width: 264,
    direction: 'left',
  },
  {
    label: '노출 타이틀',
    width: 264,
    direction: 'left',
  },
  {
    label: '완료',
    width: 80,
    direction: 'center',
  },
];
