export const CONTENT_IMAGE_GUIDES = [
  '10MB 이하의 png, jpeg, jpg 파일만 첨부 가능합니다.',
  '최적 사이즈는 세로 2160*3400 / 가로 3840*1918 입니다.',
  '기준 사이즈와 맞지 않을 경우 영역에 맞게 확대되어 제공됩니다.',
];

export const CONTENT_VIDEO_GUIDES = [
  '50MB 이하의 mp4 파일만 첨부 가능합니다.',
  '최적 사이즈는 세로 2160*3400 / 가로 3840*1918 입니다.',
];
