import React from 'react';
import './GradientLoader.css';

const GradientLoader = () => {
  return (
    <div className='wrapper'>
      <div className='gradient_loader' />
    </div>
  );
};
export default GradientLoader;
