import CommonCalendarHeader from 'components/common/calendar/common/header/CommonCalendarHeader';
import FormControl from 'components/common/form-control/FormControl';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { ko } from 'date-fns/locale';
import dayjs from 'dayjs';
import { Dispatch, RefObject, SetStateAction, useRef } from 'react';
import DatePicker from 'react-datepicker';

type Props = {
  selectedStart: Date | undefined;
  selectedEnd: Date | undefined;
  setSelectedStart: Dispatch<SetStateAction<Date>>;
  setSelectedEnd: Dispatch<SetStateAction<Date>>;
};

const TenantRangeFilter = ({ selectedStart, selectedEnd, setSelectedStart, setSelectedEnd }: Props) => {
  const startRef = useRef<DatePicker>(null);
  const endRef = useRef<DatePicker>(null);

  // 실시간 데이터 지원하지 않음
  const yesterday = dayjs().subtract(1, 'day');

  // 시작일 변경 시
  const onChangeStart = (startDate: Date) => {
    setSelectedStart(startDate);
  };

  // 종료일 변경 시
  const onChangeEnd = (endDate: Date) => {
    setSelectedEnd(endDate);
  };

  // 아이콘 클릭 시 캘린더 오픈
  const onClickCalendarIcon = (calendarRef: RefObject<DatePicker>) => {
    if (calendarRef.current) {
      calendarRef.current?.setFocus();
    }
  };

  return (
    <div className='mr-2.5'>
      <FormControl
        name='기간'
        labelSize='none'
        control={
          <div className='flex items-center gap-2.5'>
            <div className='w-[140px] h-9 flex items-center justify-center gap-2.5 bg-white border border-gray-99 pl-3 pr-2'>
              {/**
               * 시작일
               * - 디폴트: 어제로부터 30일전
               * - 최대값: 종료일
               */}
              <DatePicker
                ref={startRef}
                onChange={onChangeStart}
                selected={selectedStart}
                maxDate={selectedEnd}
                locale={ko}
                selectsRange={false}
                dateFormat='yyyy.MM.dd'
                renderCustomHeader={CommonCalendarHeader}
                className={`bg-transparent w-full h-full py-1 border-none text-gray-44 tracking-tighter ${TextType.body1} cursor-pointer`}
              />

              <img
                className='cursor-pointer'
                onClick={onClickCalendarIcon.bind(this, startRef)}
                alt='calendar'
                src={ICON.CALENDAR}
              />
            </div>

            <div>~</div>

            <div className='w-[140px] h-9 flex items-center justify-center gap-2.5 bg-white border border-gray-99 pl-3 pr-2'>
              {/**
               * 종료일
               * - 디폴트: 어제
               * - 최대값: 어제
               */}
              <DatePicker
                ref={endRef}
                onChange={onChangeEnd}
                selected={selectedEnd}
                minDate={selectedStart}
                maxDate={yesterday.toDate()}
                locale={ko}
                selectsRange={false}
                dateFormat='yyyy.MM.dd'
                renderCustomHeader={CommonCalendarHeader}
                className={`bg-transparent w-full h-full py-1 border-none text-gray-44 tracking-tighter ${TextType.body1} cursor-pointer`}
              />

              <img
                className='cursor-pointer'
                onClick={onClickCalendarIcon.bind(this, endRef)}
                alt='calendar'
                src={ICON.CALENDAR}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};
export default TenantRangeFilter;
