import { ICON } from 'constants/icons';
import { FILE_TYPES, FileType } from 'types/common/file/file';

type Props = {
  fileType: FileType;
};

const FileTypeIcon = ({ fileType }: Props) => {
  return (
    <span className='shrink-0'>
      <img
        src={fileType === FileType.IMAGE ? ICON.CONTENT_IMAGE : ICON.CONTENT_VIDEO}
        alt={`${FILE_TYPES[fileType]} icon`}
      />
    </span>
  );
};
export default FileTypeIcon;
