import { create } from 'zustand';

type MapStore = {
  isMapLoading: boolean;
  setMapLoading: (load: boolean) => void;

  loadMapScript: boolean;
  setLoadMapScript: (value: boolean) => void;
};

const useMapStore = create<MapStore>((set, get) => ({
  isMapLoading: false,
  setMapLoading(isMapLoading: boolean) {
    set({ isMapLoading });
  },

  loadMapScript: false,
  setLoadMapScript(loadMapScript: boolean) {
    set(() => ({ loadMapScript }));
  },
}));

export default useMapStore;
