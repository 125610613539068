import { MM_DD } from 'data/common';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

// 월,일,요일 형태로 변환
export function convertCurrentDay(date: string) {
  if (date) {
    const currentYear = dayjs().get('year').toString();

    const currentDate = currentYear.concat('-', date.replace('.', '-'));
    const convertedDate = dayjs(currentDate).locale('ko').format('MM/DD(dd)');
    return convertedDate;
  }
}

// 현재 년,월,일 형태로 변환
export function convertStringToCurrentDate(date: string): Date {
  const currentYear = new Date().getFullYear().toString();

  // const currentDate = currentYear.concat('-', date.replace('.', '-'));
  const mmddyyyy = date.replace('.', '/').concat(`/${currentYear}`);

  const convertedDate = new Date(mmddyyyy);

  return convertedDate;
}

// date를 2023-10-10 형태로 반환
export function sliceDate(date: string): string {
  return convertDashToDot(date.slice(0, 10));
}

// 휴무일 등록에 필요한 날짜 포맷으로 변경
export function closedDaysToFormat(closedDays: string[]) {
  return closedDays.map(day => dayjs(day).format('MM.DD'));
}

// 10.10
export function closedDayToFormat(closedDay: Date): string {
  return dayjs(closedDay).format('MM.DD');
}

// Date 타입의 객체를 넣으면 '2023-01-01' 형식의 string으로 반환
export function convertDateToString(date: Date | null): string {
  return dayjs(date).format('YYYY-MM-DD');
}

export function convertDashToDot(date: string) {
  return date.replaceAll('-', '.');
}

// YYYY-MM-DD 를 MM.DD로 치환
export function convertServerDateToClientDate(date: string) {
  return dayjs(date).format(MM_DD);
}
