export type VennData = {
  sets: string[];
  size: number;
  label: string;
  name: '키오스크' | '모바일';
  key: string;
};

export const vennConfig = {
  scale: {
    color: {
      range: ['#92D5DE', '#B7B7B7'],
    },
  },
  setsField: 'sets',
  sizeField: 'size',
  style: {
    fillOpacity: 0.8,
    fill: (datum: VennData, index: any, data: any) => {
      const { name } = datum;
      if (name === '키오스크') return '#92D5DE';
      if (name === '모바일') return '#B7B7B7';
    },
  },
  legend: false,
  label: {
    position: 'inside',
    style: {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: 'pretendard',
      fontStyle: 'normal',
      lineHeight: 28,
      letterSpacing: -0.4,
    },
    text: (datum: VennData) => datum.label || '',
  },
  interaction: {
    elementHighlight: false,
  },
  tooltip: {
    title: false,
    items: [
      (d: any) => {
        return { name: d.key, value: `${d.size}%` };
      },
    ],
  },
};
