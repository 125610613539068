import { AxiosError } from 'axios';
import AlertModal from 'components/common/modal/alert/AlertModal';
import MenuContentAddContainer from 'components/feature/menu/add/MenuContentAddContainer';
import useModal from 'hooks/common/useModal';
import useCustomBoard from 'hooks/feature/menu/custom/useCustomBoard';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomBoard } from 'types/feature/menu/customBoard';

const MenuContentsEditPage = () => {
  const { customBoardId } = useParams();
  const { getCustomBoard } = useCustomBoard();
  const { openModalPopup, openAlert, closeModalPopup } = useModal();

  const [customBoard, setCustomBoard] = useState<CustomBoard>();
  const [message, setMessage] = useState('');

  const fetchCustomBoard = async () => {
    try {
      if (customBoardId) {
        const board = await getCustomBoard(customBoardId);

        if (board) {
          setCustomBoard(board);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          openModalPopup('ALERT');
          setMessage('메뉴 콘텐츠를 불러올 수 없습니다.');
        }
      }
    }
  };

  useEffect(() => {
    fetchCustomBoard();
  }, [customBoardId]);

  return (
    <>
      <MenuContentAddContainer isEdit board={customBoard} />

      {openAlert && (
        <AlertModal
          message={message}
          closeModal={() => {
            closeModalPopup('ALERT');
          }}
        />
      )}
    </>
  );
};
export default MenuContentsEditPage;
