import React from 'react';
import TableTotal from '../total/TableTotal';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';

type Props = {
  totalCount: number;
  onClickDownload: () => Promise<void>;
};

const TableCsvDownload = (props: Props) => {
  return (
    <div className='flex items-center justify-between'>
      <TableTotal totalCount={props.totalCount} />

      <Button
        onClick={props.onClickDownload}
        size='csv'
        textStyle={TextType.h5}
        color={ButtonColor.csv}
        text='엑셀 다운로드'
        iconPath={ICON.DOWNLOAD}
      />
    </div>
  );
};
export default TableCsvDownload;
