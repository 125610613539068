import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { TextType } from 'components/common/title/Title';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { reverseIndex } from 'utils/index/reverseIndex';
import { CHANGED_INFO_TABLE_HEADER } from './header/changedInfoTableHeader';
import ChangedInfoTableRow from './row/ChangedInfoTableRow';
import TableException from './exception/TableException';
import { ChangeTenant, ChangedTenants } from 'types/feature/tenant/changedTenant';

type Props = {
  setCurrentTenant: Dispatch<SetStateAction<ChangeTenant | null>>;
  changedTenants: ChangedTenants | null;
  currentTenantId: string;
  isLoading: boolean;
};

const ChangedInfoTable = ({ setCurrentTenant, changedTenants, currentTenantId, isLoading }: Props) => {
  return (
    <div className='flex flex-col gap-5 p-6 h-[18px] w-full'>
      <div className={`w-full flex gap-1 justify-end`}>
        <span className={`${TextType.h4_bold} text-gray-99`}>{changedTenants?.synchronizedDate.slice(0, 10)}</span>
        <span className={`${TextType.h4} text-gray-99`}>동기화 진행</span>
      </div>

      <table className='block w-full'>
        <CommonTableHeader tableHead={CHANGED_INFO_TABLE_HEADER} ableToScroll />

        {changedTenants?.changeTenants && changedTenants.changeTenants.length > 0 ? (
          <tbody className='w-full max-h-[520px] overflow-y-auto block'>
            {changedTenants?.changeTenants.map((changeTenant, index) => {
              return (
                <Fragment key={changeTenant.id}>
                  <ChangedInfoTableRow
                    changeTenant={changeTenant}
                    index={reverseIndex(changedTenants?.changeTenants.length)[index]}
                    currentTenantId={currentTenantId}
                    setCurrentTenant={setCurrentTenant}
                  />
                </Fragment>
              );
            })}
          </tbody>
        ) : (
          <tbody className='flex items-center justify-center w-full h-full py-20'>
            <tr>
              <td>
                <TableException
                  message={isLoading ? '변경된 정보를 가져오는 중 입니다...' : '변경된 정보가 없습니다.'}
                  type={isLoading ? 'loading' : 'no_content'}
                />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};
export default ChangedInfoTable;
