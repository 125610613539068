import { useNavigate } from 'react-router-dom';
import useModalStore from 'stores/modal';
import useWorkspaceStore from 'stores/workspace';
import { RoutePath } from 'types/common/paths';
import { Workspace } from 'types/feature/workspace/workspace';
import useCustomNavigate from './useCustomNavigate';

const useWorkspaceStatus = () => {
  const modalStore = useModalStore();
  const workspaceStore = useWorkspaceStore();

  const { navigateToReplacedPath } = useCustomNavigate();
  const navigate = useNavigate();

  // 워크스페이스 개수별로 페이지 이동을 분기처리 한다.
  const handleWorkspaceStatus = async (workspaces: Workspace[]) => {
    // 없을 경우 alert -> 로그아웃
    if (workspaces.length === 0) {
      modalStore.setWorkspaceModalText(`사용가능한 워크스페이스가 없습니다.\n관리자에게 문의 바랍니다.`);
      modalStore.setOpenWorkspaceModal(true);

      return;
    }

    // 워크스페이스 개수, 아이디로 현재 유저의 워크스페이스 상태를 저장
    workspaceStore.setWorkspaceStatus(workspaces.length, workspaces[0].id);

    // 1개일 경우 메인페이지로 바로 이동
    if (workspaces.length === 1) {
      navigateToReplacedPath(RoutePath.dashboard, workspaces[0].id);
      return;
    }
    // 여러개일 경우 워크스페이스 리스트 페이지로 이동
    navigate(RoutePath.workspaces);
  };

  return {
    handleWorkspaceStatus,
  };
};
export default useWorkspaceStatus;
