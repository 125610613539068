import AlertModal from 'components/common/modal/alert/AlertModal';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { DISPLAY_TYPE } from 'data/display';
import { useState } from 'react';
import { ScreenType } from 'types/feature/content/contents';
import { DisplayStatus, DisplayType, GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { convertDashToDot } from 'utils/date/dateUtils';
import ScheduleEditModal from './edit-modal/ScheduleEditModal';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';

type Props = {
  screensaver: GetScreensaver;
  refetchScreensaver: (pointId: string, screenType: ScreenType) => Promise<void>;
  disabled: boolean;
  selectedPoint: CommonOptionType | undefined;
};

const ScheduleDisplayRange = ({ screensaver, refetchScreensaver, disabled, selectedPoint }: Props) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const openModal = () => {
    setOpenEditModal(true);
  };

  const openDoneAlert = () => {
    setOpenAlert(true);
  };

  return (
    <div className={`${TextType.body1} text-gray-44 text-left`}>
      <div className={`${TextType.body2} flex items-center gap-2  px-2.5`}>
        {!disabled && (
          <img className='cursor-pointer' onClick={openModal} src={ICON.CALENDAR_BUTTON} alt='달력 아이콘' />
        )}

        <button
          onClick={openModal}
          disabled={disabled}
          className={`${TextType.body2} ${disabled ? 'cursor-default' : 'cursor-pointer'} text-gray-44`}
        >
          {screensaver.displayType === DisplayType.ALWAYS_ON ? (
            // 상시 노출
            <>
              <span>{convertDashToDot(screensaver.startDate)}</span>
              <span> ~ </span>
              {/* 상시노출, 삭제예정 스케줄은 종료날짜 보여주기 */}
              {screensaver.displayStatus === DisplayStatus.WAIT_DELETE ? (
                <span>{convertDashToDot(screensaver.endDate)}</span>
              ) : (
                <span>{DISPLAY_TYPE[screensaver.displayType]}</span>
              )}
            </>
          ) : (
            // 기간 노출
            <>
              <span>{convertDashToDot(screensaver.startDate)}</span>
              <span> ~ </span>
              <span>{convertDashToDot(screensaver.endDate)}</span>
            </>
          )}
        </button>
      </div>

      {openEditModal && (
        <ScheduleEditModal
          refetchScreensaver={refetchScreensaver}
          screensaver={screensaver}
          closeModal={() => setOpenEditModal(false)}
          openDoneAlert={openDoneAlert}
          selectedPoint={selectedPoint}
        />
      )}

      {openAlert && <AlertModal message='광고 스케줄을 수정하였습니다.' closeModal={() => setOpenAlert(false)} />}
    </div>
  );
};
export default ScheduleDisplayRange;
