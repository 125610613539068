import axios, { AxiosError, AxiosInstance } from 'axios';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useHandleError from 'hooks/common/useError';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore } from 'stores/auth';
import { RoutePath } from 'types/common/paths';

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
  put<T>(...params: Parameters<AxiosInstance['put']>): Promise<T>;
  delete<T>(...params: Parameters<AxiosInstance['delete']>): Promise<T>;
}

export const useAuthApi = () => {
  // hook
  const { handleResponseError } = useHandleError();
  const navigate = useNavigate();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();

  // store
  const authStore = useAuthStore();

  const authApiInstance: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_SSO_API_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  authApiInstance.interceptors.request.use(
    async res => {
      return res;
    },
    error => Promise.reject(error),
  );

  let isRetrying = false;

  authApiInstance.interceptors.response.use(
    response => {
      return response.data;
    },
    async (error: AxiosError) => {
      const { response } = error;

      if (response) {
        // 통합인증 API response status 가 400대 라면 토큰만료이기 때문에 바로 통합인증 로그인페이지로 이동한다.
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500 &&
          !isRetrying
        ) {
          authStore.setIsTokenExpired(true);
        }

        // api 에러
        handleResponseError(error);
        throw error;
      }

      // 서버 장애, 네트워크 에러 등
      if (workspaceId) {
        navigateToReplacedPath(RoutePath.errorWithWorkspaceId, workspaceId);
        throw error;
      }

      navigate(RoutePath.error);
      throw error;
    },
  );

  return { authApiInstance };
};
