import { IndexType } from '.';

export enum ChangedStatus {
  ADD = 'ADD',
  MODIFY = 'MODIFY',
  ALL = '',
}

export const ChangedStatusKo: IndexType = {
  ADD: '추가',
  MODIFY: '수정',
};
