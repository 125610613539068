// 익일 설정
export const setTomorrow = () => {
  // const today = new Date();
  // const tomorrow = new Date(today.setDate(today.getDate() + 1));

  // return tomorrow;

  // TODO: tomorrow 로 변경
  return new Date();
};
