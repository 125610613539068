import LOADING_ITEM_1 from 'assets/icon/loading/loading-item-1.svg';
import LOADING_ITEM_2 from 'assets/icon/loading/loading-item-2.svg';
import LOADING_ITEM_3 from 'assets/icon/loading/loading-item-3.svg';

import INTRO from 'assets/intro.svg';
import LOGO_GRAY from 'assets/logo/logo-gray.svg';
import LOGO_P_GRAY from 'assets/logo/logo-p-gray.svg';
import LOGO_P_WHITE from 'assets/logo/logo-p-white.svg';
import LOGO_P from 'assets/logo/logo-p.svg';
import LOADING from 'assets/loading/loading.gif';
import LOADING_BACKGROUND from 'assets/icon/loading/loading-background.svg';
import LOADING_ARROW from 'assets/icon/loading/loading-arrow.svg';

import THUMBNAIL_DEFAULT from 'assets/images/thumb_default.png';
import MAP from 'assets/images/map.svg';
import MAP_CENTER from 'assets/images/center.svg';

export const IMAGES = {
  INTRO,
  LOGO_P_WHITE,
  LOGO_GRAY,
  LOGO_P,
  LOGO_P_GRAY,

  LOADING_ITEM_1,
  LOADING_ITEM_2,
  LOADING_ITEM_3,
  LOADING_BACKGROUND,
  LOADING_ARROW,

  LOADING,
  THUMBNAIL_DEFAULT,
  MAP,
  MAP_CENTER,
};
