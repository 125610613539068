import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ContentLayout from 'components/common/layout/content/ContentLayout';
import FormLayout from 'components/common/layout/form/FormLayout';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useModal from 'hooks/common/useModal';
import useCustomBoard from 'hooks/feature/menu/custom/useCustomBoard';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { CustomBoard } from 'types/feature/menu/customBoard';
import { DisplayType } from 'types/feature/screensaver/getScreensaver';
import { setTomorrow } from 'utils/date/setTomorrow';
import DisplayDateSelect from './display-date/DisplayDateSelect';
import ImageForm from './image/ImageForm';
import MenuTitleInputs from './title/MenuTitleInputs';
import { convertDateToString } from 'utils/date/dateUtils';

type Props = {
  isEdit: boolean;
  board?: CustomBoard;
};

const MenuContentAddContainer = ({ isEdit, board }: Props) => {
  const { pointId, customPageId, customBoardId } = useParams();
  const { state: customPageType } = useLocation();
  const { customNavigate } = useCustomNavigate();
  const { openModalPopup, closeModalPopup, openAlert, openConfirm } = useModal();
  const { postCustomBoard, putCustomBoard, deleteCustomBoard } = useCustomBoard();

  const [message, setMessage] = useState('');
  const [customBoard, setCustomBoard] = useState<CustomBoard>({
    id: '',
    title: {
      ko: '',
      en: '',
      ja: '',
      cn: '',
      tw: '',
    },
    customPageId: '',
    displayType: DisplayType.ALWAYS_ON,
    startDate: convertDateToString(setTomorrow()),
    endDate: convertDateToString(setTomorrow()),
    mainImage: null,
    contentImage: null,
  });
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  // 메뉴 컨텐츠 페이지 목록으로 이동
  const moveToContentListPage = () => {
    customNavigate(RoutePath.pointCustomBoards, pointId, customPageId);
  };

  // validation
  const validCustomBoard = () => {
    const { ko, en, ja, cn, tw } = customBoard.title;

    // 타이틀 하나라도 입력
    if (!ko && !en && !ja && !cn && !tw) {
      setMessage('타이틀을 입력해주세요.');
      openModalPopup('ALERT');

      return false;
    }

    // 목록 이미지는 목록형일 경우만 필수
    if (customPageType === 'LIST') {
      if (!customBoard.mainImage) {
        setMessage('목록 이미지를 추가해주세요.');
        openModalPopup('ALERT');
        return false;
      }
    }

    // 상세 이미지는 항상 필수
    if (!customBoard.contentImage) {
      setMessage('상세 이미지를 추가해주세요.');
      openModalPopup('ALERT');
      return false;
    }

    return true;
  };

  // 컨펌창
  const onClickSave = () => {
    if (validCustomBoard()) {
      openModalPopup('CONFIRM');
      setMessage(`콘텐츠를 ${isEdit ? '수정' : '등록'}하시겠습니까?`);
    }
  };

  // 저장
  const addCustomBoard = async () => {
    if (customPageId) {
      await postCustomBoard({
        ...customBoard,
        customPageId,
      });
    }
  };

  // 수정
  const editCustomBoard = async () => {
    if (customBoardId) {
      await putCustomBoard(customBoardId, customBoard);
    }
  };

  // 등록/수정 컨펌창 확인 시
  const onClickOk = async () => {
    try {
      if (customPageId) {
        if (isEdit) {
          await editCustomBoard();
        } else {
          await addCustomBoard();
        }

        // 콘텐츠 목록으로 이동
        moveToContentListPage();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          closeModalPopup('CONFIRM');
          openModalPopup('ALERT');
          setMessage(`콘텐츠를 ${isEdit ? '수정' : '등록'}할 수 없습니다.`);
        }
      }
    }
  };

  // custom board 삭제
  const onDeleteCustomBoard = async () => {
    try {
      if (customBoardId) {
        setOpenDeleteConfirm(false);

        // board 삭제 api
        await deleteCustomBoard(customBoardId);

        // 콘텐츠 목록으로 이동
        moveToContentListPage();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          closeModalPopup('CONFIRM');

          openModalPopup('ALERT');
          setMessage('메뉴 콘텐츠를 삭제할 수 없습니다.');
        }
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      if (board) {
        setCustomBoard({ ...board });
      }
    }
  }, [board]);

  return (
    <>
      <ContentLayout>
        <FormLayout>
          <div className='flex flex-col w-full gap-6'>
            <div className='flex gap-3'>
              {/* 타이틀 */}
              <MenuTitleInputs customBoard={customBoard} setCustomBoard={setCustomBoard} />

              {/* 노출 설정 */}
              <DisplayDateSelect customBoard={customBoard} setCustomBoard={setCustomBoard} />
            </div>

            <Border />

            {/* 목록 이미지, 상세 이미지, 가이드 */}
            <ImageForm
              customBoard={customBoard}
              setCustomBoard={setCustomBoard}
              customPageType={customPageType}
              isEdit={isEdit}
            />

            <Border />

            {/* 버튼 */}
            <div className='relative flex items-center justify-end w-full h-14'>
              <div className='flex justify-center gap-2.5 absolute left-1/2 top-1/2 -translate-x-1/2  -translate-y-1/2 w-full'>
                <Button text='목록으로' color={ButtonColor.secondary} size={120} onClick={moveToContentListPage} />
                <Button
                  text={`${isEdit ? '수정' : '등록'}`}
                  onClick={onClickSave}
                  color={ButtonColor.primary}
                  size={120}
                />
              </div>

              {isEdit && (
                <div className='z-50'>
                  <Button
                    text='삭제'
                    onClick={() => {
                      setOpenDeleteConfirm(true);
                    }}
                    color={ButtonColor.secondary}
                    size={120}
                  />
                </div>
              )}
            </div>
          </div>
        </FormLayout>
      </ContentLayout>

      {openAlert && (
        <AlertModal
          message={message}
          closeModal={() => {
            closeModalPopup('ALERT');
          }}
        />
      )}

      {openConfirm && (
        <ConfirmModal
          message={message}
          onClickCancel={() => {
            closeModalPopup('CONFIRM');
          }}
          onClickOk={onClickOk}
        />
      )}

      {openDeleteConfirm && (
        <ConfirmModal
          message={'메뉴 콘텐츠를 삭제하시겠습니까?'}
          onClickCancel={() => {
            setOpenDeleteConfirm(false);
          }}
          onClickOk={onDeleteCustomBoard}
        />
      )}
    </>
  );
};
export default MenuContentAddContainer;
