import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useContents from 'hooks/feature/content/useContents';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFilterStore from 'stores/filter';
import { RoutePath } from 'types/common/paths';
import { ContentsByPage, ScreenType } from 'types/feature/content/contents';
import ContentsTable from './body/ContentsTable';
import ContentsHeader from './header/ContentsHeader';

const ScreensaverContentsContainer = () => {
  const [searchWord, setSearchWord] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalContents, setTotalContents] = useState<ContentsByPage>();

  const inputRef = useRef<HTMLInputElement>(null);

  const { getContents } = useContents();
  const { workspaceId } = useParams();
  const { navigateToReplacedPath } = useCustomNavigate();

  const contentFilterStore = useFilterStore();

  // 스크린 타입 검색
  const handleChangeFilter = (type: ScreenType) => {
    contentFilterStore.setContentFilter(type);
  };

  // 타이틀 검색
  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const word = e.target.value;
    setSearchWord(word);
  };

  // 페이지 클릭시 동작
  const handleChangePage = async (pageNum: number) => {
    if (totalContents) {
      if (totalContents?.content.length > 0) {
        setCurrentPage(pageNum);
        await fetchContents(pageNum, searchWord);
      }
    }
  };

  const fetchContents = async (pageNum: number, title?: string, screenType?: ScreenType) => {
    const result = await getContents(pageNum, title, screenType);

    if (result) {
      setTotalContents(result);
    }
  };

  // 콘텐츠 등록 페이지로 이동
  const clickAdd = () => {
    if (workspaceId) {
      navigateToReplacedPath(RoutePath.contentAdd, workspaceId);
    }
  };

  // 타이틀 검색 새로고침
  const resetFilter = async () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setSearchWord('');
      contentFilterStore.setContentFilter('VERTICAL');
      await fetchContents(0, '', 'VERTICAL');
    }
  };

  // 페이지 리셋
  const resetPage = () => {
    setCurrentPage(0);
  };

  useEffect(() => {
    fetchContents(currentPage, '', contentFilterStore.contentFilter);
  }, []);

  if (!totalContents) {
    return <> </>;
  }

  return (
    <>
      <div className='flex flex-col gap-6'>
        <ContentsHeader
          handleChangeTitle={handleChangeTitle}
          searchWord={searchWord}
          fetchContents={fetchContents}
          inputRef={inputRef}
          resetFilter={resetFilter}
          clickAdd={clickAdd}
          currentPage={currentPage}
          resetPage={resetPage}
          screenType={contentFilterStore.contentFilter}
          handleChangeFilter={handleChangeFilter}
        />
        <ContentsTable
          contents={totalContents?.content}
          currentPage={currentPage}
          totalElements={totalContents && totalContents.totalElements}
          refreshContents={fetchContents}
          onChangePage={handleChangePage}
        />
      </div>
    </>
  );
};
export default ScreensaverContentsContainer;
