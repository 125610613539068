// 다음 가장 가까운 10의 배수로 올림
export function roundUpToNearestTen(num: number): number {
  return Math.ceil(num / 10) * 10;
}

export function divideIntoSixSteps(maxNumber: number): number[] {
  // maxNumber 이 10 이하일 경우
  if (maxNumber <= 10) {
    return [0, 2, 4, 6, 8, 10];
  }

  // maxNumber 이 60보다 작을 경우
  if (maxNumber < 60) {
    return [0, 12, 24, 36, 48, 60];
  }

  const INTERVAL = 5; // 색상을 6개로 나눔
  const steps = []; // 범위

  // 시작은 0, 끝은 maxNumber 가 되어야한다.
  for (let i = 0; i < 6; i++) {
    const stepValue = roundUpToNearestTen(Math.ceil(maxNumber / INTERVAL) * i);
    steps.push(Math.min(stepValue, maxNumber)); // maxNumber 을 초과하지 않도록 보정
  }

  return steps;
}
