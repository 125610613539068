import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useEffect } from 'react';
import { RoutePath } from 'types/common/paths';
import DashboardSectionNoBg from '../section/DashboardSectionNoBg';
import DashboardState from '../section/DashboardState';
import { DashBoardScreensaverStatus } from 'types/feature/dashboard/dashboard';

const DashboardScreensaverSchedule = (props: {
  selectedPoint: CommonOptionType;
  screensaverStatus: DashBoardScreensaverStatus;
  loading: boolean;
  error: boolean;
  fetchScreensaverStatus: (pointId: string) => Promise<void>;
}) => {
  const { customNavigate } = useCustomNavigate();

  useEffect(() => {
    props.fetchScreensaverStatus('');
  }, []);

  return (
    <div className='flex flex-col flex-1 gap-5'>
      <DashboardSectionNoBg
        title='스크린세이버 스케줄'
        loading={props.loading}
        error={props.error}
        onClickMore={() => {
          customNavigate(RoutePath.schedule, '', '', '', {
            state: {
              pointId: props.selectedPoint.value,
              pointName: props.selectedPoint.label,
            },
          });
        }}
        height='124px'
      >
        <div className='flex gap-5'>
          <DashboardState label='진행' value={props.screensaverStatus.display} />
          <DashboardState label='종료' value={props.screensaverStatus.close} theme='DISABLED' />
          <DashboardState label='진행 예정' value={props.screensaverStatus.wait} theme='DISABLED' />
        </div>
      </DashboardSectionNoBg>
    </div>
  );
};
export default DashboardScreensaverSchedule;
