import ScheduleCalendar from 'components/common/calendar/schedule/ScheduleCalendar';
import FormControl from 'components/common/form-control/FormControl';
import AlertModal from 'components/common/modal/alert/AlertModal';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import { DISPLAY_TYPE_OPTIONS } from 'data/select-box/options';
import useModal from 'hooks/common/useModal';
import { useEffect, useState } from 'react';
import { CustomBoard } from 'types/feature/menu/customBoard';
import { DisplayType } from 'types/feature/screensaver/getScreensaver';
import { convertDateToString } from 'utils/date/dateUtils';
import { setTomorrow } from 'utils/date/setTomorrow';

type Props = {
  customBoard: CustomBoard;
  setCustomBoard: React.Dispatch<React.SetStateAction<CustomBoard>>;
};

const DisplayDateSelect = ({ customBoard, setCustomBoard }: Props) => {
  const [displayType, setDisplayType] = useState(DisplayType.ALWAYS_ON);
  const [selectedOption, setSelectedOption] = useState<CommonOptionType>();
  const [startDate, setStartDate] = useState<Date | null>(setTomorrow());
  const [endDate, setEndDate] = useState<Date | null>(setTomorrow());
  const [alertMessage, setAlertMessage] = useState<string>('');

  const { openModalPopup, openAlert, closeModalPopup } = useModal();

  useEffect(() => {
    setDisplayType(customBoard.displayType as DisplayType);

    const findOption = DISPLAY_TYPE_OPTIONS.find(option => option.value === customBoard.displayType);

    if (findOption) {
      setSelectedOption(findOption);
    }
  }, [customBoard]);

  const onChangeDisplayType = (option: CommonOptionType) => {
    const displayType = option.value as DisplayType;
    setDisplayType(displayType);

    setCustomBoard(prev => ({
      ...prev,
      displayType,
      startDate: convertDateToString(startDate),
      endDate: convertDateToString(endDate),
    }));
  };

  // TODO: calendar 관리 hook 으로 변경
  // 시작날짜 valid
  const validStartDate = (date: Date | null) => {
    if (!date) {
      openModalPopup('ALERT');
      setAlertMessage('날짜를 입력해주세요.');
      return false;
    }

    return true;
  };

  // 상시
  const handleDate = (date: Date | null) => {
    try {
      if (validStartDate(date)) {
        setStartDate(date);

        setCustomBoard(prev => ({
          ...prev,
          displayType,
          startDate: convertDateToString(date),
          endDate: convertDateToString(date),
        }));
      }
    } catch (error) {
      if (error instanceof Error) {
        openModalPopup('ALERT');
        setAlertMessage('날짜 설정을 다시 해주세요.');
      }
    }
  };

  // 기간
  const handleRange = (dates: [Date | null, Date | null]) => {
    try {
      const [start, end] = dates;

      if (validStartDate(start)) {
        setStartDate(start);
        setEndDate(end);

        setCustomBoard(prev => ({
          ...prev,
          displayType,
          startDate: convertDateToString(start),
          endDate: convertDateToString(end),
        }));
      }
    } catch (e) {
      if (e instanceof Error) {
        openModalPopup('ALERT');
        setAlertMessage('기간 설정을 다시 해주세요.');
      }
    }
  };

  return (
    <>
      <div className='flex-1'>
        <FormControl
          labelSize={200}
          name='노출 설정'
          required
          control={
            <div className='flex gap-2.5 z-30'>
              <CommonSelectBox
                selected={selectedOption}
                options={DISPLAY_TYPE_OPTIONS}
                onChangeSelect={onChangeDisplayType}
              />

              <ScheduleCalendar
                displayType={displayType}
                handleDate={handleDate}
                handleRange={handleRange}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          }
        />
      </div>

      {openAlert && (
        <AlertModal
          message={alertMessage}
          closeModal={() => {
            closeModalPopup('ALERT');
          }}
        />
      )}
    </>
  );
};
export default DisplayDateSelect;
