import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Workspace } from 'types/feature/workspace/workspace';
import { PostWorkspace } from 'types/feature/workspace/postWorkspace';
import { useApi } from 'api/useApi';

const useWorkspace = () => {
  const { api } = useApi();

  const getWorkspaces = async () => {
    const res = await api.get<ApiResponse<Workspace[]>>(Endpoint.workspaces);
    return res.data;
  };

  const getWorkspace = async (workspaceId: string) => {
    const result = await api.get<ApiResponse<Workspace>>(Endpoint.workspace.replace(':workspaceId', workspaceId));
    return result.data;
  };

  const saveWorkspace = async (workspace: PostWorkspace) => {
    const result = api.post<ApiResponse<any>>(Endpoint.workspaces, workspace);
    return result;
  };

  const deleteWorkspace = async (id: string) => {
    const res = api.delete(`${Endpoint.workspaces}/${id}`);
    return res;
  };

  const putWorkspace = async (id: string, updateForm: { name: string; description: string }) => {
    const result = api.put<ApiResponse<any>>(Endpoint.workspace.replace(':workspaceId', id), updateForm);
    return result;
  };

  return { getWorkspaces, getWorkspace, saveWorkspace, deleteWorkspace, putWorkspace };
};
export default useWorkspace;
