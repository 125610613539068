import { TextType } from 'components/common/title/Title';
import { IMAGES } from 'constants/images';
import { useRef } from 'react';
import { ErrorCode, FileError, FileRejection, useDropzone } from 'react-dropzone';
import { FLEX_CENTER } from 'styles/flex';
import { convertMbToByte } from 'utils/file/size/convertMbToByte';

type Accept = {
  'image/jpeg'?: string[];
  'image/png'?: string[];
  'video/mp4'?: string[];
};

type Props = {
  uploadFile: (file: File) => Promise<void>;
  fileUri: string;
  loading?: boolean;
  openFileErrorAlert: (message: string) => void;
  maxSize: number;
  accept: Accept;
};

const TenantFileDropArea = ({ uploadFile, fileUri, loading, openFileErrorAlert, maxSize, accept }: Props) => {
  const dropAreaRef = useRef<HTMLDivElement>(null);

  // drop 했을 경우
  const onDrop = async (acceptedFiles: File[]) => {
    await uploadFile(acceptedFiles[0]);
  };

  // drop 실패 시
  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections[0].errors.forEach(({ code }: FileError) => {
      switch (code) {
        case ErrorCode.FileInvalidType:
          openFileErrorAlert('파일 확장자를 확인해주세요.');
          break;

        case ErrorCode.FileTooSmall:
          openFileErrorAlert('파일 용량이 너무 작습니다.');
          break;

        case ErrorCode.FileTooLarge:
          openFileErrorAlert(`파일 용량은 ${maxSize}MB를 넘어갈 수 없습니다.`);
          break;

        case ErrorCode.TooManyFiles:
          openFileErrorAlert('파일을 최대 1개만 등록할 수 있습니다.');
          break;

        default:
          openFileErrorAlert('파일을 업로드할 수 없습니다.');
          break;
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
    maxSize: convertMbToByte(maxSize),
    maxFiles: 1,
  });

  return (
    <>
      {loading ? (
        <div className='flex flex-col justify-center w-full h-full gap-2 bg-bg-f1'>
          {/* 로딩 */}
          <div className='relative bg-center bg-no-repeat h-9 bg-loading'>
            <div className={` ${FLEX_CENTER.row} h-full animate-[arrowMove_1s_ease-in-out_infinite]`}>
              <img src={IMAGES.LOADING_ARROW} alt='arrow' />
            </div>
          </div>
          <div className={`${TextType.h5} text-center text-gray-cc whitespace-nowrap`}>{`파일 업로드 중입니다.`}</div>
        </div>
      ) : fileUri ? (
        // 업로드 중 미리보기
        <div className='w-[180px]'>
          <img src={fileUri} alt='콘텐츠' />
        </div>
      ) : (
        // 드래그 영역
        <div
          {...getRootProps()}
          ref={dropAreaRef}
          className={`w-full h-full
            items-center cursor-pointer rounded border border-dashed border-gray-44 flex flex-col justify-center
            hover:bg-[#f1f1f1] flex-1 shrink-0
            `}
        >
          <div className={`flex items-center justify-center text-xs text-center`}>
            <input {...getInputProps()} />

            {/* 파일 업로드 가능한 상태 */}
            {isDragAccept && <p className={`text-xs text-gray-99`}>파일을 여기에 놓아주세요!</p>}

            {/* 업로드 불가한 상태 */}
            {isDragReject && <p className={`text-xs text-gray-99`}>업로드할 수 없는 파일입니다.</p>}

            {/* default 상태 */}
            {!isDragActive && (
              <div className='flex flex-col items-center gap-1 cursor-pointer'>
                <div
                  className={`${TextType.h5} text-gray-ea flex h-6 px-2 rounded-[3px] bg-gray-22 w-[57px] whitespace-nowrap items-center`}
                >
                  파일선택
                </div>

                <pre className={`${TextType.body2} text-gray-44`}>{`또는 파일을 여기로\n드래그해주세요`}</pre>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default TenantFileDropArea;
