import IMAGE7 from 'assets/guide/image/image7.png';
import IMAGE8 from 'assets/guide/image/image8.png';
import IMAGE9 from 'assets/guide/image/image9.png';
import IMAGE10 from 'assets/guide/image/image10.png';
import IMAGE11 from 'assets/guide/image/image11.png';
import React from 'react';

import ImageWrapper from 'components/guide/image/Image';
import { LIST_STYLE } from 'data/guide/ui';
import Title from 'components/guide/title/Title';

const ContentsGuide = () => {
  return (
    <>
      <Title title='콘텐츠 관리' />

      <div className='flex flex-col gap-20'>
        {/* 등록 */}
        <section className='flex flex-col gap-7'>
          <p id='add-content' className='text-[20px] font-semibold'>
            4-1. 콘텐츠 등록
          </p>
          <ImageWrapper imageUrl={IMAGE7} />
          <ul className={LIST_STYLE}>
            <li>{`스크린세이버에 노출할 콘텐츠 파일을 미리 등록하는 기능으로 스크린세이버 > 콘텐츠관리에서 진행하실 수 있습니다.`}</li>
            <li>{`1) 콘텐츠 등록 버튼 선택 시 2) 타이틀 입력 및 키오스크의 유형에 맞게 가로 또는 세로를 선택한 후  영상 또는 이미지 파일을 첨부하여 콘텐츠를 등록할 수 있습니다.`}</li>
          </ul>

          <ImageWrapper imageUrl={IMAGE8} />

          <ul className={LIST_STYLE}>
            <li>{`3) 파일 첨부 완료 시 첨부된 파일을 미리 확인 하실 수 있습니다.`}</li>
          </ul>

          <div className='bg-gray-100 p-5 w-2/3 rounded-sm flex flex-col gap-1'>
            <b className='text-[#666]'>[첨부파일 조건]</b>
            <ul className='text-[#666] text-[14px] leading-6 '>
              <li>이미지 업로드는 png, jpeg, jpg 확장자만 가능합니다.</li>
              <li>영상 업로드는 mp4 확장자만 가능합니다.</li>
              <li>세로형 최적 사이즈는 2160*3400이며, 가로형 최적 사이즈는 3840*1918입니다.</li>
              <li>이미지 용량은 10mb이하만 가능하며, 영상 용량은 50mb이하만 가능합니다.</li>
            </ul>
          </div>
        </section>
        {/* 수정 */}
        <section className='flex flex-col gap-7'>
          <p id='edit-content' className='text-[20px] font-semibold'>
            4-2. 콘텐츠 수정
          </p>
          <ImageWrapper imageUrl={IMAGE9} />
          <ul className={LIST_STYLE}>
            <li>{`콘텐츠 등록 후에는 1) 타이틀 수정 및 2) 미리보기만 제공하며, 그외 수정 기능은 제공하지 않습니다.`}</li>
            <li>콘텐츠를 수정해야한다면, 삭제 후 재등록을 진행해 주세요.</li>
          </ul>
        </section>

        {/* 삭제 */}
        <section className='flex flex-col gap-7'>
          <p id='delete-content' className='text-[20px] font-semibold'>
            4-3. 콘텐츠 삭제
          </p>
          <ImageWrapper imageUrl={IMAGE10} />
          <ul className={LIST_STYLE}>
            <li>
              {`1) 삭제 시 등록된 콘텐츠가 삭제됩니다. `}{' '}
              <b>단, 해당 콘텐츠가 스케줄에 등록된 경우 스케줄에서는 삭제되지 않습니다.</b>{' '}
            </li>
          </ul>

          <ImageWrapper imageUrl={IMAGE11} />

          <ul className={LIST_STYLE}>
            <li>
              {`스케줄에서도 해당 콘텐츠 삭제를 원하신다면 2) 스크린세이버 > 스케줄관리에 들어가셔서 해당 콘텐츠를 스케줄에서 직접 삭제해 주세요.`}
            </li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default ContentsGuide;
