import { AxiosError } from 'axios';
import DisplayStatusBadge from 'components/common/badge/display-status/DisplayStatusBadge';
import TableLayout from 'components/common/layout/table/TableLayout';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import TableNoContent from 'components/common/no-content/TableNoContent';
import Pagination from 'components/common/pagination/Pagination';
import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { DISPLAY_TYPE } from 'data/display';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useModal from 'hooks/common/useModal';
import useCustomBoard from 'hooks/feature/menu/custom/useCustomBoard';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { CustomBoards } from 'types/feature/menu/customBoard';
import { reverseIndex } from 'utils/index/reverseIndex';
import { MENU_CONTENTS_TABLE_HEADER } from './data/menuContentsTableHead';
import { CustomPageType } from 'types/feature/menu/customPage';

type Props = {
  customBoards: CustomBoards | undefined;
  fetchCustomBoards: (customPageId: string, page?: number) => Promise<void>;
  pointId: string;
  pageId: string;
  customPageType: CustomPageType;
};

const MenuContentsTable = ({ customBoards, fetchCustomBoards, pointId, pageId, customPageType }: Props) => {
  const { customPageId } = useParams();
  const { deleteCustomBoard } = useCustomBoard();
  const { openConfirm, openModalPopup, closeModalPopup, openAlert } = useModal();
  const { customNavigate } = useCustomNavigate();

  const [message, setMessage] = useState('');
  const [deleteContentId, setDeleteContentId] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const onClickDelete = (e: React.MouseEvent<HTMLElement>, customBoardId: string) => {
    e.stopPropagation();

    openModalPopup('CONFIRM');
    setMessage('메뉴 콘텐츠를 삭제하시겠습니까?');

    setDeleteContentId(customBoardId);
  };

  const onClickOk = async () => {
    try {
      if (customPageId) {
        closeModalPopup('CONFIRM');

        await deleteCustomBoard(deleteContentId);
        await fetchCustomBoards(customPageId);

        openModalPopup('ALERT');
        setMessage('메뉴 콘텐츠가 삭제되었습니다.');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          closeModalPopup('CONFIRM');

          openModalPopup('ALERT');
          setMessage('메뉴 콘텐츠를 삭제할 수 없습니다.');
        }
      }
    }
  };

  // 보드 상세 페이지로 이동
  const moveToBoardDetail = (boardId: string) => {
    customNavigate(RoutePath.pointCustomBoardDetail, pointId, pageId, boardId, {
      state: customPageType,
    });
  };

  // 보드 목록 페이지 이동
  const onChangePage = async (pageNum: number) => {
    if (customPageId) {
      setCurrentPage(pageNum);
      await fetchCustomBoards(customPageId, pageNum);
    }
  };

  return (
    <>
      <TableLayout
        // 페이지네이션
        pagination={
          customBoards?.totalElements ? (
            <Pagination
              totalElements={customBoards?.totalElements}
              currentPage={currentPage}
              onChangePage={onChangePage}
            />
          ) : (
            <> </>
          )
        }
      >
        <>
          {/* 헤더 */}
          <CommonTableHeader tableHead={MENU_CONTENTS_TABLE_HEADER} />

          {/* 바디 */}
          <tbody>
            {customBoards?.content.length && customBoards?.content.length > 0 ? (
              customBoards?.content.map((board, index) => (
                <tr
                  onClick={moveToBoardDetail.bind(this, board.id)}
                  key={board.id}
                  className='w-full border-b h-[52px] cursor-pointer'
                >
                  {/* No. */}
                  <td className='text-sm text-center font-h3 text-gray-99 px-2.5'>
                    {reverseIndex(customBoards.content.length)[index]}
                  </td>

                  {/* 타이틀 */}
                  <td className={`${TextType.h4} text-gray-44 px-2.5`}>{board.title.ko}</td>

                  {/* 노출유형 */}
                  <td className={`${TextType.body1} text-gray-44 px-2.5 text-center`}>
                    {DISPLAY_TYPE[board.displayType]}
                  </td>

                  {/* 기간 */}
                  <td className={`px-2.5 text-center`}>
                    <div className={`${TextType.body1} text-gray-44`}>
                      <span className='text-inherit'>{board.startDate}</span>-
                      {board.displayType === 'ALWAYS_ON' ? (
                        <span className='text-inherit'>종료시</span>
                      ) : (
                        <span className='text-inherit'>{board.endDate}</span>
                      )}
                    </div>
                  </td>

                  {/* 진행상태 */}
                  <td className={`${TextType.body1} text-gray-44 px-2.5 text-center`}>
                    {<DisplayStatusBadge status={board.displayStatus ?? 'NONE'} />}
                  </td>

                  {/* 삭제 */}
                  <td className={`${TextType.body1} text-gray-44 px-2.5 text-center`}>
                    <button
                      onClick={e => onClickDelete(e, board.id)}
                      className='flex items-center justify-center w-full h-full'
                    >
                      <img className='h-[18px]' alt='삭제' src={ICON.DELETE_BUTTON} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <TableNoContent message='등록된 콘텐츠가 없습니다.' colSpan={6} />
            )}
          </tbody>
        </>
      </TableLayout>

      {openConfirm && (
        <ConfirmModal
          message={message}
          onClickCancel={() => {
            closeModalPopup('CONFIRM');
          }}
          onClickOk={onClickOk}
        />
      )}

      {openAlert && (
        <AlertModal
          message={message}
          closeModal={() => {
            closeModalPopup('ALERT');
          }}
        />
      )}
    </>
  );
};
export default MenuContentsTable;
