import React from 'react';
import { BorderColor } from './Border.type';

type BorderProps = {
  borderColor?: BorderColor;
  position?: string;
};

const Border = ({ borderColor = BorderColor.gray, position }: BorderProps) => {
  return <div className={`w-full border-b z-10 ${borderColor} ${position}`} />;
};
export default Border;
