import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import useModal from 'hooks/common/useModal';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiResponse } from 'types/api/response';
import { Language } from 'types/feature/multi-lang/language';
import { handle400Error } from 'utils/error/handle400Error';

const useLanguage = () => {
  const { api } = useApi();
  const { workspaceId } = useParams();
  const { openAlert, openModalPopup, closeModalPopup } = useModal();

  const [langs, setLangs] = useState<Language[]>();
  const [mainLang, setMainLang] = useState<string>();

  const [alertMessage, setAlertMessage] = useState<string>('');

  const handleOpenAlert = () => {
    openModalPopup('ALERT');
  };

  const getLanguages = async () => {
    if (workspaceId) {
      const result = await api.get<ApiResponse<Language[]>>(Endpoint.languages, {
        params: {
          workspaceId,
        },
      });

      return result.data;
    }
  };

  const fetchLanguages = async () => {
    try {
      const languages = await getLanguages();

      if (languages) {
        const mainLang = languages.find(lang => lang.main);

        // 메인 언어
        if (mainLang) {
          setMainLang(mainLang.code);
        }

        // 노출 언어
        const displayLangs = languages.filter(lang => lang.display);

        if (displayLangs) {
          setLangs(displayLangs);
        }
      }
    } catch (error) {
      setAlertMessage('언어 목록을 불러올 수 없습니다. 다시 시도해주세요.');
      handle400Error(error, handleOpenAlert);
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchLanguages();
    }
  }, [workspaceId]);

  return {
    getLanguages,
    langs,
    mainLang,
    openAlert,
    alertMessage,
    closeModalPopup,
  };
};

export default useLanguage;
