import { ICON } from 'constants/icons';
import useOutsideClick from 'hooks/common/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

export type OptionType = {
  label: string;
  value: string;
  data?: any;
};

type Props = {
  direction?: 'UP' | 'DOWN';
  disabled?: boolean;
  options: OptionType[];
  placeholder?: string | null | undefined;
  onChangeSelect: (option: OptionType) => void;
  width?: string;
};

const SelectBox = ({ disabled, options, onChangeSelect, placeholder, width, direction = 'DOWN' }: Props) => {
  const [openSelectBox, setOpenSelectBox] = useState(false);
  const [currentOptionLabel, setCurrentOptionLabel] = useState(placeholder || options[0]?.label || '');

  const selectRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLLIElement>(null);

  const closeSelectBox = () => {
    setOpenSelectBox(false);
  };

  useOutsideClick({ elementRef: selectRef, callback: closeSelectBox });

  useEffect(() => {
    if (options.length > 0) {
      if (!placeholder) {
        setCurrentOptionLabel(currentOptionLabel || options[0].label);
      }
    }
  }, [options]);

  useEffect(() => {
    if (placeholder) {
      setCurrentOptionLabel(placeholder);
    }
  }, [placeholder]);

  const toggleSelectBox = () => {
    if (disabled) return;

    setOpenSelectBox(!openSelectBox);
  };

  const handleClickOption = (option: OptionType) => {
    setCurrentOptionLabel(option.label);
    onChangeSelect(option);
    setOpenSelectBox(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={closeSelectBox}>
      <div className='relative' ref={selectRef}>
        <button
          disabled={disabled}
          className={`${
            disabled ? 'bg-bg-f9 outline-gray-cc' : 'bg-white cursor-pointer outline-gray-99 hover:outline-gray-44'
          } ${width ? width : 'w-[120px]'} h-9 outline outline-1 flex items-center justify-between px-3`}
          onClick={toggleSelectBox}
        >
          <div className={`text-h4 ${disabled ? 'text-gray-cc' : 'text-gray-44'} flex items-center gap-1`}>
            <span>{currentOptionLabel}</span>
          </div>
          <div>
            {disabled ? (
              <img src={ICON.ARROW} alt='disable-arrow' />
            ) : (
              <img src={openSelectBox ? ICON.ARROW_UP_ACTIVE : ICON.ARROW_DOWN_ACTIVE} alt='arrow' />
            )}
          </div>
        </button>

        {openSelectBox && (
          <ul
            className={`${
              direction === 'UP' ? 'bottom-[37px]' : 'left-0 top-[37px]'
            } z-30 list-none cursor-pointer absolute outline outline-1 outline-gray-99 p-0 bg-white shadow-mdDrop ${
              width ? width : 'w-[120px]'
            }  max-h-[144px] overflow-y-auto`}
          >
            {options.map(option => (
              <li
                ref={itemRef}
                key={option.value}
                id={option.label}
                className={`${
                  option.label === currentOptionLabel && 'bg-state-blue_bg'
                } flex items-center px-3 h-9 text-h4 `}
                onClick={handleClickOption.bind(this, option)}
                value={option.data}
              >
                <div className='flex items-center gap-1'>
                  <span>{option.label}</span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default SelectBox;
