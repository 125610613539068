import React from 'react';
type Props = {
  color: string;
};

const InfoSvg = ({ color }: Props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <rect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke={color} />
      <rect x='8.5' y='12' width='1' height='5' transform='rotate(180 8.5 12)' fill={color} />
      <rect x='8.75' y='6' width='1.5' height='1.5' transform='rotate(180 8.75 6)' fill={color} />
    </svg>
  );
};
export default InfoSvg;
