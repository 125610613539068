import ScheduleCalendar from 'components/common/calendar/schedule/ScheduleCalendar';
import FormControl from 'components/common/form-control/FormControl';
import AlertModal from 'components/common/modal/alert/AlertModal';
import SelectBox, { OptionType } from 'components/common/select/SelectBox';
import { DISPLAY_TYPE_OPTIONS } from 'data/select-box/options';
import { useState } from 'react';
import { DisplayType } from 'types/feature/screensaver/getScreensaver';
import { WaitingList } from 'types/feature/screensaver/waitingList';
import { convertDateToString } from 'utils/date/dateUtils';
import { setTomorrow } from 'utils/date/setTomorrow';

type Props = {
  selectedContent: WaitingList | null;
  handleClickItem: (content: WaitingList) => void;
};

const DisplaySetting = ({ selectedContent, handleClickItem }: Props) => {
  const [type, setType] = useState(DisplayType.ALWAYS_ON);
  const [startDate, setStartDate] = useState<Date | null>(setTomorrow());
  const [endDate, setEndDate] = useState<Date | null>(setTomorrow());
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  // 노출 기간
  const handleChangeDisplayType = (option: OptionType) => {
    const displayType = option.value as DisplayType;
    setType(displayType);

    if (!selectedContent) return;
    handleClickItem({
      ...selectedContent,
      displayType,
    });
  };

  // 시작날짜 valid
  const validStartDate = (date: Date | null) => {
    if (!date) {
      setOpenAlert(true);
      setAlertMessage('날짜를 입력해주세요.');
      return false;
    }

    return true;
  };

  // error handle
  const handleDateError = (message: string) => {
    setOpenAlert(true);
    setAlertMessage(message);
  };

  // 상시
  const handleDate = (date: Date | null) => {
    try {
      if (validStartDate(date)) {
        setStartDate(date);

        updateSelectedContent(date, date);
      }
    } catch (error) {
      if (error instanceof Error) {
        handleDateError('날짜 설정을 다시 해주세요.');
      }
    }
  };

  // 기간
  const handleRange = (dates: [Date | null, Date | null]) => {
    try {
      const [start, end] = dates;

      if (validStartDate(start)) {
        setStartDate(start);
        setEndDate(end);

        updateSelectedContent(start, end);
      }
    } catch (e) {
      if (e instanceof Error) {
        handleDateError('기간 설정을 다시 해주세요.');
      }
    }
  };

  const updateSelectedContent = (start: Date | null, end: Date | null) => {
    if (!selectedContent) return;

    const convertedContent = {
      ...selectedContent,

      startDate: convertDateToString(start),
      endDate: convertDateToString(end),
    };

    if (!convertedContent) return;

    handleClickItem(convertedContent);
  };

  return (
    <div className='flex items-center justify-center px-10'>
      <FormControl
        required
        labelSize={100}
        name='노출 설정'
        control={
          <div className='flex items-center gap-2.5'>
            <SelectBox options={[...DISPLAY_TYPE_OPTIONS]} onChangeSelect={handleChangeDisplayType} />
            <ScheduleCalendar
              displayType={type}
              handleDate={handleDate}
              handleRange={handleRange}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        }
      />

      {openAlert && <AlertModal message={alertMessage} closeModal={() => setOpenAlert(false)} />}
    </div>
  );
};
export default DisplaySetting;
