import React, { useState } from 'react';

export type MousePosition = {
  x: number;
  y: number;
};

const useMouseOverlay = () => {
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [mousePosition, setMousePosition] = useState<MousePosition>({ x: 0, y: 0 });

  const handleMouseEnter = (index: number, e: React.MouseEvent) => {
    setHoveredRow(index);
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  return { hoveredRow, mousePosition, handleMouseEnter, handleMouseLeave };
};
export default useMouseOverlay;
