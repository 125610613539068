import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Floor } from 'types/feature/floor/floor';

const useFloor = () => {
  const { api } = useApi();

  const getFloors = async (pointId: string) => {
    const result = await api.get<ApiResponse<Floor[]>>(Endpoint.floors, {
      params: {
        pointId,
      },
    });

    return result.data;
  };

  return {
    getFloors,
  };
};

export default useFloor;
