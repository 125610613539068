import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import useModalStore from 'stores/modal';
import useWorkspaceStore, { WorkspaceStatus } from 'stores/workspace';
import { RoutePath } from 'types/common/paths';
import useCustomNavigate from './useCustomNavigate';

const useHandleError = () => {
  const workspaceStore = useWorkspaceStore();

  const navigate = useNavigate();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();

  const modalStore = useModalStore();

  // ! Response ERROR: 서버로부터 응답이 온 경우 (예: 401, 404, 500 등)
  const handleResponseError = (error: AxiosError) => {
    // 토큰 만료일 경우 에러처리 (401 INVALID_CREDENTIALS)
    // handleInvalidError(error);

    // 그 외 에러처리 (400 ~ 500)
    handleOtherErrors(error);
  };

  // 토큰 만료일 경우 에러처리
  const handleInvalidError = async (error: AxiosError) => {
    if (!error.response) return;

    const errorResponse = error.response;

    if (errorResponse.status === 401) {
      // TODO:
      // 유저가 직접 로그아웃한 경우
      // 토큰 만료일 경우
    }
  };

  // ! 나머지 axios response 에러처리
  const handleOtherErrors = (error: AxiosError) => {
    const { response } = error;

    if (!response) {
      throw error;
    }

    switch (response.status) {
      case 404:
        if (workspaceId) {
          navigateToReplacedPath(RoutePath.notFoundWithWorkspaceId, workspaceId);
          return;
        }

        navigate(RoutePath.notFound);
        return;
      case 500:
        if (workspaceId) {
          navigateToReplacedPath(RoutePath.errorWithWorkspaceId, workspaceId);
          throw error;
        }

        navigate(RoutePath.error);
        throw error;
      case 400:
        throw error;
      case 401:
        throw error;
      case 403:
        // 권한이 없는 경우 워크스페이스 갯수별로 페이지 이동을 분기처리한다.
        // 워크스페이스 없을 경우 alert
        if (workspaceStore.workspaceStatus === WorkspaceStatus.NONE) {
          modalStore.setWorkspaceModalText(`사용가능한 워크스페이스가 없습니다.\n관리자에게 문의 바랍니다.`);
          modalStore.setOpenWorkspaceModal(true);
          return;
        }

        // 1개일 경우 메인페이지로 바로 이동
        if (workspaceStore.workspaceStatus === WorkspaceStatus.SINGLE) {
          navigateToReplacedPath(RoutePath.dashboard, workspaceStore.mainWorkspaceId);
          return;
        }

        // 여러개일 경우 워크스페이스 리스트 페이지로 이동
        navigate(RoutePath.workspaces);
        throw error;
      default:
        throw error;
    }
  };

  return { handleResponseError };
};
export default useHandleError;
