import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useEffect } from 'react';
import { RoutePath } from 'types/common/paths';
import { TenantUsage } from 'types/feature/statistics/tenantUsage';
import DashboardSection from '../section/DashboardSection';
import TenantShareItem from './item/TenantShareItem';

const DashboardPopularTenants = (props: {
  selectedPoint: CommonOptionType;
  loading: boolean;
  error: boolean;
  fetchTenantUsage: (pointId: string) => Promise<void>;
  tenantUsage: TenantUsage[];
}) => {
  const { customNavigate } = useCustomNavigate();

  useEffect(() => {
    props.fetchTenantUsage('');
  }, []);

  return (
    <DashboardSection
      title='인기 매장 TOP 10'
      width='860px'
      onClickMore={() => {
        customNavigate(RoutePath.tenantUsageStats, '', '', '', {
          state: {
            pointId: props.selectedPoint.value,
            pointName: props.selectedPoint.label,
          },
        });
      }}
      gap='40px'
      loading={props.loading}
      error={props.error}
      onRetry={async () => {
        await props.fetchTenantUsage(props.selectedPoint.value);
      }}
      noData={props.tenantUsage.length < 1}
    >
      <div className='flex gap-[60px]'>
        <div className='flex flex-col flex-1 gap-6'>
          {/* 1위 ~ 5위 */}
          {props.tenantUsage.slice(0, 5).map((data, index) => (
            <TenantShareItem key={data.tenantId} tenant={data} numbering={index} />
          ))}
        </div>

        <div className='flex flex-col flex-1 gap-6'>
          {/* 6위 ~ 10위 */}
          {props.tenantUsage.slice(5, 10).map((data, index) => (
            <TenantShareItem key={data.tenantId} tenant={data} numbering={index + 5} />
          ))}
        </div>
      </div>
    </DashboardSection>
  );
};
export default DashboardPopularTenants;
