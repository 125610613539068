import ButtonLoadingSpinner from '../loading/spinner/ButtonLoadingSpinner';
import { TextType } from '../title/Title';
import { ButtonColor, ButtonProps, ButtonSize } from './Button.type';

const Button = ({
  loading,
  text,
  onClick,
  size = 'default',
  disabled,
  color,
  type = 'button',
  children,
  buttonStyle,
  textStyle = TextType.h4,
  id,
  iconPath,
}: ButtonProps) => {
  // disable color
  const returnDisableColor = () => {
    switch (color) {
      case ButtonColor.primary:
        return ButtonColor.primary_disable;

      case ButtonColor.secondary:
        return ButtonColor.secondary_disable;

      case ButtonColor.point:
        return ButtonColor.point_disable;

      case ButtonColor.delete:
        return ButtonColor.delete_disable;

      case ButtonColor.gray:
        return ButtonColor.gray_disable;

      case ButtonColor.csv:
        return ButtonColor.csv_disable;

      default:
        return ButtonColor.primary_disable;
    }
  };

  return (
    <button
      id={id}
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${buttonStyle} ${ButtonSize[size]} ${
        size === 'rounded' || size === 'more' ? `${TextType.h5}` : textStyle
      } ${disabled ? `pointer-events-none cursor-not-allowed ${returnDisableColor()}` : `${color}`} 
      flex items-center justify-center gap-1 border text-center
      `}
    >
      <>
        {iconPath && <img src={iconPath} alt='icon' />}
        {text && <div className='text-inherit'>{text}</div>}
        {loading && (
          <div className='pl-1'>
            <ButtonLoadingSpinner />
          </div>
        )}
        {children && children}
      </>
    </button>
  );
};
export default Button;
