import { GlobalMenu } from 'types/common/globalMenu';
import { RoutePath } from 'types/common/paths';

export const GLOBAL_MENUS: GlobalMenu[] = [
  { id: '대시보드', path: RoutePath.dashboard },
  { id: '기본정보 관리', path: RoutePath.basicSetting },
  {
    id: '스크린세이버',
    path: RoutePath.screensaver,
    subMenus: [
      {
        id: '콘텐츠 관리',
        path: RoutePath.content,
        // 서브메뉴 example)
        // subMenus: [
        //   { id: '가로형', path: RoutePath.workspace },
        //   { id: '세로형', path: RoutePath.workspace },
        // ],
      },
      { id: '스케줄 관리', path: RoutePath.schedule },
    ],
  },
  {
    id: '매장 관리',
    path: RoutePath.tenantManage,

    subMenus: [
      { id: '매장정보 관리', path: RoutePath.tenantInfo },
      { id: '변경정보 관리', path: RoutePath.tenantChangedInfo },
    ],
  },
  {
    id: '메뉴 관리',
    path: RoutePath.customPages,
  },
  {
    id: '기기 상태',
    path: RoutePath.machineManage,
  },
  {
    id: '통계',
    path: RoutePath.stats,
    subMenus: [
      { id: '전체 사용량', path: RoutePath.totalUsageStats },
      { id: '기기별 사용량', path: RoutePath.machineUsageStats },
      { id: '인기 매장 순위', path: RoutePath.tenantUsageStats },
      { id: '인기 카테고리', path: RoutePath.categoryUsageStats },
    ],
  },
];
