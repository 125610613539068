import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import FormControl from 'components/common/form-control/FormControl';
import AlertModal from 'components/common/modal/alert/AlertModal';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import Switch from 'components/common/switch/Switch';
import Title, { TextType } from 'components/common/title/Title';
import useModal from 'hooks/common/useModal';
import useTenant from 'hooks/feature/tenant/useTenant';
import { Fragment, useEffect, useState } from 'react';
import useTenantToastStore from 'stores/tenantToast';
import { ChangedStatus } from 'types/common/status';
import { Language } from 'types/feature/multi-lang/language';
import { Tenant, TenantCustomField, TenantPopupType } from 'types/feature/tenant/tenant';
import CustomFieldTenantInfo from './custom/CustomFieldTenantInfo';
import TenantImages from './image/TenantImages';
import TenantMultiLangInput from './multi-lang/TenantMultiLangInput';
import WarnMessage from './warn/WarnMessage';

type Props = {
  languageList: Language[];
  tenant: Tenant;
  fetchTenant: (pointId: string, status?: ChangedStatus, floorId?: string, viewAll?: boolean) => Promise<void>;
  status?: ChangedStatus;
  floorId?: string;
  viewAll?: boolean;
  pointId: string;
};

const TenantChangedInfo = ({ languageList, tenant, fetchTenant, pointId, status, floorId, viewAll }: Props) => {
  // state
  const [tenantUsed, setTenantUsed] = useState<boolean>(tenant?.used);
  const [selectedPopupType, setSelectedPopupType] = useState<CommonOptionType>();
  const [alertMessage, setAlertMessage] = useState('');

  // store
  const tenantToastStore = useTenantToastStore();

  // hook
  const { patchTenant } = useTenant();
  const { openModalPopup, openAlert, closeModalPopup } = useModal();

  // 매장정보 관리, 변경정보 관리별로 fetch 분기처리
  const reFetchTenant = async () => {
    // 변경 정보 관리
    await fetchTenant(pointId, status, floorId, viewAll);
  };

  // 팝업 사용 / 미사용
  const handleUpdateTenantUsed = async (option: boolean) => {
    try {
      setTenantUsed(option);
      await patchTenant(tenant?.id, {
        used: option,
      });

      await reFetchTenant();

      // 토스트 팝업
      tenantToastStore.setIsToastOpen(true);
      tenantToastStore.setToastPopupMessage('매장 정보가 저장되었습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        openModalPopup('ALERT');
        setAlertMessage('오류가 발생했습니다.');
      }
    }
  };

  // 팝업 타입 변경
  const onChangePopupType = async (option: CommonOptionType) => {
    try {
      // 수정 api
      await patchTenant(tenant.id, {
        popupType: option.value as TenantPopupType,
      });

      // 조회 api
      await reFetchTenant();

      // 토스트 팝업
      tenantToastStore.setIsToastOpen(true);
      tenantToastStore.setToastPopupMessage('매장 정보가 저장되었습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        openModalPopup('ALERT');
        setAlertMessage('오류가 발생했습니다.');
      }
    }
  };

  useEffect(() => {
    setTenantUsed(tenant.used);
    setSelectedPopupType({
      label: tenant.popupType === 'DEFAULT' ? '기본형' : '축약형',
      value: tenant.popupType,
    });
  }, [tenant]);

  return (
    <>
      <div className='flex flex-col gap-3 px-5'>
        {/* 매장 정보 */}
        <div className='z-30 flex items-center gap-2.5 px-5'>
          <Title text='매장 정보' textType={TextType.h3_bold} />

          {/* 팝업 사용 여부 */}
          <Switch
            type='USE'
            color={tenantUsed ? 'BLUE' : 'WHITE'}
            value={tenantUsed}
            refreshKey={tenant.id}
            onChangeOption={handleUpdateTenantUsed}
          />

          {/* 팝업 미사용시 안내 문구 노출 */}
          {!tenantUsed && <WarnMessage />}
        </div>

        <div className='pb-10'>
          {/* 팝업 타입 */}
          <div className='py-3 h-[60px] px-5'>
            <FormControl
              gap='gap-0'
              labelSize={80}
              labelColor='text-gray-77'
              labelStyle='text-[13px] font-bold'
              name='팝업타입'
              control={
                <CommonSelectBox
                  width={80}
                  height='h-[30px]'
                  textType={TextType.h5}
                  options={[
                    {
                      label: '기본형',
                      value: 'DEFAULT',
                    },
                    {
                      label: '축약형',
                      value: 'SMALL',
                    },
                  ]}
                  selected={selectedPopupType}
                  onChangeSelect={onChangePopupType}
                />
              }
            />
          </div>

          {/* 노출 타이틀 */}
          <div className='py-2'>
            <TenantMultiLangInput
              contentKey='name'
              tenantId={tenant.id}
              title='노출 타이틀'
              languageList={languageList}
              tenant={tenant}
              tenantName={tenant.name}
              fetchTenant={reFetchTenant}
            />
          </div>

          {/* 상세설명 */}
          <TenantMultiLangInput
            contentKey='description'
            tenantId={tenant.id}
            title='상세 설명'
            languageList={languageList}
            tenant={tenant}
            tenantName={tenant.description}
            fetchTenant={reFetchTenant}
          />

          <div className='pt-3'>
            <Border borderColor={BorderColor.light_gray} />
          </div>

          {/* 커스텀 필드 */}
          {tenant.tenantCustomFields &&
            tenant.tenantCustomFields.map((field: TenantCustomField, fieldIndex) => {
              return (
                <Fragment key={fieldIndex}>
                  <CustomFieldTenantInfo
                    key={field.id}
                    tenantId={tenant.id}
                    field={field}
                    languageList={languageList}
                    fetchTenant={reFetchTenant}
                  />
                  <Border borderColor={BorderColor.light_gray} />
                </Fragment>
              );
            })}

          {/* 이미지 */}
          <TenantImages tenant={tenant} fetchTenant={reFetchTenant} />
        </div>
      </div>
      {openAlert && <AlertModal message={alertMessage} closeModal={() => closeModalPopup('ALERT')} />}
    </>
  );
};

export default TenantChangedInfo;
