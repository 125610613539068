import Border from 'components/common/border/Border';
import GradientLoader from 'components/common/loading/gradient/GradientLoader';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import Title, { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { SERVER_DATE_FORMAT } from 'data/common';
import useAccount from 'hooks/common/useAccount';
import useDashBoard from 'hooks/feature/dashboard/useDashBoard';
import usePoint from 'hooks/feature/point/usePoint';
import useStatistics from 'hooks/feature/stats/useStatistics';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AccountMap } from 'types/feature/account/account';
import {
  DashBoardMachineStatus,
  DashBoardScreensaverStatus,
  DashBoardTotalUsage,
  DashBoardWeeklyUsage,
} from 'types/feature/dashboard/dashboard';
import { CategoryUsage } from 'types/feature/statistics/categoryUsage';
import { TenantUsage } from 'types/feature/statistics/tenantUsage';
import { Point, Workspace } from 'types/feature/workspace/workspace';
import { yesterday } from 'utils/date/state';
import { handle400Error } from 'utils/error/handle400Error';
import DashboardPopularCategories from './category/DashboardPopularCategories';
import RealtimeMachineStatus from './machine/RealtimeMachineStatus';
import DashboardScreensaverSchedule from './screensaver/ScreensaverSchedule';
import DashboardError from './section/DashboardError';
import DashboardPopularTenants from './tenant/DashboardPopularTenants';
import DashboardTotalUsage from './total-usage/DashboardTotalUsage';
import DashboardWeeklyUsage from './weekly-usage/DashboardWeeklyUsage';

const DashboardContainer = () => {
  const { workspaceId } = useParams();
  const { account, findAccountMap } = useAccount();
  const { getWorkspace } = useWorkspace();
  const { getMyPoints } = usePoint();
  const { getDashBoardMachineStatus, getDashBoardScreensaverStatus, getDashBoardTotalUsage, getDashBoardWeeklyUsage } =
    useDashBoard();
  const { getTenantUsage, getCategoryUsage } = useStatistics();

  const [workspace, setWorkspace] = useState<Workspace>();
  const [points, setPoints] = useState<Point[]>([]);
  const [accountMap, setAccountMap] = useState<AccountMap>();
  const [selectedPoint, setSelectedPoint] = useState<CommonOptionType>({
    value: '',
    label: '전체',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // 기기 현황
  const [machineStatus, setMachineStatus] = useState<DashBoardMachineStatus>({
    connected: 0,
    disconnected: 0,
  });
  const [machineUsageLoading, setMachineUsageLoading] = useState(true);
  const [machineUsageError, setMachineUsageError] = useState(false);

  // 스크린세이버
  const [screensaverStatus, setScreensaverStatus] = useState<DashBoardScreensaverStatus>({
    display: 0,
    close: 0,
    wait: 0,
  });
  const [screensaverStatusLoading, setScreensaverStatusLoading] = useState(true);
  const [screensaverStatusError, setScreensaverStatusError] = useState(false);

  // 전체 사용량
  const [totalUsage, setTotalUsage] = useState<DashBoardTotalUsage>({
    machine: 0,
    mobile: 0,
    increase: 0,
    totalCount: 0,
  });
  const [totalUsageLoading, setTotalUsageLoading] = useState(true);
  const [totalUsageError, setTotalUsageError] = useState(false);

  // 지난주 대비 일자별 사용량
  const [weeklyUsage, setWeeklyUsage] = useState<DashBoardWeeklyUsage>({
    lastWeekUsages: [
      {
        date: '',
        totalCount: 0,
      },
    ],
    thisWeekUsages: [
      {
        date: '',
        totalCount: 0,
      },
    ],
  });
  const [weeklyUsageLoading, setWeeklyUsageLoading] = useState(true);
  const [weeklyUsageError, setWeeklyUsageError] = useState(false);

  // 인기 매장 TOP 10
  const [tenantUsage, setTenantUsage] = useState<TenantUsage[]>([]);
  const [tenantUsageLoading, setTenantUsageLoading] = useState(true);
  const [tenantUsageError, setTenantUsageError] = useState(false);

  // 인기 카테고리 TOP 10
  const [categoryUsage, setCategoryUsage] = useState<CategoryUsage[]>([]);
  const [categoryUsageLoading, setCategoryUsageLoading] = useState(true);
  const [categoryUsageError, setCategoryUsageError] = useState(false);

  // 포인트 목록 조회
  const fetchPoints = async () => {
    if (workspaceId) {
      const points = await getMyPoints();

      if (points) {
        setPoints(points);

        // 선택된 포인트 설정
        setSelectedPoint({
          value: '',
          label: '전체',
        });
      }
    }
  };

  // 워크스페이스, 포인트 조회
  const fetchWorkspaceAndPoints = async () => {
    try {
      setLoading(true);
      if (workspaceId) {
        const workspace = await getWorkspace(workspaceId);
        setWorkspace(workspace);

        await fetchPoints();
        setError(false);
      }
    } catch (error) {
      handle400Error(error, () => {
        setError(true);
      });
    } finally {
      setLoading(false);
    }
  };

  // 실시간 기기 현황
  const fetchMachineUsage = async (pointId: string) => {
    try {
      setMachineUsageLoading(true);

      if (workspaceId) {
        const response = await getDashBoardMachineStatus(workspaceId, pointId);
        setMachineStatus(response);
        setMachineUsageError(false);
      }
    } catch (error) {
      handle400Error(error, () => {
        setMachineUsageError(true);
      });
    } finally {
      setMachineUsageLoading(false);
    }
  };

  // 스크린세이버 현황
  const fetchScreensaverStatus = async (pointId: string) => {
    try {
      setScreensaverStatusLoading(true);

      if (workspaceId) {
        const response = await getDashBoardScreensaverStatus(workspaceId, pointId);

        setScreensaverStatus(response);
        setScreensaverStatusError(false);
      }
    } catch (error) {
      handle400Error(error, () => {
        setScreensaverStatusError(true);
      });
    } finally {
      setScreensaverStatusLoading(false);
    }
  };

  // 전체 사용량
  const fetchYesterdayUsage = async (pointId: string) => {
    try {
      setTotalUsageLoading(true);

      if (workspaceId) {
        const response = await getDashBoardTotalUsage(workspaceId, yesterday.format(SERVER_DATE_FORMAT), pointId);
        setTotalUsage(response);
        setTotalUsageError(false);
      }
    } catch (error) {
      handle400Error(error, () => {
        setTotalUsageError(true);
      });
    } finally {
      setTotalUsageLoading(false);
    }
  };

  // 지난주 대비 일자별 사용량
  const fetchWeeklyUsage = async (pointId: string) => {
    try {
      setWeeklyUsageLoading(true);

      if (workspaceId) {
        const response: DashBoardWeeklyUsage = await getDashBoardWeeklyUsage(
          workspaceId,
          yesterday.format(SERVER_DATE_FORMAT),
          pointId,
        );
        setWeeklyUsage(response);
        setWeeklyUsageError(false);
      }
    } catch (error) {
      handle400Error(error, () => {
        setWeeklyUsageError(true);
      });
    } finally {
      setWeeklyUsageLoading(false);
    }
  };

  // 인기 매장 TOP 10
  const fetchTenantUsage = async (pointId: string) => {
    try {
      setTenantUsageLoading(true);

      if (workspaceId) {
        const response = await getTenantUsage(
          workspaceId,
          pointId,
          '',
          yesterday.format(SERVER_DATE_FORMAT),
          yesterday.format(SERVER_DATE_FORMAT),
          10,
        );
        setTenantUsage(response);
        setTenantUsageError(false);
      }
    } catch (error) {
      handle400Error(error, () => {
        setTenantUsageError(true);
      });
    } finally {
      setTenantUsageLoading(false);
    }
  };

  // 인기 카테고리 TOP 10
  const fetchCategoryUsage = async (pointId: string) => {
    try {
      setCategoryUsageLoading(true);

      if (workspaceId) {
        const response = await getCategoryUsage(
          workspaceId,
          pointId,
          yesterday.format(SERVER_DATE_FORMAT),
          yesterday.format(SERVER_DATE_FORMAT),
        );
        setCategoryUsage(response);
        setCategoryUsageError(false);
      }
    } catch (error) {
      handle400Error(error, () => {
        setCategoryUsageError(true);
      });
    } finally {
      setCategoryUsageLoading(false);
    }
  };

  // 모든 api 호출
  const fetchAllApis = async (pointId: string) => {
    await fetchMachineUsage(pointId);
    await fetchScreensaverStatus(pointId);
    await fetchYesterdayUsage(pointId);
    await fetchWeeklyUsage(pointId);
    await fetchTenantUsage(pointId);
    await fetchCategoryUsage(pointId);
  };

  // 포인트 변경
  const onChangePoint = async (option: CommonOptionType) => {
    await fetchAllApis(option.value);
    setSelectedPoint(option);
  };

  useEffect(() => {
    fetchWorkspaceAndPoints();
  }, [workspaceId]);

  useEffect(() => {
    if (account && workspace) {
      const findMap = findAccountMap(account.maps, workspace.map.id);
      setAccountMap(findMap);
    }
  }, [account, workspace]);

  return (
    <div className='flex flex-col'>
      {loading ? (
        <div className='h-[36px] mb-[20px]'>
          <GradientLoader />
        </div>
      ) : error ? (
        <div className='pb-5 h-[56px]'>
          <DashboardError onRetry={fetchWorkspaceAndPoints} />
        </div>
      ) : (
        <div className='flex items-center justify-start gap-10 mb-5 h-[36px]'>
          {/* 지도 정보 */}
          <div className='flex items-center justify-start max-w-[80%]'>
            <img className='w-6 h-6' src={ICON.DASHBOARD_MAP} alt='map' />
            {/*
             * 지도 이름 : Editor 에서 실시간으로 조회
             * 지도 버전 : 현재 workspace 의 지도 버전 조회
             */}
            <div className={`${TextType.h3} pl-1 pr-2 text-gray-44 max-w-[95%] truncate cursor-default shrink-0`}>
              {accountMap?.name || ''}
            </div>
            <div
              className={`${TextType.body3} flex items-center justify-center border border-gray-44 rounded w-[31px] h-[20px] cursor-default`}
            >
              {workspace?.map.version}v
            </div>
          </div>

          {/* 빌딩 선택 */}
          {points.length > 1 && (
            <div className='w-[230px]'>
              <CommonSelectBox
                options={[
                  {
                    value: '',
                    label: '전체',
                  },
                  ...points.map(point => ({
                    value: point.id,
                    label: point.name.ko,
                  })),
                ]}
                onChangeSelect={onChangePoint}
                selected={selectedPoint}
                width='full'
              />
            </div>
          )}
        </div>
      )}

      <Border />

      <div className='flex flex-col gap-10 pt-10'>
        <div className='flex gap-10'>
          {/* 실시간 기기현황 */}
          <RealtimeMachineStatus
            selectedPoint={selectedPoint}
            machineStatus={machineStatus}
            loading={machineUsageLoading}
            error={machineUsageError}
            fetchMachineUsage={fetchMachineUsage}
          />

          {/* 스크린세이버 스케줄 */}
          <DashboardScreensaverSchedule
            selectedPoint={selectedPoint}
            screensaverStatus={screensaverStatus}
            loading={screensaverStatusLoading}
            error={screensaverStatusError}
            fetchScreensaverStatus={fetchScreensaverStatus}
          />
        </div>

        <div className='flex flex-col gap-1'>
          {/* 기준일 */}
          <div className='flex items-center justify-start gap-1'>
            <div className='w-[18px] h-[18x]'>
              <img src={ICON.CLOCK} alt='icon' />
            </div>

            {/* 전일 기준 */}
            <Title text={yesterday.format(SERVER_DATE_FORMAT)} color='text-gray-77' textType={TextType.h4_bold} />
            <Title text='데이터 기준' color='text-gray-77' textType={TextType.h4} />
          </div>

          <div className='flex gap-10 h-[354px]'>
            {/* 전체 사용량 */}
            <DashboardTotalUsage
              selectedPoint={selectedPoint}
              totalUsage={totalUsage}
              loading={totalUsageLoading}
              error={totalUsageError}
              fetchYesterdayUsage={fetchYesterdayUsage}
            />

            {/* 지난주 대비 일자별 사용량 */}
            <DashboardWeeklyUsage
              selectedPoint={selectedPoint}
              weeklyUsage={weeklyUsage}
              loading={weeklyUsageLoading}
              error={weeklyUsageError}
              fetchWeeklyUsage={fetchWeeklyUsage}
            />
          </div>
        </div>

        <div className='flex gap-10 h-[326px]'>
          {/* 인기 매장 */}
          <DashboardPopularTenants
            selectedPoint={selectedPoint}
            tenantUsage={tenantUsage}
            loading={tenantUsageLoading}
            error={tenantUsageError}
            fetchTenantUsage={fetchTenantUsage}
          />

          {/* 인기 카테고리 */}
          <DashboardPopularCategories
            selectedPoint={selectedPoint}
            categoryUsage={categoryUsage}
            loading={categoryUsageLoading}
            error={categoryUsageError}
            fetchCategoryUsage={fetchCategoryUsage}
          />
        </div>
      </div>
    </div>
  );
};
export default DashboardContainer;
