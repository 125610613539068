import { TableHeader } from 'types/common/tableHeader';

export const MENU_CONTENTS_TABLE_HEADER: TableHeader = [
  {
    label: 'No.',
    width: 80,
    direction: 'center',
  },
  {
    label: '타이틀',
    width: 700,
    direction: 'left',
  },
  {
    label: '노출유형',
    width: 100,
    direction: 'center',
  },
  {
    label: '기간',
    width: 200,
    direction: 'center',
  },

  {
    label: '진행상태',
    width: 100,
    direction: 'center',
  },
  {
    label: '삭제',
    width: 140,
    direction: 'center',
  },
];
