import React from 'react';
import LOGO from 'assets/guide/logo.svg';

const Header = () => {
  return (
    <div className='w-full h-20 flex items-center gap-2 p-7 border-b font-bold text-lg bg-blue-950 '>
      <div className='w-20'>
        <img src={LOGO} alt='logo' />
      </div>
      <div className='text-white'>DID Platform 운영 가이드</div>
    </div>
  );
};

export default Header;
