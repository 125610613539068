// router 설정, 메뉴 관리, navigate할 때 사용하는 enum
export enum RoutePath {
  // TODO: 제거
  main = '/',

  workspaces = '/',
  plan = '/plan',

  dashboard = '/dashboard/:workspaceId',

  // 기본정보 관리
  basicSetting = '/:workspaceId/setting',
  pointBasicSetting = '/:workspaceId/setting/:pointId',

  // 광고 (스크린세이버)
  screensaver = '/:workspaceId/screensaver',
  content = '/:workspaceId/screensaver/content',
  contentAdd = '/:workspaceId/screensaver/content/add',
  contentDetail = '/:workspaceId/screensaver/content/:contentId',

  schedule = '/:workspaceId/screensaver/schedule',

  // 매장
  tenantManage = '/:workspaceId/tenant',
  tenantInfo = '/:workspaceId/tenant/info',
  tenantChangedInfo = '/:workspaceId/tenant/changed',

  // 카테고리
  category = '/:workspaceId/category',

  // 커스텀 메뉴
  custom = '/:workspaceId/custom',

  // 기기관리
  machineManage = '/:workspaceId/machine-manage',

  // 통계
  stats = '/:workspaceId/stats',
  totalUsageStats = '/:workspaceId/stats/total-usage',
  machineUsageStats = '/:workspaceId/stats/machine-usage',
  tenantUsageStats = '/:workspaceId/stats/tenant-usage',
  categoryUsageStats = '/:workspaceId/stats/category-usage',

  // 모니터링 관리
  monitoring = '/:workspaceId/monitoring',

  // 메뉴 관리
  customPages = '/:workspaceId/custom-pages',
  pointCustomPages = '/:workspaceId/custom-pages/:pointId',
  pointCustomBoards = '/:workspaceId/custom-pages/:pointId/:customPageId/custom-boards',
  pointCustomBoardAdd = '/:workspaceId/custom-pages/:pointId/:customPageId/custom-boards/add',
  pointCustomBoardDetail = '/:workspaceId/custom-pages/:pointId/:customPageId/custom-boards/:customBoardId',

  // 에러
  errorWithWorkspaceId = '/:workspaceId/error',
  error = '/error',
  notFoundWithWorkspaceId = '/:workspaceId/error/not-found',
  notFound = '/error/not-found',

  // 가이드
  guide = '/guide',
  loginGuide = '/guide/login',
  basicGuide = '/guide/basic',
  workspaceGuide = '/guide/workspace',
  contentsGuide = '/guide/contents',
  scheduleGuide = '/guide/schedule',
  storeGuide = '/guide/store',
}
