import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useBreadcrumb from 'hooks/common/useBreadcrumb';

const Breadcrumb = () => {
  const { crumbs } = useBreadcrumb();

  return (
    <div className='flex gap-2.5 items-center justify-center cursor-default h-4.5'>
      <span>
        <img src={ICON.HOME} alt='home' />
      </span>

      {crumbs?.map((crumb, index) => {
        return (
          <div className='flex justify-center items-center gap-2.5 h-4.5' key={crumb}>
            <div className='h-4.5 flex justify-center items-center'>
              <img src={ICON.ARROW_RIGHT_NO_PADDING} alt='right arrow' />
            </div>
            <span
              className={`${TextType.body2} mt-0.5 ${index === crumbs.length - 1 ? 'text-gray-44' : 'text-gray-99'}`}
              key={crumb}
            >
              {crumb}
            </span>
          </div>
        );
      })}
    </div>
  );
};
export default Breadcrumb;
