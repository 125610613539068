import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useContents from 'hooks/feature/content/useContents';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useFilterStore from 'stores/filter';
import { CONTENTS_BG } from 'styles/background';
import { RoutePath } from 'types/common/paths';
import { PostContent } from 'types/feature/content/postContent';
import ContentAddForm from './form/ContentAddForm';

const ScreensaverContentAddContainer = () => {
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();
  const { uploadContent } = useContents();

  const contentFilterStore = useFilterStore();

  const [addForm, setAddForm] = useState<PostContent>({
    workspaceId: '',
    title: '',
    fileType: null,
    screenType: null,
    type: 'KIOSK',
    file: {
      name: '',
      originalName: '',
      delete: false,
    },
  });
  const [openFailPopup, setOpenFailPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmSave, setConfirmSave] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const validateForm = () => {
    if (!addForm.title) {
      handleAlert('타이틀을 입력해주세요.');
      return false;
    }

    if (!addForm.screenType) {
      handleAlert('콘텐츠 유형을 선택해주세요.');
      return false;
    }

    if (!addForm.fileType) {
      handleAlert('파일 유형을 선택해주세요.');
      return false;
    }

    if (!addForm.file.name || !addForm.file.originalName) {
      handleAlert('파일을 등록해주세요.');
      return false;
    }

    return true;
  };

  // 저장
  const handleSave = () => {
    if (validateForm()) {
      setConfirmSave(true);

      contentFilterStore.setContentFilter(addForm.screenType ?? contentFilterStore.contentFilter);
    }
  };

  // 콘텐츠 등록 클릭 시
  const createContent = async () => {
    if (disableSave) return;

    try {
      setDisableSave(true);

      if (workspaceId) {
        const result = await uploadContent(addForm);
        if (result?.result === 'SUCCESS') {
          moveToContentPage();
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          handleAlert('콘텐츠를 등록하는 중 문제가 발생하였습니다.');
        }
      }
    }
  };

  // 취소 클릭 시
  const clickCancelBtn = () => {
    moveToContentPage();
  };

  const moveToContentPage = () => {
    if (workspaceId) {
      navigateToReplacedPath(RoutePath.content, workspaceId);
    }
  };

  const handleAlert = (message: string) => {
    setAlertMessage(message);
    setConfirmSave(false);
    setOpenFailPopup(true);
  };

  return (
    <>
      <div className={`${CONTENTS_BG} p-10 flex flex-col items-center gap-6`}>
        <ContentAddForm addForm={addForm} setAddForm={setAddForm} />
        <Border borderColor={BorderColor.gray} />
        <div className='flex gap-2.5'>
          <Button size={120} text='취소' color={ButtonColor.secondary} onClick={clickCancelBtn} />
          <Button size={120} text='저장' color={ButtonColor.primary} onClick={handleSave} />
        </div>
      </div>

      {confirmSave && (
        <ConfirmModal
          message='콘텐츠를 등록하시겠습니까?'
          onClickCancel={() => setConfirmSave(false)}
          onClickOk={createContent}
        />
      )}

      {openFailPopup && (
        <AlertModal width='min-w-[380px]' closeModal={() => setOpenFailPopup(false)} message={alertMessage} />
      )}
    </>
  );
};
export default ScreensaverContentAddContainer;
