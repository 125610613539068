import ScreensaverContentEditContainer from 'components/feature/screensaver/contents/edit/ScreensaverContentEditContainer';
import useContents from 'hooks/feature/content/useContents';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Content } from 'types/feature/content/contents';

const ScreensaverContentDetailPage = () => {
  const [currentContent, setCurrentContent] = useState<Content>();

  const { getContent } = useContents();
  const { contentId } = useParams();

  const fetchContent = async () => {
    if (contentId) {
      const result = await getContent(contentId);
      setCurrentContent(result);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  if (!currentContent) {
    return <> </>;
  }

  return <ScreensaverContentEditContainer fetchContent={fetchContent} content={currentContent} />;
};
export default ScreensaverContentDetailPage;
