import useBreadcrumb from 'hooks/common/useBreadcrumb';
import Title, { TextType } from '../title/Title';
import Breadcrumb from './breadcrumb/Breadcrumb';

const TitleContainer = () => {
  const { title } = useBreadcrumb();

  return (
    <div className='flex items-center justify-between w-full h-[30px]'>
      <Title text={title} textType={TextType.h1} />
      <Breadcrumb />
    </div>
  );
};
export default TitleContainer;
