import TenantChangedEditableFields from 'components/feature/tenant/edit/fields/TenantChangedEditableFields';
import { useRef } from 'react';
import { CONTENTS_BG } from 'styles/background';
import { ChangedStatus } from 'types/common/status';
import { Language } from 'types/feature/multi-lang/language';
import { Tenant } from 'types/feature/tenant/tenant';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  mainLanguage: string;
  languageList: Language[];
  tenant: Tenant | null;
  fetchTenant: (pointId: string, status?: ChangedStatus, floorId?: string, viewAll?: boolean) => Promise<void>;
  status?: ChangedStatus;
  floorId?: string;
  viewAll?: boolean;
  point: Point;
  clickedPoiId?: string;
};

const TenantChangedEditSpace = ({
  languageList,
  mainLanguage,
  tenant,
  fetchTenant,
  status,
  floorId,
  viewAll,
  point,
  clickedPoiId,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const returnTenantEditableField = () => {
    if (!tenant) {
      return <> </>;
    }

    return (
      <TenantChangedEditableFields
        tenant={tenant}
        languageList={languageList}
        mainLanguage={mainLanguage}
        fetchTenant={fetchTenant}
        status={status}
        floorId={floorId}
        viewAll={viewAll}
        pointName={point.name}
        pointId={point.id}
        clickedPoiId={clickedPoiId}
      />
    );
  };

  return (
    <div className={`h-[800px] ${CONTENTS_BG} w-[480px] overflow-y-auto`}>
      <div ref={scrollRef} />

      <div className='flex flex-col w-full'>{returnTenantEditableField()}</div>
    </div>
  );
};

export default TenantChangedEditSpace;
