import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { useParams } from 'react-router-dom';
import { ApiResponse } from 'types/api/response';
import { BusinessHours, ClosedDays, Point } from 'types/feature/workspace/workspace';

const usePoint = () => {
  const { api } = useApi();
  const { workspaceId } = useParams();

  const getMyPoints = async () => {
    const result = await api.get<ApiResponse<Point[]>>(Endpoint.points, {
      params: {
        workspaceId,
      },
    });

    if (result.data) {
      return result.data;
    }
  };

  const getPoints = async (workspaceId: string) => {
    const result = await api.get<ApiResponse<Point[]>>(Endpoint.points, {
      params: {
        workspaceId,
      },
    });

    if (result.data) {
      return result.data;
    }
  };

  const patchPoint = async (id: string, point: BusinessHours | ClosedDays) => {
    await api.patch(Endpoint.points.concat(`/${id}`), point);
  };

  return {
    patchPoint,
    getMyPoints,
    getPoints,
  };
};

export default usePoint;
