import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { ConnectedMachine } from 'types/feature/machine/machine';

const useMachines = () => {
  const { api } = useApi();

  const getMachines = async (pointId: string) => {
    const url = `${Endpoint.machines}?pointId=${pointId}`;
    const result = await api.get<ApiResponse<ConnectedMachine[]>>(url);
    return result;
  };

  const resetMachine = async (machineId: string) => {
    await api.post(Endpoint.machines.concat(`/${machineId}/reset`));
  };

  return {
    getMachines,
    resetMachine,
  };
};

export default useMachines;
