import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import Input from 'components/common/input/Input';
import SelectBox, { OptionType } from 'components/common/select/SelectBox';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { SCREEN_TYPE } from 'data/screenType';
import { SCREEN_TYPE_OPTIONS } from 'data/select-box/options';
import React, { RefObject, useEffect, useState } from 'react';
import useFilterStore from 'stores/filter';
import { ScreenType, ScreenTypeCode } from 'types/feature/content/contents';

type Props = {
  handleChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchWord: string;
  fetchContents: (pageNum: number, searchWord?: string, screenType?: ScreenType) => Promise<void>;
  inputRef: RefObject<HTMLInputElement>;
  resetFilter: () => void;
  clickAdd: () => void;
  currentPage: number;
  resetPage: () => void;
  screenType: ScreenType;
  handleChangeFilter: (type: ScreenType) => void;
};

const ContentsHeader = ({
  currentPage,
  handleChangeTitle,
  searchWord,
  fetchContents,
  inputRef,
  resetFilter,
  clickAdd,
  resetPage,
  screenType,
  handleChangeFilter,
}: Props) => {
  const contentFilterStore = useFilterStore();
  const [disableRefresh, setDisableRefresh] = useState(true);

  // 타이틀 검색
  const handleSearchTitle = async (e: any) => {
    e.preventDefault();

    resetPage();
    await fetchContents(0, searchWord, screenType);
  };

  // 필터
  const handleChangeOption = async (option: OptionType) => {
    handleChangeFilter(option.value as ScreenTypeCode);
    await fetchContents(0, searchWord, option.value as ScreenTypeCode);
  };

  useEffect(() => {
    if (inputRef.current?.value.length === 0) {
      setDisableRefresh(true);
    } else {
      setDisableRefresh(false);
    }
  }, [inputRef.current?.value]);

  return (
    <div className='flex justify-between'>
      <div className='flex w-full gap-2.5 h-9'>
        <SelectBox
          placeholder={SCREEN_TYPE[contentFilterStore.contentFilter]}
          options={[...SCREEN_TYPE_OPTIONS]}
          onChangeSelect={handleChangeOption}
        />
        <form onSubmit={handleSearchTitle} className='flex w-full gap-2.5 h-9'>
          <Input size={224} placeholder='타이틀 검색' onChange={handleChangeTitle} inputRef={inputRef} />
          <Button onClick={handleSearchTitle} size={80} type='submit' text='검색' color={ButtonColor.primary} />
          <Button disabled={disableRefresh} onClick={resetFilter} size={80} type='button' color={ButtonColor.secondary}>
            <div className={`flex items-end justify-center w-full gap-1`}>
              <img className='h-full' src={disableRefresh ? ICON.REFRESH_GRAY : ICON.REFRESH_BK} alt='refresh icon' />
              <span className={`${TextType.h5} h-full whitespace-nowrap ${disableRefresh && 'text-gray-cc'}`}>
                초기화
              </span>
            </div>
          </Button>
        </form>
      </div>
      <Button type='button' onClick={clickAdd} textStyle={TextType.h5} size={96} color={ButtonColor.primary}>
        <div className='flex items-center justify-center h-4 gap-1 pl-2 pr-3'>
          <img src={ICON.PLUS_WHITE} alt='plus icon' />
          <span className={`${TextType.h5} text-gray-ea whitespace-nowrap`}>콘텐츠 등록</span>
        </div>
      </Button>
    </div>
  );
};
export default ContentsHeader;
