import Input from 'components/common/input/Input';
import { ChangeEvent } from 'react';
import { FLEX_CENTER } from 'styles/flex';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  point: Point;
  handlePoint: (point: Point) => void;
};

const TimeForm = ({ point, handlePoint }: Props) => {
  const handleChangeTime = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    handlePoint({
      ...point,
      operation: {
        ...point.operation,
        [name]: value,
      },
    });
  };

  return (
    <div className={`flex justify-start items-center gap-2.5`}>
      <div className={`${FLEX_CENTER.row} gap-2.5`}>
        <span className='text-h4 text-gray-99'>시작</span>
        <span>
          <Input
            type='time'
            size={128}
            name='hoursStart'
            onChange={handleChangeTime}
            value={point.operation.hoursStart}
          />
        </span>
      </div>
      -
      <div className={`${FLEX_CENTER.row} gap-2.5`}>
        <span className='text-h4 text-gray-99'>종료</span>
        <span>
          <Input type='time' size={128} name='hoursEnd' onChange={handleChangeTime} value={point.operation.hoursEnd} />
        </span>
      </div>
    </div>
  );
};

export default TimeForm;
