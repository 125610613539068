import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GradientLoader from 'components/common/loading/gradient/GradientLoader';
import Title, { TextType } from 'components/common/title/Title';
import DashboardError from './DashboardError';
import { DashboardSectionProps } from './DashboardSection';

const DashboardSectionNoBg = ({
  title,
  onClickMore,
  width = '100%',
  height = '100%',
  children,
  gap,
  loading,
  error,
  onRetry,
}: DashboardSectionProps) => {
  return (
    <div
      style={{
        width,
        height,
        gap,
      }}
      className='flex flex-col gap-5 cursor-default shrink-0'
    >
      {/* 제목 */}
      <div className='flex justify-between px-1'>
        <Title text={title} color='text-gray-99' textType={TextType.h4_bold} />

        <Button onClick={onClickMore} text='더보기' color={ButtonColor.secondary} size='more' />
      </div>

      {/* 내용 */}
      {loading ? (
        // 로딩
        <GradientLoader />
      ) : error ? (
        // 에러
        <DashboardError onRetry={onRetry} />
      ) : (
        // 성공
        <div>{children}</div>
      )}
    </div>
  );
};
export default DashboardSectionNoBg;
