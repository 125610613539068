import { MENUS } from 'data/guide/menu';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'types/guide/menu';

const Navigation = () => {
  const location = useLocation();

  return (
    <div className='border-r w-96 min-h-screen pt-12 pl-12 pr-12'>
      <div className='text-[36px] font-bold'>Index</div>

      <div className='flex flex-col gap-2'>
        {MENUS.map((menu: Menu) => (
          <div key={menu.path}>
            <Link
              replace
              to={menu.path}
              className='font-semibold text-[16px] leading-8 hover:font-bold'
            >{`${menu.index}. ${menu.title}`}</Link>
            <div className='flex flex-col'>
              {menu.children?.map((child: Menu) => (
                <Link
                  key={child.path}
                  to={`${menu.path}/#${child.path}`}
                  className={`text-[16px] pl-2 leading-8 hover:font-bold ${
                    location.hash === `#${child.path}` && 'font-bold'
                  }`}
                >{`${child.index}. ${child.title}`}</Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Navigation;
