import { MousePosition } from 'hooks/common/useMouseOverlay';
import { CommonFile } from 'types/common/file/file';
import { formatMegaByte } from 'utils/file/unit/fileUnit';

type ThumbNailOverlayProps = {
  mousePosition: MousePosition;
  file: CommonFile;
  fileType: string;
};

const ThumbnailOverlay = ({ mousePosition, file, fileType }: ThumbNailOverlayProps) => {
  return (
    <div
      className='flex flex-col items-center justify-center gap-2 p-2 border bg-primary-White w-60'
      style={{
        position: 'fixed',
        top: mousePosition.y + 10,
        left: mousePosition.x + 10,
        zIndex: 1000,
      }}
    >
      <div>
        {fileType === 'IMAGE' ? (
          <img className='object-contain h-36' src={file.uri} alt='thumbnail' />
        ) : (
          <video className='object-contain h-36' autoPlay muted src={file.uri}>
            <source />
          </video>
        )}
      </div>

      <div className='w-full h-full bg-white'>
        <div className='flex gap-2'>
          <span className='text-xs text-gray-44 shrink-0 font-h4-bold'>* 파일명 : </span>
          <div className='overflow-hidden text-xs font-normal truncate text-gray-44 text-h4 text-ellipsis'>
            {file.originalName}
          </div>
        </div>

        <div className='flex gap-2'>
          <span className='text-xs text-gray-44 shrink-0 text-h4 font-h4-bold'>* 크기 : </span>
          <span className='text-xs font-normal text-h4 text-gray-44'>{formatMegaByte(file.size)}MB</span>
        </div>
      </div>
    </div>
  );
};
export default ThumbnailOverlay;
