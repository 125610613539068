import { Area } from '@ant-design/plots';
import Legend from 'components/common/graph/legend/Legend';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import { TextType } from 'components/common/title/Title';
import { SERVER_DATE_FORMAT } from 'data/common';
import dayjs from 'dayjs';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useEffect } from 'react';
import { RoutePath } from 'types/common/paths';
import { DashBoardWeeklyUsage } from 'types/feature/dashboard/dashboard';
import { yesterday } from 'utils/date/state';
import DashboardSection from '../section/DashboardSection';

type WeekType = '지난주' | '이번주';

type WeekUsage = {
  date: string;
  group: WeekType;
  count: number;
};

type TooltipItem = { value: number; name: WeekType; color: string };

const DashboardWeeklyUsage = (props: {
  selectedPoint: CommonOptionType;
  weeklyUsage: DashBoardWeeklyUsage;
  loading: boolean;
  error: boolean;
  fetchWeeklyUsage: (pointId: string) => Promise<void>;
}) => {
  const { customNavigate } = useCustomNavigate();

  useEffect(() => {
    props.fetchWeeklyUsage('');
  }, []);

  const lastWeekGraphData: WeekUsage[] = props.weeklyUsage.lastWeekUsages.map(usage => {
    return {
      date: dayjs(usage.date).add(7, 'day').format(SERVER_DATE_FORMAT),
      group: '지난주',
      count: usage.totalCount,
    };
  });

  const thisWeekGraphData: WeekUsage[] = props.weeklyUsage.thisWeekUsages.map(usage => {
    return {
      date: usage.date,
      group: '이번주',
      count: usage.totalCount,
    };
  });

  const graphData: WeekUsage[] = [...lastWeekGraphData, ...thisWeekGraphData];

  // 데이터의 totalCount 들의 max 값을 구한다.
  const totalArr = graphData.map(item => item.count);
  const max = Math.max(...totalArr);

  const config = {
    xField: (d: WeekUsage) => dayjs(d.date).format('M.D(ddd)'),
    yField: 'count',
    seriesField: 'group',
    colorField: 'group',
    stack: false,
    legend: false,

    scale: {
      color: { range: ['#BFBFBF', '#92D5DE'] },
      y: {
        domainMin: 0,
        domainMax: max > 5 ? max : 5, // 5 이하부터 y축에 소수점이 노출되므로 눈금의 최대값을 지정해준다.
        tickCount: 4,
      },
    },

    axis: {
      x: {
        style: {
          tick: false,

          label: true,
          labelFontSize: 12,
          labelFontWeight: 400,
          labelFill: '#999',
          labelAutoEllipsis: true,
          labelAutoWrap: true,
          labelAutoHide: false,
          labelAutoRotate: false,
          labelSpacing: 26,

          line: true,
          lineStroke: '#eaeaea',
          lineLineWidth: 1,
          lineStrokeOpacity: 1,
        },
      },
      y: {
        labelFormatter: (d: number) => d.toLocaleString(),
        style: {
          tick: false,

          label: true,
          labelFontSize: 12,
          labelFontWeight: 400,
          labelFill: '#BFBFBF',
          labelAutoHide: false,
          labelAutoRotate: false,
          labelSpacing: 45,
        },
      },
    },

    // 애니메이션
    // https://ant-design-charts.antgroup.com/en/options/plots/animation/overview
    animate: { enter: { type: 'growInX' } },

    style: {
      fill: (chart: WeekUsage[]) => {
        return chart[0].group === '이번주'
          ? 'linear-gradient(90deg, rgba(146, 213, 222, 0.30) 0.87%, rgba(146, 213, 222, 0.00) 100%)'
          : 'transparent';
      },
      fillOpacity: 1,
    },

    line: {
      style: {
        stroke: (chart: WeekUsage[]) => {
          return chart[0].group === '이번주' ? '#92D5DE' : '#BFBFBF';
        },
        strokeWidth: 1.5,
      },
    },

    interaction: {
      // 툴팁
      tooltip: {
        outerWidth: 98,
        innerWidth: 78,
        outerHeight: 58,
        innerHeight: 38,

        render: (e: any, { title, items }: { title: string; items: TooltipItem[] }) => {
          const findThisWeekValue = items.find(item => item.name === '이번주')?.value;
          const findLastWeekValue = items.find(item => item.name === '지난주')?.value;

          return (
            <div className='flex flex-col justify-between gap-2'>
              <div className='flex items-start gap-1 h-[18px]'>
                {/* 이번주 */}
                <div className={`${TextType.h5_bold} text-[#8CCCD5] h-[16px]`}>{title}</div>

                {/* 이번주 사용량 */}
                <div className='text-gray-44 font-normal text-xs leading-[18px]'>
                  {findThisWeekValue?.toLocaleString()}
                </div>
              </div>

              <div className='flex items-center gap-1 h-[18px]'>
                {/* 지난주 */}
                <div className={`${TextType.h5_bold} text-[#999] h-[16px]`}>
                  {dayjs(convertToFullDate(title)).subtract(7, 'day').format('M.D(ddd)')}
                </div>

                {/* 지난주 사용량 */}
                <div className='text-gray-99 font-normal text-xs leading-[18px]'>
                  {findLastWeekValue?.toLocaleString()}
                </div>
              </div>
            </div>
          );
        },
      },
    },
  };

  // "5.27(월)" 형식의 문자열에서 월과 일을 추출
  function convertToFullDate(dateStr: string) {
    const year = yesterday.year();
    const [month, dayWithDayName] = dateStr.split('.');
    const day = dayWithDayName.split('(')[0]; // '27(월)' 에서 '27' 추출

    // 날짜를 YYYY-MM-DD 형식의 문자열로 구성
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  return (
    <DashboardSection
      title='지난주 대비 일자별 사용량'
      width='860px'
      gap='24px'
      onClickMore={() => {
        customNavigate(RoutePath.totalUsageStats, '', '', '', {
          state: {
            pointId: props.selectedPoint.value,
            pointName: props.selectedPoint.label,
          },
        });
      }}
      loading={props.loading}
      error={props.error}
      onRetry={async () => {
        await props.fetchWeeklyUsage('');
      }}
    >
      <div className='flex justify-between w-full'>
        {/* 범례 */}
        <Legend
          data={[
            {
              title: '이번주 사용량',
              color: '#92D5DE',
            },
            {
              title: '지난주 사용량',
              color: '#B7B7B7',
            },
          ]}
          colorChipSize='12px'
          colorChipType='rounded-square'
          listDirection='column'
          gap='8px'
        />

        <div className='w-[576px] h-[230px]'>
          <Area className='w-full' data={graphData} {...config} />
        </div>
      </div>
    </DashboardSection>
  );
};
export default DashboardWeeklyUsage;
