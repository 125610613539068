/**
 * 두 개의 배열을 순회하면서 하나라도 같은 string 이 있는지 확인하는 util
 *
 * @param arr1 문자열 배열1
 * @param arr2 문자열 배열2
 * @returns 배열1과 배열2에 하나라도 같은 string 이 있는지 여부(boolean) 반환
 */
export function hasCommonElement(arr1: string[], arr2: string[]): boolean {
  // arr1을 Set으로 변환하여 중복을 제거하고 검색 시간을 줄인다.
  const set1 = new Set(arr1);

  // arr2를 순회하면서 set1에 있는지 확인한다.
  for (const element of arr2) {
    if (set1.has(element)) {
      return true;
    }
  }

  // 공통 요소가 없으면 false를 반환한다.
  return false;
}
