export enum LangCode {
  ko = 'ko',
  en = 'en',
  ja = 'ja',
  cn = 'cn',
  tw = 'tw',
}

// 지도 언어코드로 변경
export const changeLangCodeForMap = (lang: string) => {
  let langCodeForMap = lang;

  // TODO: 중국어 번체는 추가 예정
  if (lang === LangCode.cn || lang === LangCode.tw) {
    langCodeForMap = 'zh-CN';
  }

  return langCodeForMap;
};

export const convertLangCodeForMap = (lang: string) => {
  let langCodeForMap = lang;

  // TODO: 중국어 번체는 추가 예정
  if (lang === LangCode.cn || lang === LangCode.tw) {
    langCodeForMap = 'zh-CN';
  }
  return langCodeForMap;
};
