import useMultipartApi from 'api/useMultipartApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { FileUploadType } from 'types/common/file/fileUpload';
import { UploadFile } from 'types/feature/content/contents';

const useFileUpload = () => {
  const { apiFile } = useMultipartApi();

  // 파일 등록
  const uploadFile = async (file: File, fileType: FileUploadType) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('uploadType', fileType);

    const result = await apiFile.post<ApiResponse<UploadFile>>(Endpoint.files, formData);
    if (result) {
      return result;
    }
  };

  return {
    uploadFile,
  };
};

export default useFileUpload;
