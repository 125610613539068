import { Column } from '@ant-design/plots';
import Legend from 'components/common/graph/legend/Legend';
import Title, { TextType } from 'components/common/title/Title';
import { MultiLangs } from 'types/feature/multi-lang/language';
import { TenantUsage } from 'types/feature/statistics/tenantUsage';

type Props = {
  tenantUsageList: TenantUsage[];
};

type DataType = 'TOTAL' | 'NAVI';

type GraphData = {
  tenantId: string;
  tenantName: string;
  value: number;
  type: DataType;
};

type TooltipItem = { value: number; name: DataType };

const PopularTenantsGraph = ({ tenantUsageList }: Props) => {
  const top20TenantData: GraphData[] = tenantUsageList.slice(0, 20).flatMap(data => [
    {
      tenantId: data.tenantId,
      tenantName: data.tenantName.ko,
      value: data.totalCount,
      type: 'TOTAL',
    },
    {
      tenantId: data.tenantId,
      tenantName: data.tenantName.ko,
      value: data.navigationCount,
      type: 'NAVI',
    },
  ]);

  const tenantNameMap = new Map<string, MultiLangs>(tenantUsageList.map(item => [item.tenantId, item.tenantName]));

  const config = {
    xField: 'tenantId',
    yField: 'value',
    legend: false,
    group: true, // 데이터 그루핑 유무

    axis: {
      x: {
        labelFormatter: (val: string) => {
          const findName = tenantNameMap.get(val);

          if (findName) {
            // 문자열을 두 부분으로 나눈다.
            const firstHalf = findName.ko.substring(0, 5);
            const secondHalf = findName.ko.substring(5);

            return `${firstHalf}\n${secondHalf}`;
          }

          return '';
        },

        style: {
          // 축
          line: true,

          // 눈금
          tick: true,
          tickLineWidth: 12,
          tickStroke: '#fff',

          // 라벨
          label: true,
          labelFontSize: 12,
          labelFontWeight: 400,
          labelFill: '#999',
          labelAutoEllipsis: true,
          labelAutoWrap: true,
          labelAutoHide: false,
          labelAutoRotate: false,
        },
      },
      y: {
        labelFormatter: (val: string) => `${val.toLocaleString()}`, // 1,000

        style: {
          // 눈금
          tick: true,
          tickLineWidth: 10,
          tickStroke: '#fff',

          // 라벨
          labelFontSize: 12,
          labelFontWeight: 400,
          labelFill: '#888',

          // 그리드
          grid: true,
          gridLineDash: [0, 0],
        },
      },
    },

    colorField: 'type', // 색상값 (필수)

    style: {
      maxWidth: 12,

      fill: (data: GraphData) => {
        if (data.type === 'TOTAL') {
          return '#92D5DE';
        }

        return '#B7B7B7';
      },
    },

    interaction: {
      elementHighlightByColor: { background: true },

      // 툴팁
      tooltip: {
        shared: true,
        outerWidth: 88,
        innerWidth: 68,
        outerHeight: 80,
        innerHeight: 60,

        render: (e: any, { title, items }: { title: string; items: TooltipItem[] }) => {
          const findTenantName = tenantNameMap.get(title);

          const total = items.find(item => item.name === 'TOTAL');
          const navi = items.find(item => item.name === 'NAVI');

          return (
            <div className='flex flex-col justify-between gap-2'>
              <div className='flex flex-col'>
                {/* 총합 */}
                <div className={`${TextType.h4_bold} text-[#92D5DE] h-[18px]`}>{total?.value.toLocaleString()}</div>

                {/* 테넌트 이름 */}
                <div className={`${TextType.h5_bold} text-gray-99 h-[16px]`}>{findTenantName?.ko}</div>
              </div>

              <div className='flex flex-col gap-1'>
                <div className='flex items-center gap-1 h-[18px]'>
                  {/* 길찾기 */}
                  <div className={`${TextType.h5_bold} text-[#B7B7B7]`}>길찾기</div>

                  {/* 길찾기 진행건 */}
                  <div className='text-gray-44 font-normal text-xs leading-[18px]'>{navi?.value.toLocaleString()}</div>
                </div>
              </div>
            </div>
          );
        },
      },
    },
  };

  // 데이터의 totalCount 들의 max 값을 구한다.
  const totalArr = tenantUsageList.map(item => item.totalCount);
  const max = Math.max(...totalArr);
  // const tickArr = Array.from({ length: max + 1 }, (_, i) => i); // [0, 1, ... , max]

  return (
    <div className='flex flex-col h-[414px] w-full p-10 bg-white border border-gray-ea gap-10'>
      <div className='flex items-center justify-between h-5'>
        <Title text='인기 매장 TOP20' textType={TextType.h3_bold} />

        {tenantUsageList && tenantUsageList.length > 0 && (
          <>
            {/* 범례 */}
            <Legend
              data={[
                {
                  title: '매장 진입',
                  color: '#92D5DE',
                },
                {
                  title: '길찾기',
                  color: '#B7B7B7',
                },
              ]}
            />
          </>
        )}
      </div>

      <div className='h-[274px]'>
        {
          <Column
            data={top20TenantData}
            {...config}
            scale={{
              x: {
                padding: 0.5,
                align: 0.5, //  그래프 중앙 정렬
              },
              y: {
                domainMin: 0,
                domainMax: max > 5 ? max : 5, // 5 이하부터 y축에 소수점이 노출되므로 눈금의 최대값을 지정해준다.
                tickCount: 5,
                nice: true,
                // tickMethod: () => {   // 눈금 변경 메서드 (없어도 무방)
                //   if (max > 5) {
                //     return tickArr;
                //   }

                //   return [0, 1, 2, 3, 4, 5];
                // },
              },
            }}
          />
        }
      </div>
    </div>
  );
};
export default PopularTenantsGraph;
