import TableCsvDownload from 'components/common/table/csv/TableCsvDownload';
import NoData from 'components/common/table/no-data/NoData';
import React from 'react';
import { TotalUsagePerDate, TotalUsagePerDayOfWeek, TotalUsagePerTime } from 'types/feature/statistics/totalUsage';

type Props = {
  graph: React.ReactElement;
  table: React.ReactElement;
  totalUsageList: TotalUsagePerDate[] | TotalUsagePerDayOfWeek[] | TotalUsagePerTime[];
  onClickDownload: () => Promise<void>;
};

const TotalUsageStatsSection = (props: Props) => {
  return (
    <>
      <>
        {/* 그래프 */}
        {props.graph}

        <div className='flex flex-col w-full gap-3 px-10 pt-6 pb-10 bg-white border border-gray-ea'>
          {/* 총 개수, 다운로드 버튼 */}
          <TableCsvDownload totalCount={props.totalUsageList.length} onClickDownload={props.onClickDownload} />

          {/* 테이블 */}
          {props.table}
        </div>
      </>

      <NoData visible={props.totalUsageList.length === 0} message='데이터가 없습니다.' />
    </>
  );
};
export default TotalUsageStatsSection;
