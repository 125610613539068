import Triangle from 'assets/header/triangle.svg';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GlobalMenu } from 'types/common/globalMenu';
import { RoutePath } from 'types/common/paths';
import TwoDepthSubMenus from './TwoDepthSubMenus';
import { TextType } from 'components/common/title/Title';

type Props = {
  menus: GlobalMenu[];
  setShowSubMenus: (value: boolean) => void;
};

const SubMenus = ({ menus, setShowSubMenus }: Props) => {
  const { navigateToReplacedPath, replaceWorkspaceId } = useCustomNavigate();
  const { workspaceId } = useParams();
  const location = useLocation();

  const [showTwoDepthMenu, setShowTwoDepthMenu] = useState(false);

  const handleClickSubMenu = (path: RoutePath) => {
    navigateToReplacedPath(path, workspaceId);
  };

  const openTwoDepthSubMenus = () => {
    setShowTwoDepthMenu(true);
  };

  const closeTwoDepthSubMenus = () => {
    setShowTwoDepthMenu(false);
  };

  return (
    <div
      onMouseOver={() => setShowSubMenus(true)}
      onMouseLeave={() => setShowSubMenus(false)}
      className={`w-[120px] absolute top-[62px] z-50 left-[50%] -translate-x-1/2 cursor-pointer`}
    >
      <div className='flex justify-center'>
        <img src={Triangle} alt='arrow' />
      </div>

      <div className='shadow-drop rounded-[2px] py-1 bg-primary-White'>
        {menus.map(({ id, path, subMenus }, index) => {
          const subPath = replaceWorkspaceId(path, workspaceId);
          const isSubActive = location.pathname.includes(subPath);

          return (
            <div key={id} className='flex flex-col items-center justify-center'>
              <div
                onFocus={() => subMenus && openTwoDepthSubMenus()}
                onMouseOver={() => subMenus && openTwoDepthSubMenus()}
                onMouseLeave={() => subMenus && closeTwoDepthSubMenus()}
                onBlur={() => subMenus && closeTwoDepthSubMenus()}
                onClick={handleClickSubMenu.bind(this, path)}
                className={`flex items-center justify-center w-full h-[40px]
                text-center ${TextType.h4}
                ${isSubActive ? 'text-gray-44 !font-bold' : 'text-gray-99'} 
                hover:!font-bold whitespace-nowrap
                `}
              >
                {id}
              </div>
              {index !== menus.length - 1 && <div className='w-[60px] h-[1px] bg-gray-ea' />}

              {/* 2 depth sub menu */}
              <div className='absolute top-0 right-0'>
                {showTwoDepthMenu && subMenus && (
                  <TwoDepthSubMenus
                    menus={subMenus}
                    setShowSubMenus={setShowTwoDepthMenu}
                    setShowParentMenu={setShowSubMenus}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubMenus;
