import React from 'react';
import POWERED from 'assets/guide/powered.svg';

const Footer = () => {
  return (
    <div className='bg-gray-100 w-full pb-10 border-t'>
      <div className='flex items-start'>
        <div>
          <img src={POWERED} alt='logo' />
        </div>
        <div className='pt-[30px] text-gray-500 text-sm'>
          <div>
            <a target='_blank' href='https://dabeeo.com/'>
              Dabeeo.
            </a>
          </div>
          <div>
            <a target='_blank' href='https://www.dabeeostudio.com/'>
              Dabeeo Studio.
            </a>
          </div>
          <div>
            <a target='_blank' href='https://partner.did.dabeeo.co.kr/'>
              Dabeeo DID Platform.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
