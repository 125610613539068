import { IMAGES } from 'constants/images';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { RoutePath } from 'types/common/paths';
import { Workspace } from 'types/feature/workspace/workspace';

type Props = {
  workspace: Workspace;
};

const WorkspaceCard = ({ workspace }: Props) => {
  const { navigateToReplacedPath } = useCustomNavigate();

  const onClick = (id: string) => {
    // 메인페이지로 이동
    navigateToReplacedPath(RoutePath.dashboard, id);
  };

  return (
    <div
      className='w-full h-[210px] bg-primary-White p-10 rounded-xl flex flex-col justify-between shadow cursor-pointer border-2 border-white hover:bg-state-blue_bg hover:border-state-blue hover:border-2'
      onClick={onClick.bind(this, workspace.id)}
    >
      <div>
        <div className='flex items-start justify-between mb-4'>
          {/* // TODO: points[0] */}
          <img className='h-[30px]' src={workspace.points[0].logo?.uri || IMAGES.LOGO_P_GRAY} alt='로고' />
          <div className='h-5 border px-[6px] flex justify-center items-center border-gray-44 rounded'>
            <span className='font-body text-body3 leading-body3'>{workspace.map.version}</span>
          </div>
        </div>

        <div className='break-all whitespace-pre-wrap font-h2 text-h2 leading-h2 line-clamp-2 tracking-default text-gray-44'>
          {workspace.name}
        </div>
      </div>

      <div className='flex gap-1 text-center text-gray-cc font-body text-body2 leading-body2'>
        <span className='text-inherit'>{workspace.updatedDate.slice(0, 10)}</span>
        <span className='text-inherit'>최종 수정</span>
      </div>
    </div>
  );
};
export default WorkspaceCard;
