import { TextType } from 'components/common/title/Title';
import React from 'react';
import { IndexType } from 'types/common';

type BadgeProps = {
  status: boolean;
};

export const DISPLAY_BADGE_TYPE: IndexType = {
  DISPLAY: 'bg-state-green_bg text-state-green',
  CLOSE: 'bg-state-red_bg text-state-red',
};

const ConnectedBadge = ({ status }: BadgeProps) => {
  return (
    <div className={`flex h-full`}>
      <div
        className={`${
          status ? 'bg-state-green_bg text-state-green' : 'bg-state-red_bg text-state-red'
        } w-[60px] h-[32px] flex items-center ${TextType.body2} m-auto justify-center text-center rounded-full`}
      >
        {status ? 'ON' : 'OFF'}
      </div>
    </div>
  );
};

export default ConnectedBadge;
