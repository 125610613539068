import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { Dispatch, SetStateAction } from 'react';
import { ChangedStatusKo } from 'types/common/status';
import { ChangeTenant } from 'types/feature/tenant/changedTenant';

type Props = {
  changeTenant: ChangeTenant;
  index: number;
  currentTenantId: string;
  setCurrentTenant: Dispatch<SetStateAction<ChangeTenant | null>>;
};

const ChangedInfoTableRow = ({
  changeTenant: { tenant, type, changed },
  changeTenant,
  index,
  currentTenantId,
  setCurrentTenant,
}: Props) => {
  const isCurrentTenant = currentTenantId === tenant.id;
  return (
    <tr
      onClick={() => {
        setCurrentTenant(changeTenant);
      }}
      className={`${
        isCurrentTenant && 'bg-state-blue_bg'
      } h-[52px] border-b text-center text-gray-44 hover:bg-state-blue_bg cursor-pointer w-full flex items-center`}
    >
      {/* No. */}
      <td className={`${TextType.h4} px-2.5 w-[60px] border-r h-full`}>
        <div className={`flex items-center justify-center h-full text-gray-99 ${isCurrentTenant && 'font-bold'}`}>
          {index}
        </div>
      </td>

      {/* 상태 */}
      <td className={`${TextType.h4} text-inherit text-left px-2.5 w-[99px] border-r h-full`}>
        <div className={`flex items-center h-full ${isCurrentTenant && 'font-bold'}`}>{ChangedStatusKo[type]}</div>
      </td>

      {/* 층 */}
      <td className={`${TextType.h4} text-inherit px-2.5 w-[79px] border-r h-full`}>
        <div className={`flex items-center h-full ${isCurrentTenant && 'font-bold'}`}>
          {tenant.content.floor.mainName}
        </div>
      </td>

      {/* 지도 타이틀 */}
      <td className={`${TextType.h4} text-inherit text-left px-2.5 w-[263px] border-r h-full`}>
        <div className={`flex items-center h-full ${isCurrentTenant && 'font-bold'}`}>{tenant.content.mainName}</div>
      </td>

      {/* 노출 타이틀 */}
      <td className={`${TextType.h4} text-inherit text-left px-2.5 w-[263px] border-r h-full`}>
        <div className={`flex items-center h-full ${isCurrentTenant && 'font-bold'}`}>{tenant.mainName}</div>
      </td>

      {/* 완료 */}
      <td className={`${TextType.h4} text-inherit px-2.5 w-[80px]`}>
        <div className='flex items-center justify-center'>
          {changed && <img src={ICON.CHECK_BLUE_CIRCLE} alt='check' />}
        </div>
      </td>
    </tr>
  );
};
export default ChangedInfoTableRow;
