import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertModal from 'components/common/modal/alert/AlertModal';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import SelectBox, { OptionType } from 'components/common/select/SelectBox';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { SCREEN_TYPE } from 'data/screenType';
import { SCREEN_TYPE_OPTIONS } from 'data/select-box/options';
import useContents from 'hooks/feature/content/useContents';
import usePoint from 'hooks/feature/point/usePoint';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useFilterStore from 'stores/filter';
import { Content, ScreenType } from 'types/feature/content/contents';
import { DisplayType, GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { WaitingList } from 'types/feature/screensaver/waitingList';
import { Point } from 'types/feature/workspace/workspace';
import { convertDateToString } from 'utils/date/dateUtils';
import { setTomorrow } from 'utils/date/setTomorrow';
import ScheduleAddModal from '../add-modal/ScheduleAddModal';
import ScheduleTable from './table/ScheduleTable';

const ScreensaverScheduleContainer = () => {
  const [points, setPoints] = useState<Point[]>([]);
  const [selectedPoint, setSelectedPoint] = useState<CommonOptionType>();
  const [screensavers, setScreensavers] = useState<GetScreensaver[]>([]);

  const [contents, setContents] = useState<WaitingList[]>([]);
  const [selectedContent, setSelectedContent] = useState<WaitingList | null>(contents?.[0] ?? null);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { getScreensavers } = useScreensaver();
  const { getContentsWithoutPage } = useContents();
  const { getMyPoints } = usePoint();

  const { workspaceId } = useParams();
  const location = useLocation();

  const filterStore = useFilterStore();

  // 포인트 목록, 스크린세이버 목록 조회
  const fetchPointsAndScreensavers = async (type: ScreenType) => {
    const points = await getMyPoints();
    if (points) {
      await fetchScreensavers(location.state?.pointId || points[0].id, type);

      setPoints(points);
      setSelectedPoint({
        value: points[0].id,
        label: points[0].name.ko,
        data: points[0],
      });
    }
  };

  // 스크린세이버 목록 조회
  const fetchScreensavers = async (pointId: string, type: ScreenType) => {
    try {
      const result = await getScreensavers(pointId, type);

      if (result) {
        setScreensavers(result);
        filterStore.setScheduleScreenType(type);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setAlertMessage('광고 스케줄을 불러올 수 없습니다.');
          setOpenAlert(true);
        }
      }
    }
  };

  // 포인트 변경
  const onChangePoint = async (option: CommonOptionType) => {
    setSelectedPoint(option);

    const point = option.data as Point;
    await fetchScreensavers(point.id, filterStore.scheduleScreenType);
  };

  // 가로/세로 변경 시
  const onChangeScreenType = async (option: OptionType) => {
    const screenType = option.value as ScreenType;
    filterStore.setScheduleScreenType(screenType);

    await fetchScreensavers(selectedPoint?.value || '', screenType);
  };

  // 콘텐츠 목록 조회
  const fetchContents = async (screenType: ScreenType) => {
    const result: Content[] | undefined = await getContentsWithoutPage(screenType);

    if (!result) return;

    const contents: WaitingList[] = result.map((content: Content) => {
      const { id, screenType, fileType, title, file } = content;

      return {
        id,
        screenType,
        fileType,
        title,
        displayType: DisplayType.ALWAYS_ON,
        startDate: convertDateToString(setTomorrow()),
        endDate: convertDateToString(setTomorrow()),
        file: { ...file },
      };
    });

    setContents(contents);
    setSelectedContent(contents[0]);
  };

  // 콘텐츠 클릭
  const handleClickItem = (content: WaitingList) => {
    setSelectedContent(content);
  };

  // 저장 완료 팝업
  const openDoneAlert = () => {
    setOpenAlert(true);
    setAlertMessage('광고 스케줄을 등록하였습니다.');
  };

  const findPathPoint = () => {
    if (!points) return;

    const findPoint = points.find(point => point.id === location.state?.pointId);

    if (findPoint) {
      setSelectedPoint({
        label: findPoint.name.ko,
        value: findPoint.id,
      });
    }
  };

  useEffect(() => {
    fetchPointsAndScreensavers('VERTICAL');
  }, [workspaceId, location.state]);

  useEffect(() => {
    findPathPoint();
  }, [location.state, points]);

  return (
    <>
      <div className='flex flex-col w-full gap-6'>
        <div className='flex justify-between w-full'>
          {/* 필터 */}
          <div className='flex gap-2.5'>
            {/* 빌딩 */}
            <CommonSelectBox
              width={160}
              options={points.map(point => ({
                value: point.id,
                label: point.name.ko,
                data: point,
              }))}
              onChangeSelect={onChangePoint}
              selected={{
                label: selectedPoint?.label || '',
                value: selectedPoint?.value || '',
                data: selectedPoint,
              }}
            />

            {/* 스크린 타입 */}
            <SelectBox
              width='w-[160px]'
              placeholder={SCREEN_TYPE[filterStore.scheduleScreenType]}
              options={[...SCREEN_TYPE_OPTIONS]}
              onChangeSelect={onChangeScreenType}
            />
          </div>

          {/* 스케쥴 추가 */}
          <Button onClick={() => setOpenAddModal(true)} textStyle={TextType.h5} size={96} color={ButtonColor.primary}>
            <div className='flex items-center justify-center h-4 gap-1 pl-2 pr-3'>
              <img src={ICON.PLUS_WHITE} alt='plus icon' />
              <span className={`${TextType.h5} text-gray-ea whitespace-nowrap`}>스케줄 추가</span>
            </div>
          </Button>
        </div>

        <ScheduleTable
          screensavers={screensavers}
          refetchScreensaver={fetchScreensavers}
          selectedPoint={selectedPoint}
        />
      </div>

      {openAddModal && (
        <ScheduleAddModal
          contents={contents}
          selectedContent={selectedContent}
          closeModal={() => setOpenAddModal(false)}
          fetchContents={fetchContents}
          handleClickItem={handleClickItem}
          fetchScreensavers={fetchScreensavers}
          openDoneAlert={openDoneAlert}
          onChangeScreenType={onChangeScreenType}
          points={points}
          onChangePoint={onChangePoint}
          selectedPoint={selectedPoint}
        />
      )}

      {openAlert && <AlertModal message={alertMessage} closeModal={() => setOpenAlert(false)} />}
    </>
  );
};
export default ScreensaverScheduleContainer;
