import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import LangTag from './tag/LangTag';
import useLanguage from 'hooks/feature/language/useLanguage';
import { ChangeEvent } from 'react';
import { CustomBoard } from 'types/feature/menu/customBoard';
import LoadingSpinner from 'components/common/loading/spinner/LoadingSpinner';

type Props = {
  customBoard: CustomBoard;
  setCustomBoard: React.Dispatch<React.SetStateAction<CustomBoard>>;
};

const MenuTitleInputs = ({ customBoard, setCustomBoard }: Props) => {
  const { langs } = useLanguage();

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setCustomBoard(prev => ({
      ...prev,
      title: {
        ...prev.title,
        [id.toLowerCase()]: value,
      },
    }));
  };

  return (
    <div className='flex-1'>
      <FormControl
        labelSize={168}
        name='타이틀'
        required
        control={
          <div className='flex flex-col gap-2'>
            {langs ? (
              langs.map(lang => {
                return (
                  <div key={lang.id} className='flex gap-2.5 items-center'>
                    <LangTag code={lang.code} isMain={lang.main} />

                    <Input
                      id={lang.code}
                      onChange={handleChangeInput}
                      size={360}
                      useWordLimit
                      placeholder='제목을 입력해주세요'
                      limit={lang.code === 'EN' ? 58 : 40}
                      value={customBoard.title[lang.code.toLowerCase()] || ''}
                    />
                  </div>
                );
              })
            ) : (
              <div className='h-[200px]'>
                <LoadingSpinner position='relative' />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};
export default MenuTitleInputs;
