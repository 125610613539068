import ReactWordcloud, { CallbacksProp, Scale, Word } from 'react-wordcloud';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import { divideIntoSixSteps } from 'utils/legend/makeSixStepsLegend';

// 그래프에서 사용할 색상값
export const WORD_COLORS: string[] = ['#D9F0F4', '#C8EAEF', '#B3E2E8', '#A2DCE3', '#92D5DE', '#8CCCD5'];

const CustomWordCloud = ({
  data,
  maxCount,
  height = '100%',
  scale = 'sqrt',
}: {
  data: Word[];
  maxCount: number;
  height?: string;
  scale?: Scale;
}) => {
  // 콜백 함수
  const callbacks: CallbacksProp = {
    getWordColor: (word: Word) => {
      const value = word.value;
      const steps: number[] = divideIntoSixSteps(maxCount);

      if (value <= steps[0]) {
        return WORD_COLORS[0];
      } else if (steps[0] < value && value <= steps[1]) {
        return WORD_COLORS[1];
      } else if (steps[1] < value && value <= steps[2]) {
        return WORD_COLORS[2];
      } else if (steps[2] < value && value <= steps[3]) {
        return WORD_COLORS[3];
      } else if (steps[3] < value && value <= steps[4]) {
        return WORD_COLORS[4];
      } else if (steps[4] < value && value <= steps[5]) {
        return WORD_COLORS[5];
      } else {
        return WORD_COLORS[5];
      }
    },
  };
  return (
    <div
      style={{
        height,
      }}
      className='w-full'
    >
      <ReactWordcloud
        words={data}
        callbacks={callbacks}
        options={{
          enableTooltip: false,
          deterministic: false, // 새로운 모양 생성 여부
          fontFamily: 'pretendard',
          fontSizes: [12, 54], // 최소, 최대
          fontStyle: 'normal',
          fontWeight: 'normal',
          scale: scale, // 단어 간격과 배치 방법 ('linear' | 'log' | 'sqrt')
          spiral: 'archimedean', // 단어 배치 방법 (나선형/직사각형)
          transitionDuration: 1000,
          rotations: 0,
        }}
      />
    </div>
  );
};
export default CustomWordCloud;
