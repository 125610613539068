import FormControl from 'components/common/form-control/FormControl';
import Title, { TextType } from 'components/common/title/Title';
import { FLEX_CENTER } from 'styles/flex';
import { Language, MultiLangs } from 'types/feature/multi-lang/language';
import { Tenant } from 'types/feature/tenant/tenant';

type Props = {
  tenant: Tenant;
  languageList: Language[];
  mainLanguage: string;
  pointName: MultiLangs;
};

const PoiInfo = ({ tenant, languageList, mainLanguage, pointName }: Props) => {
  return (
    <div className='flex flex-col gap-3 px-10'>
      <Title text='지도 정보' textType={TextType.h3_bold} />

      <div>
        {/* 타이틀 */}
        <FormControl
          direction='row'
          labelSize={80}
          labelColor='text-gray-77'
          labelStyle='text-[13px] font-bold'
          name='타이틀'
          control={
            <div className={`flex flex-col`}>
              {languageList.map((lang, langIndex) => {
                return (
                  <div key={langIndex} className='flex items-center gap-2 h-9'>
                    <div className={`${FLEX_CENTER.row} bg-[#999] text-white w-[22px] text-[11px]`}>{lang.code}</div>
                    <div className={`text-gray-77 text-[14px] font-medium`}>
                      {tenant?.content.name[lang.code.toLowerCase()] || '-'}
                    </div>
                  </div>
                );
              })}
            </div>
          }
        />

        {/* 위치 */}
        <FormControl
          labelSize={80}
          labelColor='text-gray-77'
          labelStyle='text-[13px] font-bold'
          name='위치'
          control={
            <div className='flex items-center gap-2.5 text-gray-77 text-[14px] font-medium'>
              <span className='text-inherit'>{pointName.ko}</span>
              <div className='w-[1px] h-[8px] bg-[#ccc]' />
              <span className='text-inherit'>{tenant?.content.floor.name[mainLanguage.toLowerCase()]}</span>
            </div>
          }
        />

        {/* Content Id */}
        <FormControl
          labelSize={80}
          labelColor='text-gray-77'
          labelStyle='text-[13px] font-bold'
          name='Code'
          control={<div className={`${TextType.body2} text-gray-99`}>{tenant?.contentId}</div>}
        />
      </div>
    </div>
  );
};

export default PoiInfo;
