import CustomWordCloud from 'components/common/graph/word-cloud/CustomWordCloud';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useEffect } from 'react';
import { Word } from 'react-wordcloud';
import { RoutePath } from 'types/common/paths';
import DashboardSection from '../section/DashboardSection';
import { CategoryUsage } from 'types/feature/statistics/categoryUsage';

const DashboardPopularCategories = (props: {
  selectedPoint: CommonOptionType;
  categoryUsage: CategoryUsage[];
  loading: boolean;
  error: boolean;
  fetchCategoryUsage: (pointId: string) => Promise<void>;
}) => {
  const { customNavigate } = useCustomNavigate();

  const data: Word[] = props.categoryUsage.slice(0, 10).map(usage => ({
    ...usage,
    text: usage.tenantCategoryGroupName.ko,
    value: usage.count,
  }));
  const totalArr = props.categoryUsage.map(item => item.count); // 데이터의 max 값을 구한다.
  const maxCount: number = Math.max(...totalArr); // 데이터 최대값

  useEffect(() => {
    props.fetchCategoryUsage('');
  }, []);

  return (
    <DashboardSection
      title='인기 카테고리 TOP 10'
      width='500px'
      gap='24px'
      onClickMore={() => {
        customNavigate(RoutePath.categoryUsageStats, '', '', '', {
          state: {
            pointId: props.selectedPoint.value,
            pointName: props.selectedPoint.label,
          },
        });
      }}
      loading={props.loading}
      error={props.error}
      onRetry={async () => {
        await props.fetchCategoryUsage(props.selectedPoint.value);
      }}
      noData={props.categoryUsage.length < 1}
    >
      <div className='flex gap-[60px] h-[202px] w-full p-6'>
        <CustomWordCloud data={data} maxCount={maxCount} height='202px' scale='sqrt' />
      </div>
    </DashboardSection>
  );
};
export default DashboardPopularCategories;
