import NEXT_BTN from 'assets/icon/button/next-btn.svg';
import PREV_BTN from 'assets/icon/button/prev-btn.svg';
import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import LoadingSpinner from 'components/common/loading/spinner/LoadingSpinner';
import { TextType } from 'components/common/title/Title';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import useEvent from 'hooks/feature/plan/event/useEvent';
import usePlan from 'hooks/feature/plan/usePlan';
import { Fragment, useEffect, useState } from 'react';
import { EventData, HallData, HallWithPosition } from 'types/feature/plan/event';
import { Plan } from 'types/feature/plan/plan';
import HallEventItem from './event/HallEventItem';
import { EVENT_COLORS } from './resource/colors';
import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import { ICON } from 'constants/icons';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from 'data/common';

dayjs.locale('ko');

const HallContainer = () => {
  const [firstYearAndMonth, setFirstYearAndMonth] = useState<string>('');
  const [secondYearAndMonth, setSecondYearAndMonth] = useState<string>('');

  // ! MVP 미팅용 날짜 세팅 (@2024.05.13)
  const TEMP_TODAY = '2025-05-05';
  const today = dayjs(TEMP_TODAY);
  const [thisMonday, setThisMonday] = useState(today.startOf('week').day(1));
  const [weekDates, setWeekDates] = useState<string[]>([]);

  const [plan, setPlan] = useState<Plan>();
  // server 에서 내려주는 데이터
  const [hallData, setHallData] = useState<HallData[]>();
  // 정제한 데이터
  const [hallWithPosition, setHallWithPosition] = useState<HallWithPosition[]>([]);
  // eventId 별 색상 매핑한 Map 자료구조 생성
  const [eventColorMap, setEventColorMap] = useState(new Map());

  const [isEventLoading, setEventLoading] = useState<boolean>(true);
  const [isPlanLoading, setPlanLoading] = useState<boolean>(true);

  const [isOpenAlert, setOpenAlert] = useState<boolean>(false);
  const [isOpenShiftConfirm, setOpenShiftConfirm] = useState<boolean>(false);
  const [isOpenResetConfirm, setOpenResetConfirm] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const [enableReset, setEnableReset] = useState<boolean>(true);
  const [enableShift, setEnableShift] = useState<boolean>(true);

  const { getPlans, postShiftEvent, postResetPlan } = usePlan();
  const { getEvents } = useEvent();

  // 이벤트 목록 조회
  const fetchEvents = async (planId: string, monday: Dayjs) => {
    try {
      setEventLoading(true);

      const startDate = monday.format(SERVER_DATE_FORMAT);
      const endDate = monday.add(6, 'day').format(SERVER_DATE_FORMAT);

      // TODO: startDate, endDate 포맷 validate
      const events = await getEvents(planId, startDate, endDate);
      setHallData(events);

      setEventLoading(false);
    } catch (error) {
      handleAxiosError(error);
    }
  };

  // 플랜 조회
  const fetchPlans = async () => {
    try {
      setPlanLoading(true);
      const plans = await getPlans();

      if (plans) {
        // TODO: 플랜 변경 기능 필요
        setPlan(plans[0]);

        const monday = today.startOf('week').day(1);
        fetchEvents(plans[0].id, monday);
      }

      setPlanLoading(false);
    } catch (error) {
      handleAxiosError(error);
    }
  };

  function handleAxiosError(error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 400) {
        setEventLoading(false);
        setPlanLoading(false);
        setOpenAlert(true);
        setMessage('플랜을 불러올 수 없습니다.');

        // ! MVP 용
        setEnableReset(false);
        setEnableShift(false);
      }
    }
  }

  // 년도, 월
  const setYearAndMonth = () => {
    const firstYear = thisMonday.year();
    const firstMonth = thisMonday.month() + 1;

    const secondYear = thisMonday.add(6, 'day').year();
    const secondMonth = thisMonday.add(6, 'day').month() + 1;

    // 첫번째랑 두번째랑 년도가 다를 경우
    if (firstYear !== secondYear) {
      setFirstYearAndMonth(`${firstYear}년 ${firstMonth}월`);
      setSecondYearAndMonth(`${secondYear}년 ${secondMonth}월`);
    }
    // 년도가 같을 경우
    else {
      // 첫번째랑 두번째랑 월이 같을 경우
      if (firstMonth === secondMonth) {
        setFirstYearAndMonth(`${firstYear}년 ${firstMonth}월`);
        setSecondYearAndMonth('');
      }
      // 첫번째랑 두번째랑 월이 다를 경우
      else {
        setFirstYearAndMonth(`${firstYear}년 ${firstMonth}월`);
        setSecondYearAndMonth(`${secondMonth}월`);
      }
    }
  };

  // 일주일치 날짜 배열
  const handleWeekDates = () => {
    let weekDates = [];
    for (let i = 0; i < 7; i++) {
      weekDates.push(thisMonday.add(i, 'day').format(DATE_FORMAT));
    }

    setWeekDates(weekDates);

    return weekDates;
  };

  // 이전
  const onClickPrev = async () => {
    // 일주일 전 월요일
    const oneWeekAgoMonday = thisMonday.subtract(1, 'week');
    setThisMonday(oneWeekAgoMonday);

    await fetchEvents(plan?.id || '', oneWeekAgoMonday);
  };

  // 다음
  const onClickNext = async () => {
    // 일주일 후 월요일
    const oneWeekAfterMonday = thisMonday.add(1, 'week');
    setThisMonday(oneWeekAfterMonday);

    await fetchEvents(plan?.id || '', oneWeekAfterMonday);
  };

  // 이번주
  const onClickThisWeek = async () => {
    const monday = today.startOf('week').day(1);
    setThisMonday(monday);

    await fetchEvents(plan?.id || '', monday);
  };

  /**
   * 현재 일주일의 데이터를 기준으로, hall_data 내 position 구하기
   */
  function findEventPositions(weekArray: string[], hallData: HallData[]): HallWithPosition[] {
    const halls: HallWithPosition[] = hallData.map(hall => ({
      ...hall,
      events: hall.events.map((event: EventData) => {
        const start = dayjs(event.startDate);
        const end = dayjs(event.endDate);

        const offset = end.diff(start, 'day');
        const startIndex = weekArray.indexOf(start.format(DATE_FORMAT));
        const endIndex = weekArray.indexOf(end.format(DATE_FORMAT));

        return {
          ...event,
          // start: startIndex >= 0 ? startIndex + 1 : -1, // 0부터 시작하는 index를 +1 (0으로 진행해도 O)
          // end: endIndex >= 0 ? endIndex + 1 : -1,

          gap: offset,
          start: startIndex,
          end: endIndex,
        };
      }),
    }));
    return halls;
  }

  // TODO: 테스트코드 작성
  function makeColorMap(halls: HallData[]) {
    halls.forEach(hall => {
      hall.events.forEach(event => {
        const findColor = eventColorMap.get(event.id);

        if (!findColor) {
          // 색상의 index 에 접근
          const color = EVENT_COLORS[eventColorMap.size % EVENT_COLORS.length];
          eventColorMap.set(event.id, color);
          setEventColorMap(eventColorMap);
        }
      });
    });
  }

  const handleHallEvents = () => {
    if (hallData) {
      const halls = findEventPositions(weekDates, hallData);
      makeColorMap(hallData);
      setHallWithPosition(halls);
    }
  };

  // ! MVP 용으로 plan을 처음으로 돌린다.
  const resetPlan = async () => {
    try {
      await postResetPlan(plan?.id || '');
      await fetchEvents(plan?.id || '', thisMonday);

      setOpenResetConfirm(false);
      setOpenAlert(true);
      setMessage('전시 스케쥴을 처음으로 리셋하였습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setEventLoading(false);
          setPlanLoading(false);

          setOpenResetConfirm(false);
          setOpenAlert(true);
          setMessage('전시 스케쥴을 리셋할 수 없습니다.');
        }
      }
    }
  };

  // ! MVP 용으로 다음 event 로 이동한다.
  const shiftEvent = async () => {
    try {
      await postShiftEvent(plan?.id || '');
      await fetchEvents(plan?.id || '', thisMonday);

      setOpenShiftConfirm(false);
      setOpenAlert(true);
      setMessage('전시 스케쥴을 다음으로 변경하였습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setEventLoading(false);
          setPlanLoading(false);

          setOpenShiftConfirm(false);
          setOpenAlert(true);
          setMessage('전시 스케쥴을 변경할 수 없습니다.');
        }
      }
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    setYearAndMonth();
  }, [today]);

  useEffect(() => {
    handleWeekDates();
  }, [thisMonday]);

  useEffect(() => {
    handleHallEvents();
  }, [weekDates, hallData]);

  return (
    <div className='flex flex-col w-[1400px] items-center py-10 gap-5'>
      {isOpenAlert && <AlertModal message={message} closeModal={() => setOpenAlert(false)} />}

      {/* header */}
      <div className='flex justify-between w-full h-9'>
        {/* 년도, 월 */}
        <div className={`${TextType.h2} cursor-default flex gap-3 items-center`}>
          <div>{firstYearAndMonth}</div>
          {secondYearAndMonth && (
            <>
              <div className='w-[1px] h-[16px] bg-gray-99' />
              <div>{secondYearAndMonth}</div>
            </>
          )}
        </div>

        <div className='flex gap-5'>
          {/* // ! 전시 스케쥴 리셋 (임시) */}
          <Button
            onClick={() => {
              setOpenResetConfirm(true);
              setMessage(`전시 스케쥴을 리셋하시겠습니까?\n이 작업은 되돌릴 수 없습니다.`);
            }}
            color={ButtonColor.secondary}
            text='전시 스케쥴 리셋'
            size='default'
            iconPath={enableReset ? ICON.REFRESH_BK : ICON.REFRESH_GRAY}
            disabled={!enableReset}
          />

          {isOpenResetConfirm && (
            <ConfirmModal message={message} onClickOk={resetPlan} onClickCancel={() => setOpenResetConfirm(false)} />
          )}

          {/* // ! 다음 전시 스케쥴로 변경 (임시) */}
          <Button
            onClick={() => {
              setOpenShiftConfirm(true);
              setMessage(`다음 전시 스케쥴로 변경하시겠습니까?\n이 작업은 되돌릴 수 없습니다.`);
            }}
            color={ButtonColor.delete}
            text='다음 전시 스케쥴로 변경'
            size='default'
            disabled={!enableShift}
          />

          {isOpenShiftConfirm && (
            <ConfirmModal message={message} onClickOk={shiftEvent} onClickCancel={() => setOpenShiftConfirm(false)} />
          )}

          {/* 이번주 버튼 */}
          <Button
            onClick={onClickThisWeek}
            disabled={
              // 월요일이 이번주 월요일과 같다면 버튼 비활성
              thisMonday.format(DATE_FORMAT) === today.startOf('week').day(1).format(DATE_FORMAT) ||
              isEventLoading ||
              isPlanLoading
            }
            color={ButtonColor.point}
            text='이번주'
            size={80}
          />

          <div className='flex gap-2'>
            {/* 이전 버튼 */}
            <button
              disabled={isEventLoading || isPlanLoading}
              onClick={onClickPrev}
              className='w-[36px] h-[36px] border flex items-center justify-center cursor-pointer'
            >
              <img src={PREV_BTN} alt='이전 버튼' />
            </button>

            {/* 다음 버튼 */}
            <button
              disabled={isEventLoading || isPlanLoading}
              onClick={onClickNext}
              className='w-[36px] h-[36px] border flex items-center justify-center cursor-pointer'
            >
              <img src={NEXT_BTN} alt='다음 버튼' />
            </button>
          </div>
        </div>
      </div>

      {/* body */}
      <div className='flex'>
        {/* left-side */}
        <div className='flex flex-col h-[570px] pt-[90px] bg-bg-f1 w-[100px]'>
          {isPlanLoading ? (
            <div className='h-[570px w-[100px]'></div>
          ) : (
            <>
              {hallData?.map(hall => (
                <div
                  key={hall.hall.code}
                  className={`${TextType.h4_bold} w-[100px] h-[120px] flex flex-col items-center justify-center cursor-default `}
                >
                  {/* 층 이름 */}
                  {/* <div className='text-inherit'>1F</div> */}
                  {/* Hall 이름 */}
                  <div className='text-inherit text-gray-77'>{hall.hall.name}</div>
                </div>
              ))}
            </>
          )}
        </div>

        {/* table */}
        <div className='w-[1300px] bg-white h-[570px] flex items-center flex-col relative pb-4'>
          {/* 날짜 */}
          <div className='relative grid h-[90px] grid-cols-7 cursor-default px-5'>
            {weekDates.map((date: string) => {
              const splitDate = date.split('-'); // ['2024', '4', '22', '월']
              const isToday = date === dayjs(TEMP_TODAY).format(DATE_FORMAT);
              // 오늘이라면 색을 변경해준다.
              const dateColor = isToday ? 'text-primary-DA_Blue_light' : 'text-gray-77';

              return (
                <div
                  key={date}
                  className={`${
                    isToday ? 'bg-[#F6F8FF]' : 'bg-transparent'
                  } h-[90px] w-[180px] flex flex-col items-center justify-center`}
                >
                  {/* 날짜 */}
                  <div className={`${TextType.h2} ${dateColor}`}>{splitDate[2]}</div>
                  {/* 요일 */}
                  <div className={`${TextType.h5} ${dateColor}`}>{splitDate[3]}</div>
                </div>
              );
            })}
          </div>

          <Border borderColor={BorderColor.light_gray} position='absolute top-[90px] left-0' />

          <div className='w-[1260px] h-full'>
            {isEventLoading ? (
              <LoadingSpinner position='relative' message='플랜을 불러오는 중 입니다.' />
            ) : (
              <>
                {/* 이벤트 - client data */}
                {hallWithPosition?.map((hall, hallIndex) => {
                  return (
                    <div key={hall.hall.code} className='relative grid grid-cols-7'>
                      {/* row 생성 */}
                      {weekDates.map((date, i) => {
                        const isToday = date === dayjs(TEMP_TODAY).format(DATE_FORMAT);

                        return (
                          <div
                            key={i}
                            className={`h-[120px] ${isToday ? 'bg-[#F6F8FF]' : 'bg-transparent'} border-gray-ea ${
                              i !== 6 && 'border-r'
                            } 
                            `}
                          ></div>
                        );
                      })}

                      {/* 이벤트를 row 에 부착 - client data  */}
                      {hall.events.map(event => {
                        const findColor = eventColorMap.get(event.id);

                        return (
                          <Fragment key={event.id}>
                            <HallEventItem event={event} eventColor={findColor} />
                          </Fragment>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HallContainer;
