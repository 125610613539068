import { TextType } from 'components/common/title/Title';
import { useEffect, useState } from 'react';
import { EventWithPosition } from 'types/feature/plan/event';
import Now from './now/Now';

type Props = {
  event: EventWithPosition;
  eventColor: string;
};

type EventType = 'IN' | 'BEFORE' | 'AFTER' | 'FULL';

const HallEventItem = ({ event, event: { start, end, gap, active }, eventColor }: Props) => {
  const [type, setType] = useState<EventType>('IN');

  useEffect(() => {
    if (start < 0 && end > 0) {
      setType('BEFORE');
    } else if (end < 0 && start > 0) {
      setType('AFTER');
    } else if (gap > 7) {
      setType('FULL');
    } else {
      setType('IN');
    }

    // else if (start < 1 && end < 1 && gap <= 7) {
    // setType('OUTSIDE');
    // }
  }, [start, end, gap]);

  return (
    <>
      {
        // BEFORE && AFTER
        type === 'FULL' ? (
          <div
            key={event.name}
            className={`
            absolute flex items-center justify-center 
            ${TextType.h3} text-gray-44 text-sm text-center
            px-6 cursor-default
            border-t  border-b 
            ${eventColor} 
            ${active && '!border-primary-DA_Red gap-2'}
          `}
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 8,
              width: 'calc(100% - 8px)',
              height: '80px',
              top: '20px',
              left: '4px',
              zIndex: 10,
            }}
          >
            {active && <Now />}
            <span className={`${active && 'text-primary-DA_Red'}`}>{event.name}</span>
          </div>
        ) : type === 'BEFORE' ? (
          <div
            key={event.name}
            className={`
            absolute flex items-center justify-center 
            ${TextType.h3} text-gray-44 text-sm text-center
            px-6 cursor-default
            border-t border-r border-b rounded-r-xl rounded-b-xl rounded-l-none
            ${eventColor} 
            ${active && '!border-primary-DA_Red gap-2'}
            `}
            style={{
              gridColumnStart: 1,
              gridColumnEnd: Number(event.end) + 2,
              width: 'calc(100% - 8px)',
              height: '80px',
              top: '20px',
              left: '4px',
              zIndex: 10,
            }}
          >
            {active && <Now />}
            <span className={`${active && 'text-primary-DA_Red'}`}>{event.name}</span>
          </div>
        ) : type === 'AFTER' ? (
          <div
            key={event.name}
            className={`
            absolute flex items-center justify-center 
            ${TextType.h3} text-gray-44 text-sm text-center
            px-6 cursor-default
            border-t border-l border-b rounded-l-xl rounded-b-xl rounded-r-none
            ${eventColor} 
            ${active && '!border-primary-DA_Red gap-2'}
            `}
            style={{
              gridColumnStart: Number(event.start) + 1,
              gridColumnEnd: 8,
              width: 'calc(100% - 8px)',
              height: '80px',
              top: '20px',
              left: '4px',
              zIndex: 10,
            }}
          >
            {active && <Now />}
            <span className={`${active && 'text-primary-DA_Red'}`}>{event.name}</span>
          </div>
        ) : type === 'IN' ? (
          <div
            key={event.name}
            className={`
            absolute flex items-center justify-center 
            ${TextType.h3} text-gray-44 text-sm text-center
            border rounded-xl 
            px-6 cursor-default
            ${eventColor} 
            ${active && '!border-primary-DA_Red gap-2'}
            `}
            style={{
              gridColumnStart: Number(event.start) + 1,
              gridColumnEnd: Number(event.end) + 2,
              width: 'calc(100% - 8px)',
              height: '80px',
              top: '20px',
              left: '4px',
              zIndex: 10,
            }}
          >
            {active && <Now />}
            <span className={`${active && 'text-primary-DA_Red'}`}>{event.name}</span>
          </div>
        ) : (
          <></>
        )
      }
    </>
  );
};
export default HallEventItem;
