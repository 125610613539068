import React from 'react';

type Props = {
  children: React.ReactElement;
};

const FormLayout = ({ children }: Props) => {
  return (
    <div className='w-full h-full p-10 bg-white border'>
      <div>{children}</div>
    </div>
  );
};
export default FormLayout;
