import React from 'react';
import { TextType } from '../title/Title';

type Props = {
  name: string;
  disabled?: boolean;
  required?: boolean;
  control: React.ReactElement;
  direction?: 'column' | 'row';
  gap?: string;
  controlWidth?: string;
  labelSize?: 'none' | 80 | 100 | 168 | 200;
  labelStyle?: TextType | string;
  labelColor?: string;
};

const LabelSize = {
  none: '',
  80: 'w-[80px]',
  100: 'w-[100px]',
  168: 'w-[168px]',
  200: 'w-[200px]',
};

const FormControl = ({
  name,
  direction,
  disabled,
  control,
  required,
  gap = 'gap-2.5',
  controlWidth,
  labelSize = 200,
  labelStyle = TextType.h4,
  labelColor = 'text-gray-44',
}: Props) => {
  return (
    <div
      className={`
    flex 
    ${direction === 'column' ? 'flex-col items-center justify-center' : 'items-start justify-center'}
    ${gap}
    ${disabled && 'opacity-50'} 
    `}
    >
      {/* 타이틀 */}
      <div className={`${LabelSize[labelSize]} shrink-0 flex h-9 items-center justify-start gap-1`}>
        <span className={`${labelStyle} ${labelColor}`}>{name}</span>
        {required && <span className='pt-1 text-base text-primary-DA_Red'>*</span>}
      </div>

      {/* 내용 */}
      <div className={`flex flex-col justify-center min-h-[36px] flex-1 shrink-0 ${controlWidth}`}>{control}</div>
    </div>
  );
};

export default FormControl;
