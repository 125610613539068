import React from 'react';

const TableTotal = ({ totalCount }: { totalCount: number }) => {
  return (
    <div className='flex w-full gap-1'>
      <div className='text-sm font-bold text-gray-44'>Total</div>
      <div className='text-sm font-bold text-primary-DA_Blue_light'>{totalCount.toLocaleString()}</div>
    </div>
  );
};
export default TableTotal;
