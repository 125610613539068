import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GlobalMenu } from 'types/common/globalMenu';
import { hasCommonElement } from 'utils/search/hasCommonElement';
import SubMenus from './subMenus/SubMenus';
import { TextType } from 'components/common/title/Title';

type Props = {
  menu: GlobalMenu;
};

const MenuItem = ({ menu, menu: { id, path, subMenus } }: Props) => {
  const { navigateToReplacedPath } = useCustomNavigate();
  const location = useLocation();
  const { workspaceId } = useParams();

  const [showSubMenus, setShowSubMenus] = useState(false);
  const [isMainActive, setMainActive] = useState(false);

  const onClickMenu = (menu: GlobalMenu) => {
    if (workspaceId) {
      // 서브 메뉴가 있다면 첫번째 경로로 이동
      if (menu.subMenus && menu.subMenus.length > 0) {
        return navigateToReplacedPath(menu.subMenus[0].path, workspaceId);
      }

      // 나머지 경로 이동
      navigateToReplacedPath(menu.path, workspaceId);
    }
  };

  const openSubMenus = () => {
    setShowSubMenus(true);
  };

  const closeSubMenus = () => {
    setShowSubMenus(false);
  };

  //  메인메뉴 active 변경
  const changeMenuActive = () => {
    const locationArr: string[] = location.pathname.split('/').filter(el => el);
    const pathArr: string[] = path.split('/');

    if (hasCommonElement(locationArr, pathArr)) {
      setMainActive(true);
    } else {
      setMainActive(false);
    }
  };

  useEffect(() => {
    changeMenuActive();
  }, [location.pathname]);

  return (
    <div className='relative'>
      {/* 메인 메뉴 */}
      <div
        className={`flex items-center ${
          isMainActive ? `${TextType.h3_bold} text-gray-22` : `${TextType.h3} text-gray-99`
        } cursor-pointer h-20 whitespace-nowrap hover:font-h3_bold text-center mt-0.5`}
        onMouseOver={subMenus && openSubMenus}
        onMouseLeave={subMenus && closeSubMenus}
        onClick={onClickMenu.bind(this, menu)}
      >
        {id}
      </div>
      {/* 서브 메뉴 */}
      {showSubMenus && subMenus && <SubMenus menus={subMenus} setShowSubMenus={setShowSubMenus} />}
    </div>
  );
};

export default MenuItem;
