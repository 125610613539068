import { ICON } from 'constants/icons';
import React from 'react';

type Props = {
  deleteFile: () => void;
};

const FileDeleteButton = ({ deleteFile }: Props) => {
  return (
    <div className='cursor-pointer' onClick={deleteFile}>
      <img src={ICON.CLOSE_BUTTON} alt='delete button' />
    </div>
  );
};
export default FileDeleteButton;
