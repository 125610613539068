import React from 'react';
import { TextType } from '../title/Title';

type Props = {
  message: string;
  colSpan: number;
};

const TableNoContent = ({ message, colSpan }: Props) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className={`${TextType.h4} flex justify-center w-full h-full py-20 text-gray-cc`}>{message}</div>
      </td>
    </tr>
  );
};
export default TableNoContent;
