import { TableHeader } from 'types/common/tableHeader';

export const TIME_USAGE_TABLE_HEADER: TableHeader = [
  {
    label: '시간',
    width: 140,
    direction: 'left',
  },
  {
    label: '키오스크 사용량',
    width: 400,
    direction: 'left',
  },
  {
    label: '모바일 사용량',
    width: 400,
    direction: 'left',
  },
  {
    label: '전체 사용량',
    width: 240,
    direction: 'left',
  },
  {
    label: '비율',
    width: 140,
    direction: 'left',
  },
];
