import { Column } from '@ant-design/plots';
import { TextType } from 'components/common/title/Title';
import { TotalUsagePerDate } from 'types/feature/statistics/totalUsage';
import TotalUsageGraphLegend from '../legend/TotalUsageGraphLegend';
import { convertServerDateToClientDate } from 'utils/date/dateUtils';

type Props = {
  totalUsageList: TotalUsagePerDate[];
};

const DateUsageGraph = ({ totalUsageList }: Props) => {
  const machineData = totalUsageList.map(data => {
    return {
      date: convertServerDateToClientDate(data.date),
      value: data.machineCount,
      total: data.totalCount,
      type: 'MACHINE',
    };
  });

  const mobileData = totalUsageList.map(data => {
    return {
      date: convertServerDateToClientDate(data.date),
      value: data.mobileCount,
      total: data.totalCount,
      type: 'MOBILE',
    };
  });

  type TooltipItem = { value: number; color: string; name: 'MACHINE' | 'MOBILE' };

  const chartData = machineData.concat(mobileData);

  // 데이터의 totalCount 들의 max 값을 구한다.
  const totalArr = chartData.map(item => item.total);
  const max = Math.max(...totalArr);

  const config = {
    data: chartData,
    xField: 'date', // 가로축 데이터
    yField: 'value', // 세로축 데이터
    colorField: 'type', // 색상값 (필수)
    stack: true, // 데이터 쌓아서 보여줄지 유무
    legend: false, // 상단 범례 유무

    // 범위
    scale: {
      x: {
        align: 0.5, // 그래프 중앙 정렬
      },
      y: {
        domainMin: 0,
        domainMax: max > 5 ? max : 5, // 5 이하부터 y축에 소수점이 노출되므로 눈금의 최대값을 지정해준다.
        tickCount: 5, // 원하는 눈금 개수 설정
        nice: true, // 눈금을 적절하게 분배하도록 설정
      },
    },

    // 축 스타일링
    axis: {
      x: {
        style: {
          // 축
          line: true,

          // 눈금
          tick: true,
          tickLineWidth: 12,
          tickStroke: '#fff',

          // 라벨
          labelFontSize: 12,
          labelFontWeight: 700,
          labelFill: '#BFBFBF',
          labelTransform: 'rotate(0)',
        },
      },
      y: {
        labelFormatter: (val: string) => `${val.toLocaleString()}`, // 1,000

        style: {
          // 눈금
          tick: true,
          tickLineWidth: 10,
          tickStroke: '#fff',

          // 라벨
          labelFontSize: 12,
          labelFontWeight: 400,
          labelFill: '#888',

          // 그리드
          grid: true,
          gridLineDash: [0, 0],
        },
      },
    },

    // 그래프 스타일링
    style: {
      // 그래프 두계
      maxWidth: 12,

      // 색상
      fill: (data: { date: string; type: 'MACHINE' | 'MOBILE'; value: number }) => {
        // 키오스크
        if (data.type === 'MACHINE') {
          return '#00A472';
        }

        // 모바일
        return '#9CE1CC';
      },
    },

    interaction: {
      // TODO: backgroundColor: '#EBFAF4'
      // hover 시 배경 색 변경
      elementHighlightByColor: { background: true },

      // 툴팁
      tooltip: {
        shared: true,
        outerWidth: 88,
        innerWidth: 68,
        outerHeight: 84,
        innerHeight: 64,
        render: (e: any, { title, items }: { title: string; items: TooltipItem[] }) => {
          const totalCount = items[0].value + items[1].value;

          return (
            <div className='flex flex-col justify-between gap-2'>
              {/* 기기 사용량 + 모바일 사용량 합계 */}
              <div key={title} className={`${TextType.h4_bold} text-gray-22 h-[18px]`}>
                {totalCount.toLocaleString()}
              </div>

              <div className='flex flex-col gap-1'>
                {items.map((item: TooltipItem) => {
                  const { name, value } = item;
                  return (
                    <div key={item.name} className='flex items-center gap-1 h-[18px]'>
                      {/* 기기 종류 */}
                      <div
                        className={`${TextType.h5_bold}`}
                        style={{
                          color: name === 'MACHINE' ? '#00A472' : '#9CE1CC',
                        }}
                      >
                        {name === 'MACHINE' ? 'Kiosk' : 'Mobile'}
                      </div>

                      {/* 수치 */}
                      <div className='font-sans text-gray-44 font-normal text-xs leading-[18px]'>
                        {value.toLocaleString()}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        },
      },
    },
  };

  return (
    <div className='flex flex-col h-[414px] w-full p-10 bg-white border border-gray-ea gap-10'>
      <TotalUsageGraphLegend title='일자별 사용량' />

      <div className='h-[274px]'>
        <Column {...config} />
      </div>
    </div>
  );
};
export default DateUsageGraph;
