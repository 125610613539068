import { FileType } from 'types/common/file/file';

export type ContentsByPage = {
  content: Content[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  number: number;
  numberOfElements: number;
  size: number;
  empty: boolean;
};

export type Content = {
  id: string;
  title: string;
  type: ContentType;
  fileType: FileType;
  screenType: ScreenType;
  updatedDate: string;
  file: ContentFile;
};

export type ContentFile = {
  uri: string;
  originalName: string;
  size: number;
  width: number;
  height: number;
  name: string;
};

type Pageable = {
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
};

export type UploadFile = {
  fileName: string;
  fileUri: string;
};

type ContentType = 'KIOSK' | 'MOBILE';

// export enum ContentTypeCode {
//   IMAGE = 'CONTENT_IMAGE',
//   VIDEO = 'CONTENT_VIDEO',
// }

// export type FileType = keyof typeof ContentTypeCode;

export enum ContentTypeCodeKo {
  IMAGE = '이미지',
  VIDEO = '동영상',
}

export enum ScreenTypeCode {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  ETC = 'ETC',
}

// export enum ScreenTypeCodeKo {
//   VERTICAL = '세로형',
//   HORIZONTAL = '가로형',
//   ETC = '기타',
// }

export type ScreenType = 'VERTICAL' | 'HORIZONTAL' | 'ETC';

export enum ContentForm {
  screenType = 'screenType',
  fileType = 'fileType',
  file = 'file',
}

// export const ContentFileUploadType: IndexType = {
//   IMAGE: 'CONTENT_IMAGE',
//   VIDEO: 'CONTENT_VIDEO',
// };
