import { create } from 'zustand';

type AccountProps = {
  accountId: string;
  setAccountId: (accountId: string) => void;
};

const useAccountStore = create<AccountProps>((set, get) => ({
  accountId: '',
  setAccountId: (accountId: string) => set(() => ({ accountId })),
}));

export default useAccountStore;
