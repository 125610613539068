import { Venn } from '@ant-design/plots';
import Legend from 'components/common/graph/legend/Legend';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useEffect } from 'react';
import { RoutePath } from 'types/common/paths';
import DashboardSection from '../section/DashboardSection';
import { vennConfig } from './vennConfig';
import { DashBoardTotalUsage } from 'types/feature/dashboard/dashboard';

const DashboardTotalUsage = (props: {
  selectedPoint: CommonOptionType;
  totalUsage: DashBoardTotalUsage;
  loading: boolean;
  error: boolean;
  fetchYesterdayUsage: (pointId: string) => Promise<void>;
}) => {
  const { customNavigate } = useCustomNavigate();

  useEffect(() => {
    props.fetchYesterdayUsage('');
  }, []);

  const machineSize = props.totalUsage.machine;
  const mobileSize = props.totalUsage.mobile;

  return (
    <DashboardSection
      title='전체 사용량'
      width='500px'
      height='358px'
      gap='24px'
      onClickMore={() => {
        customNavigate(RoutePath.totalUsageStats, '', '', '', {
          state: {
            pointId: props.selectedPoint.value,
            pointName: props.selectedPoint.label,
          },
        });
      }}
      loading={props.loading}
      error={props.error}
      onRetry={async () => {
        await props.fetchYesterdayUsage(props.selectedPoint.value);
      }}
      noData={machineSize === 0 && mobileSize === 0}
    >
      <div className='flex justify-between w-full h-[230px]'>
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-2.5'>
            {/* 총합 */}
            <div className='text-gray-44 text-[36px] font-bold leading-[42px]'>
              {props.totalUsage.totalCount.toLocaleString()}
            </div>
            <div className={`${TextType.h4} flex items-center h-[18px]`}>
              <div className='text-gray-99 shrink-0'>전일 대비</div>

              {props.totalUsage.increase > 0 ? (
                // 증가
                <>
                  <div className='mx-1 text-state-red shrink-0'>{props.totalUsage.increase}%</div>
                  <img className='w-[18px] h-[18px]' src={ICON.ARROW_INCREASE} alt='증가' />
                </>
              ) : props.totalUsage.increase < 0 ? (
                // 감소
                <>
                  <div className='mx-1 text-state-blue shrink-0'>{props.totalUsage.increase}%</div>
                  <img className='w-[18px] h-[18px]' src={ICON.ARROW_DECREASE} alt='감소' />
                </>
              ) : (
                <div className={`${TextType.h4} text-gray-44 shrink-0 mx-1 w-[48px] whitespace-nowrap`}>변동없음</div>
              )}
            </div>
          </div>

          {/* 범례 */}
          <Legend
            data={[
              {
                title: '키오스크',
                color: '#92D5DE',
              },
              {
                title: '모바일',
                color: '#B7B7B7',
              },
            ]}
            colorChipSize='12px'
            colorChipType='rounded-square'
            listDirection='column'
            gap='8px'
          />
        </div>

        {/* 그래프 */}
        <div className='flex flex-col items-end justify-end w-[220px] h-[212px]'>
          <Venn
            className='w-[220px] h-[212px]'
            data={[
              {
                sets: ['키오스크'],
                size: machineSize,
                // label: `${machineSize}%`,
                name: '키오스크',
              },
              {
                sets: ['모바일'],
                size: mobileSize,
                // label: `${mobileSize}%`,
                name: '모바일',
              },
              // { sets: ['키오스크', '모바일'], size: 1 },
            ]}
            {...vennConfig}
          />
        </div>
      </div>
    </DashboardSection>
  );
};
export default DashboardTotalUsage;
