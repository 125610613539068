import { AxiosError } from 'axios';
import AlertModal from 'components/common/modal/alert/AlertModal';
import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import useModal from 'hooks/common/useModal';
import useTenant from 'hooks/feature/tenant/useTenant';
import { ChangeEvent, useState } from 'react';
import useTenantToastStore from 'stores/tenantToast';
import { FLEX_CENTER } from 'styles/flex';
import { Language, MultiLangs } from 'types/feature/multi-lang/language';
import { TenantCustomField } from 'types/feature/tenant/tenant';
import SaveEditBtns from '../multi-lang/save-edit-btns/SaveEditBtns';

type Props = {
  tenantId: string;
  field: TenantCustomField;
  languageList: Language[];
  fetchTenant: () => Promise<void>;
};

const CustomFieldTenantInfo = ({ tenantId, field, languageList, fetchTenant }: Props) => {
  // state
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [customFieldContent, setCustomFieldContent] = useState<MultiLangs>({
    ko: field.content.ko,
    en: field.content.en,
    ja: field.content.ja,
    cn: field.content.cn,
    tw: field.content.tw,
  });

  // hook
  const { openAlert, openModalPopup, closeModalPopup } = useModal();
  const { updateTenantCustomField } = useTenant();

  // store
  const tenantToastStore = useTenantToastStore();

  const onClickEditBtn = () => {
    setShowEditMode(false);
    setIsEditMode(true);
  };

  const handleShowEditBtn = () => {
    if (isEditMode) return;
    setShowEditMode(true);
  };

  const handleChangeMultiLangInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setCustomFieldContent((prev: MultiLangs) => ({ ...prev, [name]: value }));
  };

  const updateContent = async () => {
    try {
      await updateTenantCustomField(field.id, {
        tenantId,
        customFieldId: field.customField.id,
        content: customFieldContent,
      });
      await fetchTenant();

      // 토스트 팝업
      tenantToastStore.setIsToastOpen(true);
      tenantToastStore.setToastPopupMessage('매장 정보가 저장되었습니다.');

      setIsEditMode(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          openModalPopup('ALERT');
        }
      }
    }
  };

  return (
    <div
      onFocus={handleShowEditBtn}
      onMouseOver={handleShowEditBtn}
      onMouseLeave={() => setShowEditMode(false)}
      onClick={onClickEditBtn}
      className={`relative flex flex-col items-end w-full h-full gap-2 py-2 px-5 ${showEditMode && 'bg-bg-f9'}`}
    >
      <div className={`flex flex-col items-center justify-end gap-2 w-full h-full`}>
        {languageList.map((language, languageIndex) => (
          <div className='flex items-center w-full h-9' key={languageIndex}>
            {/* 언어 코드 (key) */}
            <div className='flex items-center gap-1 w-28'>
              <div className={`${FLEX_CENTER.row} bg-[#999] text-white w-[22px] h-[16px] text-[11px]`}>
                {language.code}
              </div>
              <div className={`text-[12px] text-[#777] font-bold`}>
                {field.customField.title[language.code.toLowerCase()] || '-'}
              </div>
            </div>

            {/* 내용 */}
            <div className={`flex-1 w-80`}>
              {isEditMode ? (
                <input
                  className={`
                w-[100%] h-9 border border-gray-99 placeholder:text-gray-cc pl-2 placeholder:text-[13px]
                ${TextType.body1}
                `}
                  defaultValue={field.content[language.code.toLowerCase()]}
                  placeholder={`사용자 정의 항목 값을 입력해주세요.`}
                  name={language.code.toLowerCase()}
                  onChange={handleChangeMultiLangInput}
                />
              ) : (
                <div>
                  {field.content && field.content[language.code.toLowerCase()] ? (
                    <span
                      className={`text-gray-44 w-[80%] overflow-hidden text-ellipsis whitespace-nowrap ${TextType.body1}`}
                    >
                      {field.content[language.code.toLowerCase()]}
                    </span>
                  ) : (
                    <span className={`${TextType.body1} text-gray-99`}>-</span>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}

        {/* 수정 버튼 */}
        {showEditMode && (
          <div className='absolute bottom-2 right-2'>
            <img src={ICON.PENCIL_WHITE} alt='pencil' />
          </div>
        )}

        {/* 오류 발생 시 알림 노출  */}
        {openAlert && (
          <AlertModal message={'매장 정보를 저장할 수 없습니다.'} closeModal={() => closeModalPopup('ALERT')} />
        )}
      </div>

      {/* 저장, 취소 버튼 */}
      {!showEditMode && isEditMode && (
        <SaveEditBtns
          onClickCancel={() => {
            setIsEditMode(false);
          }}
          onClickSave={updateContent}
        />
      )}
    </div>
  );
};

export default CustomFieldTenantInfo;
