import React from 'react';
import './LoadingSpinner.css';
import { TextType } from 'components/common/title/Title';

type Props = {
  message?: string;
  position: 'relative' | 'absolute';
  height?: string;
};

const LoadingSpinner = ({ message, position, height = '100%' }: Props) => {
  return (
    <>
      {position === 'absolute' ? (
        // 전체 로딩
        <div className='absolute top-0 left-0 flex flex-col items-center justify-center w-screen h-screen gap-10 bg-opacity-70 bg-gray-ea'>
          <span className='loading_spinner' />
          <span className={`${TextType.body1} text-gray-cc`}>{message}</span>
        </div>
      ) : (
        // 일부 로딩
        <div
          style={{
            height,
          }}
          className='flex flex-col items-center justify-center w-full h-full gap-4 bg-opacity-70'
        >
          <span className='loading_spinner' />
          <span className={`${TextType.body1} text-gray-cc`}>{message}</span>
        </div>
      )}
    </>
  );
};
export default LoadingSpinner;
