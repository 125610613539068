import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import InnerGnb from 'components/common/layout/gnb/inner/InnerGnb';
import { TextType } from 'components/common/title/Title';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className='absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full gap-4 bg-bg-f9'>
      <InnerGnb />

      <div className='flex flex-col items-center justify-center'>
        <pre className={`${TextType.h4} text-center text-gray-44 w-full h-full`}>
          {`서버에 일시적인 오류가 발생했습니다.\n다시 시도해주세요.`}
        </pre>

        <Button
          text='다시 시도하기'
          color={ButtonColor.secondary}
          onClick={() => {
            navigate(-1);
          }}
          size={120}
        />
      </div>
    </div>
  );
};

export default ErrorPage;
