import { useApi } from 'api/useApi';
import { PAGINATION_LIMIT } from 'data/common';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { CustomBoard, CustomBoards } from 'types/feature/menu/customBoard';

const useCustomBoard = () => {
  const { api } = useApi();

  const getCustomBoards = async (customPageId: string, currentPage?: number) => {
    const result = await api.get<ApiResponse<CustomBoards>>(Endpoint.customBoards, {
      params: {
        customPageId,
        page: currentPage ?? 0,
        size: PAGINATION_LIMIT,
      },
    });

    if (result) {
      return result.data;
    }
  };

  const getCustomBoard = async (customBoardId: string) => {
    const result = await api.get<ApiResponse<CustomBoard>>(`${Endpoint.customBoards}/${customBoardId}`);

    if (result) {
      return result.data;
    }
  };

  const postCustomBoard = async (customBoard: CustomBoard) => {
    await api.post(Endpoint.customBoards, customBoard);
  };

  const deleteCustomBoard = async (customBoardId: string) => {
    await api.delete(`${Endpoint.customBoards}/${customBoardId}`);
  };

  const putCustomBoard = async (customBoardId: string, customBoard: CustomBoard) => {
    await api.put(`${Endpoint.customBoards}/${customBoardId}`, customBoard);
  };

  return {
    getCustomBoards,
    getCustomBoard,
    postCustomBoard,
    deleteCustomBoard,
    putCustomBoard,
  };
};
export default useCustomBoard;
