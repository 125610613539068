import TableCsvDownload from 'components/common/table/csv/TableCsvDownload';
import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { Fragment } from 'react';
import { MachineUsage } from 'types/feature/statistics/machineUsage';
import { MACHINE_USAGE_TABLE_HEADER } from './head/machineUsageTableHead';
import { MACHINE_USAGE_TABLE_HEADER_WITHOUT_POINT } from './head/machineUsageTableHeadWithoutPoint';

type Props = {
  machineStats: MachineUsage[];
  isAll: boolean;
  onClickDownload: () => Promise<void>;
};

const MachineUsageTable = ({ machineStats, isAll, onClickDownload }: Props) => {
  return (
    <div className='flex flex-col w-full gap-3 px-10 pt-6 pb-10 bg-white border border-gray-ea'>
      {/* 총 개수, 다운로드 버튼 */}
      <TableCsvDownload totalCount={machineStats.length} onClickDownload={onClickDownload} />

      <table className='w-full border-collapse'>
        {isAll ? (
          <CommonTableHeader tableHead={MACHINE_USAGE_TABLE_HEADER} />
        ) : (
          <CommonTableHeader tableHead={MACHINE_USAGE_TABLE_HEADER_WITHOUT_POINT} />
        )}

        {machineStats.map((data, index) => (
          <Fragment key={data.machineId}>
            <tbody>
              <tr className='h-[52px] text-[14px]'>
                {/* 순위 */}
                <td className='border-b p-2.5'>{index + 1}</td>

                {/* 기기코드 */}
                <td className='border p-2.5'>{data.machineIdSuffix}</td>

                {/* 빌딩 - 전체 빌딩일 경우만 노출 */}
                {isAll && <td className='border p-2.5'>{data.pointName.ko}</td>}

                {/* 층 */}
                <td className='border p-2.5'>{data.floorName.ko}</td>

                {/* 키오스크 사용량 */}
                <td className='border p-2.5 bg-bg-f9'>{Number(data.machineCount).toLocaleString()}</td>

                {/* 모바일 사용량 */}
                <td className='border p-2.5 bg-bg-f9'>{Number(data.mobileCount).toLocaleString()}</td>

                {/* 전체 사용량 */}
                <td className='border p-2.5'>{Number(data.totalCount).toLocaleString()}</td>

                {/* 비율 */}
                <td className='border border-r-0 p-2.5'>{data.ratio}%</td>
              </tr>
            </tbody>
          </Fragment>
        ))}
      </table>
    </div>
  );
};
export default MachineUsageTable;
