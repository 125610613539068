import { AxiosError } from 'axios';
import AlertModal from 'components/common/modal/alert/AlertModal';
import useScript from 'hooks/common/useScript';
import useSsoAuth from 'hooks/common/useSsoAuth';
import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthStore } from 'stores/auth';
import useModalStore from 'stores/modal';
import { isLocalEnv } from 'utils/common/commonUtils';
import Layout from './components/common/layout/Layout';

function App() {
  // hook
  useScript();
  const { signIn, refreshToken, logout } = useSsoAuth();

  // store
  const modalStore = useModalStore();
  const authStore = useAuthStore();

  // state
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openExpireTokenAlert, setExpireTokenAlert] = useState(false);

  /**
   * 토큰을 1분 단위로 refresh 처리하기 위한 interval 객체
   * @function startTokenRefreshInterval
   * @returns {void}
   */
  const startTokenRefreshInterval = useCallback((): (() => void) => {
    const intervalId = setInterval(async () => {
      try {
        await refreshToken();

        if (isLocalEnv) {
          console.log('Token refreshed successfully.');
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        clearInterval(intervalId);
        setIsLoggedIn(false);
      }
    }, 60 * 1000); // 1분 단위 refresh
    // unmount 시 인터벌 객체 clear
    return () => clearInterval(intervalId);
  }, [refreshToken]);

  /**
   * 애플리케이션 시작 시 인증을 초기화합니다.
   * local 환경에서는 signIn API를 호출하고,
   * 그 외 환경에서는 refreshToken API를 호출합니다.
   * @function initializeAuth
   * @returns {Promise<void>}
   */
  const initializeAuth = async () => {
    // local 환경
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      try {
        // TODO: local 테스트용 계정 하드 코딩
        await signIn({ username: 'platformcell', password: 'dabeeo0228!@' });

        setIsLoggedIn(true);

        startTokenRefreshInterval();
      } catch (signInError) {
        console.error('Sign-in error:', signInError);
        setIsLoggedIn(false);
      }
    }
    // local 아닌 환경
    else {
      try {
        await refreshToken();

        setIsLoggedIn(true);

        startTokenRefreshInterval();
      } catch (refreshTokenError) {
        if (
          refreshTokenError instanceof AxiosError &&
          refreshTokenError.response &&
          refreshTokenError.response.status >= 400 &&
          refreshTokenError.response.status < 500
        ) {
          authStore.setIsTokenExpired(true);
        }
        setIsLoggedIn(false);
      }
    }
  };

  /**
   * 토큰 만료 alert close 콜백 이벤트로 로그아웃 처리를 수행한다.
   * @async
   * @function onCloseExpireTokenAlert
   * @returns {Promise<void>}
   */
  const onCloseExpireTokenAlert = async (): Promise<void> => {
    authStore.setIsTokenExpired(false);
    await logout();
  };

  const onCloseWorkspaceModal = async () => {
    modalStore.setOpenWorkspaceModal(false);
    await logout();
  };

  useEffect(() => {
    initializeAuth();
  }, []);

  // token 만료 alert open
  useEffect(() => {
    setExpireTokenAlert(authStore.isTokenExpired);
  }, [authStore.isTokenExpired]);
  return (
    <>
      {isLoggedIn && (
        <Layout>
          <Outlet />
        </Layout>
      )}

      {/* 워크스페이스 없을 경우 나오는 modal */}
      {modalStore.isOpenWorkspaceModal && (
        <AlertModal message={modalStore.workspaceModalText} closeModal={onCloseWorkspaceModal} />
      )}

      {/** 토큰 만료 alert modal */}
      {openExpireTokenAlert && (
        <AlertModal
          message={'장기간 활동하지 않아 로그아웃되었습니다.\n다시 로그인해 주세요.'}
          maxZIndex={true}
          buttonText='확인'
          closeModal={onCloseExpireTokenAlert}
        />
      )}
    </>
  );
}

export default App;
