import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GradientLoader from 'components/common/loading/gradient/GradientLoader';
import AlertModal from 'components/common/modal/alert/AlertModal';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import { ICON } from 'constants/icons';
import { SERVER_DATE_FORMAT } from 'data/common';
import dayjs from 'dayjs';
import useStatistics from 'hooks/feature/stats/useStatistics';
import useStatsDownload from 'hooks/feature/stats/useStatsDownload';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TotalUsagePerDate, TotalUsagePerDayOfWeek, TotalUsagePerTime } from 'types/feature/statistics/totalUsage';
import { convertDateToString } from 'utils/date/dateUtils';
import { handle400Error } from 'utils/error/handle400Error';
import BuildingFilter from './filter/building/BuildingFilter';
import RangeFilter from './filter/range/RangeFilter';
import GraphTypeFilter from './filter/type/GraphTypeFilter';
import DateUsageGraph from './graph/date/DateUsageGraph';
import DayOfWeekUsageGraph from './graph/day-of-week/DayOfWeekUsageGraph';
import TimeUsageGraph from './graph/time/TimeUsageGraph';
import TotalUsageStatsSection from './section/TotalUsageStatsSection';
import DateUsageTable from './table/date/DateUsageTable';
import DayOfWeekUsageTable from './table/day-of-week/DayOfWeekUsageTable';
import TimeUsageTable from './table/time/TimeUsageTable';
import NoData from 'components/common/table/no-data/NoData';

export type TotalUsageFilterType = 'date' | 'day-of-week' | 'time';

// ! 날짜는 한국 시간 기준
const TotalUsageStatsContainer = () => {
  const [selectedPointOption, setSelectedPointOption] = useState<CommonOptionType>();
  const [selectedTypeOption, setSelectedTypeOption] = useState<CommonOptionType>({ label: '일자별', value: 'date' });

  // 실시간 데이터 지원하지 않음
  const yesterday = dayjs().subtract(1, 'day');

  const [selectedStart, setSelectedStart] = useState<Date>(yesterday.subtract(30, 'day').toDate());
  const [selectedEnd, setSelectedEnd] = useState<Date>(yesterday.toDate());

  const [dateUsageList, setDateUsageList] = useState<TotalUsagePerDate[]>([]);
  const [dayUsageList, setDayUsageList] = useState<TotalUsagePerDayOfWeek[]>([]);
  const [timeUsageList, setTimeUsageList] = useState<TotalUsagePerTime[]>([]);

  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { getUsagePerDate, getUsagePerDayOfWeek, getUsagePerTime } = useStatistics();
  const { downloadTotalUsagePerDate, downloadTotalUsagePerDayOfWeek, downloadTotalUsagePerTime } = useStatsDownload();
  const { workspaceId } = useParams();

  // 전체 사용량 api 호출
  const refetchTotalUsage = async (type: TotalUsageFilterType, pointId?: string) => {
    if (workspaceId) {
      // 일자별 전체 사용량 조회 api 호출
      if (type === 'date') {
        // 시작일과 종료일의 차이가 최대 31일을 넘었을 경우
        const gapBetweenStartAndEnd = dayjs(selectedEnd).diff(dayjs(selectedStart), 'day');

        // 시작일, 종료일 초기화 해주고 알림을 준다.
        if (gapBetweenStartAndEnd > 30) {
          // 기간 초기화
          refreshStartAndEndDates();

          // 일자별 전체 사용량 조회 api 호출
          const response = await getUsagePerDate(
            workspaceId,
            selectedPointOption?.value,
            yesterday.subtract(30, 'day').format(SERVER_DATE_FORMAT),
            yesterday.format(SERVER_DATE_FORMAT),
          );

          setDateUsageList(response);

          setAlertMessage('일자별 검색은 최대 30일까지 가능합니다.');
          setOpenAlert(true);

          return;
        }

        const response = await getUsagePerDate(
          workspaceId,
          pointId ?? selectedPointOption?.value,
          convertDateToString(selectedStart),
          convertDateToString(selectedEnd),
        );

        setDateUsageList(response);
      }
      // 요일별 전체 사용량 조회 api 호출
      else if (type === 'day-of-week') {
        const response = await getUsagePerDayOfWeek(
          workspaceId,
          pointId ?? selectedPointOption?.value,
          convertDateToString(selectedStart),
          convertDateToString(selectedEnd),
        );

        setDayUsageList(response);
      }
      // 시간별 전체 사용량 조회 api 호출
      else if (type === 'time') {
        const response = await getUsagePerTime(
          workspaceId,
          pointId ?? selectedPointOption?.value,
          convertDateToString(selectedStart),
          convertDateToString(selectedEnd),
        );

        setTimeUsageList(response);
      }
    }
  };

  // 타입 변경 시
  const onChangeType = async (option: CommonOptionType) => {
    setSelectedTypeOption(option);

    await refetchTotalUsage(option.value as TotalUsageFilterType, selectedPointOption?.value);
  };

  // 검색 버튼 클릭 시
  const onClickSearch = async () => {
    try {
      if (!workspaceId) {
        return;
      }

      setLoading(true);

      // 일자별 전체 사용량 조회 api 호출
      if (selectedTypeOption.value === 'date') {
        const response = await getUsagePerDate(
          workspaceId,
          selectedPointOption?.value,
          convertDateToString(selectedStart),
          convertDateToString(selectedEnd),
        );

        setDateUsageList(response);
      }
      // 요일별 전체 사용량 조회 api 호출
      else if (selectedTypeOption.value === 'day-of-week') {
        const response = await getUsagePerDayOfWeek(
          workspaceId,
          selectedPointOption?.value,
          convertDateToString(selectedStart),
          convertDateToString(selectedEnd),
        );

        setDayUsageList(response);
      }
      // 시간별 전체 사용량 조회 api 호출
      else if (selectedTypeOption.value === 'time') {
        const response = await getUsagePerTime(
          workspaceId,
          selectedPointOption?.value,
          convertDateToString(selectedStart),
          convertDateToString(selectedEnd),
        );

        setTimeUsageList(response);
      }

      setLoading(false);
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlert(true);
        setAlertMessage('통계 데이터를 불러올 수 없습니다.');
        setLoading(false);
      });
    }
  };

  // 기간 초기화
  const refreshStartAndEndDates = () => {
    setSelectedStart(yesterday.subtract(30, 'day').toDate());
    setSelectedEnd(yesterday.toDate());
  };

  // 초기화 클릭 시 기간 초기화
  const onClickRefresh = async () => {
    if (!workspaceId) {
      return;
    }

    setLoading(true);
    setSelectedTypeOption({ label: '일자별', value: 'date' });
    refreshStartAndEndDates();

    // 일자별 전체 사용량 조회 api 호출
    const response = await getUsagePerDate(
      workspaceId,
      selectedPointOption?.value,
      yesterday.subtract(30, 'day').format(SERVER_DATE_FORMAT),
      yesterday.format(SERVER_DATE_FORMAT),
    );

    setDateUsageList(response);
    setLoading(false);
  };

  // 일자별 전체 사용량 다운로드
  const onClickDownloadDate = async () => {
    await downloadTotalUsagePerDate({
      workspaceId: workspaceId || '',
      startDate: convertDateToString(selectedStart),
      endDate: convertDateToString(selectedEnd),
      pointId: selectedPointOption?.value,
    });
  };

  // 요일별 전체 사용량 다운로드
  const onClickDownloadDayOfWeek = async () => {
    await downloadTotalUsagePerDayOfWeek({
      workspaceId: workspaceId || '',
      startDate: convertDateToString(selectedStart),
      endDate: convertDateToString(selectedEnd),
      pointId: selectedPointOption?.value,
    });
  };

  // 시간별 전체 사용량 다운로드
  const onClickDownloadTime = async () => {
    await downloadTotalUsagePerTime({
      workspaceId: workspaceId || '',
      startDate: convertDateToString(selectedStart),
      endDate: convertDateToString(selectedEnd),
      pointId: selectedPointOption?.value,
    });
  };

  return (
    <div className='flex flex-col gap-6'>
      {/* 빌딩 필터 */}
      <BuildingFilter
        selectedPointOption={selectedPointOption}
        setSelectedPointOption={setSelectedPointOption}
        selectedTypeOption={selectedTypeOption}
        refetchTotalUsage={refetchTotalUsage}
        setLoading={setLoading}
      />

      <div className='flex'>
        {/* 일자별 / 요일별 / 시간대별 */}
        <GraphTypeFilter selected={selectedTypeOption} onChangeType={onChangeType} />

        {/* 검색 기간 필터 */}
        <RangeFilter
          selectedStart={selectedStart}
          selectedEnd={selectedEnd}
          setSelectedStart={setSelectedStart}
          setSelectedEnd={setSelectedEnd}
          selectedType={selectedTypeOption.value}
        />

        <div className='flex gap-2.5'>
          {/* 검색 버튼 */}
          <Button onClick={onClickSearch} color={ButtonColor.primary} text='검색' size={80} />

          {/* 초기화 버튼 */}
          <Button
            onClick={onClickRefresh}
            color={ButtonColor.secondary}
            text='초기화'
            size={80}
            iconPath={ICON.REFRESH_BK}
          />
        </div>
      </div>

      {loading ? (
        <div className='w-full h-[404px] bg-white border border-gray-ea'>
          <GradientLoader />
        </div>
      ) : // ! 일자별
      selectedTypeOption.value === 'date' ? (
        <TotalUsageStatsSection
          graph={<DateUsageGraph totalUsageList={dateUsageList} />}
          table={<DateUsageTable totalUsageList={dateUsageList} />}
          totalUsageList={dateUsageList}
          onClickDownload={onClickDownloadDate}
        />
      ) : // ! 요일별
      selectedTypeOption.value === 'day-of-week' ? (
        <TotalUsageStatsSection
          graph={<DayOfWeekUsageGraph totalUsageList={dayUsageList} />}
          table={<DayOfWeekUsageTable totalUsageList={dayUsageList} />}
          totalUsageList={dayUsageList}
          onClickDownload={onClickDownloadDayOfWeek}
        />
      ) : (
        // ! 시간별
        <TotalUsageStatsSection
          graph={<TimeUsageGraph totalUsageList={timeUsageList} />}
          table={<TimeUsageTable totalUsageList={timeUsageList} />}
          totalUsageList={timeUsageList}
          onClickDownload={onClickDownloadTime}
        />
      )}

      {openAlert && (
        <AlertModal
          message={alertMessage}
          closeModal={() => {
            setOpenAlert(false);
          }}
        />
      )}
    </div>
  );
};
export default TotalUsageStatsContainer;
