import { ICON } from 'constants/icons';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { TextType } from '../title/Title';

// TODO: id 추가
export type CommonOptionType = {
  label: string;
  value: string;
  data?: any;
  iconUrl?: string;
};

type Props = {
  options: CommonOptionType[];
  onChangeSelect: (option: CommonOptionType) => void;
  width?: keyof typeof SelectBoxWidth;
  height?: string;
  selected?: CommonOptionType;
  disabled?: boolean;
  direction?: 'UP' | 'DOWN';
  textType?: TextType;
};

const SelectBoxWidth = {
  80: 'w-[80px]',
  100: 'w-[100px]',
  120: 'w-30',
  160: 'w-40',
  200: 'w-[200px]',
  240: 'w-[240px]',
  full: 'w-full',
};

const CommonSelectBox = ({
  selected,
  disabled,
  options,
  onChangeSelect,
  width = 120,
  direction = 'DOWN',
  height = 'h-9',
  textType = TextType.h4,
}: Props) => {
  const [openSelectBox, setOpenSelectBox] = useState(false);
  const [currentOption, setCurrentOption] = useState(selected || options[0]);

  const toggleSelectBox = () => {
    if (disabled) {
      return;
    }

    setOpenSelectBox(!openSelectBox);
  };

  const handleClickOption = (option: CommonOptionType) => {
    toggleSelectBox();
    setCurrentOption(option);

    onChangeSelect(option);
  };

  const handleCurrentOption = () => {
    if (selected) {
      setCurrentOption(selected);
    } else {
      setCurrentOption(options[0]);
    }
  };

  useEffect(() => {
    handleCurrentOption();
  }, [selected, options]);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenSelectBox(false)}>
      <div className='relative'>
        {/* 선택된 옵션 */}
        <button
          disabled={disabled}
          className={`${
            disabled
              ? 'bg-bg-f9 outline-gray-cc opacity-50'
              : 'bg-white cursor-pointer outline-gray-99 hover:outline-gray-44'
          } ${SelectBoxWidth[width]} ${height} outline outline-1 flex items-center justify-between px-2`}
          onClick={toggleSelectBox}
        >
          <div className={`${textType} ${disabled ? 'text-gray-cc' : 'text-gray-44'} flex items-center gap-1`}>
            {selected ? (
              <>
                {selected?.iconUrl && <img className='w-[18px]' src={selected.iconUrl ?? ''} alt='' />}
                <span>{selected?.label}</span>
              </>
            ) : (
              <>
                {options[0]?.iconUrl && <img className='w-[18px]' src={options[0].iconUrl ?? ''} alt='' />}
                <span>{options[0]?.label}</span>
              </>
            )}
          </div>

          <div>
            {disabled ? (
              <img src={ICON.ARROW} alt='disable-arrow' />
            ) : (
              <img src={openSelectBox ? ICON.ARROW_UP_ACTIVE : ICON.ARROW_DOWN_ACTIVE} alt='arrow' />
            )}
          </div>
        </button>

        {openSelectBox && (
          <ul
            className={`${direction === 'UP' ? 'bottom-0' : 'left-0 top-0'} 
            z-30 list-none cursor-pointer absolute outline outline-1 outline-gray-99 p-0 bg-white shadow-mdDrop max-h-[144px] overflow-y-auto
            ${SelectBoxWidth[width]} 
            `}
          >
            {options.map(option => (
              <li
                key={option.value}
                id={option.value}
                className={`flex items-center px-2 ${height} hover:bg-state-blue_bg`}
                onClick={handleClickOption.bind(this, option)}
                value={option.data}
              >
                <div className='flex items-center gap-1'>
                  {option.iconUrl && <img className='w-[18px]' src={option.iconUrl} alt='' />}
                  <span
                    className={`${
                      option.value === currentOption.value
                        ? `${textType} !font-bold text-primary-DA_Blue_light`
                        : textType
                    } `}
                  >
                    {option.label}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default CommonSelectBox;
