import MapDraw from '../mapDraw';

export const showPoiLocation = async (floor: string, position: { x: number; y: number }) => {
  MapDraw.map.markers.clearAll();

  await MapDraw.map.markers.set({
    marker: [
      {
        x: Math.abs(Number(position.x)),
        y: Math.abs(Number(position.y)),
        iconOption: {
          positionZ: 50,
          anchor: {
            x: 0.5,
            y: 0.5,
          },
          iconUrl: '/assets/icon/arrive.png',
          width: 14,
          height: 40,
          visibleIcon: true,
        },
        floorId: floor,
      },
    ],
  });
};
