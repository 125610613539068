import { TableHeader } from 'types/common/tableHeader';

export const CATEGORY_USAGE_TABLE_HEADER_WITHOUT_POINT: TableHeader = [
  {
    label: '순위',
    width: 70,
    direction: 'left',
  },
  {
    label: '카테고리명',
    width: 600,
    direction: 'left',
  },
  {
    label: '검색수',
    width: 200,
    direction: 'left',
  },
  {
    label: '점유율',
    width: 200,
    direction: 'left',
  },
];
