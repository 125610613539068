import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import Switch from 'components/common/switch/Switch';
import Title, { TextType } from 'components/common/title/Title';
import useModal from 'hooks/common/useModal';
import usePoint from 'hooks/feature/point/usePoint';
import { ClosedDays, Point } from 'types/feature/workspace/workspace';
import HolidayForm from './HolidayForm';

type Props = {
  point: Point;
  handlePoint: (point: Point) => void;
  fetchPoints: () => Promise<Point[] | undefined>;
};

const Holiday = ({ point, fetchPoints, handlePoint }: Props) => {
  const { patchPoint } = usePoint();
  const { openModalPopup, openConfirm, openAlert, closeModalPopup } = useModal();

  const handleChangeOption = (closedDaysUsed: boolean) => {
    handlePoint({
      ...point,
      operation: {
        ...point.operation,
        closedDaysUsed,
      },
    });
  };

  const updateClosedDays = async () => {
    try {
      const request: ClosedDays = {
        ...point.operation,
        closedDays: point.operation.closedDaysUsed ? point.operation.closedDays : [],
      };
      await patchPoint(point.id, request);
      await fetchPoints();
      closeModalPopup('CONFIRM');
    } catch (error) {
      if (error instanceof AxiosError && error.status === 400) {
        closeModalPopup('CONFIRM');
        openModalPopup('ALERT');
      }
    }
  };

  const props = {
    point,
    handlePoint,
  };

  return (
    <>
      <Title text='휴무일 설정' textType={TextType.h3_bold} />
      <div className='flex flex-col gap-6'>
        <div className='flex items-center gap-2.5'>
          <FormControl
            name='휴무일 사용여부'
            required
            control={
              <Switch
                type='USE'
                color='BLUE'
                value={point.operation.closedDaysUsed}
                onChangeOption={handleChangeOption}
              />
            }
          />
        </div>
        {point.operation.closedDaysUsed && (
          <FormControl name='날짜 선택' required control={<HolidayForm {...props} />} />
        )}
      </div>
      <div className='flex justify-end'>
        <Button text='저장' color={ButtonColor.point} size={80} onClick={() => openModalPopup('CONFIRM')} />
      </div>

      {openConfirm && (
        <ConfirmModal
          message='저장 하시겠습니까?'
          onClickCancel={() => closeModalPopup('CONFIRM')}
          onClickOk={updateClosedDays}
        />
      )}

      {openAlert && <AlertModal message='오류가 발생했습니다.' closeModal={() => closeModalPopup('ALERT')} />}
    </>
  );
};

export default Holiday;
