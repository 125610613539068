import IMAGE21 from 'assets/guide/image/image21.png';
import IMAGE22 from 'assets/guide/image/image22.png';
import IMAGE23 from 'assets/guide/image/image23.png';
import IMAGE24 from 'assets/guide/image/image24.png';
import ImageWrapper from 'components/guide/image/Image';
import Title from 'components/guide/title/Title';
import { LIST_STYLE } from 'data/guide/ui';
import React from 'react';

const StoreGuide = () => {
  return (
    <div>
      <Title title='매장 관리' />

      <div className='flex flex-col gap-20'>
        <section className='flex flex-col gap-7'>
          <p id='store' className='text-[20px] font-semibold'>
            6-1. 매장 정보 확인
          </p>
          <ImageWrapper imageUrl={IMAGE21} />
          <ul className={LIST_STYLE}>
            <li>{`1) 지도에서 POI를 클릭하시면 2) 관련 정보를 확인할 수 있습니다.`}</li>
            <li>{`3) 언어선택 버튼을 통해 지도에 노출되는 언어를 변경할 수 있으며, 4) 층 버튼을 통해 다른 층 지도를 확인할 수 있습니다`}</li>
          </ul>
        </section>

        <section className='flex flex-col gap-7'>
          <p id='set-use-store' className='text-[20px] font-semibold'>
            6-2. 매장 사용유무 설정
          </p>
          <ImageWrapper imageUrl={IMAGE22} />
          <ul className={LIST_STYLE}>
            <li>{`1) 사용 또는 미사용 선택을 통해 키오스크에서 해당 매장 노출여부를 설정할 수 있습니다.`}</li>
            <li>
              오픈준비중 또는 단순 정보성 POI인 경우 미사용 처리하시면 지도에서 선택해도 정보성 팝업이 노출되지
              않습니다.
            </li>
          </ul>
        </section>

        <section className='flex flex-col gap-7'>
          <p id='set-popup' className='text-[20px] font-semibold'>
            6-3. 팝업 타입 설정
          </p>
          <ImageWrapper imageUrl={IMAGE23} />
          <ul className={LIST_STYLE}>
            <li>
              {`1) 팝업 타입은 기본형과 축약형 두가지를 제공하고 있습니다.`}
              <ul className='pl-5 list-disc'>
                <li>기본형의 경우 입력한 모든 정보가 정보성 팝업에 노출됩니다.</li>
                <li>축약형의 경우 타이틀과 상세이미지, 층, 카테고리만 정보성 팝업에 노출됩니다.</li>
              </ul>
            </li>
          </ul>
        </section>

        <section className='flex flex-col gap-7'>
          <p id='set-store' className='text-[20px] font-semibold'>
            6-4. 매장 정보 입력
          </p>
          <div className='w-1/2'>
            <ImageWrapper imageUrl={IMAGE24} />
          </div>
          <ul className={LIST_STYLE}>
            <li>
              매장 정보는 기본적으로 타이틀, 상세설명, 목록이미지, 상세이미지가 제공되며, 타이틀과 상세설명의 경우
              다국어 설정이 가능합니다.
              <ul className='pl-5 list-decimal'>
                <li>정보 영역에 마우스 호버 시 개별 수정이 가능합니다. </li>
                <li>파일 선택 또는 드래그 방식으로 이미지 업로드가 가능합니다.</li>
              </ul>
            </li>
          </ul>
          <div className='flex flex-col w-2/3 gap-1 p-5 bg-gray-100 rounded-sm'>
            <b className='text-[#666]'>[첨부파일 조건]</b>
            <ul className='text-[#666] text-[14px] leading-6 '>
              <li>파일확장자는 png, jpeg, jpg만 가능합니다.</li>
              <li>최적사이즈는 UHD 기준 목록이미지 360*360, 상세이미지 1240*960입니다.</li>
              <li>이미지 용량은 10mb이하만 가능합니다.</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default StoreGuide;
