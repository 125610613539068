import { TextType } from 'components/common/title/Title';
import React from 'react';

type DashBoardThemeType = {
  [key: string]: {
    bg: string;
    text: string;
  };
};

const DashBoardTheme: DashBoardThemeType = {
  DEFAULT: {
    bg: 'bg-white',
    text: 'text-gray-44',
  },
  DISABLED: {
    bg: 'bg-bg-f1',
    text: 'text-gray-44',
  },
  GREEN: {
    bg: 'bg-state-green_bg',
    text: 'text-state-green',
  },
  RED: {
    bg: 'bg-state-red_bg',
    text: 'text-state-red',
  },
};

type DashBoardThemeColor = keyof typeof DashBoardTheme;

type Props = {
  theme?: DashBoardThemeColor;
  label: string;
  value: number;
};

const DashboardState = ({ theme = 'DEFAULT', label, value }: Props) => {
  return (
    <div
      className={`flex justify-between items-center flex-1 p-10 rounded-[20px] h-20 
      ${DashBoardTheme[theme].bg}
    
      `}
    >
      <span className={`${TextType.h5_bold} ${DashBoardTheme[theme].text} shrink-0`}>{label}</span>
      <span className={`${TextType.h1} ${DashBoardTheme[theme].text} shrink-0`}>{value}</span>
    </div>
  );
};
export default DashboardState;
