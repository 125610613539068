import Checkbox from 'components/common/checkbox/Checkbox';
import FormControl from 'components/common/form-control/FormControl';
import SelectBox, { OptionType } from 'components/common/select/SelectBox';
import { CHANGED_STATUS_OPTIONS } from 'data/select-box/options';
import { ChangeEvent } from 'react';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  onChangeStatus: (option: OptionType) => Promise<void>;
  onChangePoint: (option: OptionType) => Promise<void>;
  onChangeFloor: (option: OptionType) => Promise<void>;
  onChangeViewAll: (e: ChangeEvent<HTMLInputElement>) => void;
  viewAll: boolean;
  points: Point[];
  allFloorOptions: OptionType[];
};

const ChangedInfoFilter = ({
  onChangeStatus,
  onChangePoint,
  onChangeFloor,
  onChangeViewAll,
  viewAll,
  points,
  allFloorOptions,
}: Props) => {
  const pointOptions = points.map(point => ({
    label: point.name.ko || '',
    value: point.id || '',
    data: point,
  }));

  return (
    <div className='flex w-full gap-5 px-6 h-[80px] border-b items-center'>
      <FormControl
        name='상태'
        labelSize='none'
        controlWidth='w-[120px]'
        control={<SelectBox options={CHANGED_STATUS_OPTIONS} onChangeSelect={onChangeStatus} />}
      />

      <FormControl
        name='빌딩'
        labelSize='none'
        controlWidth='w-[120px]'
        control={<SelectBox options={pointOptions} onChangeSelect={onChangePoint} />}
      />

      <FormControl
        name='층'
        labelSize='none'
        controlWidth='w-[120px]'
        control={
          <SelectBox
            options={allFloorOptions}
            placeholder={allFloorOptions[0]?.label || ''}
            onChangeSelect={onChangeFloor}
          />
        }
      />

      <Checkbox label='전체보기' onChange={onChangeViewAll} checked={viewAll} />
    </div>
  );
};
export default ChangedInfoFilter;
