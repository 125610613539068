import { useAuthApi } from 'api/useAuthApi';
import { AxiosError } from 'axios';
import { Endpoint } from 'data/endPoint';
import { loginLocalAccess } from 'data/storage/storageKey';
import { useAuthStore } from 'stores/auth';
import { ApiResponse } from 'types/api/response';
import { AuthTokens, SignInCredentials } from 'types/common/auth';
import { redirectToIntegratedAuthUrl } from 'utils/common/commonUtils';

const useSsoAuth = () => {
  const authStore = useAuthStore();

  const { authApiInstance } = useAuthApi();

  /**
   * local 환경일 경우 통합인증 로그인 처리 및 토큰 정보 세션 저장
   * @async
   * @function signIn
   * @param {SignInCredentials} credentials 로그인 정보
   */
  const signIn = async (credentials: SignInCredentials): Promise<AuthTokens> => {
    const response = await authApiInstance.post<ApiResponse<AuthTokens>>(Endpoint.ssoSignIn, credentials);
    const { access_token, refresh_token } = response.data;

    sessionStorage.setItem(loginLocalAccess, access_token);

    return { access_token, refresh_token };
  };

  /**
   * 토큰 갱신 처리 및 토큰 정보 세션 저장
   * @async
   * @function refreshToken
   * @returns {Promise<AuthTokens>} 토큰 데이터를 포함한 응답 객체
   */
  const refreshToken = async (): Promise<AuthTokens> => {
    try {
      const response = await authApiInstance.post<ApiResponse<AuthTokens>>(Endpoint.ssoRefreshToken);
      const { access_token, refresh_token } = response.data;

      sessionStorage.setItem(loginLocalAccess, access_token);

      return { access_token, refresh_token };
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        authStore.setIsTokenExpired(true);
      }
      console.error('refresh token error:', error);
      throw error;
    }
  };

  /**
   * 통합인증 로그아웃 처리 후 로그인 화면으로 redirect
   * @async
   * @function logout
   * @returns {Promise<void>} 토큰 데이터를 포함한 응답 객체
   */
  const logout = async (): Promise<void> => {
    sessionStorage.removeItem(loginLocalAccess);

    await authApiInstance.post<ApiResponse<void>>(Endpoint.ssoLogout);
    redirectToIntegratedAuthUrl();
  };

  return {
    signIn,
    refreshToken,
    logout,
  };
};
export default useSsoAuth;
