import React, { RefObject, useEffect } from 'react';

type Props = {
  elementRef: RefObject<HTMLElement>;
  callback: () => void;
};

const useOutsideClick = ({ elementRef, callback }: Props) => {
  const handleClickOutsideElement = (event: MouseEvent) => {
    if (elementRef.current && !elementRef.current.contains(event.target as HTMLElement)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideElement);

    return () => {
      document.removeEventListener('click', handleClickOutsideElement);
    };
  }, []);
};

export default useOutsideClick;
