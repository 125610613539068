import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ModalContainer from 'components/common/modal/container/ModalContainer';
import Title, { TextType } from 'components/common/title/Title';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import useKeyboard from 'hooks/common/useKeyboard';
import { useParams } from 'react-router-dom';
import { ModalType } from 'types/common/modalType';
import { RoutePath } from 'types/common/paths';
import { SyncResult } from 'types/feature/sync/sync';
import { sliceDate } from 'utils/date/dateUtils';

type Props = {
  legacy: boolean;
  syncResult: SyncResult;
  closeModal: () => void;
};

const SyncResultModal = ({ legacy, syncResult, closeModal }: Props) => {
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();

  const moveToChangedInfo = () => {
    navigateToReplacedPath(RoutePath.tenantChangedInfo, workspaceId);
  };

  useKeyboard({ modalType: ModalType.confirm, closeModal: closeModal, callback: moveToChangedInfo });

  return (
    <ModalContainer>
      <div className='px-8 py-10 w-[480px] gap-6 flex flex-col'>
        <div className='h-9'>
          <Title text='지도 동기화 결과' textType={TextType.h3_bold} />
        </div>

        <table>
          <colgroup>
            <col width='240px' />
            <col width='240px' />
          </colgroup>

          <tbody className={`text-center border border-gray-22 ${TextType.h4}`}>
            <tr className={`border-b border-gray-22 h-9`}>
              <td className={`border-r border-gray-22 bg-state-blue_bg`}>동기화 진행일</td>
              <td className='font-semibold'>{sliceDate(syncResult.synchronizedDate)}</td>
            </tr>

            <tr className={`border-b border-gray-22 h-9`}>
              <td className={`border-r border-gray-22 bg-state-blue_bg`}>변경된 지도 버전</td>
              <td className='font-semibold'>{syncResult.mapVersion}v</td>
            </tr>

            {/* legacy 인 경우만 노출 */}
            {legacy && (
              <tr className={` h-9`}>
                <td className={`border-r border-gray-22 bg-state-blue_bg`}>변경된 매장(POI) 수</td>
                <td className='font-semibold'>{syncResult.changedTenantCount}개</td>
              </tr>
            )}
          </tbody>
        </table>

        <Border />

        <div className='flex items-center justify-center w-full gap-2.5'>
          <Button onClick={closeModal} color={ButtonColor.secondary} size={80} text='취소' textStyle={TextType.body2} />

          {/* legacy 인 경우만 노출 */}
          {legacy && (
            <Button
              onClick={moveToChangedInfo}
              color={ButtonColor.primary}
              size={128}
              text='변경 정보 관리로 이동'
              textStyle={TextType.body2}
              // disabled // TODO:최신버전인 경우 비활성
            />
          )}
        </div>
      </div>
    </ModalContainer>
  );
};
export default SyncResultModal;
