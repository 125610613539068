import { IMAGES } from 'constants/images';
import React from 'react';

type Props = {
  message: string;
};

const LoadingBackground = ({ message }: Props) => {
  return (
    <div className='w-screen h-screen fixed top-0 left-0 z-50 inset-y-0 bg-gray-99/[0.5] flex flex-col justify-center items-center gap-4'>
      <div>
        <img src={IMAGES.LOADING} alt='loading' />
      </div>

      <div className='text-h2 font-bold text-white'>{message}</div>
    </div>
  );
};

export default LoadingBackground;
