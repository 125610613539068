import { ICON } from 'constants/icons';
import React from 'react';
import { TextType } from '../title/Title';

type Props = {
  message: string;
};

const SaveGuideDescription = ({ message }: Props) => {
  return (
    <div className='flex items-center gap-2.5'>
      <img src={ICON.INFO_RED} alt='info' />
      <div className={`${TextType.h4} text-primary-DA_Red`}>{message}</div>
    </div>
  );
};

export default SaveGuideDescription;
