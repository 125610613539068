import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMapStore from 'stores/map';
import { RoutePath } from 'types/common/paths';
import { getTimeStamp } from 'utils/date/timeStamp';
import MapDraw from 'utils/map/mapDraw';

/**
 * dabeeomaps api cache 무효화를 위해 동적으로 script를 생성해서 넣어주는 훅
 */
const useScript = () => {
  const navigate = useNavigate();

  const mapStore = useMapStore();

  const createMapSdkScript = () => {
    const CURRENT_DATE_TIME_FORMAT = 'YYYYMMDDHHmmss';
    const script = document.createElement('script');

    script.async = true;
    script.type = 'application/javascript';

    const scriptUrl = `${process.env.REACT_APP_MAP_API}?vdt=${getTimeStamp(CURRENT_DATE_TIME_FORMAT)}`;

    // script src에 timeStamp 를 붙여서 넣어준다.
    if (process.env.REACT_APP_MAP_API) {
      script.src = scriptUrl;
    }

    // script 로드 시
    script.onload = () => {
      mapStore.setLoadMapScript(true);
      MapDraw.getInstance();
    };

    // 에러 발생 시
    script.onerror = () => {
      navigate(RoutePath.error);
    };

    document.head.appendChild(script);
  };

  useLayoutEffect(() => {
    createMapSdkScript();
  }, []);
};
export default useScript;
