import { TextType } from 'components/common/title/Title';
import { ICON } from 'constants/icons';
import { Dispatch, SetStateAction } from 'react';
import { Point } from 'types/feature/workspace/workspace';
import { convertStringToCurrentDate, convertCurrentDay } from 'utils/date/dateUtils';

type Props = {
  day: string;
  selectedDays: string[];
  setSelectedDay: Dispatch<SetStateAction<string[]>>;
  setSelectedCalendarDay: Dispatch<SetStateAction<Date[]>>;
  point: Point;
  handlePoint: (point: Point) => void;
};

const SelectedDay = ({ day, selectedDays, setSelectedDay, setSelectedCalendarDay, handlePoint, point }: Props) => {
  // 삭제
  const handleDeleteDay = () => {
    if (selectedDays.length > 0) {
      const filteredDays = selectedDays.filter(selectedDay => selectedDay !== day);
      setSelectedDay(filteredDays);

      handlePoint({
        ...point,
        operation: {
          ...point.operation,
          closedDays: filteredDays,
        },
      });

      const convertFilteredDays = filteredDays.map(day => convertStringToCurrentDate(day));
      setSelectedCalendarDay(convertFilteredDays);
    }
  };
  return (
    <div
      onClick={handleDeleteDay}
      className='flex items-center gap-1 pl-3 pr-2 rounded-full bg-[#4F74F9] h-9 shrink-0 cursor-pointer'
    >
      <div className={`text-white ${TextType.h4}`}>{convertCurrentDay(day)}</div>
      <div>
        <img src={ICON.DELETE_WHITE} alt='delete-white' />
      </div>
    </div>
  );
};

export default SelectedDay;
